import { IListProjectsDirectionEnum } from "@apacta/sdk";
import {
  DocumentDuplicateIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { EntityTable } from "~/lib/entity-ui/entity-table";
import { Currency } from "~/lib/ui/currency";
import { PageLayout } from "~/lib/ui/page-layout";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import ProjectStatusChange from "~/pages/projects/_cmp/project-status-change";
import { usePagination } from "~/lib/utils/pagination";
import { useMe } from "~/lib/auth/use-me";
import { trimString } from "~/lib/utils/utils";
import { useNavigate } from "react-router";
import { FilterGroupProjectStatus } from "~/lib/ui/filters/filter-group-project-status";
import { FilterGroupEmployees } from "~/lib/ui/filters/filter-group-employees";
import { linkToCustomer, linkToProject } from "~/lib/utils";
import { FilterGroupCustomers } from "~/lib/ui/filters/filter-group-customers";
import { CreateProjectDialog } from "./_cmp/create-project-dialog";
import { Dialog, Icon, getIcon } from "~/lib/ui";
import { Button } from "~/lib/ui/buttons/button";
import { useLocale } from "~/lib/utils/date";
import { Link } from "react-router-dom";
import { LinkedButton } from "~/lib/ui/buttons/linked-button";
import Tooltip from "~/lib/ui/tooltip";

export const CACHE_PROJECTS = "projects"; // Used to cache the list query, and invalidated by create/delete
export const DEFAULT_STATUS_ID_FILTER = "bea03f03-42ba-4f5c-91e7-4609f86e00a8"; // Open
export const MAXIMUM_DESCRIPTION_LENGTH = 200;

export default function ProjectListPage() {
  const api = useAPI();
  const [pagination, setPagination] = usePagination();
  const [projectFilters, setProjectFilters] = useTypedSearchParams<{
    isOffer?: boolean;
    status?: string;
    employees?: Array<string>;
    activities?: Array<string>;
    customer?: string;
    q?: string;
  }>();

  const { format } = useLocale();

  const { t } = useTranslation();
  const { companySettings } = useMe();
  const navigate = useNavigate();

  const defaultSortBy = companySettings.projectNumbers ? "project_number" : "created";

  return (
    <PageLayout
      title={t("common:project", { count: 2 })}
      renderActions={() => (
        <>
          <Dialog
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={PlusIcon}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
            render={({ onClose: onCloseCreateProject }) => (
              <CreateProjectDialog
                onProjectCreated={(newProjectId) => {
                  onCloseCreateProject();
                  navigate(
                    linkToProject(newProjectId, {
                      subPage: "data",
                    })
                  );
                }}
                onOpenChange={() => onCloseCreateProject()}
              />
            )}
          />
        </>
      )}
    >
      <EntityTable
        cacheKey={[CACHE_PROJECTS, projectFilters]}
        dataFn={() =>
          api.iListProjects({
            page: pagination.page,
            q: pagination.q,
            sort: pagination.sort ?? defaultSortBy,
            direction: pagination.direction ?? ("desc" as IListProjectsDirectionEnum), // not ideal, but it's sdk generators fault
            isOffer: projectFilters.isOffer,
            projectStatusId: projectFilters.status,
            activityIds: projectFilters.activities,
            employeeIds: projectFilters.employees,
            contactId: projectFilters.customer,
          })
        }
        fields={{
          number: {
            label: t("projects:fields.number"),
            href: (row) => linkToProject(row.id),
            renderColumn: (row) => (
              <span className="block font-semibold ">#{row.projectNumber}</span>
            ),
            collapse: {
              breakpoint: "md",
            },
            sortBy: "project_number",
            hideColumn: !companySettings.projectNumbers,
          },
          nameDescription: {
            label: t("common:name"),
            tdClass: "max-w-sm",
            renderColumn: (row) => (
              <div className="flex flex-row items-center justify-between">
                <div>
                  <span className="block font-semibold">
                    <Link to={linkToProject(row.id)}>{row.name}</Link>
                    {row.isRotten && (
                      <Tooltip
                        trigger={
                          <span>
                            <Icon name="warningTriangle" className="ml-2 inline" />
                          </span>
                        }
                      >
                        {t("projects:rotten_project_warning")}
                      </Tooltip>
                    )}
                  </span>
                  {row.description && (
                    <span className="mt-2 block text-gray-400">
                      {trimString(row.description, MAXIMUM_DESCRIPTION_LENGTH)}
                    </span>
                  )}
                </div>
                <LinkedButton
                  variant="secondary"
                  size="small"
                  className="ml-2"
                  linkOptions={{
                    target: "_blank",
                  }}
                  Icon={getIcon("externalLink")}
                  to={linkToProject(row.id)}
                />
              </div>
            ),
            sortBy: "name",
          },
          customer: {
            label: t("common:customer", { count: 1 }),
            href: (row) => (row.contact ? linkToCustomer(row.contact.id) : undefined),
            renderColumn: (row) =>
              row.contact ? (
                <div>
                  <span className="block">{row.contact.name}</span>
                  <span className="mt-2 block text-gray-400">{row.streetName}</span>
                </div>
              ) : (
                <div className="text-sm text-gray-400">{t("common:not_available")}</div>
              ),
            collapse: {
              on: "nameDescription",
              breakpoint: "xl",
            },
          },
          firstActivity: {
            label: t("projects:fields.first_activity", { defaultValue: "First activity" }),
            renderColumn: (row) =>
              row.firstActivityDate ? (
                format(row.firstActivityDate, {
                  excludeTime: true,
                  shortDate: true,
                })
              ) : (
                <div className="text-sm text-gray-400">{t("common:not_available")}</div>
              ),
            collapse: {
              breakpoint: "lg",
            },
            sortBy: "first_activity_date",
          },
          invoicable: {
            label: t("projects:fields.uninvoiced_amount"),
            renderColumn: (row) => (
              <Currency className={row.notInvoicedAmount > 0 ? "text-orange-600" : ""}>
                {row.notInvoicedAmount}
              </Currency>
            ),
            collapse: {
              breakpoint: "lg",
            },
            sortBy: "not_invoiced_amount",
          },
          status: {
            label: t("common:status"),
            collapse: {
              breakpoint: "sm",
              on: "nameDescription",
            },
            sortBy: "project_status_id",
            tdClass: "",
            renderColumn: (row) => (
              <ProjectStatusChange project={row} initialStatusId={row.projectStatusId} />
            ),
          },
        }}
        actions={[
          {
            icon: PencilIcon,
            label: t("common:edit"),
            mustConfirm: false,
            hideToast: true,
            onExecute: async (project) => navigate(linkToProject(project.id, { subPage: "data" })),
          },
          {
            icon: DocumentDuplicateIcon,
            label: t("common:duplicate"),
            mustConfirm: true,
            onExecute: (project) => api.duplicateProject({ projectId: project.id }),
          },
          {
            icon: TrashIcon,
            label: t("common:delete"),
            description: t("common:action_can_not_be_undone", "The action can not be undone."),
            mustConfirm: true,
            onExecute: (project) => api.iDeleteProject({ projectId: project.id }),
          },
        ]}
        filters={() => (
          <>
            <FilterGroupProjectStatus
              value={projectFilters.status}
              onConfirmSelection={(selection) => setProjectFilters("status", selection[0].id)}
              onClear={() => setProjectFilters("status", undefined)}
            />

            <FilterGroupCustomers
              value={projectFilters.customer}
              onConfirmSelection={(selectedCustomers) => {
                setProjectFilters("customer", selectedCustomers[0].id);
              }}
              onClear={() => setProjectFilters("customer", undefined)}
            />

            <FilterGroupEmployees
              value={projectFilters.employees}
              onConfirmSelection={(selectedUsers) => {
                setProjectFilters(
                  "employees",
                  selectedUsers.map((u) => u.id)
                );
              }}
              onClear={() => setProjectFilters("employees", undefined)}
            />
          </>
        )}
      />
    </PageLayout>
  );
}
