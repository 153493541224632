import { Project } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { EntityTable } from "~/lib/entity-ui";
import { i18n } from "~/lib/i18n/i18n";
import { formatDate } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";
import { Link } from "react-router-dom";
import { linkToExpense, usePagination } from "~/lib/utils";

export default function ProjectFinanceExpensesPage() {
  const { entity: project } = useOutletContext<{ entity: Project }>();
  const api = useAPI();
  const { t } = useTranslation();
  const [{ page }] = usePagination();
  const projectParams = useProjectParams();

  return (
    <>
      <EntityTable
        showSearch={false}
        dataFn={() =>
          api.expensesList({
            projectId: project.id as string,
            deliveryOrCreatedAfter: projectParams.startDate,
            deliveryOrCreatedBefore: projectParams.endDate,
            page,
            activityIds: projectParams.activities,
            isInvoiced: projectParams.isInvoiced,
          })
        }
        cacheKey={["projectExpenses", project.id as string, projectParams]}
        fields={{
          productName: {
            label: t("common:vendor_name", "Vendor name"),
            renderColumn: (row) => (
              <Link to={linkToExpense(row.id, "view")}>{row.vendor?.name}</Link>
            ),
          },
          date: {
            label: t("common:date"),
            renderColumn: (row) =>
              row.deliveryDate
                ? formatDate(row.deliveryDate, i18n.resolvedLanguage, {
                    excludeTime: true,
                  })
                : "N/A",
          },

          totalCost: {
            label: t("finance:products.total_cost", "Total cost"),
            renderColumn: (row) =>
              row.totalBuyingPrice ? formatCurrency(row.totalBuyingPrice) : "N/A",
          },
          totalPrice: {
            label: t("finance:products.total_price", "Total price"),
            renderColumn: (row) =>
              row.totalSellingPrice ? formatCurrency(row.totalSellingPrice) : "N/A",
          },
        }}
      />
    </>
  );
}
