import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  Ref,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import {
  ButtonSizes,
  ButtonVariant,
  getButtonClasses,
  getButtonIconClass,
} from "./shared/button.shared";
import { IconProp } from "../types";
import { Spinner } from "../spinner";

export const Button = forwardRef(function ButtonInner(
  props: {
    children?: ReactNode;
    variant?: ButtonVariant;
    size?: ButtonSizes;
    disabled?: boolean;
    className?: string;
    /**
     * Note: Icon should be on the left side. Chevron and the like should be built on top of this instead of adding
     */
    Icon?: IconProp;
    iconClassName?: string;
    loading?: boolean;
  } & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  forwardedRef: Ref<HTMLButtonElement>
) {
  const {
    variant,
    className,
    Icon,
    children,
    iconClassName,
    onClick,
    loading,
    size,
    ...restProps
  } = props;
  const baseIconClass = getButtonIconClass({ size });
  const [internalLoading, setInternalLoading] = useState(false);

  async function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClick) {
      setInternalLoading(true);
      // This await a promise (or creates one), then set loading to false regardless of outcome
      await Promise.resolve(onClick(e)).finally(() => {
        setInternalLoading(false);
      });
    }
  }

  const isLoading = loading ?? internalLoading;

  return (
    <button
      className={twMerge(
        getButtonClasses({ variant, disabled: restProps.disabled, size }),
        className
      )}
      type={restProps.type || "button"}
      ref={forwardedRef}
      onClick={handleClick}
      {...restProps}
      disabled={restProps.disabled || isLoading}
    >
      {isLoading && (
        <Spinner className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
      )}
      {Icon && !isLoading && (
        <Icon className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
      )}
      {children && <>{children}</>}
    </button>
  );
});
