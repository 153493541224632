import { MouseEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import { PaginationParams, usePagination } from "../utils/hooks";
import { tableCellClasses } from "./table-row";
import { TableField } from "./types";
import { Icon } from "../ui";

export function TableTH<Entity>({
  fc,
  pagination,
}: {
  fc: TableField<Entity> & { fieldKey: string };
  pagination?: PaginationParams;
}) {
  const [, setPagination] = usePagination();
  const iconClass = "inline ml-2 h-5 w-5";
  let arrow = <Icon name="chevronDown" className={iconClass} />;
  if (pagination?.sort === fc.sortBy) {
    arrow =
      pagination?.direction === "asc" ? (
        <Icon name="chevronDown" className={iconClass} />
      ) : (
        <Icon name="chevronUp" className={iconClass} />
      );
  }

  const handleSortClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    if (fc.sortBy) {
      setPagination("sort", fc.sortBy);
      if (pagination?.direction === "asc") {
        setPagination("direction", "desc");
      } else {
        setPagination("direction", "asc");
      }
    }
  };

  return (
    <th key={fc.fieldKey} scope="col" className={tableCellClasses(fc, "th")}>
      <span>
        {fc.sortBy ? (
          <a
            className={twMerge(
              "flex items-center whitespace-nowrap hover:text-primary hover:underline",
              pagination?.sort === fc.sortBy && "text-primary"
            )}
            href="#"
            onClick={handleSortClick}
          >
            {fc.label} {pagination?.sort === fc.sortBy && arrow}
          </a>
        ) : (
          fc.label
        )}
      </span>
    </th>
  );
}
