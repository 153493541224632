import { Outlet, useNavigate, useParams } from "react-router";
import { Suspense } from "react";
import { useAPI } from "~/lib/api";
import { keepPreviousData, QueryObserverBaseResult, useQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import { PageSpinner } from "~/lib/ui/page-spinner";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Product } from "@apacta/sdk";
import { getIcon } from "~/lib/ui/icons/icon";
import { Button } from "~/lib/ui";
import { useNavigator } from "~/lib/utils/use-navigator";

export type OutletContext = {
  product: Product;
  refetch: QueryObserverBaseResult<Product, unknown>["refetch"];
};

export default function ProductPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: productId } = useParams();
  const { copyToClipboard } = useNavigator();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["product", productId],
    queryFn: () => api.productsView({ productId: productId as string, includeVariants: true }),
    placeholderData: keepPreviousData,
  });
  const pageTabs = [
    {
      id: "overview",
      label: t("common:overview"),
      to: "./",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "pricing",
      label: t("projects:pricing", "Pricing"),
      to: "pricing/",
      Icon: getIcon("masterData"),
    },
  ];
  const product = data?.data;

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("common:loading")} />
      </div>
    );
  }

  return (
    <PageLayout
      title={product?.name ?? ""}
      onBackClick={() => navigate(-1)}
      renderActions={
        product?.erpId
          ? () => (
              <Button
                Icon={getIcon("clipboard")}
                className="px-2.5"
                title={t("products:copy_erp_to_clipboard")}
                variant="secondary"
                onClick={() =>
                  copyToClipboard({
                    value: product.erpId!,
                    toastTitle: t("products:copied_to_clipboard.title"),
                    toastDescription: t("products:copied_to_clipboard.description"),
                  })
                }
              ></Button>
            )
          : undefined
      }
    >
      <PageTabs tabs={pageTabs} />
      <Suspense>
        <Outlet context={{ product, refetch }} />
      </Suspense>
    </PageLayout>
  );
}
