import { OrderLinesBuilderTable } from "~/lib/ui/order-lines/table/order-lines-builder-table";
import {
  OrderLine,
  OrderLinesActionVisibilityTypes,
  OrderLinesBuilderRef,
  OrderLinesColumnVisibilityTypes,
} from "~/lib/ui/order-lines/lib/types";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { ButtonSizes } from "~/lib/ui/buttons/shared/button.shared";
import { PopoverSide } from "~/lib/ui/popover/popover";

type OrderLinesBuilderOptions = {
  isChild?: boolean;
  depth?: number;
  parentId?: string | null;
  orderLinesActionVisibility?: OrderLinesActionVisibilityTypes | undefined;
  orderLinesColumnsVisibility?: OrderLinesColumnVisibilityTypes | undefined;
  popoverOptions?: {
    allowedProductTypes?: Array<string>;
    side?: PopoverSide;
  };
};

export type OrderLinesBuilderProps = {
  lines?: Array<Partial<OrderLine>>;
  companyVatPercent?: number;
  editMode?: boolean;
  showAll?: boolean;
  overwriteSubAmount?: number;
  offerLinesRule?: string;
  options?: OrderLinesBuilderOptions;
  hideProductBundleContent?: boolean;
  maxHeight?: `max-h-${string}`;
  buttonSizes?: ButtonSizes;
};

export const OrderLinesBuilder = forwardRef(OrderLinesBuilderInner) as (
  props: OrderLinesBuilderProps & { ref?: ForwardedRef<OrderLinesBuilderRef> }
) => ReturnType<typeof OrderLinesBuilderInner>;

export function OrderLinesBuilderInner(
  {
    editMode,
    options,
    companyVatPercent,
    showAll,
    overwriteSubAmount,
    offerLinesRule,
    hideProductBundleContent,
    maxHeight,
    buttonSizes = "medium",
  }: OrderLinesBuilderProps,
  ref: ForwardedRef<OrderLinesBuilderRef>
) {
  const { orderLines, viewOrderLines } = useOrderLinesBuilder();
  const tableRef = useRef<OrderLinesBuilderRef>(null);
  const [useOverwriteValue, setUseOverwriteValue] = useState<boolean>(
    offerLinesRule === "gather_offer_lines" ||
      offerLinesRule === "show_only_product_bundles" ||
      offerLinesRule === "total_price_line"
  );

  useImperativeHandle(ref, () => ({
    getLines: () => tableRef.current?.getLines() ?? [],
    getVat: () => tableRef.current?.getVat() ?? 25,
    isModified: () => tableRef.current?.isModified() ?? false,
    isValid: () => tableRef.current?.isValid() ?? false,
    resetInitialValues: () => tableRef.current?.resetInitialValues(),
    setViewLines: (l: Array<Partial<OrderLine>>) => tableRef.current?.setViewLines(l),
  }));

  const defaultOptions: Required<OrderLinesBuilderOptions> = {
    isChild: false,
    depth: 0,
    parentId: null,
    orderLinesActionVisibility: {
      showAddProduct: true,
      showAddHours: true,
      showAddProductBundle: true,
      showAddText: true,
      showSubtotal: true,
      showVAT: true,
      showTotal: true,
      sumCostPrice: false,
    },
    orderLinesColumnsVisibility: {
      showHandle: true,
      showProduct: true,
      showQuantity: true,
      showCostPrice: true,
      showSalePrice: true,
      showDiscount: true,
      showTotal: true,
      showActions: true,
    },
    popoverOptions: {
      allowedProductTypes: undefined,
      side: undefined,
    },
  };

  const [opts, setOpts] = useState<Required<OrderLinesBuilderOptions>>({
    ...defaultOptions,
    ...options,
  });

  useEffect(() => {
    if (
      (offerLinesRule === "gather_offer_lines" ||
        offerLinesRule === "show_only_product_bundles" ||
        offerLinesRule === "total_price_line") &&
      opts
    ) {
      setOpts((prevState) => {
        return {
          ...prevState,
          orderLinesColumnsVisibility: {
            ...prevState?.orderLinesColumnsVisibility,
            showTotal: false,
            showSalePrice: false,
            showDiscount: false,
          },
        };
      });

      setUseOverwriteValue(true);
    } else {
      // reset to default instead of hard setting anything
      setOpts({ ...defaultOptions, ...options });
      setUseOverwriteValue(false);
    }
  }, [offerLinesRule]);

  return (
    <OrderLinesBuilderTable
      ref={tableRef}
      lines={editMode || showAll ? orderLines : viewOrderLines ?? []}
      editMode={editMode}
      depth={options?.depth}
      parentId={options?.parentId}
      isChild={options?.isChild}
      companyVatPercent={companyVatPercent}
      showAll={showAll}
      orderLinesColumnVisibility={opts?.orderLinesColumnsVisibility}
      orderLinesActionVisibility={opts?.orderLinesActionVisibility}
      overwriteSubAmount={useOverwriteValue ? overwriteSubAmount : undefined}
      hideProductBundleContent={hideProductBundleContent}
      maxHeight={maxHeight}
      buttonSizes={buttonSizes}
      popoverOptions={opts?.popoverOptions}
    />
  );
}
