import { createContext, ReactElement, useCallback, useState } from "react";
import { ToastNotification } from "~/lib/ui/toast";

export const ToastContext = createContext<{
  items: Array<ToastNotification>;
  add: (v: ToastNotification) => void;
  remove: (v: ToastNotification) => void;
}>({
  items: [],
  add: () => undefined,
  remove: () => undefined,
});

export const ToastProvider = ({ children }: { children: ReactElement }) => {
  const [items, setItems] = useState<Array<ToastNotification>>([]);

  const add = useCallback(
    (v: ToastNotification): void => {
      v.id = new Date().getTime().toString();
      setItems((prev) => [...prev, v]);
    },
    [setItems]
  );

  const remove = useCallback(
    (i: ToastNotification): void => {
      setItems((prev) => {
        const newItems = [...prev];
        return newItems.filter((item) => item.id !== i.id);
      });
    },
    [setItems, items]
  );

  return <ToastContext.Provider value={{ items, add, remove }}>{children}</ToastContext.Provider>;
};
