import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { Suspense, useState } from "react";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { DeleteUserRequest, EditUserOperationRequest } from "@apacta/sdk";
import { useToasts } from "~/lib/toast/use-toasts";
import { ChangeUserPasswordModal } from "~/pages/employees/_cmp/change-user-password-modal";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { twMerge } from "tailwind-merge";
import { useToastOnError } from "~/lib/utils/hooks";
import { Dialog, getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";

export default function UserPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: userId } = useParams();
  const { show: addToast } = useToasts();
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["user", userId],
    queryFn: () => api.viewUser({ userId: userId as string }),
  });

  const [passwordChangeOpen, setPasswordChangeOpen] = useState<boolean>(false);

  useToastOnError(error);

  const user = data?.data;
  const userDelete = useMutation({
    mutationFn: (args: DeleteUserRequest) => api.deleteUser(args),
    onSuccess: async () => {
      addToast({
        title: t("users:toast.delete.title", "Delete user?"),
        description: t("users:toast.delete.description"),
        Icon: getIcon("success"),
        timeout: 5000,
      });
      await queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      navigate("/users", { state: { user_deleted: true } });
    },
  });

  const resendWelcomeSms = useMutation({
    mutationFn: () =>
      api.resendWelcomeSms({
        userId: userId as string,
      }),
    onSuccess: () => {
      addToast({
        title: t("users:toast.resend_sms.success.title"), // Success
        description: t("users:toast.resend_sms.success.description"), // 'Der er sendt en SMS til brugeren'
        Icon: getIcon("success"),
        timeout: 5000,
      });
    },
    onError: () => {
      addToast({
        title: t("users:toast.resend_sms.fail.title"), // Failure
        description: t("users:toast.resend_sms.fail.description"), // 'Der skete en fejl ved afsendelse af SMS'
        Icon: getIcon("errorCircle"),
        timeout: 5000,
      });
    },
  });

  const handleUserActivation = async () => {
    if (!user) return;
    userActivation
      .mutateAsync({
        userId: user.id as string,
        editUserRequest: {
          ...user,
          isActive: !user.isActive,
        },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
      });
  };

  const userActivation = useMutation({
    mutationFn: (args: EditUserOperationRequest) => api.editUser(args),
  });

  const handleDeleteUser = async () => {
    if (!user) return;
    await userDelete
      .mutateAsync({
        userId: user.id as string,
      })
      .then(() => {
        navigate("/users");
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
      });
  };

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("common:loading")} />
      </div>
    );
  }

  return (
    <PageLayout
      title={user ? user.fullName : ""}
      onBackClick={() => navigate(-1)}
      titleLabel={() => (
        <div className="ml-5 mt-2.5">
          <span
            className={twMerge(
              "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10",
              user?.isActive ? "bg-green-400 text-green-100" : "bg-red-400 text-slate-100"
            )}
          >
            {user?.isActive ? t("common:active") : t("common:inactive")}
          </span>
        </div>
      )}
      renderActions={() => (
        <ActionButtons
          dropdownClassName="bg-white p-2"
          collapseAt={0}
          actions={[
            {
              Icon: getIcon("sms"),
              label: t("users:resend_welcome_sms.title"),
              mustConfirm: true,
              confirmOptions: {
                Icon: getIcon("sms"),
                title: t("users:resend_welcome_sms.title"),
                description: t("users:resend_welcome_sms.description"),
                variant: "warning",
              },
              isHidden: !user?.isActive,
              onClick: () => resendWelcomeSms.mutateAsync(),
            },
            {
              Icon: getIcon("password"),
              label: t("users:change_password"),
              isHidden: !user?.isActive,
              onClick: () => setPasswordChangeOpen(true),
            },
            {
              Icon: getIcon("deactivateUser"),
              label: t("users:deactivate"),
              onClick: () => handleUserActivation(),
              isHidden: !user?.isActive,
            },
            {
              Icon: getIcon("activateUser"),
              label: t("users:activate"),
              onClick: () => handleUserActivation(),
              isHidden: user?.isActive,
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              mustConfirm: true,
              confirmOptions: {
                Icon: getIcon("delete"),
                title: t("users:delete_employee_confirmation.title"),
                description: t("users:delete_employee_confirmation.description"),
                variant: "alert",
              },
              onClick: () => handleDeleteUser(),
            },
          ]}
        />
      )}
    >
      <>
        {user && (
          <Dialog
            open={passwordChangeOpen}
            onOpenChange={setPasswordChangeOpen}
            render={({ onClose }) => <ChangeUserPasswordModal onClose={onClose} user={user} />}
          />
        )}
        <PageTabs
          tabs={[
            {
              id: "primary",
              label: t("users:tabs.primary_label"),
              to: "",
            },
            {
              id: "settings",
              label: t("users:tabs.settings"),
              to: "settings/",
            },
          ]}
        />
        <Suspense>{!isLoading && <Outlet context={{ user }} />}</Suspense>
      </>
    </PageLayout>
  );
}
