import { useTranslation } from "react-i18next";
import { DayPicker } from "./day-picker";
import * as dateFn from "date-fns";
import { ButtonGroup } from "../buttons/button-group";
import { Button } from "../buttons/button";

export type DateRangeSelectProps = {
  from?: Date;
  to?: Date;
  className?: string;
  onChange?: (from?: Date, to?: Date, shouldClose?: boolean) => void;
};

export function DateRangeSelect({ className, from, to, onChange }: DateRangeSelectProps) {
  const { t } = useTranslation();

  function handlePresetClick(
    preset:
      | "entire_period"
      | "today"
      | "yesterday"
      | "this_week"
      | "last_week"
      | "last_month"
      | "this_month"
  ) {
    switch (preset) {
      case "entire_period":
        onChange?.(undefined, undefined, true);
        break;
      case "today":
        onChange?.(dateFn.startOfDay(new Date()), undefined, true);
        break;
      case "yesterday":
        const yesterday = dateFn.startOfDay(new Date());
        yesterday.setDate(yesterday.getDate() - 1);

        onChange?.(yesterday, undefined, true);
        break;
      case "this_week":
        // get dates for start and end of this week
        const startOfWeek = dateFn.startOfWeek(new Date(), { weekStartsOn: 1 });
        const endOfWeek = dateFn.endOfWeek(new Date(), { weekStartsOn: 1 });
        onChange?.(startOfWeek, endOfWeek, true);
        break;
      case "last_week":
        // get dates for start and end of last week
        const lastWeekDay = dateFn.setDay(new Date(), -7, { weekStartsOn: 1 });
        const startOfLastWeek = dateFn.startOfWeek(lastWeekDay, { weekStartsOn: 1 });
        const endOfLastWeek = dateFn.endOfWeek(lastWeekDay, { weekStartsOn: 1 });
        onChange?.(startOfLastWeek, endOfLastWeek, true);
        break;
      case "last_month":
        // get dates for start and end of last month
        const lastMonthDay = dateFn.subMonths(new Date(), 1);
        const startOfMonth = dateFn.startOfMonth(lastMonthDay);
        const endOfMonth = dateFn.endOfMonth(lastMonthDay);
        onChange?.(startOfMonth, endOfMonth, true);
        break;
      case "this_month":
        // get dates for start and end of this month
        const thisMonthDay = new Date();
        const startOfThisMonth = dateFn.startOfMonth(thisMonthDay);
        const endOfThisMonth = dateFn.endOfMonth(thisMonthDay);
        onChange?.(startOfThisMonth, endOfThisMonth, true);
        break;
    }
  }
  return (
    <div className="rounded-t-md">
      <DayPicker
        mode="range"
        showWeekNumber
        numberOfMonths={2}
        onSelect={(range) => onChange?.(range?.from, range?.to, false)}
        className={className}
        selected={{
          from: from,
          to: to,
        }}
        /*
      footer={
        <div className="flex max-w-xs flex-col flex-wrap items-center justify-center overflow-hidden bg-shade-50 px-2 py-2 text-xs">
          <ButtonGroup className="text-xs">
            <Button variant="secondary" onClick={() => handlePresetClick("entire_period")}>
              {t("ui:date_range_picker.entire_period", "Entire period")}
            </Button>
            <Button variant="secondary" onClick={() => handlePresetClick("today")}>
              {t("ui:date_range_picker.today", "Today")}
            </Button>
            <Button variant="secondary" onClick={() => handlePresetClick("yesterday")}>
              {t("ui:date_range_picker.yesterday", "Yesterday")}
            </Button>
            <Button variant="secondary" onClick={() => handlePresetClick("this_week")}>
              {t("ui:date_range_picker.this_week", "This week")}
            </Button>

            <Button variant="secondary" onClick={() => handlePresetClick("last_week")}>
              {t("ui:date_range_picker.last_week", "Last week")}
            </Button>
            <Button variant="secondary" onClick={() => handlePresetClick("this_month")}>
              {t("ui:date_range_picker.this_month", "This month")}
            </Button>
            <Button variant="secondary" onClick={() => handlePresetClick("last_month")}>
              {t("ui:date_range_picker.last_month", "Last month")}
            </Button>
          </ButtonGroup>
        </div>
      }*/
      />
      <div className="hidden w-full flex-col flex-wrap items-center justify-center overflow-hidden bg-shade-50 px-2 py-2 text-xs md:flex">
        <ButtonGroup className="text-xs">
          <Button variant="secondary" onClick={() => handlePresetClick("entire_period")}>
            {t("ui:date_range_picker.entire_period", "Entire period")}
          </Button>
          <Button variant="secondary" onClick={() => handlePresetClick("today")}>
            {t("ui:date_range_picker.today", "Today")}
          </Button>
          <Button variant="secondary" onClick={() => handlePresetClick("yesterday")}>
            {t("ui:date_range_picker.yesterday", "Yesterday")}
          </Button>
          <Button variant="secondary" onClick={() => handlePresetClick("this_week")}>
            {t("ui:date_range_picker.this_week", "This week")}
          </Button>

          <Button variant="secondary" onClick={() => handlePresetClick("last_week")}>
            {t("ui:date_range_picker.last_week", "Last week")}
          </Button>
          <Button variant="secondary" onClick={() => handlePresetClick("this_month")}>
            {t("ui:date_range_picker.this_month", "This month")}
          </Button>
          <Button variant="secondary" onClick={() => handlePresetClick("last_month")}>
            {t("ui:date_range_picker.last_month", "Last month")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
