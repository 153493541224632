import TextInput from "../form-elements/text-input";
import Calendar from "./calendar";
import * as Popover from "@radix-ui/react-popover";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMount } from "../../lifecycle-helpers";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import { twMerge } from "tailwind-merge";
import { Icon } from "../icons/icon";

export default function DateInput({
  label,
  name,
  value,
  disabled = false,
  className,
  onChange,
}: {
  label: string;
  name: string;
  value?: Date;
  disabled?: boolean;
  className?: string;
  onChange?: (d: Date) => void;
}) {
  const [selectedDate, setSelectedDate] = useState<Date>(value || new Date());
  const [inputValue, setInputValue] = useState<string>(formatDate(value));
  const [open, setOpen] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      setInputValue(formatDate(selectedDate));
    }
  }, [selectedDate]);

  useMount(() => {
    setInitialized(true);
  });

  function handleSelectDate(v: Date) {
    setSelectedDate(v);
    onChange?.(v);

    setOpen(false);
    setMobileOpen(false);
  }

  function handleChange(v: string): void {
    const date = stringToDate(v);
    if (!isNaN(date.getTime()) && date.getTime() > 0) {
      handleSelectDate(date);
    }
    setInputValue(v);
  }

  function handleBlur(v: string): void {
    const date = stringToDate(v);
    if (!isNaN(date.getTime()) && date.getTime() > 0) {
      setSelectedDate(date);
    }
  }

  function stringToDate(s: string): Date {
    const parts = s.split("/");

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    return new Date(`${year}-${month}-${day}`);
  }

  function handleAutoFocus(e: Event) {
    e.preventDefault();
    buttonRef?.focus();
  }

  function formatDate(date?: Date): string {
    if (!date) return "";
    const year = new Intl.DateTimeFormat("default", { year: "numeric" }).format(date);
    const month = new Intl.DateTimeFormat("default", { month: "2-digit" }).format(date);
    const day = new Intl.DateTimeFormat("default", { day: "2-digit" }).format(date);

    return `${day}/${month}/${year}`; // needs to be controlled by settings somewhere
  }

  const inputRef = useRef<HTMLInputElement>(null);

  // very very very bad hack to make the input controlled
  useEffect(() => {
    if (inputRef.current) {
      const event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      inputRef.current.value = formatDate(value);
      inputRef.current.dispatchEvent(event);
    }
  }, [value]);

  return (
    <>
      <div className={twMerge("sm:hidden", className)}>
        <TextInput
          ref={inputRef}
          name={name}
          label={label}
          disabled={disabled}
          defaultValue={inputValue}
          placeholder="dd/mm/yyyy"
          readonly={true}
          onClick={() => setMobileOpen(true)}
        />
        <Transition.Root show={mobileOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setMobileOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto sm:hidden">
              <div className="flex min-h-full w-full items-center justify-center px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                    <div className="sm:hidden">
                      <Calendar
                        defaultDate={selectedDate}
                        onSelect={(d: Date) => handleSelectDate(d)}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <div className={twMerge("hidden sm:block", className)}>
        <div className="flex items-end">
          <TextInput
            ref={inputRef}
            name={name}
            label={label}
            disabled={disabled}
            defaultValue={inputValue}
            placeholder="dd/mm/yyyy"
            onChange={(v) => handleChange(v)}
            onBlur={(v) => handleBlur(v)}
            wrapperClassName="rounded-r-none"
            hasButton={true}
          />

          <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger
              disabled={disabled}
              className={twMerge("-ml-px", !disabled && "hover:z-10")}
            >
              <ButtonLegacy
                onClick={() => setOpen(!open)}
                as="span"
                disabled={disabled}
                variant="secondary"
                className="rounded-l-none border-gray-300 bg-gray-50"
              >
                <Icon name="calendar" size="small" className="my-px -ml-px" />
              </ButtonLegacy>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content onCloseAutoFocus={(e) => handleAutoFocus(e)}>
                <div className="hidden sm:block">
                  <Calendar
                    defaultDate={selectedDate}
                    onSelect={(d: Date) => handleSelectDate(d)}
                  />
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </div>
    </>
  );
}
