import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { captureException } from "../error-reporting";
import { useToasts } from "../toast/use-toasts";
import { usePagination } from "~/lib/utils";
import { TableTopBar, SearchFilterProps } from "./table-top-bar";
import { EntityTableRow } from "./table-row";
import { TableTH } from "./table-th";
import {
  EntityBase,
  EntityResponse,
  TableActions,
  TableField,
  TableFields,
  TableSelection,
} from "./types";
import { Fragment, useEffect, useState } from "react";
import { ResponseError } from "@apacta/sdk";
import { RowLoader } from "./table-row-loader";
import { Checkbox } from "../ui/form-elements/checkbox";
import { PaginationBar } from "./pagination-bar";
import noRowsSVG from "./no-rows.svg";

export type EntityTableProps<
  GenericResponse extends EntityResponse,
  EntityType extends EntityBase = GenericResponse["data"][number],
> = {
  cacheKey: Array<string | object>;
  fields: TableFields<EntityType>;
  actions?: TableActions<EntityType>;
  onRowClick?: (entity: EntityType) => void;
  /** Rendered after the row. Makes it possible to implement row-expansion in the parent */
  renderRowExpansion?: ({
    entity,
    numberOfColumns,
  }: {
    entity: EntityType;
    numberOfColumns: number;
  }) => React.ReactNode;
  showSearch?: boolean;
  dataFn: () => Promise<GenericResponse>;
  filters?: SearchFilterProps<GenericResponse>;
  /** Enable selection functionality */
  selection?: TableSelection<EntityType>;
};

/**
 * @deprecated Use `DataTable` instead, see Playground for examples
 */
export function EntityTable<
  GenericResponse extends EntityResponse,
  EntityType extends EntityBase = GenericResponse["data"][number],
>({
  fields,
  actions,
  onRowClick,
  dataFn,
  cacheKey,
  renderRowExpansion,
  filters,
  showSearch = true,
  selection,
}: EntityTableProps<GenericResponse>) {
  const { t } = useTranslation();
  const [pagination, setPagination] = usePagination();
  const toast = useToasts();
  const queryClient = useQueryClient();
  const [currentSelection, setCurrentSelection] = useState<Array<EntityType>>([]);

  if (!fields) {
    throw new Error("EntityTable will not function without field configuration");
  }

  const fieldArr: Array<TableField<EntityType> & { fieldKey: string }> = Object.entries(fields)
    .filter(([, value]) => !!value) // Value must not be undefined
    .filter(([, value]) => value.hideColumn !== true) // Value must not be explicitly hidden
    .map(([fk, value]) => ({ ...value, fieldKey: fk }));

  const { isLoading, isRefetching, data, error } = useQuery({
    queryKey: [...cacheKey, pagination], // TODO: Maybe not inject pagination automatically?
    queryFn: dataFn,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!error) return;
    // Check if 404 response error. This can happen if the user is on page 2 and the entity is deleted
    if (error instanceof ResponseError && error.response.status === 404) {
      if (pagination?.page && pagination?.page > 1) {
        setPagination("page", pagination.page - 1);
        return;
      }
    }

    toast.showTemplate("UNEXPECTED_ERROR");
    captureException(new Error("Failed to populate entity list", { cause: error }));
  }, [error]);

  const entities = data?.data || [];
  const selectableEntities = entities.filter((entity) => !selection?.isDisabled?.(entity));

  if (!Array.isArray(entities)) {
    console.error("EntityTable: entities is not an array", entities);
  }

  function handleRefresh() {
    queryClient.invalidateQueries({ queryKey: cacheKey });
  }

  function handleSelection(item: EntityType) {
    setCurrentSelection((prev) => {
      if (prev.includes(item)) {
        return prev.filter((ent) => ent.id !== item.id);
      }
      return [...prev, item];
    });
  }

  function handleSelectAll() {
    if (currentSelection.length === selectableEntities.length) {
      setCurrentSelection([]);
    } else {
      // Add each entity id to currentSelection
      setCurrentSelection((prev) => {
        const newSelection = [...prev];
        selectableEntities.forEach((entity) => {
          if (!prev.includes(entity as EntityType)) {
            newSelection.push(entity as EntityType);
          }
        });
        return newSelection;
      });
    }
  }

  let numberOfColumns = fieldArr.length;
  if (actions && actions.length > 0) numberOfColumns++;
  if (selection) numberOfColumns++;

  return (
    <>
      <TableTopBar
        data={data}
        filters={filters}
        selection={{ ...selection, currentSelection }}
        showSearch={showSearch}
        onActionSuccess={() => setCurrentSelection([])}
      />

      <div className="table">
        <table>
          <thead>
            <tr>
              {selection && (
                <th className="w-2">
                  <Checkbox
                    checked={
                      currentSelection.length > 0 &&
                      currentSelection.length === selectableEntities.length
                    }
                    onChange={handleSelectAll}
                    disabled={selectableEntities.length === 0}
                  />
                </th>
              )}
              {fieldArr.map((fc, idx) => (
                <TableTH pagination={pagination} fc={fc} key={idx} />
              ))}
              {actions && actions.length > 0 && <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading || isRefetching ? (
              <RowLoader hasActions={!!actions} fieldArr={fieldArr} numberOfRows={10} />
            ) : entities.length > 0 ? (
              entities.map((row) => (
                <Fragment key={row.id}>
                  <EntityTableRow
                    entity={row as EntityType}
                    fieldArr={fieldArr}
                    actions={actions}
                    onRefresh={handleRefresh}
                    onRowClick={onRowClick}
                    selection={selection ? { ...selection, currentSelection } : undefined}
                    onSelect={handleSelection}
                  />
                  {renderRowExpansion?.({
                    entity: row,
                    numberOfColumns,
                  })}
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={numberOfColumns} className="text-center">
                  <img className="inline h-30" src={noRowsSVG} />
                  <p className="mt-4 text-gray-500">{t("common:no_results")}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        {data?.pagination && <PaginationBar paginationDetails={data.pagination} />}
      </div>
    </>
  );
}
