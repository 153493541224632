import { toLocalDateString } from "~/lib/utils/date";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { sub } from "date-fns";
import { useEffect } from "react";

// Thin wrapper for search-params with a few helpers
export function useEmployeesParams() {
  const [employeesParams, setEmployeesParams] = useTypedSearchParams<{
    start_date?: string;
    end_date?: string;
    employeeIds?: Array<string>;
  }>();
  /** Set default date range to prevent fetching data for years in the past */
  useEffect(() => {
    if (!employeesParams.start_date && !employeesParams.end_date) {
      handlePeriodChange(sub(new Date(), { days: 14 }), new Date());
    }
  }, [employeesParams]);

  function handlePeriodChange(start?: Date, end?: Date) {
    setEmployeesParams("start_date", toLocalDateString(start));
    setEmployeesParams("end_date", toLocalDateString(end));
  }

  return {
    handlePeriodChange,
    setEmployeesParams,
    startDate: employeesParams.start_date,
    endDate: employeesParams.end_date,
    employeeIds: employeesParams.employeeIds,
  };
}
