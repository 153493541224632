import {
  ExpenseLine,
  Product,
  ProductBundle,
  ProductBundleLine,
  UsersPriceGroup,
  VendorProduct,
} from "@apacta/sdk";
import {
  MutableOrderLineKeys,
  OrderLine,
  OrderLineAdd,
  OrderLineType,
} from "~/lib/ui/order-lines/lib/types";
import { calculateChildLinesCost } from "~/lib/ui/order-lines/lib/calculations";
import { randomUUID } from "~/lib/uuid/random-uuid";

const addProductLine = (product: Product, position: number): Partial<OrderLine> | null => {
  if (!product) return null;

  const name = product?.name ?? "";
  const sellingPrice = product?.sellingPrice ?? 0;
  const costPrice = product?.buyingPrice ?? 0;

  return {
    type: "product",
    productId: product.id,
    quantity: 1,
    name,
    sellingPrice,
    costPrice,
    discountType: "fixed",
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

const addExpenseLineLine = (
  expenseLine: ExpenseLine,
  position: number
): Partial<OrderLine> | null => {
  if (!expenseLine) return null;

  const name = expenseLine?.text ?? "";
  const sellingPrice = expenseLine?.sellingPrice ?? 0;
  const costPrice = expenseLine?.buyingPrice ?? 0;

  return {
    type: "expense_line",
    expenseLineId: expenseLine.id,
    quantity: 1,
    name,
    sellingPrice,
    costPrice,
    discountType: "fixed",
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

const addVendorProductLine = (
  vendorProduct: VendorProduct,
  position: number
): Partial<OrderLine> | null => {
  if (!vendorProduct) return null;

  const name = vendorProduct?.name ?? "";
  const sellingPrice = vendorProduct?.sellingPrice ?? 0;
  const costPrice = vendorProduct?.buyingPrice ?? 0;

  return {
    type: "vendor_product",
    vendorProductId: vendorProduct.id,
    quantity: 1,
    name,
    sellingPrice,
    costPrice,
    discountType: "fixed",
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

const addProductBundleLine = (
  productBundle: ProductBundle,
  position: number
): Partial<OrderLine> | null => {
  if (!productBundle) return null;

  const name = productBundle?.name ?? "";
  let sellingPrice = productBundle.salesPrice ?? 0;

  if (sellingPrice === 0 && productBundle.productBundleLines?.length) {
    sellingPrice = productBundle.productBundleLines.reduce((a, b) => a + (b.sellingPrice ?? 0), 0);
  }

  const costPrice =
    productBundle.productBundleLines?.reduce(
      (a, b) => a + ((b.buyingPrice ?? 0) * b.quantity ?? 0),
      0
    ) ?? 0;

  const productBundleChildType = (l: ProductBundleLine): OrderLineType => {
    if (l.productId || l.expenseLineId || l.vendorProductId) return "product";
    if (l.usersPriceGroupId) return "hours";
    return "text";
  };

  return {
    type: "bundle",
    productBundleId: productBundle.id,
    quantity: 1,
    name,
    sellingPrice,
    costPrice,
    discountType: "fixed",
    childOfferLines: productBundle.productBundleLines?.map((line, idx) => {
      return {
        name: line.name ?? "",
        type: productBundleChildType(line),
        quantity: line.quantity ?? 0,
        sellingPrice: line.sellingPrice ?? 0,
        costPrice: line.buyingPrice ?? 0,
        discountType: "fixed",
        identifier: randomUUID(),
        position: idx,
        productId: line.productId,
        expenseLineId: line.expenseLineId,
        vendorProductId: line.vendorProductId,
      };
    }),
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

const addTextLine = (position: number): Partial<OrderLine> | null => {
  return {
    type: "text",
    name: randomUUID() /** needed for the backend **/,
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

const addTimeLine = (group: UsersPriceGroup, position: number): Partial<OrderLine> | null => {
  if (!group) return null;

  const costPrice = (group.costPrice ?? 0) + (group.extraPrice ?? 0);
  const sellingPrice = group.salePrice ?? 0;

  return {
    type: "hours",
    usersPriceGroupId: group.id,
    quantity: 1,
    name: group.name ?? "",
    costPrice,
    sellingPrice,
    discountType: "fixed",
    identifier: randomUUID() /** needed for us to be able to mutate the specific offer line **/,
    position,
  };
};

export const addOrderLine = (opts: OrderLineAdd, position: number): Partial<OrderLine> | null => {
  let newOfferLine: Partial<OrderLine> | null = null;
  switch (opts.type) {
    case "product":
      newOfferLine = addProductLine(opts.options?.product as Product, position);
      break;
    case "expense_line":
      newOfferLine = addExpenseLineLine(opts.options?.expenseLine as ExpenseLine, position);
      break;
    case "vendor_product":
      newOfferLine = addVendorProductLine(opts.options?.vendorProduct as VendorProduct, position);
      break;
    case "bundle":
      newOfferLine = addProductBundleLine(opts.options?.bundle as ProductBundle, position);
      break;
    case "text":
      newOfferLine = addTextLine(position);
      break;
    case "hours":
      newOfferLine = addTimeLine(opts.options?.priceGroup as UsersPriceGroup, position);
      break;
  }
  return newOfferLine;
};

export const repositionOrderLine = (
  line: Partial<OrderLine>,
  newPos: number,
  lines: Array<Partial<OrderLine>>
): Array<Partial<OrderLine>> => {
  const oldPos = line.position ?? 0;

  if (line.position === newPos) return lines;

  const newLines = [...lines];
  newLines.splice(oldPos, 1);
  newLines.splice(newPos, 0, line);
  newLines.map((l, idx) => {
    l.position = idx;
    return l;
  });
  newLines.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));

  return [...newLines];
};

export const updateOrderLine = (
  lines: Array<Partial<OrderLine>>,
  keys: Array<MutableOrderLineKeys>,
  values: { [key in MutableOrderLineKeys]?: unknown },
  id?: string,
  parentId?: string | null
) => {
  const shouldBeString = ["name", "discountType", "description"];
  const shouldBeBoolean = ["isInvalid"];

  let newLines: Array<Partial<OrderLine>> = [...lines];

  keys.forEach((key) => {
    const value = values[key];

    if (!shouldBeBoolean.includes(key) && (value === undefined || value === null || value === "")) {
      console.error(`[${key}] must be set`);
      return lines;
    }

    if (!id) {
      console.error(`id must be set`);
      return lines;
    }

    if (shouldBeString.includes(key) && typeof value !== "string") {
      console.error(`[${key}] must be a string`);
      return lines;
    } else if (shouldBeBoolean.includes(key) && typeof value !== "boolean") {
      console.error(`[${key}] must be a string`);
      return lines;
    }

    if (
      !shouldBeString.includes(key) &&
      !shouldBeBoolean.includes(key) &&
      typeof value !== "number"
    ) {
      console.error(`[${key}] must be a number`);
      return lines;
    }

    newLines = newLines.map((line) => {
      /** If there is a parentId, the line we're editing is a child offer line **/
      if (parentId) {
        if (line.identifier === parentId) {
          const editedLine = {
            ...line,
            childOfferLines: line.childOfferLines?.map((childLine) => {
              if (childLine.identifier === id) {
                return {
                  ...childLine,
                  [key]: value,
                };
              }
              return childLine;
            }),
          };
          /** If we change costPrice or quantity, recalculate the parent line cost price **/
          if (["costPrice", "quantity"].includes(key)) {
            editedLine.costPrice = calculateChildLinesCost(editedLine.childOfferLines ?? []);
          }

          return editedLine;
        }
      } else {
        if (line.identifier === id) {
          return {
            ...line,
            [key]: value,
          };
        }
      }

      return line;
    });
  });
  return newLines;
};
