import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { useDataColumns } from "~/lib/ui/data-table/use-data-columns";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { TabHeading } from "~/lib/ui/tabs/heading";

const columnHelper = createColumnHelper<PizzaTopping>();

export default function TablePlaygroundClient() {
  const tableState = useDataTableState();
  const { t } = useTranslation();

  const columns = useDataColumns(() => [
    columnHelper.accessor("name", {
      header: "Topping",
      enableSorting: true,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("price", {
      header: t("common:price"),
      enableSorting: true,
      sortingFn: "auto",
      meta: {
        className: "text-right",
      },
      //footer: "test",
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      data,
      tableState: tableState,
      isLoading: false,
      mode: "client",
    },
    {
      enableGlobalFilter: true,
    }
  );

  // Leave this for testing
  console.log("table render");

  return (
    <>
      <TabHeading>Client data</TabHeading>
      <DataTable table={table} />
    </>
  );
}

type PizzaTopping = {
  id: number;
  name: string;
  price: number;
};

const data = [
  { id: 1, name: "Pepperoni", price: 1.5 },
  { id: 2, name: "Sausage", price: 1 },
  { id: 3, name: "Mushrooms", price: 1 },
  { id: 4, name: "Onions", price: 0.5 },
  { id: 6, name: "Green Peppers", price: 0.5 },
  { id: 7, name: "Pineapple", price: 0.5 },
  { id: 8, name: "Spinach", price: 1.5 },
  { id: 9, name: "Tomatoes", price: 1 },
  { id: 10, name: "Broccoli", price: 1 },
  { id: 11, name: "Jalapenos", price: 0.5 },
  { id: 12, name: "Banana Peppers", price: 0.5 },
  { id: 15, name: "Ham", price: 1 },
  { id: 16, name: "Beef", price: 1 },
  { id: 17, name: "Chicken", price: 1.5 },
  { id: 20, name: "Ranch", price: 0.5 },
  { id: 21, name: "Buffalo Sauce", price: 0.5 },
  { id: 22, name: "Garlic Butter Sauce", price: 0.5 },
  { id: 23, name: "Olive Oil", price: 0.5 },
  { id: 24, name: "Pesto Sauce", price: 0.5 },
  { id: 25, name: "Pizza Sauce", price: 0.5 },
  { id: 26, name: "Extra Cheese", price: 1 },
  { id: 27, name: "Feta Cheese", price: 1.5 },
  { id: 28, name: "Cheddar Cheese", price: 1 },
  { id: 29, name: "Parmesan Cheese", price: 1 },
  { id: 30, name: "Mozzarella Cheese", price: 1 },
  { id: 31, name: "Provolone Cheese", price: 1 },
  { id: 32, name: "Ricotta Cheese", price: 1 },
  { id: 33, name: "Gorgonzola Cheese", price: 1.5 },
  { id: 34, name: "Blue Cheese", price: 1.5 },
  { id: 35, name: "Swiss Cheese", price: 1 },
  { id: 36, name: "Pepper Jack Cheese", price: 1 },
  { id: 37, name: "American Cheese", price: 1 },
  { id: 38, name: "Mushroom Sauce", price: 0.5 },
  { id: 39, name: "Pesto Sauce", price: 0.5 },
  { id: 40, name: "Buffalo Sauce", price: 0.5 },
  { id: 41, name: "BBQ Sauce", price: 0.5 },
  { id: 42, name: "Alfredo Sauce", price: 0.5 },
  { id: 43, name: "Ranch", price: 0.5 },
  { id: 44, name: "Garlic Butter Sauce", price: 0.5 },
  { id: 45, name: "Olive Oil", price: 0.5 },
  { id: 46, name: "Pizza Sauce", price: 0.5 },
  { id: 47, name: "Mushrooms", price: 1 },
  { id: 48, name: "Onions", price: 0.5 },
  { id: 49, name: "Black Olives", price: 1 },
  { id: 50, name: "Green Peppers", price: 0.5 },
  { id: 51, name: "Pineapple", price: 0.5 },
  { id: 52, name: "Spinach", price: 1.5 },
  { id: 53, name: "Tomatoes", price: 1 },
  { id: 54, name: "Broccoli", price: 1 },
  { id: 55, name: "Jalapenos", price: 0.5 },
  { id: 57, name: "Artichoke Hearts", price: 1.5 },
  { id: 58, name: "Bacon", price: 1 },
] satisfies Array<PizzaTopping>;
