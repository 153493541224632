import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GlobeAltIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useNavigationContent } from "./components/navigation-item/navigation-item.content";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavigationItemMobile } from "./components/navigation-item/navigation-item-mobile";

export function SidebarMobile({
  isOpen,
  onDismiss,
  onOpenLanguageSelect,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onOpenLanguageSelect: () => void;
}) {
  const { mobileNavigation } = useNavigationContent();
  const { t } = useTranslation();

  const location = useNavigate();

  useEffect(() => {
    onDismiss();
  }, [location]);

  // Temporary until topbar handles everything
  const languageNavigationItem = {
    name: t("ui:navigation.change_language", "Change language"),
    href: "#",
    icon: GlobeAltIcon,
  };

  // Temporary until topbar handles everything
  function showChangeLanguage() {
    onDismiss();
    onOpenLanguageSelect();
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden print:hidden" onClose={() => onDismiss()}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="font-lg relative flex w-full max-w-xs flex-1 flex-col bg-primary pb-4 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => onDismiss()}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <Link to="/">
                  <img className="h-12 w-auto" src="/logo.png" alt="Apacta logo" />
                </Link>
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="flex h-full flex-col justify-between space-y-1 px-2 py-2">
                  <div>
                    {mobileNavigation.map((item) => (
                      <NavigationItemMobile item={item} key={item.name + item.featureName} />
                    ))}
                  </div>
                  <div>
                    <NavigationItemMobile
                      item={languageNavigationItem}
                      onClick={() => showChangeLanguage()}
                    />
                  </div>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
