import { Fragment, ReactNode } from "react";
import {
  EntityBase,
  SelectionWithCurrentSelection,
  TableActions,
  TableFieldWithKey,
} from "./types";
import { twMerge } from "tailwind-merge";
import { EntityActionButtons } from "./action-buttons";
import { Link } from "react-router-dom";
import { Checkbox } from "~/lib/ui/form-elements/checkbox";

export function EntityTableRow<EntityType extends EntityBase>({
  entity,
  fieldArr,
  actions,
  onRefresh,
  onRowClick,
  selection,
  onSelect,
}: {
  entity: EntityType;
  fieldArr: Array<TableFieldWithKey<EntityType>>;
  actions?: TableActions<EntityType>;
  onRefresh?: () => void;
  onRowClick?: (entity: EntityType) => void;
  selection?: SelectionWithCurrentSelection<EntityType>;
  onSelect: (entity: EntityType) => void;
}) {
  return (
    <tr
      key={`${entity.id}`}
      className={twMerge("hover:bg-gray-50", onRowClick && "cursor-pointer")}
      onClick={onRowClick ? () => onRowClick?.(entity) : undefined}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "Space") {
          onRowClick?.(entity);
        }
      }}
    >
      {selection && (
        <td className="w-2">
          <Checkbox
            checked={selection?.currentSelection.includes(entity)}
            onChange={() => onSelect(entity)}
            disabled={selection?.isDisabled?.(entity)}
          />
        </td>
      )}
      {fieldArr.map((fc) => {
        const href = fc.href?.(entity);
        return (
          <td key={fc.fieldKey} className={twMerge(tableCellClasses(fc, "td"))}>
            <CellWrapper href={href}>
              {fc.renderColumn(entity, false)}
              <dl className="font-normal">
                {fieldArr // Show collapsed fields here
                  .filter((cfc) => {
                    if (cfc.collapse?.on !== fc.fieldKey) {
                      return false;
                    }
                    return true;
                  })
                  .map((cfc) => {
                    const bp = cfc.collapse?.breakpoint;
                    return (
                      <Fragment key={`${entity.id}-${cfc.label}`}>
                        <dt className="sr-only">{cfc.label}</dt>
                        <dd
                          className={twMerge(
                            "mt-1 truncate text-gray-700",
                            bp === "sm" && "sm:hidden",
                            bp === "md" && "md:hidden",
                            bp === "lg" && "lg:hidden",
                            bp === "xl" && "xl:hidden"
                          )}
                        >
                          {cfc.renderColumn(entity, true)}
                        </dd>
                      </Fragment>
                    );
                  })}
              </dl>
            </CellWrapper>
          </td>
        );
      })}
      {actions ? (
        <td className="py-4 pr-4 text-right align-middle">
          <EntityActionButtons actions={actions} entity={entity} onRefresh={onRefresh} />
        </td>
      ) : null}
    </tr>
  );
}

export function tableCellClasses<Entity>(fc: TableFieldWithKey<Entity>, type: "td" | "th") {
  const bp = fc.collapse?.breakpoint;
  return twMerge(
    "px-6 py-4 text-left align-middle text-sm text-gray-500",
    type === "th" ? fc.thClass : fc.tdClass,
    bp === "sm" && "hidden sm:table-cell",
    bp === "md" && "hidden md:table-cell",
    bp === "lg" && "hidden lg:table-cell",
    bp === "xl" && "hidden xl:table-cell",
    fc.align === "right" && "text-right",
    fc.align === "center" && "text-center",
    fc.align === "left" && "text-left"
  );
}

export function CellWrapper({ children, href }: { children: ReactNode; href?: string }) {
  if (href === undefined) {
    return <Fragment>{children}</Fragment>;
  }
  return (
    <Link className="cursor-pointer hover:text-hover hover:underline" to={href}>
      {children}
    </Link>
  );
}
