import { Icon, IconName } from "~/lib/ui";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const CardHeader = ({
  icon,
  title,
  color,
  renderIdentifier,
}: {
  icon: IconName;
  title: string;
  color?: `#${string}`; // color code
  renderIdentifier?: () => ReactNode;
}) => {
  return (
    <div className="flex h-12 items-center border-b bg-shade-50 pr-4 font-semibold">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className={twMerge("flex h-12 items-center px-4 text-white", color ? "" : "bg-primary")}
            style={{ backgroundColor: color }}
          >
            <Icon name={icon} size="medium" />
          </div>
          <div>{title}</div>
        </div>
        <div>{renderIdentifier?.()}</div>
      </div>
    </div>
  );
};
