import { ReactNode, useEffect, useRef, useState } from "react";
import { featureEnabled } from "../../feature-helper";
import { useLocation } from "react-router";
import NavigationTopBar from "./topbar/navigation-top-bar";
import MobileNavigationTopBar from "./topbar/mobile-navigation-top-bar";

import { twMerge } from "tailwind-merge";
import { Sidebar } from "~/lib/navigation/navigation-layout/sidebar/sidebar";
import { getScreenSizeType, ScreenSizeType } from "../../utils/tailwind/screen-sizes";
import { SidebarDesktopRef } from "~/lib/navigation/navigation-layout/sidebar/sidebar-desktop";

export function NavigationLayout({ children }: { children?: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showTopBar, setShowTopBar] = useState(false);
  const [sidebarType, setSidebarType] = useState<ScreenSizeType>(getScreenSizeType());
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(sidebarType === "laptop");
  const location = useLocation();

  const sidebarDesktopRef = useRef<SidebarDesktopRef>(null);

  useEffect(() => {
    setShowTopBar(featureEnabled(location));
    if (sidebarType === "laptop") {
      setSidebarCollapsed(true);
      if (sidebarDesktopRef.current) {
        sidebarDesktopRef.current.collapse();
      }
    }
  }, [location]);

  function handleSidebarOpen() {
    setSidebarOpen(true);
  }

  return (
    <>
      <Sidebar
        ref={sidebarDesktopRef}
        sidebarOpen={sidebarOpen}
        onVisibilityChange={(val: boolean) => setSidebarOpen(val)}
        onSidebarChange={setSidebarType}
        onSidebarCollapse={setSidebarCollapsed}
      />
      <div
        className={twMerge(
          "flex h-full flex-1 flex-col",
          sidebarType === "laptop" && "pl-20",
          sidebarType === "desktop" && sidebarCollapsed && "pl-20",
          sidebarType === "desktop" && !sidebarCollapsed && "pl-64"
        )}
      >
        {showTopBar ? (
          <NavigationTopBar openSideBar={handleSidebarOpen} />
        ) : (
          <MobileNavigationTopBar openSideBar={handleSidebarOpen} />
        )}
        <main className="h-screen">{children}</main>
      </div>
    </>
  );
}
