import { CheckCircleIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Contact, ContactPerson, DeleteContactPersonRequest } from "@apacta/sdk";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import CreateEditContactPersonModal from "../_cmp/customer-tabs/modal/create-contact-person-modal";
import { useToasts } from "~/lib/toast/use-toasts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ContactCard } from "../_cmp/customer-tabs/contacts/contact-card";
import { useModals } from "~/lib/ui/modal/context";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router-dom";
import { useMount } from "~/lib/lifecycle-helpers";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import { Dialog, Button } from "~/lib/ui";

export default function ContactPersonsTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const api = useAPI();
  const { t } = useTranslation();
  const { show: addToast } = useToasts();
  const queryClient = useQueryClient();
  const { showConfirm } = useModals();
  const { id: customerId } = useParams();

  const [editModal, setEditModal] = useState<{
    open: boolean;
    contact?: ContactPerson;
  }>({ open: false });

  // TODO: This is fetched twice, due to the parent needing the name. Will be solved by router loaders
  const { data, isLoading } = useQuery({
    queryKey: ["contacts", customerId],
    queryFn: () => api.getContactPersonsList({ contactId: customerId as string, limit: 999 }),
  });

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("common:customer_contact", { count: 2 })}`);
  });

  const contacts = data?.data || [];

  const contactDelete = useMutation({
    mutationFn: (args: DeleteContactPersonRequest) => api.deleteContactPerson(args),
    onSuccess: () => {
      addToast({
        title: t("customers:contacts.toast.delete.title"),
        description: t("customers:contacts.toast.delete.description"),
        Icon: CheckCircleIcon,
        timeout: 5000,
      });
      queryClient.invalidateQueries({
        queryKey: ["contacts", customerId],
      });
    },
  });

  async function handleEditContactPerson(c: ContactPerson) {
    setEditModal({ open: true, contact: c });
  }

  async function handleDeleteContactPerson(c: ContactPerson) {
    await showConfirm(
      {
        Icon: TrashIcon,
        title: t("customers:contacts.modal.delete.title"),
        description: t("customers:contacts.modal.delete.description"),
      },
      () =>
        contactDelete.mutateAsync({
          contactPersonId: c.id as string,
          contactId: customerId as string,
        })
    );
  }

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("customers:contacts.list.loading")} />
      </div>
    );
  }

  // Resets when either/create or edit modal is closed
  function handleContactMutation() {
    queryClient.invalidateQueries({
      queryKey: ["contacts", customerId],
    });
    setEditModal({ open: false });
  }

  return (
    <>
      <div className="flex w-full flex-col gap-12">
        <div className="w-full">
          <div>
            <div className="flex flex-col items-start gap-2 sm:flex-row sm:justify-between">
              <h2 className="m-0">{t("common:customer_contact", { count: 2 })}</h2>
              <div>
                <Dialog
                  trigger={
                    <Button variant="tertiary" Icon={PlusIcon}>
                      {t("common:create", {
                        replace: {
                          entity: t("common:customer_contact", { count: 1 }).toLocaleLowerCase(),
                        },
                      })}
                    </Button>
                  }
                  render={({ onClose }) => (
                    <CreateEditContactPersonModal
                      customerId={customer.id}
                      onSuccess={handleContactMutation}
                      onClose={onClose}
                      mode="create"
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={twMerge(
                "flex w-full flex-col gap-10 rounded-md",
                contacts.length && "mt-6"
              )}
            >
              {contacts.length > 0 ? null : (
                <div className="mt-8 flex flex-col justify-center gap-4">
                  <img className="h-28 w-auto" src="/icons/no-contacts.svg" />
                  <div className="flex justify-center">
                    <div className="flex flex-col text-center">
                      <h3>{t("customers:contacts.no_results_title")}</h3>
                      <span>{t("customers:contacts.no_results_description")}</span>
                    </div>
                  </div>
                </div>
              )}
              <ul
                role="list"
                className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
              >
                {contacts.map((contact: ContactPerson) => (
                  <ContactCard
                    key={`${contact.id}`}
                    contact={contact}
                    onEdit={handleEditContactPerson}
                    onDelete={handleDeleteContactPerson}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={editModal.open}
        onOpenChange={() => setEditModal({ open: false })}
        render={({ onClose }) => (
          <CreateEditContactPersonModal
            customerId={customer.id}
            onSuccess={handleContactMutation}
            onClose={onClose}
            mode="edit"
            contactPerson={editModal.contact}
          />
        )}
      />
    </>
  );
}
