import { useOutletContext } from "react-router-dom";
import { InvoiceEditFixed } from "../_cmp/invoice-edit-fixed";
import { InvoiceView } from "../_cmp/invoice-view";
import { Invoice } from "@apacta/sdk";
import { InvoiceEditHourly } from "../_cmp/invoice-edit-hourly";

export default function InvoiceDetailPage() {
  const { invoice } = useOutletContext<{ invoice: Invoice }>();

  if (!invoice.isDraft) return <InvoiceView />;
  if (invoice.project?.isFixedPrice) return <InvoiceEditFixed />;
  return <InvoiceEditHourly />;
}
