import { WallPost, WallPostWallCommentsInner } from "@apacta/sdk";
import { ChatBubbleLeftEllipsisIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { FeedItem } from "~/pages/projects/_cmp/feed-item";
import { MediaPreviewLink } from "~/lib/ui/media/";
import { WallCommentForm } from "./wall-comment-form";
import { Fragment, useId, useState } from "react";
import { useModals } from "~/lib/ui/modal";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CACHE_PROJECTS } from "..";
import Linkify from "~/lib/utils/string/linkify";
import { EditCommentDialog } from "~/pages/projects/_cmp/edit-comment-dialog";
import { EditPostDialog } from "~/pages/projects/_cmp/edit-post-dialog";

export function WallPostItem({
  item,
  onRefresh,
  readonly,
}: {
  item: WallPost;
  onRefresh: () => void;
  readonly?: boolean;
}) {
  const { t } = useTranslation();
  const id = useId();
  const [canOpen, setCanOpen] = useState<boolean>(true);
  const { showModal, showConfirm } = useModals();
  const api = useAPI();
  const queryClient = useQueryClient();

  const mDelete = useMutation({
    mutationFn: () => api.iDeleteWallPost({ postId: item.id as string }),
  });

  const mDeleteComment = useMutation({
    mutationFn: ({ commentId }: { commentId: string }) =>
      api.iDeleteWallComment({ commentId: commentId, postId: item.id }),
  });

  const mEditPost = useMutation({
    mutationFn: ({ wallPostId, message }: { wallPostId: string; message?: string }) =>
      api.iEditWallPost({
        postId: wallPostId,
        iEditWallPostRequest: {
          id: wallPostId,
          message: message ?? "",
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    },
  });

  const mEditComment = useMutation({
    mutationFn: ({
      wallPostId,
      commentId,
      message,
    }: {
      wallPostId: string;
      commentId: string;
      message?: string;
    }) =>
      api.iEditWallComment({
        commentId: commentId,
        postId: wallPostId,
        iEditWallCommentRequest: {
          id: commentId,
          message: message ?? "",
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    },
  });

  async function handleDelete() {
    const res = await showConfirm({
      title: t("projects:delete_wall_post.title", "Delete wall post"),
      description: t(
        "projects:delete_wall_post.description",
        "Are you sure you want to delete this wall post?"
      ),
      variant: "warning",
      Icon: TrashIcon,
    });
    if (res) {
      await mDelete.mutateAsync();
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    }
  }

  async function handleEditPost(post: WallPost) {
    await showModal<string>(
      ({ onClose }) => (
        <EditPostDialog
          onClose={onClose}
          post={post}
          onEditPost={(response) =>
            mEditPost.mutateAsync({
              message: response.message,
              wallPostId: post.id,
            })
          }
        />
      ),
      {
        props: { size: "lg" },
      }
    );
  }

  async function handleEditComment(comment: WallPostWallCommentsInner) {
    await showModal<string>(
      ({ onClose }) => (
        <EditCommentDialog
          onClose={onClose}
          comment={comment}
          onEditComment={(response) =>
            mEditComment.mutateAsync({
              message: response.message,
              commentId: comment.id,
              wallPostId: comment.wallPostId as string,
            })
          }
        />
      ),
      {
        props: { size: "lg" },
      }
    );
  }

  async function handleDeleteComment(commentId: string) {
    const res = await showConfirm({
      title: t("projects:delete_wall_comment.title", "Delete wall comment"),
      description: t(
        "projects:delete_wall_comment.description",
        "Are you sure you want to delete this wall comment?"
      ),
      variant: "warning",
      Icon: TrashIcon,
    });
    if (res) {
      await mDeleteComment.mutateAsync({ commentId });
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
    }
  }

  return (
    <FeedItem
      date={item.modified ?? new Date()}
      Icon={ChatBubbleLeftEllipsisIcon}
      title={item.user?.fullName}
      actor={<UserAvatar user={item.user} />}
      onDelete={() => handleDelete()}
      onEdit={() => handleEditPost(item)}
      readonly={readonly}
    >
      {item.message && (
        <div className="mt-2">
          <Linkify>{item.message}</Linkify>
        </div>
      )}
      {item.files && item.files.length > 0 ? (
        <div className="mt-2 flex flex-wrap gap-4">
          {item.files.map((file, i) => (
            <Fragment key={`${id}-${i}`}>
              <MediaPreviewLink
                key={file.originalFilename}
                href={file.downloadUrl}
                title={file.originalFilename}
                mimeType={file.mimeType ?? ""}
                disabled={!canOpen}
              />
            </Fragment>
          ))}
        </div>
      ) : null}
      {item.wallComments && item.wallComments.length > 0 && (
        <div className="mt-4 flex flex-col gap-2 rounded-md ">
          {item.wallComments?.map((wc: WallPostWallCommentsInner) => (
            <div
              key={wc.id}
              className="ml-4 flex flex-row gap-x-4 border-b border-white bg-zinc-50 px-2 py-2"
            >
              <FeedItem
                date={wc.modified ?? new Date()}
                title={wc.user?.fullName}
                actor={<UserAvatar user={wc.user} />}
                onDelete={() => handleDeleteComment(wc.id)}
                onEdit={() => handleEditComment(wc)}
                readonly={readonly}
              >
                <div className="mt-2">
                  <Linkify>{wc.message}</Linkify>
                </div>
              </FeedItem>
            </div>
          ))}
        </div>
      )}
      <WallCommentForm postId={item.id as string} onRefresh={onRefresh} />
    </FeedItem>
  );
}
