import { useBlocker } from "react-router";
import { Dialog } from "~/lib/ui";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

type BlockNavigationProps = {
  when: boolean;
  onSaveBeforeNavigate?: () => Promise<void>;
};

export default function BlockNavigation({ when, onSaveBeforeNavigate }: BlockNavigationProps) {
  const { t } = useTranslation();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname
  );

  const handleProceed = useCallback(() => {
    if (blocker.state !== "blocked" || !blocker.proceed) return;
    blocker.proceed();
  }, [blocker.state]);

  return (
    <Dialog
      open={
        blocker.state === "blocked" && blocker.proceed !== undefined && blocker.reset !== undefined
      }
      onOpenChange={() => blocker.reset?.()}
      render={({ onClose }) => (
        <>
          <DialogHeader
            title={t("common:unsaved_changes")}
            variant="warning"
            Icon={ShieldExclamationIcon}
          />
          <div className="mb-12">
            <div className="mt-3 sm:mt-5">
              <div className="mt-2 whitespace-pre-line text-base text-shade-900">
                {t("common:unsaved_changes_description")}
              </div>
            </div>
          </div>
          <DialogFooter
            onClose={() => blocker.reset?.()}
            primary={
              onSaveBeforeNavigate
                ? {
                    label: t("common:save_and_continue"),
                    variant: "tertiary",
                    onClick: async () => {
                      await Promise.resolve(onSaveBeforeNavigate());
                      handleProceed();
                    },
                  }
                : {
                    label: t("common:continue"),
                    variant: "warning",
                    onClick: () => handleProceed(),
                  }
            }
            secondary={
              onSaveBeforeNavigate
                ? {
                    label: t("common:continue"),
                    variant: "warning",
                    onClick: () => handleProceed(),
                  }
                : undefined
            }
          />
        </>
      )}
    />
  );
}
