import { PageLayout } from "~/lib/ui/page-layout";
import {
  ChangeStatusOperationRequest,
  FormDtoWithJsonFormsData,
  IListProjectsDirectionEnum,
} from "@apacta/sdk";
import { formatDate } from "~/lib/utils/date";
import { linkToEmployee, linkToForm, linkToProject, usePagination } from "~/lib/utils";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { EntityTable } from "~/lib/entity-ui";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { ProjectStatusLabel } from "~/pages/projects/_cmp/project-status-label";
import Switch from "~/lib/ui/switch";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function Index() {
  const api = useAPI();
  const [pagination, setPagination] = usePagination();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const queryKeys = ["frontpage_unapproved_forms"];
  const changeStatusM = useMutation({
    mutationFn: (args: ChangeStatusOperationRequest) => api.changeStatus(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys,
      });
    },
  });

  async function handleStatusChange(form: FormDtoWithJsonFormsData, state: boolean) {
    return changeStatusM.mutateAsync({
      projectId: form.project.id as string,
      changeStatusRequest: {
        approve: state,
        forms: [form.id as string],
      },
    });
  }

  return (
    <PageLayout title={t("frontpage:not_approved_forms")} onBackClick={() => navigate(-1)}>
      <EntityTable
        showSearch={false}
        cacheKey={queryKeys}
        dataFn={() =>
          api.formsList({
            page: pagination.page,
            sort: pagination.sort,
            direction: pagination.direction as IListProjectsDirectionEnum,
            approved: false,
          })
        }
        fields={{
          date: {
            label: t("common:date"),
            thClass: "w-12",
            renderColumn: (row: FormDtoWithJsonFormsData) => (
              <span className="font-semibold">
                {formatDate(row.created || null, i18n.resolvedLanguage, { shortDate: true })}
              </span>
            ),
            sortBy: "invoiceNo",
          },
          projectName: {
            label: t("common:project", { count: 1 }),
            href: (row: FormDtoWithJsonFormsData) => linkToProject(row.project.id),
            renderColumn: (row: FormDtoWithJsonFormsData) => <span>{row.project.name}</span>,
          },
          projectStatus: {
            label: t("common:status"),
            href: (row: FormDtoWithJsonFormsData) =>
              row.project ? linkToProject(row.project.id) : undefined,
            renderColumn: (row: FormDtoWithJsonFormsData) => (
              <ProjectStatusLabel selectedStatusId={row.project.projectStatusId ?? ""} />
            ),
          },
          formType: {
            label: t("common:type"),
            renderColumn: (row: FormDtoWithJsonFormsData) => <span>{row.template.name}</span>,
          },
          employee: {
            label: t("common:employee", { count: 1 }),
            href: (row: FormDtoWithJsonFormsData) => linkToEmployee(row.user.id),
            renderColumn: (row: FormDtoWithJsonFormsData) => (
              <span className="text-gray-400">{row.user.fullName}</span>
            ),
          },
          hours: {
            label: t("common:hour", { count: 2 }),
            renderColumn: (row: FormDtoWithJsonFormsData) => (
              <span className="text-gray-400">{row.hoursSum}</span>
            ),
          },
          approved: {
            label: t("common:approved"),
            renderColumn: (row: FormDtoWithJsonFormsData) => (
              <Switch
                defaultChecked={!!row.approved}
                onCheckedChange={(state) => handleStatusChange(row, state)}
              />
            ),
          },
        }}
        actions={[
          {
            icon: DocumentMagnifyingGlassIcon,
            label: `${t("common:show")} ${t("common:pdf")}`,
            mustConfirm: false,
            hideToast: true,
            onExecute: async (row: FormDtoWithJsonFormsData) =>
              navigate(linkToForm(row.id, "view")),
          },
        ]}
      />
    </PageLayout>
  );
}
