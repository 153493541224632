import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function OptionalLink({
  to,
  children,
  openInNewTab = false,
  className,
}: {
  to?: Parameters<typeof Link>[0]["to"];
  children: ReactNode;
  openInNewTab?: boolean;
  className?: string;
}) {
  if (to === undefined) {
    return <>{children}</>;
  }
  return (
    <Link
      to={to}
      className={twMerge("inline hover:text-hover hover:underline", className)}
      target={openInNewTab ? "_blank" : undefined}
      rel={openInNewTab ? "noopener noreferrer" : undefined}
    >
      {children}
    </Link>
  );
}
