import { useNavigationContent } from "./components/navigation-item/navigation-item.content";
import { useTranslation } from "react-i18next";
import { forwardRef, useImperativeHandle, useState } from "react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { NavItem } from "./components/navigation-item/navigation-item.type";
import { SidebarCollapsed } from "./components/sidebars/sidebar-collapsed";
import { SidebarExpanded } from "./components/sidebars/sidebar-expanded";
import { ScreenSizeType } from "~/lib/utils/tailwind/screen-sizes";

export type SidebarLaptopTypeProps = {
  navigation: Array<NavItem>;
  adminNavigation: Array<NavItem>;
  languageNavigationItem: NavItem;
  onCollapseChange: (open: boolean) => void;
  onOpenLanguageSelect: () => void;
  sidebarType: ScreenSizeType;
};

type SidebarDesktopProps = {
  onOpenLanguageSelect: () => void;
  onCollapseChange: (open: boolean) => void;
  defaultOpen?: boolean;
  sidebarType: ScreenSizeType;
};

export type SidebarDesktopRef = {
  collapse: () => void;
  expand: () => void;
};

export const SidebarDesktop = forwardRef<SidebarDesktopRef, SidebarDesktopProps>(
  (
    {
      onOpenLanguageSelect,
      onCollapseChange,
      defaultOpen = false,
      sidebarType,
    }: SidebarDesktopProps,
    ref
  ) => {
    const { navigation, adminNavigation } = useNavigationContent();
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(!defaultOpen);

    useImperativeHandle(ref, () => ({
      collapse: () => setCollapsed(true),
      expand: () => setCollapsed(false),
    }));

    // Temporary until topbar handles everything
    const languageNavigationItem = {
      name: t("ui:navigation.change_language", "Change language"),
      href: "#",
      icon: GlobeAltIcon,
    };

    const handleCollapseChange = (col: boolean) => {
      setCollapsed(col);
      onCollapseChange(col);
    };

    if (collapsed) {
      return (
        <SidebarCollapsed
          sidebarType={sidebarType}
          navigation={navigation}
          adminNavigation={adminNavigation}
          languageNavigationItem={languageNavigationItem}
          onCollapseChange={handleCollapseChange}
          onOpenLanguageSelect={onOpenLanguageSelect}
        />
      );
    }

    return (
      <SidebarExpanded
        sidebarType={sidebarType}
        navigation={navigation}
        adminNavigation={adminNavigation}
        languageNavigationItem={languageNavigationItem}
        onCollapseChange={handleCollapseChange}
        onOpenLanguageSelect={onOpenLanguageSelect}
      />
    );
  }
);

SidebarDesktop.displayName = "SidebarDesktop";
