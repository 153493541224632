import { Button, Dialog } from "~/lib/ui";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { EntityTable } from "~/lib/entity-ui";
import MimeIcon from "~/lib/ui/icons/mime-icon";
import { useAPI } from "~/lib/api";
import { usePagination } from "~/lib/utils";
import { BaseFile, IListProjectsDirectionEnum } from "@apacta/sdk";
import CreateStandardFileDialog from "~/pages/settings/index/_cmp/create-standard-file-dialog";
import { formatDate } from "~/lib/utils/date";
import { useMutation } from "@tanstack/react-query";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";

export const StandardFilesCacheKey = "STANDARD_FILES";

export default function Files() {
  const [previewBaseFile, setPreviewBaseFile] = useState<BaseFile | undefined>();
  const { t, i18n } = useTranslation();
  const api = useAPI();
  const [pagination, setPagination] = usePagination();

  const handleDeleteFile = async (id: string) => {
    await deleteMutation.mutateAsync(id);
  };

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.iStandardOfferFileDelete({ standardOfferFileId: id }),
  });

  const handlePreviewFile = (file: BaseFile) => {
    if (file.mimeType?.endsWith("pdf")) {
      setPreviewBaseFile(file);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-4">
            <h2 className="m-0">{t("common:standard_file", { count: 2 })}</h2>
            <div className="whitespace-pre-line text-sm">
              {t("settings:standard_files.description")}
            </div>
          </div>
          <div>
            <Dialog
              className="md:max-w-3xl"
              render={({ onClose }) => (
                <CreateStandardFileDialog cacheKey={StandardFilesCacheKey} onClose={onClose} />
              )}
              trigger={
                <Button variant="tertiary" className="flex-shrink-0" Icon={PlusIcon}>
                  <span>
                    {t("common:create", {
                      entity: t("common:standard_file", { count: 1 }).toLowerCase(),
                    })}
                  </span>
                </Button>
              }
            />
          </div>
        </div>
        <div>
          {/*TODO add q when ready*/}
          <EntityTable
            cacheKey={[StandardFilesCacheKey]}
            dataFn={() =>
              api.iStandardOfferFilesList({
                page: pagination.page,
                limit: pagination.limit,
                sort: pagination.sort,
                q: pagination.q,
                direction: pagination.direction as IListProjectsDirectionEnum,
              })
            }
            fields={{
              file: {
                label: t("common:file", { count: 1 }),
                tdClass: "w-2/12 group",
                renderColumn: (row) => (
                  <div className="flex items-center gap-4">
                    <MimeIcon mime={row.file.mimeType} className="h-10 w-auto" />
                  </div>
                ),
              },
              name: {
                label: t("common:name", { count: 1 }),
                tdClass: "w-5/12 group",
                renderColumn: (row) => (
                  <span
                    className="cursor-pointer hover:underline"
                    onClick={() => handlePreviewFile(row.file)}
                  >
                    {row.file.originalFilename}
                  </span>
                ),
              },
              modifiedDate: {
                label: t("common:modified_date"),
                tdClass: "w-2/12 group",
                renderColumn: (row) => (
                  <div className="flex gap-4 font-semibold">
                    {formatDate(row.modified, i18n.resolvedLanguage, {
                      shortMonth: true,
                    })}
                  </div>
                ),
              },
              entity: {
                label: t("common:availability"),
                tdClass: "w-3/12 group",
                renderColumn: (row) => (
                  <div className="flex gap-4 font-semibold">
                    {t(`settings:standard_files.availability.${row.type}`)}
                  </div>
                ),
              },
            }}
            actions={[
              {
                icon: TrashIcon,
                label: t("common:delete"),
                mustConfirm: true,
                confirmVariant: "warning",
                description: t("settings:standard_files.delete.description"),
                onExecute: async (row) => handleDeleteFile(row.id),
                hideToast: true,
              },
            ]}
          />
        </div>
      </div>
      <FullScreenFilePreview
        open={!!previewBaseFile}
        fileUrl={previewBaseFile?.fileUrl}
        onClose={() => setPreviewBaseFile(undefined)}
      />
    </>
  );
}
