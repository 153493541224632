import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Country } from "@apacta/sdk";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";

type MobileCountryCodeType = {
  id: string;
  identifier: string;
  flag: string;
  label: string;
  value: string;
};

type Phone = {
  mobile: string;
  code: string;
};

/**
 * The PhoneInput component is a custom React component designed to provide a phone input field with a dropdown list of country codes.
 * It allows users to select their country code and enter their mobile phone number.
 *
 * @param onChange A required callback function that will be called when the phone input value changes.
 * It receives an object with the mobile and code properties representing the mobile phone number and the selected country code, respectively.
 * @param initialCountryCodeId An optional initial selected country code.
 * @param initialMobile An optional initial value of the mobile phone input field.
 * @constructor
 */
export function PhoneInput({
  onChange,
  initialCountryCodeId = "45",
  initialMobile,
}: {
  onChange: (phone: Phone) => void;
  initialCountryCodeId?: string;
  initialMobile?: string;
}) {
  function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(" ");
  }
  const { t } = useTranslation();
  const [mobile, setMobile] = useState<string>(initialMobile ?? "");

  const api = useAPI();
  const { data } = useQuery({
    queryKey: ["countries"],
    queryFn: () => api.getCountries(),
  });
  const countryCodes = data?.data.map((country) => countryToSelectOption(country)) || [];

  const defaultSelectedCode =
    countryCodes.find((c) => c.value === initialCountryCodeId) ?? countryCodes[0];
  const [selectedCode, setSelectedCode] = useState<MobileCountryCodeType | null>(
    defaultSelectedCode
  );

  useEffect(() => {
    if (defaultSelectedCode !== undefined && selectedCode === undefined) {
      setSelectedCode(defaultSelectedCode);
    }
  }, [defaultSelectedCode]);

  function countryToSelectOption(country: Country): MobileCountryCodeType {
    return {
      id: country.phoneCode || "",
      identifier: country.identifier,
      flag: `/flags/${country.identifier.toLowerCase()}.png`,
      label: country.name,
      value: country.phoneCode?.substring(1) || "",
    };
  }

  useEffect(() => {
    onChange?.({ mobile: mobile, code: selectedCode?.value ?? "" });
  }, [mobile, selectedCode]);

  return (
    <div className="w-full">
      <label htmlFor="mobile" className="mb-1 block text-left text-sm font-medium text-gray-700">
        {t("customers:modal.create_customer.input.phone")}
      </label>

      <div className="h-25 relative rounded-md border border-gray-300 text-gray-700 shadow-sm">
        <div className="absolute inset-y-0 left-0 mb-2 flex items-center">
          <Listbox onChange={setSelectedCode} defaultValue={selectedCode}>
            <>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6">
                  <span className="flex items-center">
                    <img
                      src={selectedCode?.flag}
                      alt=""
                      className="h-5 w-5 flex-shrink-0 rounded-full"
                    />
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-20 mt-1 max-h-56 w-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {countryCodes.map((code) => (
                      <Listbox.Option
                        key={code.id}
                        className={({ active }) =>
                          classNames(
                            active ? "bg-cyan-700 text-white" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={code}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={code.flag}
                                alt=""
                                className="h-5 w-5 flex-shrink-0 rounded-full"
                              />
                              <span
                                className={classNames(
                                  code.id === selectedCode?.id ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {code.id} {code.label}
                              </span>
                            </div>

                            {code.id === selectedCode?.id ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          </Listbox>
        </div>
        <input
          type="text"
          name="mobile"
          id="mobile"
          className="block w-full rounded-md border-0 py-1.5 pl-20 pr-3 text-gray-900 ring-inset focus:outline-none focus:ring-2 focus:ring-transparent sm:text-sm sm:leading-6"
          min={6}
          max={32}
          defaultValue={initialMobile}
          placeholder={t("customers:modal.create_customer.input.phone")}
          onChange={(e) => setMobile(e.currentTarget.value)}
        />
      </div>
    </div>
  );
}
