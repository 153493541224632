import { Icon } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";
import { MouseEvent, useEffect, useId, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { RepetitionDropdown } from "./calendar-section/repetition-dropdown";
import { DateInput } from "./calendar-section/date-input";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { TimeSection } from "./calendar-section//time-section";
import { DurationSection } from "./calendar-section/duration-section";
import { WeekendSection } from "./calendar-section/weekend-section";

type FocusRef = "dateFrom" | "dateTo";

export function CalendarSection({ formState }: { formState: TaskFormState }) {
  const { format, stringToDate } = useLocale();
  const { t } = useTranslation();

  // If ANY of the fields are filled, we consider the section open
  const IS_OPEN =
    !!formState.getValue("fromDate") ||
    !!formState.getValue("toDate") ||
    !!formState.getValue("includeWeekends") ||
    !!formState.getValue("duration") ||
    !!formState.getValue("startTime") ||
    !!formState.getValue("endTime") ||
    !!formState.getValue("repeat") ||
    !!formState.getValue("interval");

  const [open, setOpen] = useState<boolean>(IS_OPEN);

  const [toDateVisible, setToDateVisible] = useState<boolean>(!!formState.getValue("toDate"));
  const [focusRef, setFocusRef] = useState<FocusRef | undefined>();

  const dateFromRef = useRef<HTMLInputElement | null>(null);
  const dateToRef = useRef<HTMLInputElement | null>(null);

  const [dateFromError, setDateFromError] = useState<boolean>(false);
  const [dateToError, setDateToError] = useState<boolean>(false);

  const handleOpen = (e: MouseEvent<unknown>, focus?: FocusRef) => {
    e.stopPropagation();
    setFocusRef(focus);
    setOpen(true);
  };

  useEffect(() => {
    if (focusRef === "dateFrom") {
      dateFromRef.current?.focus();
      dateFromRef.current?.select();
    } else if (focusRef === "dateTo") {
      dateToRef.current?.focus();
      dateToRef.current?.select();
    }
  }, [open]);

  const handleDateChange = (type: FocusRef, value: string | Date | null) => {
    let date: Date | null;

    // Handle clearing dates
    if (value === null) {
      if (type === "dateFrom") {
        formState.setValues({ fromDate: null });
        formState.setValues({ toDate: null });
        dateFromRef.current!.value = "";
        dateToRef.current ? (dateToRef.current.value = "") : null;
      } else {
        formState.setValues({ toDate: null });
        dateToRef.current!.value = "";
      }
      return;
    }

    if (typeof value === "string") {
      // Value is from user inputting date manually if it's a string
      try {
        date = stringToDate(value, { shortDate: true, excludeTime: true });
      } catch (e) {
        date = null;
      }
    } else {
      // Otherwise it's from the calendar component, and is a Date object
      date = new Date(value);
    }

    // Handle invalid date and setting error state
    if (!date || isNaN(date.getTime()) || date.toString() === "Invalid Date") {
      console.debug("Invalid date");
      if (type === "dateFrom") setDateFromError(true);
      if (type === "dateTo") setDateToError(true);
      return;
    }

    if (type === "dateFrom") {
      // Reset error state
      setDateFromError(false);

      const toDate = formState.getValue("toDate");

      // Check if TO date is before FROM date as we can't have TO date before FROM date
      if (toDate && toDate.getTime() < date.getTime()) {
        // Set the values in the forms tate
        formState.setValues({ toDate: date });

        // Update input with formatted date
        if (dateToRef.current) {
          dateToRef.current.value = format(date, {
            shortDate: true,
            excludeTime: true,
          });
        }
      }

      // Set the value in the form state
      formState.setValues({ fromDate: date });

      if (dateFromRef.current) {
        dateFromRef.current.value = format(date, {
          shortDate: true,
          excludeTime: true,
        });
      }
    } else {
      // Reset error state
      setDateToError(false);

      const fromDate = formState.getValue("fromDate");

      // check if to date is before from date, and update from date with to date
      if (fromDate && fromDate.getTime() > date.getTime()) {
        formState.setValues({ fromDate: date, toDate: date });
        if (dateFromRef.current) {
          dateFromRef.current.value = format(date, {
            shortDate: true,
            excludeTime: true,
          });
        }
      }
      formState.setValues({ toDate: date });
      if (dateToRef.current) {
        dateToRef.current.value = format(date, {
          shortDate: true,
          excludeTime: true,
        });
      }
    }
  };

  const dateFromId = useId();
  const dateToId = useId();

  return (
    <>
      <div className="flex items-start gap-3">
        <div
          title={`${t("common:date")}${t("common:settings").toLowerCase()}`}
          className="flex h-8 w-8 shrink-0 items-center justify-center pt-5"
        >
          <Icon name="calendarSolid" size="small" />
        </div>
        <div className="flex w-full items-center justify-between gap-4">
          <div
            onClick={(e) => handleOpen(e)}
            className={twMerge(
              "flex grow flex-col py-2 pl-3 ",
              open ? "" : "cursor-pointer hover:bg-shade-100"
            )}
          >
            <div>
              <div className="flex items-center gap-2">
                {open ? (
                  <label htmlFor={dateFromId} className="cursor-pointer hover:bg-shade-100">
                    <DateInput
                      id={dateFromId}
                      ref={dateFromRef}
                      defaultDate={formState.getValue("fromDate")}
                      onChange={(v) => handleDateChange("dateFrom", v)}
                      hasError={dateFromError}
                      disabledAfter={formState.getValue("toDate") ?? undefined}
                    />
                  </label>
                ) : (
                  <div
                    onClick={(e) => handleOpen(e, "dateFrom")}
                    className="cursor-pointer text-shade-500 hover:underline"
                  >
                    {formState.getValue("fromDate")
                      ? format(formState.getValue("fromDate")!, {
                          shortMonth: true,
                          excludeTime: true,
                        })
                      : t("common:select_entity", { entity: t("common:start_date").toLowerCase() })}
                  </div>
                )}

                {toDateVisible ? <div>&mdash;</div> : null}

                {open &&
                  (!toDateVisible ? (
                    <div
                      onClick={() => setToDateVisible(true)}
                      className="flex cursor-pointer gap-2 rounded-lg border px-2 py-1 text-sm hover:bg-shade-50"
                    >
                      <span>
                        {t("common:select_entity", { entity: t("common:end_date").toLowerCase() })}
                      </span>
                    </div>
                  ) : (
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-6">
                        <label htmlFor={dateToId} className="cursor-pointer hover:bg-shade-100">
                          <DateInput
                            id={dateToId}
                            ref={dateToRef}
                            defaultDate={
                              formState.getValue("toDate") ?? formState.getValue("fromDate") ?? null
                            }
                            onChange={(v) => handleDateChange("dateTo", v)}
                            hasError={dateToError}
                            disabledBefore={formState.getValue("fromDate") ?? undefined}
                          />
                        </label>
                      </div>

                      <div className="shrink-0">
                        <button
                          onClick={() => {
                            setToDateVisible(false);
                            formState.setValues({ toDate: undefined, includeWeekends: false });
                          }}
                          className="rounded-md border bg-white px-3 py-1 text-xs text-zinc-700 hover:bg-shade-50 focus:bg-shade-50"
                        >
                          {t("common:remove")}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              {open ? (
                <div className="flex flex-col gap-2 pt-2">
                  <RepetitionDropdown formState={formState} />
                  <WeekendSection formState={formState} />
                  <TimeSection formState={formState} />
                  <DurationSection formState={formState} />
                </div>
              ) : (
                <div className={twMerge("text-xs text-shade-500", open ? "pt-2" : "")}>
                  <span className="cursor-pointer hover:underline">
                    0 {t("common:hour", { count: 2 }).toLowerCase()}
                  </span>
                  ,&nbsp;
                  <span className="cursor-pointer hover:underline">{t("planning:no_repeat")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
