import { useContext } from "react";
import { ModalContext, ModalModel } from "./context";
import Modal from "./modal";

/**
 * Renders all the modals on the stack. You really shouldn't do more than one modal at a time
 */
export function ModalView() {
  const ctx = useContext(ModalContext);
  if (!ctx) {
    throw new Error("Missing modal Context");
  }
  const [state, dispatch] = ctx;

  const modals = Object.values(state);

  // Handles dismissal prevention
  function handleDismissal(m: ModalModel) {
    if (m.mustConfirm === undefined) {
      return m.onClose();
    }
    if (confirm(m.mustConfirm)) {
      return m.onClose();
    }
  }

  async function handleResolve(m: ModalModel, value: unknown) {
    // If there is no return value we skip this process
    if (value === undefined) {
      return m.onSubmit(value);
    }

    dispatch({
      type: "LOADING",
      id: m.id,
      value: true,
    });
    const res = await m.resolverFn?.(value);
    m.onSubmit(value);
    dispatch({
      type: "LOADING",
      id: m.id,
      value: true,
    });
    return res;
  }

  return (
    <>
      {modals.map((m) => {
        return (
          <Modal
            key={m.id}
            isOpen={m.isOpen}
            onClose={() => handleDismissal(m)} // Modal dismissed without value
            onClosed={() => dispatch({ type: "REMOVE", id: m.id })} // Cleanup
            {...m.props}
          >
            {m.fn({
              onSubmit: (value) => handleResolve(m, value),
              onClose: () => m.onClose(),
              isLoading: m.isLoading,
              setMustConfirm: (confirm?: string) => {
                dispatch({ type: "MUSTCONFIRM", value: confirm, id: m.id });
              },
            })}
          </Modal>
        );
      })}
    </>
  );
}
