import { QueryClient, QueryClientProvider as QCPrimitive } from "@tanstack/react-query";
import { ReactElement } from "react";
import { captureException } from "./lib/error-reporting";
import { useToasts } from "./lib/toast/use-toasts";
import { useMount } from "./lib/lifecycle-helpers";

const staticQueryClient = new QueryClient();

// Note that the client needs to be the same, otherwise page will re-render infinitely
// https://github.com/TanStack/query/issues/6116
export function QueryClientProvider({ children }: { children: ReactElement }) {
  const toasts = useToasts();
  useMount(() => {
    // Set defaults where toasts are available
    staticQueryClient.setDefaultOptions({
      queries: {
        retry: 1,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: 1,
        onError: (err: unknown) => {
          toasts.showTemplate("OPERATION_FAILED");
          captureException(new Error("Mutation failed", { cause: err }));
        },
        onSuccess: () => {
          toasts.showTemplate("CHANGES_SAVED");
        },
      },
    });
  });
  return <QCPrimitive client={staticQueryClient}>{children}</QCPrimitive>;
}
