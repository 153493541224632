import { EditorThemeClasses } from "lexical";

const EditorTheme: EditorThemeClasses = {
  heading: {
    h2: "text-3xl font-normal mb-2",
    h3: "text-2xl font-normal",
    h4: "text-1xl font-normal",
  },
  paragraph: "text-zinc-900 text-lg",
  list: {
    ol: "list-decimal pl-8",
    ul: "list-disc pl-8",
    listitem: "pl-2 text-lg",
  },
  text: {
    bold: "font-bold",
    italic: "italic",
    underline: "underline",
  },
};

export { EditorTheme };
