import { useOutletContext } from "react-router-dom";
import { VendorProduct } from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import TextInput from "~/lib/ui/form-elements/text-input";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useModals } from "~/lib/ui/modal";
import { Button } from "~/lib/ui";
import { roundToDecimal } from "~/lib/utils/number";
import { NumberInput } from "~/lib/ui/form-elements";

export default function OverviewVendorProductPage() {
  const api = useAPI();
  const { vendorProduct } = useOutletContext<{ vendorProduct: VendorProduct }>();
  const { t } = useTranslation();
  const { showConfirm, showModal } = useModals();

  const addAsFavoriteMutation = useMutation({
    mutationFn: () =>
      api.createProduct({
        createProductRequest: {
          vendorProductId: vendorProduct.id,
        },
      }),
  });

  async function handleAddAsFavourite() {
    await showConfirm(
      {
        variant: "success",
        Icon: ExclamationTriangleIcon,
        title: t("products:confirm_add_as_favourite.title"),
        description: t("products:confirm_add_as_favourite.description"),
      },
      async () => addAsFavoriteMutation.mutate()
    );
  }

  return (
    <>
      <TabHeading
        actionArea={
          <Button variant="tertiary" tabIndex={0} onClick={handleAddAsFavourite}>
            {t("products:vendors.add_to_my_products")}
          </Button>
        }
      >
        {t("products:product_overview")}
      </TabHeading>
      <div className="flex-1">
        <div className="mb-2 text-gray-500">{t("projects:project_data")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/4">
                <TextInput
                  label={t("products:number")}
                  disabled={true}
                  controlled={true}
                  value={vendorProduct.productNumber}
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-3/4">
                <TextInput
                  label={t("common:name")}
                  disabled={true}
                  value={vendorProduct.name}
                  controlled={true}
                />
              </div>
            </div>
            <TextInput
              label={t("common:barcode")}
              disabled={true}
              controlled={true}
              value={vendorProduct.barcode}
            />
            <TextInput
              label={t("common:vendor")}
              disabled={true}
              controlled={true}
              value={vendorProduct.vendor?.name}
            />
            <TextInput
              label={t("common:description")}
              disabled={true}
              controlled={true}
              value={vendorProduct.description}
            />
          </div>
        </div>
        <div className="mb-2 mt-5 text-gray-500">{t("common:pricing")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                <NumberInput
                  label={t("products:latest_buying_price")}
                  disabled={true}
                  defaultValue={roundToDecimal(vendorProduct.buyingPrice ?? 0)}
                  className="text-right"
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                <NumberInput
                  label={t("common:sales_price")}
                  disabled={true}
                  defaultValue={roundToDecimal(vendorProduct.sellingPrice ?? 0)}
                  className="text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
