import { InvoiceActivityLogPopover } from "./activity-log-popover";
import { InvoiceActivityLogDetailTrigger } from "./activity-log-detail-trigger";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { twMerge } from "tailwind-merge";
import { ContributionMarginCard, ContributionRatioCard } from "~/pages/projects/_kpi-cards";
import { KPIBetaCard } from "~/pages/projects/_cmp/kpi-beta-card";
import { getIcon } from "~/lib/ui";
import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";

export function InvoiceView() {
  const { t } = useTranslation();
  const { invoice, kpi } = useOutletContext<InvoiceOutlet>();
  const [loading, setLoading] = useState(true);

  return (
    <div className="flex flex-col">
      <div className="mb-4 grid grid-cols-4 gap-4">
        <KPIBetaCard
          label={t("finance:cost_price")}
          current={{
            label: t("common:work_hour_usage"),
            value: kpi.hourUsageAmount ?? 0,
            className: "bg-gray-300 text-black",
          }}
          estimated={{
            label: t("finance:cost_price"),
            value: (kpi.hourUsageAmount ?? 0) + (kpi.productUsageAmount ?? 0),
          }}
          remainder={{
            label: t("common:product_usage"),
            className: "bg-green-600 text-white",
          }}
          unit="currency"
          Icon={getIcon("currency")}
          barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
          containerClass="w-72"
        />
        <KPIBetaCard
          label={t("finance:total")}
          current={{
            label: t("finance:cost_price"),
            value: kpi.costPriceTotal,
            className: "bg-gray-300 text-black",
          }}
          estimated={{
            label: t("finance:sales_price"),
            value: kpi.salesPriceTotal,
          }}
          remainder={{
            label: t("finance:contribution"),
            className: "bg-green-600 text-white",
          }}
          unit="currency"
          Icon={getIcon("currency")}
          barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
          containerClass="w-72"
        />
        <ContributionMarginCard
          label={t("finance:contribution_margin_per_hour")}
          paddingXClassname=""
          contributionMargin={kpi.contributionMarginPerHour}
        />
        <ContributionRatioCard
          label={t("common:contribution_ratio")}
          paddingXClassname=""
          contributionRatio={kpi.contributionRate}
        />
      </div>

      <InvoiceActivityLogPopover
        invoiceId={invoice.id}
        trigger={<InvoiceActivityLogDetailTrigger invoiceId={invoice.id} />}
      />
      <h3 className="mt-4">{t("common:details")}</h3>

      <div className="h-screen w-full rounded-md bg-white p-1">
        {loading && <SkeletonLoader template="document" className="h-screen p-4" />}
        <embed
          src={invoice.pdfUrl}
          className={twMerge("h-screen w-full", loading && "hidden")}
          onLoad={() => setLoading(false)}
        />
      </div>
    </div>
  );
}
