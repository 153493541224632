import { Language } from "@apacta/sdk";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import SingleCombobox from "~/lib/ui/form-elements/combobox/single-combobox";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";

export function LanguagesInput({
  onChange,
  initialLanguageId,
  hideLabel = false,
}: {
  onChange: (languageId: string | undefined) => void;
  initialLanguageId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const [selected, setSelected] = useState<ComboboxListItem | null>(null);

  const { data } = useQuery({
    queryKey: ["languages"],
    queryFn: () => api.getLanguages(),
  });

  useEffect(() => {
    onChange?.(selected?.id);
  }, [selected]);

  const languages = useMemo(
    () => data?.data?.map((pt) => languageToListItem(pt)) || [],
    [data?.data]
  );
  const defaultLang = languages.find((i) => i.id === initialLanguageId) || null;
  const filtered = useMemo(() => {
    return languages.filter((li) => li.label.toLowerCase().includes(q.toLowerCase()));
  }, [q, languages]);

  return (
    <SingleCombobox
      items={filtered}
      className="w-full"
      label={hideLabel ? "" : t("common:language")}
      query={q}
      onInput={setQ}
      onSelect={(v) => setSelected(v)}
      onDeselect={() => setSelected(null)}
      onClear={() => setSelected(null)}
      defaultItem={defaultLang}
      inputDelay={500}
      hideDropdownButton={true}
      hideClearButton={true}
      required={true}
    />
  );
}

function languageToListItem(language: Language): ComboboxListItem {
  return {
    id: language.id || "",
    label: language.name || "",
    value: language.id || "",
  };
}
