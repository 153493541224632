export type DatePeriodType = "day" | "week" | "fortnight" | "month";

export type GetDatesProps = {
  type: DatePeriodType;
  date: Date;
};

export type DateObject = {
  date: Date;
  weekNumber: number;
  monthNumber: number;
};

export type GetDatesResult = {
  dates: Array<DateObject>;
  count: number;
};

export const periodLengths = {
  day: 1,
  week: 7,
  fortnight: 14,
  month: null,
};

export type DayNameFormat = Intl.DateTimeFormatOptions["weekday"];
export type DayNameFormatOptions = {
  format?: DayNameFormat;
  capitalizeFirst?: boolean;
};

export type FormatDateOptions = {
  shortDate?: boolean;
  excludeTime?: boolean;
  excludeYear?: boolean;
  shortMonth?: boolean;
  emptyStringOnNull?: boolean;
  localeStringFormat?: boolean;
};

export type DateInRangeOptions = {
  ignoreTimestamp?: boolean;
  exactFrom?: boolean;
  exactTo?: boolean;
};

export type MatchDatesOptions = {
  ignoreTimestamp: boolean;
};

export type DateFormatDetails = {
  locale: string;
  options: FormatDateOptions;
  formatParts: Array<Intl.DateTimeFormatPart>;
  dateFormat: string;
};
