import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { tableCellClasses } from "./table-row";
import { TableField } from "./types";

export function RowLoader<Entity>({
  className,
  fieldArr,
  hasActions,
  numberOfRows = 1,
}: {
  className?: string;
  fieldArr: Array<TableField<Entity> & { fieldKey: string }>;
  hasActions: boolean;
  numberOfRows?: number;
}) {
  const rows = [...Array(numberOfRows)];
  return (
    <>
      {rows.map((_, i) => (
        <tr key={i}>
          <>
            {fieldArr.map((fc, idx) => (
              <td key={idx} className={twMerge(tableCellClasses(fc, "td"))}>
                <ContentLoader className={twMerge("h-6", className)}>
                  <rect x={0} y={0} width="80%" height="100%"></rect>
                </ContentLoader>
              </td>
            ))}
            {hasActions ? (
              <td className="py-4 pr-4 text-right align-top">
                <ContentLoader className={twMerge("h-6 w-full", className)}>
                  <rect x={0} y={0} width="80%" height="100%"></rect>
                </ContentLoader>
              </td>
            ) : null}
          </>
        </tr>
      ))}
    </>
  );
}
