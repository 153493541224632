import { CheckCircleIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { useToasts } from "~/lib/toast/use-toasts";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import TextArea from "~/lib/ui/form-elements/textarea";
import TextInput from "~/lib/ui/form-elements/text-input";
import { FileItemType } from "..";

export function SendFilesDialog({
  onClose,
  onSuccess,
  defaultEmail = "",
  defaultSubject = "",
  defaultMessage = "",
  files,
  projectId,
}: {
  onClose: () => void;
  onSuccess?: () => void;
  defaultEmail?: string;
  defaultSubject?: string;
  defaultMessage?: string;
  files: Array<FileItemType>;
  projectId: string;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const { show: addToast } = useToasts();

  const { getValue, register } = useFormState({
    schema: {
      email: z.string().min(1).email(),
      subject: z.string().min(1),
      message: z.string().min(1),
    },
    initialValues: {
      email: defaultEmail,
      subject: defaultSubject,
      message: defaultMessage,
    },
  });

  const m = useMutation({
    mutationFn: () =>
      api.iProjectSendFiles({
        projectId: projectId,
        iProjectSendFilesRequest: {
          email: getValue("email"),
          message: getValue("message"),
          subject: getValue("subject"),
          projectFiles: files.map((f) => f.id),
        },
      }),
    onSuccess: () => {
      addToast({
        title: t("common:changes_saved"),
        description: t("common:changes_saved_description"),
        Icon: CheckCircleIcon,
        timeout: 5000,
      });
      onSuccess?.();
      onClose();
    },
  });

  async function handleSend() {
    await m.mutateAsync();
  }

  return (
    <>
      <DialogHeader Icon={EnvelopeIcon} title={t("projects:send_files")} />
      <div className="mb-6">
        <Trans
          i18nKey="projects:sending_x_files"
          values={{ x: files.length }}
          count={files.length}
        />
      </div>
      <div className="flex flex-col gap-4">
        <TextInput {...register("email")} label={t("common:email", "Email")} />
        <TextInput {...register("subject")} label={t("common:subject", "Subject")} />
        <TextArea required label={t("common:message")} initialValue={getValue("message")} />
      </div>
      <DialogFooter primary={{ label: t("common:send"), onClick: handleSend }} onClose={onClose} />
    </>
  );
}
