import { useOutletContext } from "react-router-dom";
import {
  CreateProductRequest,
  DeleteProductVariantsRequest,
  Product,
  ProductVariant,
} from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useToasts } from "~/lib/toast/use-toasts";
import Switch from "~/lib/ui/switch";
import { useAPI } from "~/lib/api";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, Fragment } from "react";
import { Radio } from "~/lib/ui/form-elements/radio";
import ProductPopover, { ProductItem } from "~/lib/products/product-popover";
import { Button } from "~/lib/ui";
import { formatDate } from "~/lib/utils/date";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { EntityActionButtons } from "~/lib/entity-ui/action-buttons";
import { formatCurrency } from "~/lib/utils/number";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useToastOnError } from "~/lib/utils/hooks";

export default function PricingProductPage() {
  const { product } = useOutletContext<{ product: Product }>();
  const { t } = useTranslation();
  const api = useAPI();
  const toast = useToasts();
  const queryClient = useQueryClient();
  const queryKey = ["variants"];
  const { isModified, isValid, registerNumberInput, getValue, setValues } = useFormState({
    schema: {
      buying_price: z.number().optional(),
      sales_price: z.number().optional(),
      contribution_ratio: z.number().optional(),
      price_type: z.enum(["manual", "cost_based"]),
    },
    initialValues: {
      buying_price: product.buyingPrice ? Number(product.buyingPrice.toFixed(2)) : undefined,
      sales_price: product.sellingPrice ? Number(product.sellingPrice.toFixed(2)) : undefined,
      contribution_ratio: initialContributionRatio(),
      price_type: product.priceType ?? "cost_based",
    },
  });

  function initialContributionRatio() {
    const buyingPrice = product.buyingPrice ? Number(product.buyingPrice.toFixed(2)) : 0;
    const salesPrice = product.sellingPrice ? Number(product.sellingPrice.toFixed(2)) : 0;
    const contributionMargin =
      salesPrice > 0 && salesPrice > buyingPrice ? salesPrice - buyingPrice : 0;

    return salesPrice > 0 ? Math.round((contributionMargin / salesPrice) * 100) : 0;
  }

  const dataQuery = useSuspenseQuery({
    queryFn: () =>
      api.productVariants({
        productId: product.id,
      }),
    queryKey: queryKey,
  });
  useToastOnError(dataQuery.error);

  const productVariants = dataQuery.data?.data ?? [];

  const updatePrimaryOption = function (row: ProductVariant) {
    const latestPrice = Number(row.latestPrice.toFixed(2));
    setValues({ buying_price: latestPrice });
    api
      .setPrimaryVariant({
        setPrimaryVariantRequest: {
          productId: product.id,
          variantId: row.id,
        },
      })
      .then((v) => {
        refreshData();
      });
  };

  async function refreshPrimaryOption() {
    api.productsView({ productId: product.id as string, includeVariants: true }).then((res) => {
      const primaryVariant = res.data.variants?.find((e) => e.isPrimaryBuyingOption);
      if (primaryVariant) {
        updatePrimaryOption(primaryVariant);
      }
    });
  }

  const editProductMutation = useMutation({
    mutationFn: (args: CreateProductRequest) =>
      api.editProduct({ productId: product.id, createProductRequest: args }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED", { timeout: 10000 });
      // Update product price when primary option is selected
      refreshPrimaryOption();
      refreshData();
    },
  });

  function onAddVariant(productItem: ProductItem) {
    editProductMutation.mutate({
      variantType: productItem.type,
      variantId: productItem.item.id,
    });
  }

  function handleSave() {
    editProductMutation.mutate({
      buyingPrice: getValue("buying_price"),
      sellingPrice: getValue("sales_price"),
      priceType: getValue("price_type"),
    });
  }

  function handleBuyingPrice(val: number | FormEvent<HTMLInputElement>) {
    if (typeof val !== "number") {
      val = 0;
    }

    const salesPrice = getValue("sales_price") ?? 0;
    const contributionMargin = salesPrice > 0 && salesPrice > val ? salesPrice - val : 0;
    const contributionRatio =
      salesPrice > 0
        ? Math.round((contributionMargin / salesPrice) * 100)
        : getValue("contribution_ratio");

    setValues({ buying_price: val ?? 0, contribution_ratio: contributionRatio });
  }

  function handleSalesPrice(val: number | FormEvent<HTMLInputElement>) {
    if (typeof val !== "number") {
      val = 0;
    }

    const buyingPrice = getValue("buying_price") ?? 0;
    const contributionMargin = val > 0 && val > buyingPrice ? val - buyingPrice : 0;
    const contributionRatio =
      val > 0 ? Math.round((contributionMargin / val) * 100) : getValue("contribution_ratio");

    setValues({ sales_price: val ?? 0, contribution_ratio: contributionRatio });
  }

  function handleContributionRatio(val: number | FormEvent<HTMLInputElement>) {
    if (typeof val !== "number") {
      val = 0;
    }
    const buyingPrice = getValue("buying_price") ?? 0;
    const salesPrice = buyingPrice > 0 ? Math.round(buyingPrice / (1 - val / 100)) : 0;

    setValues({ contribution_ratio: val, sales_price: salesPrice ?? 0 });
  }

  const variantDelete = useMutation({
    mutationFn: (args: DeleteProductVariantsRequest) =>
      api.deleteProductVariants({ deleteProductVariantsRequest: args }),
    onSuccess: () => {
      refreshData();
    },
    onError: () => {
      toast.show({
        title: t("products:variant_delete_error_title"),
        description: t("products:variant_delete_error_description"),
        Icon: ExclamationCircleIcon,
        variant: "error",
        timeout: 5000,
      });
    },
  });

  const handleDeleteVariant = async (productVariant: ProductVariant) => {
    if (!productVariant.id) return;
    const variantType = productVariant.type === "expense_line" ? "expenseLines" : "vendorProducts";
    await variantDelete.mutateAsync({
      productId: product.id,
      [variantType]: [productVariant.id],
    });
  };

  const buyingPriceDisabled =
    productVariants.length > 0 ||
    (productVariants.length > 0 && getValue("price_type") === "cost_based");

  const onToggleBuyingOption = (e: ChangeEvent<HTMLInputElement>, row: ProductVariant) => {
    updatePrimaryOption(row);
  };

  function refreshData() {
    queryClient.invalidateQueries({
      queryKey: ["product", product.id],
    });
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  }

  const MANUAL_PRICING_DISABLED =
    (getValue("price_type") === "cost_based" && productVariants.length <= 0) ||
    (productVariants.length > 0 && getValue("price_type") === "cost_based");

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            disabled={!isModified || !isValid}
            variant="tertiary"
            tabIndex={0}
            onClick={handleSave}
          >
            {t("common:save")}
          </Button>
        }
      >
        {t("products:pricing")}
      </TabHeading>
      <div className="flex-1">
        <div className="mb-2 text-gray-500">{t("projects:pricing")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                {buyingPriceDisabled ? (
                  <NumberFormatInput
                    label={t("products:cost_price")}
                    className="text-right text-sm"
                    name="buying_price_disabled"
                    value={getValue("buying_price")}
                    onChange={(v) => handleBuyingPrice(v)}
                    maximumDecimals={2}
                    disabled
                  />
                ) : (
                  <NumberFormatInput
                    label={t("products:cost_price")}
                    className="text-right text-sm"
                    name="buying_price"
                    defaultValue={getValue("buying_price")}
                    onChange={(v) => handleBuyingPrice(v)}
                    maximumDecimals={2}
                  />
                )}
                <div className="flex-1 pb-4">
                  <Switch
                    label={t("products:manual_selling_price_label")}
                    defaultChecked={getValue("price_type") === "manual"}
                    onCheckedChange={(v) => {
                      setValues({ price_type: v ? "manual" : "cost_based" });
                    }}
                    className="justify-between sm:justify-start"
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  label={t("products:selling_price")}
                  className="text-right text-sm"
                  defaultValue={getValue("sales_price") ?? ""}
                  onChange={(v) => (MANUAL_PRICING_DISABLED ? undefined : handleSalesPrice(v))}
                  disabled={MANUAL_PRICING_DISABLED}
                />
              </div>
              <div className="w-1/16 pt-8 text-center">{t("common:or", "eller")}</div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  defaultValue={getValue("contribution_ratio") ?? ""}
                  label={t("common:contribution_ratio")}
                  className="text-right text-sm"
                  onChange={(v) =>
                    MANUAL_PRICING_DISABLED ? undefined : handleContributionRatio(v)
                  }
                  disabled={MANUAL_PRICING_DISABLED}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-2">
          <div className="mb-10"></div>
          <div className="mb-2 mr-2 mt-5">
            <ProductPopover
              triggerRender={() => (
                <Button variant="secondary" className="flex items-center gap-3">
                  <span>{t("products:variant.add.title")}</span>
                </Button>
              )}
              onSelect={(item) => {
                onAddVariant(item);
              }}
              options={{ allowedProductTypes: ["expense_line", "vendor_product"] }}
            />
          </div>
        </div>
        <div className="-mb-5 text-gray-500">{t("products:buying_option")}</div>
        <div className="table">
          <table className="mt-0 text-left">
            <thead>
              <tr>
                <th>{t("products:variants.primary")}</th>
                <th>{t("common:name")}</th>
                <th>{t("products:variants.latest_update")}</th>
                <th>{t("products:variants.latest_price")}</th>
                <th>{t("common:vendor")}</th>
                <th>{t("common:type")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productVariants.map((item) => (
                <Fragment key={item.id}>
                  <tr>
                    <td>
                      <div className="flex items-center gap-4">
                        <Radio
                          checked={item.isPrimaryBuyingOption}
                          onChange={(e) => onToggleBuyingOption(e, item)}
                        />
                      </div>
                    </td>
                    <td className="font-semibold">
                      <div className="items-center">{item.name}</div>
                    </td>
                    <td>
                      <div className="items-center">
                        {formatDate(item.latestUpdated, getResolvedLanguage(), { shortDate: true })}
                      </div>
                    </td>
                    <td className="items-center">
                      {formatCurrency(item.latestPrice, { maximumFractionDigits: 2 })}
                    </td>
                    <td className="items-center">
                      <div>{item.vendor?.name}</div>
                    </td>
                    <td className="items-center">
                      <div>{t(`products:${item.type}`)}</div>
                    </td>
                    <td className="items-center">
                      <EntityActionButtons
                        entity={item}
                        actions={[
                          {
                            icon: TrashIcon,
                            label: t("products:variant.modal.delete.title"),
                            mustConfirm: true,
                            description: t("products:variant.modal.delete.description"),
                            onExecute: (row) => handleDeleteVariant(row),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
