import { useParams } from "react-router";
import { BACKEND_URL } from "~/lib/auth/config";

const invoiceURL = (id: string) => `${BACKEND_URL}/companies/invoices/getPDF/${id}`;

export default function InvoicePreview() {
  const { id } = useParams<{ id: string }>();
  if (!id) return null;

  // TODO: Revisit this later. Invoice PDFs are currently only created for invoiced invoices.

  return (
    <>
      <embed src={invoiceURL(id)} className="h-full w-full" />
    </>
  );
}
