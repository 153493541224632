import { formatCurrency } from "~/lib/utils/number";
import StackedBarChart, { BarChartData } from "~/lib/ui/charts/stacked-bar-chart";
import { useTranslation } from "react-i18next";

export default function ContributionChart({ data = [] }: { data: Array<BarChartData> }) {
  const { t } = useTranslation();
  return (
    <StackedBarChart
      className="h-[18rem] md:h-[26rem]"
      data={data}
      yAxisFormatter={(value) => formatCurrency(value)}
      xAxisFormatter={(value) => `${t("common:week")} ${value}`}
      legendFormatter={(value) => t(`common:${value}`)}
      totalFormatter={(values) => {
        const total = values.dataSeries.reduce((acc, curr) => {
          return acc + Object.values(curr)[0];
        }, 0);
        return formatCurrency(total);
      }}
    />
  );
}
