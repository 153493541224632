// Purpose: Shows a list of actions as a button group or as a dropdown on mobile

import { useMutation } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";
import { useModals } from "../ui/modal";
import { Spinner } from "../ui/spinner";
import { EntityAction } from "./types";
import { useMemo, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useToasts } from "../toast/use-toasts";
import { useTranslation } from "react-i18next";
import { Icon } from "../ui";

// - If on mobile, it will become a dropdown menu instead.
export function EntityActionButtons<Entity>({
  entity,
  actions,
  onRefresh,
  collapseAt = 4,
  buttonClassName,
  dropdownClassName,
}: {
  entity: Entity;
  actions: Array<EntityAction<Entity>>;
  onRefresh?: () => void;
  collapseAt?: number;
  buttonClassName?: string;
  dropdownClassName?: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const modal = useModals();
  const toast = useToasts();
  const { t } = useTranslation();

  const mutation = useMutation({
    mutationFn: ({ action }: { action: EntityAction<Entity>; idx: number }) =>
      action.onExecute(entity),
    onSuccess: (data, { action }) => {
      if (action.hideToast) {
        return;
      }
      toast.showTemplate("CHANGES_SAVED");
    },
    onSettled: (data, error, { action }) => !action.skipRefresh && onRefresh?.(),
  });

  const visibleActions = useMemo(
    () => actions.filter((action) => (action.isVisible ? action.isVisible(entity) : true)),
    [actions, entity]
  );

  async function handleActionPress(action: EntityAction<Entity>, idx: number) {
    if (action.mustConfirm) {
      const confirmed = await modal.showConfirm({
        Icon: action.icon,
        title: action.label,
        variant: action.confirmVariant || "alert",
        description:
          typeof action.description === "function"
            ? action.description(entity)
            : action.description,
      });
      if (!confirmed) {
        return;
      }
    }
    mutation.mutate({ action, idx });
  }

  const baseClass =
    "relative inline-flex items-center border bg-white text-gray-700 border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover";
  const isCollapsed = visibleActions.length < collapseAt; // This converts to a drop-down menu if there are too many items

  return (
    <>
      <span
        className={twMerge("isolate hidden rounded-md shadow-sm", isCollapsed && "lg:inline-flex")}
      >
        {visibleActions.map(({ icon: IconP, label }, idx) => (
          <button
            key={idx}
            type="button"
            title={label}
            aria-label={label}
            onClick={(e) => {
              e.stopPropagation(); // Otherwise it triggers the row click
              handleActionPress(visibleActions[idx], idx);
            }}
            className={twMerge(
              baseClass,
              idx === 0 && "rounded-l-md",
              idx === visibleActions.length - 1 && "rounded-r-md",
              buttonClassName
            )}
          >
            {mutation.isPending && mutation.variables?.idx === idx ? (
              <Spinner className="h-5 w-5" />
            ) : IconP ? (
              <IconP className="inline h-5 w-5" />
            ) : (
              label
            )}
          </button>
        ))}
      </span>
      <span className={twMerge("inline-flex", isCollapsed && "lg:hidden")}>
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger className={twMerge("-ml-px")}>
            <div
              className={twMerge(
                "rounded-md border hover:bg-tertiary hover:text-white",
                dropdownClassName
              )}
            >
              <Icon name="menuKebab" size="medium" />
            </div>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="end">
              <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
                <ul>
                  {visibleActions.map((a, idx) => (
                    <li
                      key={`item-${idx}`}
                      className="flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-tertiary hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleActionPress(a, idx);
                      }}
                    >
                      <div className="flex items-center gap-4">
                        {a.icon && <a.icon className="h-5 w-5" />}
                        <span>{a.label}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </span>
    </>
  );
}
