import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/lib/ui";
import { ButtonVariant } from "~/lib/ui/buttons/shared/button.shared";

interface Action {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => unknown | Promise<unknown>;
  variant?: ButtonVariant;
  type?: ComponentProps<typeof Button>["type"];
}

export function DialogFooter({
  primary,
  secondary,
  onClose,
  hideCancel,
}: {
  primary: Action;
  secondary?: Action;
  onClose: () => void;
  hideCancel?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <hr className="mx-auto w-[95%]"></hr>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start pt-6">
          {secondary && (
            <Button
              disabled={secondary.disabled || secondary.loading}
              loading={secondary.loading}
              variant={secondary.variant ?? "secondary"}
              className="justify-center outline-1 outline-green-900"
              onClick={secondary.onClick}
            >
              <span>{secondary.label}</span>
            </Button>
          )}
        </div>

        <div className="flex items-center justify-end gap-6 pt-6">
          {!hideCancel && (
            <Button
              className="cursor-pointer justify-center underline hover:text-hover"
              onClick={() => onClose()}
            >
              {t("common:cancel")}
            </Button>
          )}
          <Button
            type={primary.type ?? "submit"}
            variant={primary.variant ?? "tertiary"}
            className="justify-center outline-1 outline-green-900"
            disabled={primary.disabled || primary.loading}
            loading={primary.loading}
            onClick={primary.onClick}
          >
            <span>{primary.label}</span>
          </Button>
        </div>
      </div>
    </>
  );
}
