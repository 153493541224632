import { FilterList } from "~/lib/ui/form-elements/filter-list";
import TextInput from "~/lib/ui/form-elements/text-input";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import { Popover } from "~/lib/ui/popover/popover";
import { ProductBundle } from "@apacta/sdk";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { formatCurrency } from "~/lib/utils/number";
import { useProductBundles } from "~/lib/products/use-product-bundles";
import { twMerge } from "tailwind-merge";
import { Icon } from "../ui";

type ProductBundlePopoverOptions = {
  multiselect: boolean;
};

export default function ProductBundlePopover({
  triggerRender,
  initialSelectedProductBundles,
  onOpenChange,
  onSelect,
  options,
}: {
  triggerRender: () => ReactNode;
  initialSelectedProductBundles?: Array<ProductBundle>;
  onOpenChange?: (open: boolean) => void;
  onSelect?: (productBundles: Array<ProductBundle>) => void;
  options?: ProductBundlePopoverOptions;
}) {
  const { t } = useTranslation();

  const defaultProductPopoverOptions: ProductBundlePopoverOptions = {
    multiselect: true,
  };

  const opts = { ...defaultProductPopoverOptions, ...options };

  const [productBundleSearchInput, setProductBundleSearchInput] = useState<string>("");

  const { productBundles, isFetchingProductBundles, setProductBundleQuery } = useProductBundles();

  const handleSetProducts = (
    selectedProductBundles: Array<ProductBundle>,
    closeCallback: () => void
  ) => {
    onSelect?.(selectedProductBundles);
    closeCallback();
  };

  useDebounce(
    () => {
      setProductBundleQuery(productBundleSearchInput);
    },
    [productBundleSearchInput],
    500
  );

  const handleOpenChange = (to: boolean) => {
    setProductBundleSearchInput("");
    onOpenChange?.(to);
  };

  const calculateTotalCostPrice = (productBundle: ProductBundle) => {
    return (
      productBundle.productBundleLines?.reduce(
        (a, b) => a + (b.buyingPrice ?? 0) * b.quantity,
        0
      ) ?? 0
    );
  };

  return (
    <Popover
      onOpenChange={handleOpenChange}
      config={{ side: "right" }}
      triggerAsChild={true}
      triggerRender={() => triggerRender()}
    >
      {(close) => (
        <div className="flex h-96 min-w-[18em] max-w-2xl flex-shrink-0 flex-col gap-2 overflow-hidden rounded-md border bg-white p-2 shadow-sm">
          <FilterList
            isLoading={isFetchingProductBundles}
            divideItems={true}
            filterRender={() => (
              <div className="flex">
                <TextInput
                  id="employee-search"
                  label=""
                  placeholder={`${t("common:search")} ${t("common:product_bundle", {
                    count: 2,
                  }).toLowerCase()}`}
                  onChange={(value) => setProductBundleSearchInput(value)}
                />
              </div>
            )}
            itemRender={({ item, isSelected, triggerSelect }) => (
              <div
                key={`user-${item.id}`}
                className={twMerge(
                  "flex cursor-pointer items-center justify-between rounded-md px-2 py-2 hover:bg-gray-100"
                )}
                onClick={() =>
                  opts.multiselect ? triggerSelect() : handleSetProducts([item], close)
                }
              >
                <div className="flex flex-col gap-2">
                  <span>{item.name}</span>
                  <span className="text-sm">
                    {t("finance:cost_price")}:{" "}
                    {formatCurrency(calculateTotalCostPrice(item), { currency: "DKK" })}
                  </span>
                </div>
                <div className="ml-4">
                  <Icon
                    name="selectedCheck"
                    className={twMerge("h-5 w-5", !isSelected && "invisible")}
                  />
                </div>
              </div>
            )}
            buttonRender={(productList) => (
              <div className="flex items-center justify-end gap-6">
                <span
                  className="cursor-pointer justify-center underline hover:text-hover"
                  onClick={() => close()}
                >
                  {t("common:cancel")}
                </span>
                {opts.multiselect && (
                  <ButtonLegacy
                    variant="primary"
                    className="justify-center"
                    onClick={() => handleSetProducts(productList, close)}
                  >
                    {t("common:confirm")}
                  </ButtonLegacy>
                )}
              </div>
            )}
            items={productBundles}
            initialSelected={initialSelectedProductBundles}
          />
        </div>
      )}
    </Popover>
  );
}
