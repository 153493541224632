import { HTMLProps, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Input } from "./form-elements";
import { Button, Icon } from ".";

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
  debounce?: number;
  onChangeValue: (value: string) => void;
  onChange?: never;
  onClear?: () => void;
}
// Inspired by: https://codesandbox.io/p/devbox/github/tanstack/table/tree/main/examples/react/kitchen-sink?embed=1&file=%2Fsrc%2Fcomponents%2FDebouncedInput.tsx&theme=dark
export const SearchInput = forwardRef(function SearchInput(
  { debounce = 500, value: initialValue, onChangeValue, onClear, ...props }: SearchInputProps,
  ref: Ref<HTMLInputElement>
) {
  const [value, setValue] = useState<string>((initialValue as string) ?? "");
  const lastValue = useRef<typeof value>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  // Trigger onChange after debounce
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value === undefined || value === "") return;
      if (value === lastValue.current) return;
      onChangeValue(value);
      lastValue.current = value;
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  function handleClear() {
    setValue("");
    onChangeValue("");
    onClear?.();
  }

  const hasValue = value !== "";

  return (
    <div className="smd:w-1/2 relative w-full">
      <Input ref={ref} {...props} onChange={handleInputChange} value={value} />
      {hasValue ? (
        <Button
          className="absolute right-0 top-0 mr-2 mt-2 rounded-full p-1 hover:text-hover"
          disabled={value === ""}
          onClick={handleClear}
        >
          <Icon name="clearSearch" className="h-5 w-5" />
        </Button>
      ) : (
        <Button
          className="absolute right-0 top-0 mr-2 mt-2 rounded-full p-1 hover:text-hover"
          disabled={value === ""}
        >
          <Icon name="search" className="h-5 w-5" />
        </Button>
      )}
    </div>
  );
});
