import { TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { getInitials, linkToCustomer } from "~/lib/utils";
import { useQueryClient } from "@tanstack/react-query";

import { EntityTable } from "~/lib/entity-ui";
import { usePagination } from "~/lib/utils/hooks";
import { twMerge } from "tailwind-merge";

type CustomersTableProps = {
  onPageChange?: (v: number) => void;
};

const CustomersTable = ({}: CustomersTableProps) => {
  const api = useAPI();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  async function handleDeleteCustomer(customerId: string) {
    if (!customerId) return;
    return api.deleteContact({ contactId: customerId }).then(() => {
      queryClient.invalidateQueries({
        queryKey: ["customers"], // this causes a refetch
      });
    });
  }

  const [pagination] = usePagination();

  return (
    <EntityTable
      cacheKey={["customers"]}
      dataFn={() =>
        api.getContacts({
          page: pagination.page,
          q: pagination.q,
          sort: pagination.sort ?? "name",
          direction: pagination.direction ?? "asc",
          limit: pagination.limit,
        })
      }
      fields={{
        name: {
          label: t("customers:table.columns.name"),
          href: (row) => linkToCustomer(row.id),
          tdClass: "max-w-sm",
          renderColumn: (row) => (
            <div className="flex items-center gap-4">
              <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-300 text-base font-semibold text-zinc-800 sm:h-12 sm:w-12 sm:text-lg">
                {getInitials(row.name)}
              </div>
              <span className="font-semibold">{row.name}</span>
            </div>
          ),
          sortBy: "name",
        },
        address: {
          label: t("customers:table.columns.address"),
          renderColumn: (row) => (
            <div className={twMerge("flex flex-col", !row.address && "text-gray-400")}>
              {row.address ? (
                <>
                  <span>{row.address}</span>
                  <span>
                    {row.city?.zipCode} {row.city?.name}
                  </span>
                </>
              ) : (
                <span>{t("common:not_available")}</span>
              )}
            </div>
          ),
          collapse: {
            on: "name",
            breakpoint: "xl",
          },
        },
        contact: {
          label: t("customers:table.columns.contact"),
          renderColumn: (row) => (
            <div className={twMerge("flex flex-col", !row.email && !row.phone && "text-gray-400")}>
              {row.email || row.phone ? (
                <>
                  <span>{row.email}</span>
                  <span>
                    {row.phoneCountrycode} {row.phone}
                  </span>
                </>
              ) : (
                <span>{t("common:not_available")}</span>
              )}
            </div>
          ),
          collapse: {
            breakpoint: "lg",
            on: "name",
          },
          sortBy: "not_invoiced_amount",
        },
      }}
      actions={[
        {
          icon: TrashIcon,
          label: t("customers:modal.delete.title"),
          mustConfirm: true,
          description: t("customers:modal.delete.description"),
          onExecute: (row) => handleDeleteCustomer(row.id),
        },
      ]}
    />
  );
};

CustomersTable.displayName = "CustomersTable";

export { CustomersTable };
