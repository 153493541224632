import { EntityTable } from "~/lib/entity-ui";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { RichTextEditor, RichTextEditorRef } from "~/lib/ui/rich-text-editor";
import { usePagination } from "~/lib/utils";
import TextInput from "~/lib/ui/form-elements/text-input";
import { useModals } from "~/lib/ui/modal";
import CreateTemplateModal, {
  CreateTemplateData,
} from "~/pages/settings/index/_cmp/create-template-modal";
import { useAPI } from "~/lib/api";
import { TextTemplate } from "@apacta/sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import Switch from "~/lib/ui/switch";
import { deepEqual } from "~/lib/utils/object/deep-equal";
import { Button } from "~/lib/ui";

export default function TemplatesPage() {
  const [localEntity, setLocalEntity] = useState<TextTemplate | null>(null);
  const [expandedEntity, setExpandedEntity] = useState<TextTemplate | null>(null);
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const editorRef = useRef<RichTextEditorRef>(null);
  const [editorValidState, setEditorValidState] = useState<boolean>(true);

  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const [pagination] = usePagination();

  const api = useAPI();

  const { getValue, register, isValid, setValues } = useFormState({
    schema: {
      name: z.string().min(1),
    },
    initialValues: {
      name: "",
    },
  });

  const handleAction = async (action: "duplicate" | "delete", entityId: string) => {
    if (action === "delete") {
      await api.deleteTextTemplate({ textTemplateId: entityId });
      await queryClient.invalidateQueries({
        queryKey: ["templates"],
      });
    } else {
      const fetchTemplate = await api.getTextTemplate({ textTemplateId: entityId });
      if (fetchTemplate && fetchTemplate.data) {
        const template = fetchTemplate.data;
        await api.createTextTemplate({ createTextTemplateRequest: template });
      }
    }
  };

  const toggleRowExpansion = (entity: TextTemplate) => {
    setExpandedEntity((prevValue) => {
      if (prevValue?.id === entity.id) {
        setValues({ name: "" }, true);
        return null;
      }
      setValues({ name: entity.title }, true);
      return entity;
    });
  };

  // Set local entity to expanded entity to allow editing and checking for changes
  useEffect(() => {
    if (!expandedEntity) {
      setLocalEntity(null);
    } else {
      const copy = { ...expandedEntity };
      setLocalEntity(copy);
    }
  }, [expandedEntity]);

  const handleRTEChange = async (validState: boolean) => {
    setEditorValidState(validState);
    if (editorRef.current) {
      const content = await editorRef.current.getEditorMarkdown();
      setLocalEntity((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          content,
        };
      });
    }
  };

  // update local entity with new title when name changes
  useEffect(() => {
    if (expandedEntity) {
      setLocalEntity((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          title: getValue("name"),
        };
      });
    }
  }, [getValue("name")]);

  const handleAvailabilityChange = (
    entityId: string,
    key: keyof Pick<TextTemplate, "expense" | "invoice" | "offer">,
    value: boolean
  ) => {
    setLocalEntity((prev) => {
      if (!prev) return null;
      if (prev.id !== entityId) return prev;
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  // Check if local entity is different from expanded entity and whether it is valid
  useEffect(() => {
    if (localEntity && expandedEntity) {
      if (!editorValidState) {
        setSaveEnabled(false);
        return;
      }

      if (deepEqual({ ...localEntity }, { ...expandedEntity })) {
        setSaveEnabled(false);
        return;
      }

      if (isValid && localEntity.content !== "") {
        setSaveEnabled(true);
        return;
      }

      setSaveEnabled(false);
      return;
    }
    setSaveEnabled(false);
  }, [localEntity, isValid]);

  const saveChanges = async () => {
    if (localEntity) {
      await api
        .editTextTemplate({
          textTemplateId: localEntity.id,
          editTextTemplateRequest: localEntity,
        })
        .then(() => {
          setExpandedEntity(null);
          setSaveEnabled(false);
          queryClient.invalidateQueries({
            queryKey: ["templates"],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const cancelEdit = (originalContent: string) => {
    if (editorRef.current) {
      editorRef.current.setEditorMarkdown(originalContent);
      setExpandedEntity(null);
      setSaveEnabled(false);
    }
  };

  const handleCreateTemplate = async (data: CreateTemplateData, cb: () => void) => {
    await api.createTextTemplate({
      createTextTemplateRequest: {
        content: data.content,
        title: data.name,
        expense: data.availability.expense,
        offer: data.availability.offer,
        invoice: data.availability.invoice,
      },
    });
    await queryClient.invalidateQueries({
      queryKey: ["templates"],
    });
    cb();
  };

  const { showModal } = useModals();
  const showCreateModal = async () => {
    await showModal(
      ({ onSubmit, onClose }) => (
        <CreateTemplateModal
          onSubmit={(data) => handleCreateTemplate(data, onSubmit)}
          onClose={onClose}
        />
      ),
      {
        props: {
          size: "4xl",
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("settings:tabs.templates")}</h2>
        <div>
          <Button
            onClick={() => showCreateModal()}
            variant="tertiary"
            Icon={PlusIcon}
            loading={false}
          >
            <span>
              {t("common:create", { entity: t("common:template", { count: 1 }).toLowerCase() })}
            </span>
          </Button>
        </div>
      </div>
      <div>
        <EntityTable
          cacheKey={["templates"]}
          dataFn={() =>
            api.getTextTemplates({
              page: pagination.page,
              q: pagination.q,
              sort: pagination.sort ?? "created",
              direction: pagination.direction ?? "desc",
            })
          }
          fields={{
            name: {
              label: t("settings:templates.table.name"),
              tdClass: "w-8/12 group",
              renderColumn: (row) => (
                <div className="flex gap-4 font-semibold">
                  {row.id === expandedEntity?.id ? (
                    <ChevronDownIcon className="h-5 w-5" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                  <span>{row.title}</span>
                </div>
              ),
            },
            available: {
              label: t("common:enabled"),
              thClass: "text-center",
              tdClass: "w-40",
              align: "center",
              renderColumn: (row) => (
                <div className="flex justify-center">
                  {row.offer ? (
                    <CheckIcon className="h-6 w-6 text-green-500" />
                  ) : (
                    <XMarkIcon className="h-6 w-6 text-red-500" />
                  )}
                </div>
              ),
            },
          }}
          onRowClick={(entity) => toggleRowExpansion(entity)}
          renderRowExpansion={({ entity, numberOfColumns }) => {
            if (entity.id === expandedEntity?.id)
              return (
                <tr>
                  <td colSpan={numberOfColumns}>
                    <div className="flex flex-col gap-8">
                      <TextInput {...register("name")} label={t("settings:templates.table.name")} />
                      <RichTextEditor
                        ref={editorRef}
                        label={`${t("common:template", { count: 1 })}*`}
                        required={true}
                        initialData={entity.content}
                        onChange={(text, html, valid) => handleRTEChange(valid)}
                      />
                      <div>
                        <span className="mb-1 block text-left text-sm font-medium text-gray-700">
                          {t("settings:templates.modal.availability")}
                        </span>
                        <div className="mt-4 flex gap-12">
                          <div className="hidden flex-col gap-2">
                            <label>{t("common:invoice", { count: 1 })}</label>
                            <Switch
                              onCheckedChange={(val) =>
                                handleAvailabilityChange(entity.id, "invoice", val)
                              }
                            />
                          </div>
                          <div className="hidden flex-col gap-2">
                            <label>{t("common:expense", { count: 1 })}</label>
                            <Switch
                              onCheckedChange={(val) =>
                                handleAvailabilityChange(entity.id, "expense", val)
                              }
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label>{t("common:enabled", { count: 1 })}</label>
                            <Switch
                              defaultChecked={entity.offer}
                              onCheckedChange={(val) =>
                                handleAvailabilityChange(entity.id, "offer", val)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-4">
                        <Button
                          variant="tertiary"
                          disabled={!saveEnabled}
                          onClick={() => saveChanges()}
                        >
                          {t("common:save")}
                        </Button>
                        <Button variant="secondary" onClick={() => cancelEdit(entity.content)}>
                          {t("common:cancel")}
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
          }}
          actions={[
            {
              icon: DocumentDuplicateIcon,
              label: t("common:duplicate"),
              mustConfirm: false,
              onExecute: (row) => handleAction("duplicate", row.id),
            },
            {
              icon: TrashIcon,
              label: t("common:delete"),
              mustConfirm: true,
              description: t("settings:templates.delete.description"),
              onExecute: (row) => handleAction("delete", row.id),
            },
          ]}
        />
      </div>
    </div>
  );
}
