import { CogIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import TextInput from "~/lib/ui/form-elements/text-input";
import { useModals } from "~/lib/ui/modal/context";
import { PageSpinner } from "~/lib/ui/page-spinner";

export default function ModalPlayground() {
  const { showConfirm, showModal } = useModals();

  return (
    <section>
      <h2>Modal stuff</h2>

      <div>
        <h4>Simple</h4>
        <ButtonLegacy onClick={() => showModal(() => <p>Halløj</p>)}>Halløj</ButtonLegacy>
      </div>

      <div>
        <h4>With responsedata</h4>
        <ButtonLegacy
          onClick={async () => {
            const answer = await showModal<boolean>(({ onClose, onSubmit }) => (
              <div>
                <h3>Do you like it?</h3>
                <ButtonLegacy onClick={() => onSubmit(true)}>Yes</ButtonLegacy>
                <ButtonLegacy onClick={() => onSubmit(false)}>No</ButtonLegacy>
                <ButtonLegacy onClick={() => onClose()}>Just close</ButtonLegacy>
              </div>
            ));
            console.log("answer received", answer);
          }}
        >
          Do you like it?
        </ButtonLegacy>
      </div>
      <div>
        <h4>Text Input with Prevent</h4>
        <ButtonLegacy
          onClick={async () => {
            const name = await showModal<string>(({ onClose, setMustConfirm }) => {
              return <NameModal onClose={onClose} setMustConfirm={setMustConfirm} />;
            });

            if (name) {
              showModal(() => <p>{`Hej ${name}`}</p>);
            }
          }}
        >
          Open Name Dialog
        </ButtonLegacy>
      </div>
      <div>
        <h4>Confirm dialog</h4>
        <ButtonLegacy
          onClick={async () => {
            if (
              await showConfirm({
                title: "Overfør alle dine penge til mig?",
                description: "Måske spørge din mor først?",
                Icon: () => <CogIcon />,
              })
            ) {
              showModal(() => <p>Approved</p>);
            } else {
              showModal(() => <p>Cancelled</p>);
            }
          }}
        >
          Dangerous Action
        </ButtonLegacy>
      </div>
      <div>
        <h4>Compact Confirmation</h4>
        <ButtonLegacy
          onClick={async () => {
            if (await showConfirm()) {
              showModal(() => <p>Approved</p>);
            } else {
              showModal(() => <p>Cancelled</p>);
            }
          }}
        >
          Scary Action
        </ButtonLegacy>
      </div>
      <div>
        <h4>Compact Confirmation</h4>
        <ButtonLegacy
          onClick={async () => {
            if (
              await showModal(
                ({ onClose, onSubmit, isLoading }) => (
                  <div>
                    {isLoading ? (
                      <PageSpinner />
                    ) : (
                      <div>
                        <button onClick={() => onSubmit(true)}>Submit</button>
                        <button onClick={() => onClose()}>Close</button>
                      </div>
                    )}
                  </div>
                ),
                {
                  resolverFn: () => {
                    return new Promise((resolve) => {
                      setTimeout(() => {
                        resolve(undefined);
                      }, 5000);
                    });
                  },
                }
              )
            ) {
              showModal(() => <p>Approved</p>);
            } else {
              showModal(() => <p>Cancelled</p>);
            }
          }}
        >
          Async Waiting
        </ButtonLegacy>
      </div>
    </section>
  );
}

function NameModal({
  onClose,
  setMustConfirm,
}: {
  onClose: (value: string) => void;
  setMustConfirm: (prevent?: string) => void;
}) {
  const [name, setName] = useState<string>("");

  function handleChange(value: string) {
    setName(value);
  }

  useEffect(() => {
    if (name === "") return;
    setMustConfirm("Form is dirty, so very dirty. Want to continue?");
  }, [name]);

  return (
    <div>
      <h3>What is your name?</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onClose(name);
        }}
      >
        <TextInput onChange={handleChange} label="Name" name="name" />
        <button type="submit">Save</button>
      </form>
    </div>
  );
}
