import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Link } from "react-router-dom";
import { linkToRegistrations } from "~/lib/utils";

export default function TaskOverview() {
  const { t } = useTranslation();
  const api = useAPI();

  const { data, isFetching } = useQuery({
    queryKey: ["taskOverview"],
    queryFn: () => api.iGetWorkRelated({}),
  });

  const workData = data?.data;

  if (!workData) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <div className="flex gap-4">
        <div className="flex basis-1/3 flex-col overflow-hidden rounded-lg bg-white shadow-md">
          <div className="border-b px-4 py-3 text-lg">{t("frontpage:not_approved_forms")}</div>
          <div className="flex grow items-center justify-center border-b px-4 py-8 text-4xl">
            <div className="flex items-end gap-2">
              <div>{workData.notApprovedForms}</div>
              <div className="pb-0.5 text-base text-shade-400">
                {t("common:form", { count: workData.notApprovedForms })}
              </div>
            </div>
          </div>
          <Link to={linkToRegistrations(false)}>
            <div className="cursor-pointer px-4 py-3 text-center hover:bg-shade-100">
              {t("common:show")}
            </div>
          </Link>
        </div>
        {/** TODO - Implement these again when product wants to show them in a month or two
        <div className="flex basis-1/3 flex-col overflow-hidden rounded-lg bg-white shadow-md">
          <div className="border-b px-4 py-3 text-lg">{t("frontpage:not_planned_tasks")}</div>
          <div className="flex grow items-center justify-center border-b px-4 py-8 text-4xl">
            <div className="flex items-end gap-2">
              <div>{workData.notPlannedCards}</div>
              <div className="pb-0.5 text-base text-shade-400">
                {t("common:task", { count: workData.notPlannedCards ?? 2 })}
              </div>
            </div>
          </div>
          <Link to={linkToPlanning()}>
            <div className="cursor-pointer px-4 py-3 text-center hover:bg-shade-100">
              {t("common:show")}
            </div>
          </Link>
        </div>
        <div className="flex basis-1/3 flex-col overflow-hidden rounded-lg bg-white shadow-md">
          <div className="flex items-center justify-between border-b px-4 py-3 text-lg">
            <div>{t("frontpage:not_assigned_tasks")}</div>
            <div className="rounded-full bg-tertiary px-3 py-1 text-sm text-white">
              {t("frontpage:this_week")}
            </div>
          </div>
          <div className="flex grow items-center justify-center gap-4 border-b px-4 py-8 text-4xl">
            <div className="flex items-end gap-2">
              <div>{workData.notAssignedCards}</div>
              <div className="pb-0.5 text-base text-shade-400">
                {t("common:task", { count: workData.notAssignedCards ?? 2 })}
              </div>
            </div>
            <div className="text-shade-400">/</div>
            <div className="flex items-end gap-2">
              <div>{workData?.notAssignedTotalHours ?? 0}</div>
              <div className="pb-0.5 text-base text-shade-400">
                {t("common:hour", { count: workData?.notAssignedTotalHours ?? 2 })}
              </div>
            </div>
          </div>
          <Link to={linkToPlanning()}>
            <div className="cursor-pointer px-4 py-3 text-center hover:bg-shade-100">
              {t("common:show")}
            </div>
          </Link>
        </div>
         **/}
      </div>
    </div>
  );
}
