import ButtonLegacy from "../buttons/button-legacy";
import { useCalendar } from "../../calendar/use-calendar";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useMount } from "../../lifecycle-helpers";
import { twMerge } from "tailwind-merge";
import { Button, Icon } from "~/lib/ui";
import CalendarDates from "~/lib/ui/calendar/calendar-dates";

export default function Calendar({
  defaultDate,
  onSelect,
  disabledBefore,
  disabledAfter,
}: {
  defaultDate?: Date;
  onSelect?: (d: Date) => void;
  disabledBefore?: Date;
  disabledAfter?: Date;
}) {
  const { t } = useTranslation();
  const { selectedDate, setSelectedDate, months, days } = useCalendar(defaultDate);

  const [open, setOpen] = useState<boolean>(false);

  useMount(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      const el = document.activeElement as HTMLElement;
      if (el.dataset.calendarItem) {
        switch (e.code) {
          case "ArrowUp":
          case "ArrowDown":
          case "ArrowLeft":
          case "ArrowRight":
            e.preventDefault();
            e.stopPropagation();
            break;
        }
      }
    };

    window.addEventListener("keydown", (e) => handleKeydown(e));

    return window.removeEventListener("keydown", handleKeydown);
  });

  useEffect(() => {
    if (!defaultDate) return;
    setSelectedDate(defaultDate);
  }, [defaultDate]);

  const switchMonth = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setMonth(d.getMonth() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  const switchYear = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setFullYear(d.getFullYear() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  return (
    <div className="w-full overflow-hidden rounded-lg bg-white text-center shadow-md sm:w-72">
      <div className="flex items-center justify-between bg-primary px-2 py-1 text-white">
        <div className="flex gap-2">
          <Button
            variant="primary"
            className={open ? "invisible" : "px-2 py-1.5 focus:bg-hover"}
            onClick={() => switchYear(-1)}
            tabIndex={open ? -1 : 0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.previous_month")}</span>
            <Icon name="chevronDoubleLeft" className="h-3 w-auto" />
          </Button>
          <Button
            variant="primary"
            className={open ? "invisible" : "px-2 py-1.5 focus:bg-hover"}
            onClick={() => switchMonth(-1)}
            tabIndex={open ? -1 : 0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.previous_month")}</span>
            <Icon name="chevronLeft" className="h-3 w-auto" />
          </Button>
        </div>
        <span className="flex items-center px-2 py-1 text-sm text-white">
          {months[selectedDate.getMonth()].name} {selectedDate.getFullYear()}
        </span>
        <div className="flex gap-2">
          <Button
            variant="primary"
            className={open ? "invisible" : "px-2 py-1.5 focus:bg-hover"}
            onClick={() => switchMonth(1)}
            tabIndex={open ? -1 : 0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.next_month")}</span>
            <Icon name="chevronRight" className="h-3 w-auto" />
          </Button>
          <Button
            variant="primary"
            className={open ? "invisible" : "px-2 py-1.5 focus:bg-hover"}
            onClick={() => switchYear(1)}
            tabIndex={open ? -1 : 0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.next_month")}</span>
            <Icon name="chevronDoubleRight" className="h-3 w-auto" />
          </Button>
        </div>
      </div>
      <div className="relative overflow-y-hidden focus-visible:outline-none">
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-full"
        >
          <div className="absolute top-0 z-10 flex h-full w-full basis-full transform flex-col overflow-y-hidden bg-white">
            <div className="flex w-full flex-1 items-center">
              <div className="flex flex-1 flex-col gap-8">
                <div>
                  <div className="flex justify-between px-10">
                    <ButtonLegacy onClick={() => switchMonth(-1)}>
                      <Icon name="chevronLeft" className="h-4 w-auto" />
                    </ButtonLegacy>
                    <span className="flex cursor-pointer items-center text-base">
                      {months[selectedDate.getMonth()].name}
                    </span>
                    <ButtonLegacy onClick={() => switchMonth(1)}>
                      <Icon name="chevronRight" className="h-4 w-auto" />
                    </ButtonLegacy>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between px-10">
                    <ButtonLegacy onClick={() => switchYear(-1)}>
                      <Icon name="chevronLeft" className="h-4 w-auto" />
                    </ButtonLegacy>
                    <span className="flex cursor-pointer items-center text-base">
                      {selectedDate.getFullYear()}
                    </span>
                    <ButtonLegacy onClick={() => switchYear(1)}>
                      <Icon name="chevronRight" className="h-4 w-auto" />
                    </ButtonLegacy>
                  </div>
                </div>
                <div className="flex justify-center">
                  <ButtonLegacy
                    variant="primary"
                    onClick={() => setOpen(false)}
                    className="flex gap-2"
                  >
                    <Icon name="back" className="h-4 w-auto" />
                    <span>{t("common:back")}</span>
                  </ButtonLegacy>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className={twMerge("pt-2", open ? "invisible" : "")}>
          <div className="grid grid-cols-8 text-xs leading-6 text-gray-500">
            <div>{t("common:week")}</div>
            {days.map((day) => (
              <div key={day.index}>{day.name}</div>
            ))}
          </div>
          <div className="relative isolate mt-2 grid grid-cols-8 gap-px rounded-lg bg-white text-xs shadow ring-1 ring-gray-200">
            <CalendarDates
              selectedDate={selectedDate}
              options={{
                defaultDate,
                onSelect,
                disabledBefore,
                disabledAfter,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
