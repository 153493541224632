import { MouseEvent, ReactNode, useRef } from "react";
import { OptionalLink } from "../utils/routing/optional-link";
import { twMerge } from "tailwind-merge";
import DeveloperActionFab, { DeveloperAction } from "~/lib/ui/developer-action-fab";
import { To } from "react-router";
import { Icon } from "./icons/icon";
import { Button } from "./buttons/button";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "./scroll-to-top";
import { Boundary } from "./boundary";
import { usePageTitle } from "../navigation/use-page-title";
import { useMount } from "../lifecycle-helpers";

/**
 * A page layout with a title, description, actions and children.
 * @param title - Title of the page
 * @param titleLabel - Optional label to render below the title
 * @param renderDescription - Optional description to render below the title
 * @param renderActions - Optional actions to render on the right side of the page
 * @param onBackClick - Optional callback to call when the back button is clicked
 * @param children - Page contents
 * @param titleHref - Optional href to wrap the title in
 * @param developerActions - Optional developer actions to render in a popover
 * @constructor
 */
export function PageLayout({
  title,
  titleLabel,
  renderDescription,
  renderActions,
  onBackClick,
  children,
  titleHref,
  developerActions,
}: {
  title: string;
  titleLabel?: () => ReactNode;
  renderDescription?: () => ReactNode;
  renderActions?: () => ReactNode;
  onBackClick?: (e: MouseEvent) => void;
  titleHref?: To;
  children: ReactNode;
  developerActions?: Array<DeveloperAction>;
}) {
  const isDevelopment = import.meta.env.MODE === "development";
  const pageTitle = usePageTitle();

  const { t } = useTranslation();

  const container = useRef<HTMLDivElement>(null);

  useMount(() => {
    pageTitle.set(title);
  });

  return (
    <Boundary variant="page">
      {developerActions?.length && isDevelopment && (
        <DeveloperActionFab actions={developerActions} />
      )}
      <div ref={container} className="overflow-x-hidden py-8 sm:pb-18 sm:pt-4">
        <div className="flex max-w-7xl flex-col flex-wrap justify-between gap-8 px-4 py-4 sm:flex-row sm:flex-nowrap sm:px-6 md:px-8">
          <div>
            <div className={twMerge("flex items-start gap-6", renderDescription ? "mb-4" : "")}>
              {onBackClick && (
                <div>
                  <Button variant="secondary" onClick={(e) => onBackClick?.(e)}>
                    <Icon name="back" size="medium" />
                  </Button>
                </div>
              )}
              <div className="flex">
                <h1 className="m-0 text-4xl font-bold text-zinc-800">
                  <OptionalLink to={titleHref}>{title}</OptionalLink>
                </h1>
                {titleLabel && <div className="m-0">{titleLabel()}</div>}
              </div>
            </div>
            {renderDescription && <div>{renderDescription()}</div>}
          </div>
          {renderActions && <div className="flex-shrink-0">{renderActions()}</div>}
        </div>
        <section className="max-w-7xl px-4 pt-8 sm:px-6 md:px-8">
          {children}
          <ScrollToTop container={container} />
        </section>
      </div>
    </Boundary>
  );
}
