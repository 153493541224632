import { Button, Icon } from "../..";
import { Ref, forwardRef } from "react";
import { TriggerComponentProps } from "../combobox-types";
import { Spinner } from "../../spinner";
import { twMerge } from "tailwind-merge";

/** If you override this, remember to pass all the props - otherwise Radix fails to inject  */
export const DefaultSingleSelectTrigger = forwardRef(function DefaultTriggerComponent(
  {
    open,
    loading,
    icon,
    labelFn,
    valueFn,
    selectedItems,
    onRemoveItem,
    placeholder,
    readonly,
    // This is to prevent passing these props to the button
    ...props
  }: TriggerComponentProps,
  ref: Ref<HTMLButtonElement>
) {
  const renderedLabel = (() => {
    if (!selectedItems[0]) return <span className="text-shade-500">{placeholder}</span>;
    return labelFn(selectedItems[0]);
  })();

  const buttonHandle = !readonly ? (
    <Icon name="chevronUpDown" size="small" className="opacity-50" />
  ) : null;

  return (
    <Button
      ref={ref}
      variant="secondary"
      role="combobox"
      aria-expanded={open}
      className={twMerge(
        "block h-10 w-full text-left text-sm font-normal ",
        readonly && "text-black"
      )}
      disabled={loading || readonly}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <>
          <div className="flex flex-row items-center">
            {icon && <Icon name={icon} size="small" className="mr-4" />}
            {renderedLabel}
          </div>
          {loading ? <Spinner className="h-5 w-5" /> : buttonHandle}
        </>
      </div>
    </Button>
  );
});
