type TimeStringSeparator = "dot" | "colon";

type TimeStringOptions = {
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
};

export const dateToTime = (
  date?: Date | null,
  separator: TimeStringSeparator = "colon",
  options: TimeStringOptions = {}
): string | null => {
  if (!date) return null;

  const defaultTimeStringOptions: TimeStringOptions = {
    hours: true,
    minutes: true,
    seconds: false,
  };

  const timeStringOptions: TimeStringOptions = Object.assign(defaultTimeStringOptions, options);

  const locale = separator === "colon" ? "en-GB" : "da-DK";
  return date?.toLocaleTimeString(locale, {
    hour: timeStringOptions.hours ? "numeric" : undefined,
    minute: timeStringOptions.minutes ? "numeric" : undefined,
    second: timeStringOptions.seconds ? "numeric" : undefined,
  });
};
/**
 * Converts a number to a time string with proper padding
 * Returns empty string on null or undefined
 */
export const numberToTime = (num: number | null | undefined): string => {
  if (num === null || num === undefined) return "";
  return num < 10 ? `0${num}` : num.toString();
};
