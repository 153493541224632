import { OrderLine, OrderLineType } from "~/lib/ui/order-lines/lib/types";
import { randomUUID } from "~/lib/uuid/random-uuid";
import { ProductBundleLine } from "@apacta/sdk";

/**
 * Transformer method used to convert {ProductBundleLine} into {MutableProductBundleLine}
 * @param item - ProductBundleLine to transform
 * @param pos - Position in the list of lines
 */
export const productBundleLineToOrderLineTransformer = (
  item: ProductBundleLine,
  pos: number
): Partial<OrderLine> => {
  const lineTypeConverter = (): OrderLineType => {
    if (item.usersPriceGroupId) {
      return "hours";
    }
    return "product";
  };

  return {
    ...item,
    identifier: item.id ?? randomUUID(),
    type: lineTypeConverter(),
    position: pos ?? 0,
    quantity: item.quantity,
    costPrice: item.buyingPrice,
    sellingPrice: item.sellingPrice,
  };
};

export const productBundleLinesToOrderLinesTransformer = (
  lines: Array<ProductBundleLine>
): Array<Partial<OrderLine>> => {
  return lines.map((line, idx) => {
    return productBundleLineToOrderLineTransformer(line, idx);
  });
};

const orderLineToProductBundleLineTransformer = (
  line: Partial<OrderLine>,
  customParams?: { [key: string]: unknown }
): ProductBundleLine => {
  const productBundleLine = line as ProductBundleLine;

  return {
    ...productBundleLine,
    id: line.id ? line.id : "",
    usersPriceGroupId: line.usersPriceGroupId ?? undefined,
    placement: Number(line.position ?? 0) + 1,
    buyingPrice: line.costPrice,
    ...customParams,
  };
};

/**
 * Utility method used to convert {MutableProductBundleLine[]} into {ProductBundleLine[]}
 * @param {Partial<OrderLine>[]} lines - Lines to transform
 * @param customParams - Additional parameters to append to the product bundle line
 */
export const orderLinesToProductBundleLinesTransformer = (
  lines: Array<Partial<OrderLine>>,
  customParams?: { [key: string]: unknown }
): Array<ProductBundleLine> => {
  return lines.map((line) => {
    return orderLineToProductBundleLineTransformer(line, customParams);
  });
};
