import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Size } from "~/lib/types";
import { twMerge } from "tailwind-merge";

type ModalVerticalPosition = "top" | "center" | "bottom";

export default function Modal({
  isOpen,
  onClose,
  size = "lg",
  verticalPositionMobile = "bottom",
  transparent = false,
  children,
  onClosed,
}: {
  isOpen: boolean;
  onClose: () => void;
  onClosed?: () => void;
  size?: Size;
  verticalPositionMobile?: ModalVerticalPosition;
  transparent?: boolean;
  children: JSX.Element | Array<JSX.Element> | string;
}) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const { t } = useTranslation();

  useLayoutEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={onClosed}>
      <Dialog
        as="div"
        className="relative z-auto"
        static={true}
        initialFocus={cancelButtonRef}
        onClose={() => handleClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-auto overflow-y-auto">
          <div
            className={twMerge(
              "flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0",
              verticalPositionMobile === "top" && "items-start",
              verticalPositionMobile === "center" && "items-center",
              verticalPositionMobile === "bottom" && "items-end"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  "relative transform  px-4 pb-4 pt-5 text-left  transition-all sm:my-8 sm:w-full sm:p-6",
                  size === "xs" ? "w-full sm:max-w-xs" : "",
                  size === "sm" ? "w-full sm:max-w-sm" : "",
                  size === "md" ? "w-full sm:max-w-md" : "",
                  size === "lg" ? "w-full sm:max-w-lg" : "",
                  size === "xl" ? "w-full sm:max-w-xl" : "",
                  size === "2xl" ? "w-full sm:max-w-2xl" : "",
                  size === "3xl" ? "w-full sm:max-w-3xl" : "",
                  size === "4xl" ? "w-full sm:max-w-4xl" : "",
                  transparent ? "" : "rounded-lg bg-white shadow-xl"
                )}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hover focus:ring-offset-2"
                    onClick={() => handleClose()}
                  >
                    <span className="sr-only">{`${t("common:close")} ${t(
                      "common:modal"
                    ).toLowerCase()}`}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
