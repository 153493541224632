import { ComponentProps, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { RowData, Table } from "@tanstack/react-table";
import { ActionButtons } from "../action-buttons";
import { DataTable } from ".";
import { SearchInput } from "../search-input";

export type SearchFilterProps<DataResponse> = (data?: DataResponse) => ReactNode;

export function TableTopBar<T extends RowData>({
  table,
  renderFilters,
  selectionActions,
  placeholder,
}: {
  table: Table<T>;
  renderFilters?: () => ReactNode;
  selectionActions?: ComponentProps<typeof DataTable>["selectionActions"];
  placeholder?: string;
}) {
  const { t } = useTranslation();

  const isSearchEnabled = table.options.enableGlobalFilter;
  const areFiltersEnabled = renderFilters !== undefined;
  const isSelectionEnabled = table.options.enableRowSelection && selectionActions;

  const actions =
    typeof selectionActions === "function"
      ? selectionActions(table.getSelectedRowModel().rows)
      : selectionActions;

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex flex-1 flex-col items-start justify-center">
          {/* Left side */}
          {isSearchEnabled && (
            <div className="flex w-full max-w-sm flex-1">
              <SearchInput
                placeholder={placeholder ?? t("common:search")}
                value={table.getState().globalFilter}
                onChangeValue={table.setGlobalFilter}
                onClear={() => {
                  table.setPageIndex(0); // Reset page index when clearing search
                }}
                debounce={500}
              />
            </div>
          )}
          {areFiltersEnabled && (
            <div className={twMerge("flex", isSearchEnabled && "mt-4")}>
              <div className="flex flex-wrap gap-2">{renderFilters()}</div>
            </div>
          )}
        </div>
        {isSelectionEnabled && table.getSelectedRowModel() && (
          <div className="flex flex-1 flex-col items-end justify-start">
            {/* Right side. Only shown when selection is enabled */}
            <div className="flex gap-x-4">
              <>
                <div className="flex h-full items-center text-tertiary">
                  {t("common:x_selected", {
                    replace: { x: table.getSelectedRowModel().rows.length },
                    defaultValue: "{{x}} selected",
                  })}
                </div>
                {table.getSelectedRowModel().rows.length > 0 && (
                  <ActionButtons size="small" actions={actions ?? []} />
                )}
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
