import { CSSProperties, useEffect } from "react";
import { useDragLayer, XYCoord } from "react-dnd";
import { CardProps, DraggableCard } from "~/pages/planning/_cmp/draggable-card";

export const DragLayerImplementation = ({
  onDragging,
}: {
  onDragging: (state: boolean, item: CardProps) => void;
}) => {
  const layerStyles: CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  };

  function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    isSnapToGrid: boolean
  ) {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }

    let { x, y } = currentOffset;

    if (isSnapToGrid) {
      x -= initialOffset.x;
      y -= initialOffset.y;
      x += initialOffset.x;
      y += initialOffset.y;
    }

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  useEffect(() => {
    const timer = setTimeout(() => onDragging(isDragging, item), 1);
    return () => clearTimeout(timer);
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, false)}>
        <DraggableCard {...item} useBgColor={true} />
      </div>
    </div>
  );
};
