import { Suspense } from "react";
import {
  ArrayLayoutProps,
  composePaths,
  findUISchema,
  rankWith,
  isObjectArray,
  and,
} from "@jsonforms/core";
import { Button, Label, getIcon } from "~/lib/ui";
import { JsonFormsDispatch } from "@jsonforms/react";
import { range } from "~/lib/utils/utils";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { childWithAncestor } from "./childWithAncestor";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { isEntityOf } from "./testers";
import ProductPopover from "~/lib/products/product-popover";
import { ProductItem } from "~/lib/products/product-search-content";
import { useAPI } from "~/lib/api";

// Custom List renderer for Products
export function ProductLayoutRenderer(props: ArrayLayoutProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const {
    addItem,
    data,
    uischemas,
    schema,
    path,
    uischema,
    rootSchema,
    moveDown,
    moveUp,
    removeItems,
  } = props;

  //if (data === 0) return null;
  const dataArray = range(data);

  async function handleAddProduct(p: ProductItem) {
    let pId: string | undefined = undefined;

    // Create and convert
    // TODO: This behavior should be (imo) inside of ProductItemSearch
    // - but impossible do to the onSelect signature without fetching each created product
    switch (p.type) {
      case "product":
        pId = p.item.id;
        break;
      case "expense_line":
        const expenseRes = await api.createProduct({
          createProductRequest: {
            expenseLineId: p.item.id,
          },
        });
        if (expenseRes.data.id) {
          pId = expenseRes.data.id;
        }
        break;
      case "vendor_product":
        const vpRes = await api.createProduct({
          createProductRequest: {
            vendorProductId: p.item.id,
          },
        });
        if (vpRes.data.id) {
          pId = vpRes.data.id;
        }
        break;
    }
    if (!pId) {
      console.warn("Cannot add product. No product-id found");
      return;
    }

    const newProduct = { product_id: pId, quantity: 1 };
    addItem(path, newProduct)();
  }

  // TODO: Create SortableList component so we can drag the lines around
  // - OrderLineBuilder does some of it, but not in a re-usable way.

  return (
    <div>
      <Label>{props.label}</Label>
      {dataArray.map((i) => {
        const childPath = composePaths(path, `${i}`);
        const ancestor = uischema.options?.["x-ancestor"];
        const listItemUiSchema = findUISchema(
          uischemas ?? [],
          schema,
          uischema.scope,
          path,
          "HorizontalLayout",
          //ancestor ? `${ancestor}-ObjectControl` : "HorizontalLayout", // TODO: Can we do this dynamically?
          uischema,
          rootSchema
        );

        return (
          <Fragment key={childPath}>
            <Suspense fallback={<SkeletonLoader template="box" className="h-16" />}>
              <div className="flex flex-row items-center justify-between gap-4">
                <JsonFormsDispatch
                  uischema={childWithAncestor(listItemUiSchema, uischema)}
                  schema={schema}
                  path={composePaths(path, `${i}`)}
                  enabled={props.enabled}
                />
                <Button onClick={removeItems?.(path, [i])} Icon={getIcon("delete")} size="small" />
              </div>
            </Suspense>
          </Fragment>
        );
      })}
      <div className="mt-4">
        <ProductPopover
          triggerRender={() => (
            <Button Icon={getIcon("add")} variant="secondary" size="small">
              {t("common:product", { count: 1 })}
            </Button>
          )}
          onSelect={(item) => handleAddProduct(item)}
        />
      </div>
    </div>
  );
}

export const productListTester = rankWith(4, and(isObjectArray, isEntityOf("products")));
