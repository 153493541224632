import { NavItem } from "../navigation-item/navigation-item.type";
import { NavLink } from "react-router-dom";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useRoles } from "~/lib/auth/use-roles";
import { useSession, FeaturesType } from "~/lib/auth/session";
import Badge from "~/lib/ui/badge";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import Collapsible from "~/lib/ui/collapsible";

export function NavigationItemMobile({
  item,
  isSubItem = false,
  onClick,
}: {
  item: NavItem;
  isSubItem?: boolean;
  onClick?: () => void;
}) {
  const isActive = false; // TODO: Revisit when we are rid of frames

  const { me } = useSession();

  const features = useFeatureFlags();
  const roles = useRoles();

  // Skip any items that are locked behind a feature flag
  if (item.featureName && !features.has(item.featureName as FeaturesType)) {
    return null;
  }

  // Skip any items that are locked behind a role
  if (item.role && !roles.has(item.role)) {
    return null;
  }

  // Skip any items that should be hidden
  if (me && item.isHidden?.(me)) {
    return null;
  }

  // Return Disclosure if item has children
  if (item.children) {
    return (
      <Collapsible
        asChild
        triggerRender={(open) => (
          <div className="my-1">
            <button
              className={twMerge(
                isActive
                  ? "rounded-lg bg-hover text-white hover:rounded-r-none 2xl:hover:rounded-lg"
                  : "text-gray-200 hover:bg-hover",
                isSubItem ? "pl-11 pr-2" : "pl-2",
                "group relative flex w-full items-center justify-start rounded-lg py-2 pr-2 text-base 2xl:pr-0 "
              )}
            >
              {item.icon ? (
                <item.icon
                  className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
                  aria-hidden="true"
                />
              ) : null}
              <div
                className={twMerge(
                  "flex items-center overflow-x-hidden whitespace-nowrap rounded-r-lg bg-hover group-hover:w-fit",
                  "w-full overflow-x-visible bg-transparent group-hover:w-full"
                )}
              >
                <div className="flex w-full items-center justify-between  group-hover:pr-4 2xl:p-0 2xl:group-hover:pr-0">
                  <span>{item.name}</span>
                  {open ? (
                    <Icon
                      name="chevronUp"
                      className="mr-2 hidden h-5 w-5 flex-shrink-0 transform text-gray-200 transition-colors duration-150 ease-in-out group-hover:text-white 2xl:block"
                    />
                  ) : (
                    <Icon
                      name="chevronDown"
                      className="mr-2 hidden h-5 w-5 flex-shrink-0 transform text-gray-200 transition-colors duration-150 ease-in-out group-hover:text-white 2xl:block"
                    />
                  )}
                </div>
              </div>
            </button>
          </div>
        )}
      >
        {item.children?.map((subItem, idx) => (
          <NavigationItemMobile item={subItem} key={idx} isSubItem={true} onClick={onClick} />
        ))}
      </Collapsible>
    );
  }

  // Return anchor tag with link if item is targeting external website
  if (item.external) {
    return (
      <div className="my-1">
        <a
          href={item.href}
          target="_blank"
          rel="noreferrer"
          className={twMerge(
            "group flex w-full items-center rounded-md bg-primary py-2 text-base text-gray-200 hover:bg-hover",
            isSubItem ? "pl-11 pr-2" : "pl-2"
          )}
        >
          {item.icon ? (
            <item.icon
              className="mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover"
              aria-hidden="true"
            />
          ) : null}

          {item.name}
        </a>
      </div>
    );
  }

  // We must not post empty links when our frames are dependant on location, it re-renders everything
  if (onClick) {
    return (
      <div className="my-1">
        <button
          onClick={() => onClick && onClick()}
          className={twMerge(
            isActive
              ? "rounded-md text-white after:absolute after:right-2 after:h-3 after:w-3 after:rounded-full after:bg-hover after:content-[''] hover:bg-hover"
              : "text-gray-200 hover:bg-hover",
            isSubItem ? "pl-11 pr-2" : "pl-2",
            "group relative flex w-full items-center rounded-md py-2 text-left text-base"
          )}
        >
          {item.icon ? (
            <item.icon
              className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
              aria-hidden="true"
            />
          ) : null}
          {item.name}
        </button>
      </div>
    );
  }

  // Return NavLink if no other conditions are met
  return (
    <div className="my-1">
      <NavLink
        to={item.href}
        // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
        state={{ refreshKey: crypto.getRandomValues(new Uint8Array(1))[0] }}
        className={(link) =>
          twMerge(
            link.isActive ? "rounded-md bg-hover text-white" : "text-gray-200 hover:bg-hover",
            isSubItem ? "pl-11 pr-2" : "pl-2",
            "group relative flex w-full items-center rounded-md  py-2 text-base"
          )
        }
      >
        {item.icon ? (
          <item.icon
            className={twMerge("mr-3 h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover")}
            aria-hidden="true"
          />
        ) : null}
        {item.name}
        {item.badge ? (
          <Badge
            className={twMerge("ml-4", item.badge.className)}
            size="sm"
            label={item.badge.label}
          />
        ) : null}
      </NavLink>
    </div>
  );
}
