import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { graphql, useGraphQLClient } from "~/lib/gql";
import { Icon, getIcon } from "~/lib/ui";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { CheckboxLine } from "~/lib/ui/form-elements/checkbox-line";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { linkToInvoiceV3 } from "~/lib/utils";
import { formatCurrency } from "~/lib/utils/number";

const queryForTotalInvoice = graphql(/* GraphQL */ `
  query queryForTotalInvoice($id: ID!) {
    project(id: $id) {
      id
      totalInvoicedAmount
    }
  }
`);

export function CreateInvoiceDialog(props: { onClose: () => void }) {
  const { t } = useTranslation();
  const api = useAPI();
  const navigate = useNavigate();
  const client = useGraphQLClient();

  const formState = useFormState({
    schema: {
      projectId: z.string().uuid(),
      options: z
        .discriminatedUnion("projectType", [
          z.object({
            projectType: z.literal("fixed"),
            totalSalesPrice: z.number().positive(),
            notInvoicedAmount: z.number(),
            isFinalInvoice: z.boolean().optional(),
            hideUsagePrices: z.boolean().optional(), // Hide hour/product pricing
          }),
          z.object({
            projectType: z.literal("hourly"),
            fromDate: z.date(),
            toDate: z.date(),
            includeInvoicedForms: z.boolean().optional(),
            combineProductLines: z.boolean().optional(),
            combineWorkingTimeLines: z.boolean().optional(),
            markInvoiced: z.boolean().optional(),
            viewFormsPerEmployeePerDay: z.boolean().optional(),
          }),
        ])
        .optional(),
    },
    initialValues: {
      projectId: "",
      options: undefined,
    },
  });

  const invoicedTotalQ = useQuery({
    queryKey: ["queryForTotalInvoice", formState.getValues().projectId],
    queryFn: () => client.request(queryForTotalInvoice, { id: formState.getValues().projectId }),
    enabled: formState.getValues().options?.projectType === "fixed",
  });

  const dataM = useMutation({
    mutationFn: () => {
      const v = formState.getValues();
      switch (v.options?.projectType) {
        case "hourly":
          return api.createInvoice({
            createInvoiceRequest: {
              projectId: v.projectId,
              dateFrom: v.options.fromDate,
              dateTo: v.options.toDate,
              combineProductLines: v.options.combineProductLines,
              combineWorkingTimeLines: v.options.combineWorkingTimeLines,
              includeInvoicedForms: v.options.includeInvoicedForms,
              markInvoiced: v.options.markInvoiced,
              groupByForms: v.options.viewFormsPerEmployeePerDay,
            },
          });
        case "fixed":
          return api.createInvoice({
            createInvoiceRequest: {
              projectId: v.projectId,
              isFinalInvoice: v.options.isFinalInvoice,
              // TODO API: hideUsagePrices: v.options.hideUsagePrices,
            },
          });
        default:
          // Not a valid submission
          throw new Error("Invalid project type");
      }
    },

    onSuccess: () => {
      props.onClose();
    },
  });

  async function handleCreateInvoice() {
    const res = await dataM.mutateAsync();
    if (res.data.id) {
      navigate(linkToInvoiceV3(res.data.id));
    }
    props.onClose();
  }

  function handleProjectSelect(project: ControlPanelApiProjectViewOrIndexResponse) {
    if (project.isFixedPrice) {
      formState.setValues({
        projectId: project.id,
        options: {
          projectType: "fixed",
          totalSalesPrice: project.totalSalesPrice ?? 0,
          notInvoicedAmount: project.notInvoicedAmount ?? 0,
        },
      });
    } else {
      formState.setValues({
        projectId: project.id,
        options: {
          projectType: "hourly",
          fromDate: startOfDay(new Date()),
          toDate: endOfDay(new Date()),
          markInvoiced: true,
        },
      });
    }
  }

  const { options } = formState.getValues();
  const projectType = options?.projectType;
  const totalInvoicedAmount = invoicedTotalQ.data?.project.totalInvoicedAmount ?? 0;

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          defaultValue: "Create {{entity}}",
          replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={getIcon("invoice")}
      />
      <ProjectSelection onSelect={(_, project) => handleProjectSelect(project)} />
      <div className="mt-4 min-h-40">
        {projectType === undefined && (
          <div className="flex h-full w-full items-center justify-center text-gray-400">
            <Icon name="pointUp" className="mr-2 h-6 w-6" />
            {t(
              "invoices:select_project_to_create_invoice",
              "Select a project to create an invoice"
            )}
          </div>
        )}
        {options && projectType === "hourly" && (
          <div>
            <DateRangePopover
              label={t("common:period", "Period")}
              from={options.fromDate}
              size="medium"
              to={options.toDate}
              onChange={(from, to) => {
                if (!from || !to) return;
                formState.onChange("options", {
                  ...options,
                  fromDate: from,
                  toDate: to,
                });
              }}
            />
            <div className="my-4 flex flex-col gap-2">
              <CheckboxLine
                label={t("invoices:include_invoiced_forms", "Include invoiced forms")}
                checked={options.includeInvoicedForms}
                onChange={(e) => {
                  formState.onChange("options", {
                    ...options,
                    includeInvoicedForms: e.currentTarget.checked,
                  });
                }}
              />
              <CheckboxLine
                label={t("invoices:combine_product_lines", "Collect all products in one line")}
                checked={options.combineProductLines}
                onChange={(e) => {
                  formState.onChange("options", {
                    ...options,
                    combineProductLines: e.currentTarget.checked,
                  });
                }}
              />
              <CheckboxLine
                label={t("invoices:combine_working_time_lines", "Collect all hours in one line")}
                checked={options.combineWorkingTimeLines}
                onChange={(e) => {
                  formState.onChange("options", {
                    ...options,
                    combineWorkingTimeLines: e.currentTarget.checked,
                  });
                }}
              />
              <CheckboxLine
                label={t(
                  "invoices:view_forms_per_employee_per_day",
                  "View forms per employee per day"
                )}
                checked={options.viewFormsPerEmployeePerDay}
                onChange={(e) => {
                  formState.onChange("options", {
                    ...options,
                    viewFormsPerEmployeePerDay: e.currentTarget.checked,
                  });
                }}
              />
              <CheckboxLine
                label={t(
                  "invoices:mark_hours_and_products_as_invoiced",
                  "Mark hours and materials as invoiced"
                )}
                checked={options.markInvoiced}
                onChange={(e) => {
                  formState.onChange("options", {
                    ...options,
                    markInvoiced: e.currentTarget.checked,
                  });
                }}
              />
            </div>
          </div>
        )}

        {options && projectType === "fixed" && (
          <div className="my-4 flex flex-col gap-2">
            <div className="mb-2 rounded-md border p-4 font-semibold">
              <div className="flex flex-row justify-between ">
                <span>{t("finance:total_sales_price", "Total sales price")}</span>
                <span>{formatCurrency(options.totalSalesPrice)}</span>
              </div>
              <div className="flex flex-row justify-between">
                <span>{t("finance:total_invoiced_amount", "Total invoiced amount")}</span>
                <span>{formatCurrency(totalInvoicedAmount)}</span>
              </div>
              <div className="flex flex-row justify-between">
                <span>{t("finance:not_invoiced_amount", "Not invoiced amount")}</span>
                <span>{formatCurrency(options.notInvoicedAmount)}</span>
              </div>
            </div>

            <CheckboxLine
              label={t("invoices:is_final_invoice", "Is final invoice")}
              checked={options.isFinalInvoice}
              onChange={(e) => {
                formState.onChange("options", {
                  ...options,
                  isFinalInvoice: e.currentTarget.checked,
                });
              }}
            />
            <CheckboxLine
              label={t("invoices:hide_usage_prices", "Hide hour/product pricing")}
              // TODO: API: hideUsagePrices: v.options.hideUsagePrices,
              className="border-spacing-6 rounded-md outline-dotted outline-2 outline-orange-600"
              checked={options.hideUsagePrices}
              onChange={(e) => {
                formState.onChange("options", {
                  ...options,
                  hideUsagePrices: e.currentTarget.checked,
                });
              }}
            />
          </div>
        )}
      </div>

      <DialogFooter
        onClose={props.onClose}
        primary={{
          label: t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: handleCreateInvoice,
        }}
      />
    </>
  );
}
