import { useOutletContext } from "react-router-dom";
import { EditUserOperationRequest, LabelEntity, User } from "@apacta/sdk";
import { Suspense, useId, useState } from "react";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import TextInput from "~/lib/ui/form-elements/text-input";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { ZipInput } from "~/lib/ui/zip-input";
import { useFormState } from "~/lib/form-state";
import { userSchema } from "~/pages/employees/_cmp/user-schema";
import { LanguagesInput } from "~/pages/employees/_cmp/languages";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useToasts } from "~/lib/toast/use-toasts";
import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";
import { PhoneInput } from "~/lib/ui/phone-input";
import { UsersPriceGroups } from "~/pages/employees/_cmp/users-price-groups";
import { Checkbox, NumberInput } from "~/lib/ui/form-elements";
import { Button, getIcon } from "~/lib/ui";
import { LabelsSelection } from "~/pages/employees/_cmp/labels-selection";

export default function EditUserPage() {
  const { t } = useTranslation();
  const { user } = useOutletContext<{ user: User }>();
  const api = useAPI();
  const manualCheckboxId = useId();
  const { id: userId } = useParams();
  const toast = useToasts();
  const queryClient = useQueryClient();
  const [isUsingManualPrice, setIsUsingManualPrice] = useState(user.manualPrice);

  const employeeLabelsIds = () => {
    if (user.labels === undefined || user.labels?.length < 1) {
      return [];
    }
    // Sort by name(Label.text)
    return user?.labels
      ?.sort((first: LabelEntity, next: LabelEntity) => (first.text > next.text ? 1 : -1))
      .map((label: LabelEntity) => label.id);
  };

  const {
    isValid,
    isModified,
    getValue,
    getValues,
    register,
    setValues,
    onChange,
    resetInitialValues,
  } = useFormState({
    schema: userSchema,
    initialValues: {
      first_name: user.firstName,
      last_name: user.lastName,
      profile_image: user.imageUrl,
      email: user.email,
      password: user.password,
      mobile_country_code: user.mobileCountrycode,
      mobile: user.mobile,
      address: user.streetName,
      zip_code: user.zipCode,
      city_name: user.cityName,
      language_id: user.languageId as string,
      erp_id: user.erpId,
      city_id: user.cityId ?? undefined,
      users_price_group_id: user.usersPriceGroupId,
      cost_price: user.costPrice,
      extra_price: user.extraPrice,
      manual_price: user.manualPrice ?? false,
      labels: employeeLabelsIds(),
    },
  });

  async function handleUpload(fileList: FileList) {
    try {
      // We only upload one user photo
      const file = fileList[0];
      await api.uploadUserImage({
        userId: userId as string,
        image: file as File,
      });
    } catch (err) {
      toast.showTemplate("OPERATION_FAILED");
    } finally {
      toast.show({
        title: t("users:files.title"),
        description: t("users:files.description"),
        Icon: getIcon("selectedCheck"),
      });
      await queryClient.invalidateQueries({
        queryKey: ["user", userId], // this causes a refetch
      });
    }
  }

  const userEdit = useMutation({
    mutationFn: (args: EditUserOperationRequest) => api.editUser(args),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
      queryClient.invalidateQueries({
        queryKey: ["user", userId],
      });
      resetInitialValues(getValues());
    },
  });

  const handleSaveUser = () => {
    if (userId) {
      userEdit.mutate({
        userId: userId,
        editUserRequest: {
          firstName: getValue("first_name"),
          lastName: getValue("last_name") as string,
          email: getValue("email") as string,
          mobileCountrycode: getValue("mobile_country_code") ?? "",
          mobile: getValue("mobile") ?? "",
          streetName: getValue("address") as string,
          zipCode: getValue("zip_code") as string,
          cityName: getValue("city_name") as string,
          languageId: getValue("language_id") as string,
          erpId: getValue("erp_id") as string,
          usersPriceGroupId: getValue("users_price_group_id") ?? undefined,
          costPrice: getValue("cost_price") as number,
          extraPrice: getValue("extra_price") as number,
          manualPrice: isUsingManualPrice,
          labelIds: getValue("labels"),
        },
      });
    }
  };

  return (
    <>
      <div className="flex w-full flex-col gap-12">
        <Suspense
          fallback={
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          }
        >
          <>
            <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
              <h2 className="m-0">{t("users:tabs.primary_label")}</h2>
              <div>
                <Button
                  onClick={() => handleSaveUser()}
                  variant="tertiary"
                  disabled={!isModified || !isValid}
                >
                  {t("common:save_changes")}
                </Button>
              </div>
            </div>
            {/* User input form fields */}
            <div className="flex h-full flex-col gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <div className="flex-1">
                  <div className="mb-2 text-gray-500">{t("users:tabs.primary_label")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                      <TextInput
                        {...register("first_name")}
                        label={t("users:first_name")}
                        name="name"
                        placeholder={t("common:name")}
                      />
                      <TextInput
                        {...register("last_name")}
                        label={t("users:last_name")}
                        name="name"
                        placeholder={t("common:name")}
                      />
                      <TextInput
                        label={t("common:email")}
                        placeholder={t("common:email")}
                        {...register("email")}
                      />
                      <div className="">
                        <PhoneInput
                          onChange={(phone) => {
                            setValues({
                              mobile: phone.mobile,
                              mobile_country_code: phone.code,
                            });
                          }}
                          initialMobile={user.mobile}
                          initialCountryCodeId={user.mobileCountrycode}
                        ></PhoneInput>
                      </div>
                      <LabelsSelection
                        defaultValues={getValue("labels")}
                        onChange={(labelIds) => {
                          setValues({ labels: labelIds });
                        }}
                      />
                    </div>
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                      <div className="mt-4 flex items-center sm:flex">
                        <div className="mr-4">
                          <UserAvatar user={user} />
                        </div>
                        <div className="ml-4">
                          <UploadButton
                            variant="primary"
                            onSelected={handleUpload}
                            multiple={false}
                          >
                            {t("users:change")}
                          </UploadButton>
                        </div>
                      </div>
                      <StreetAddressInput
                        label={t("common:address")}
                        {...register("address")}
                        onSelect={(item) => {
                          setValues({
                            address: item.addressWithNumber,
                            zip_code: item.zipCode,
                            city_name: item.cityName,
                          });
                        }}
                      />
                      <div className="sm:flex sm:gap-4">
                        <ZipInput
                          label={t("common:zip_code")}
                          placeholder={t("common:zip_code")}
                          {...register("zip_code")}
                          onSelect={(item) => {
                            setValues({
                              zip_code: item.zipCode,
                              city_name: item.cityName,
                            });
                          }}
                        />
                        <TextInput
                          label={t("common:city_name")}
                          placeholder={t("common:city_name")}
                          {...register("city_name")}
                        />
                      </div>
                      <div className="sm:col-span-3 ">
                        <LanguagesInput
                          {...register("language_id")}
                          onChange={(languageId) => {
                            languageId && onChange("language_id", languageId);
                          }}
                          initialLanguageId={getValue("language_id")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex h-full flex-col gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <div className="flex-1">
                  <div className="mb-2 text-gray-500">{t("users:prices")}</div>
                  <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/3">
                      <div className="sm:col-span-2">
                        <div className="mt-2">
                          <UsersPriceGroups
                            onChange={(usersPriceGroup) => {
                              setValues({
                                cost_price: usersPriceGroup?.costPrice ?? user.costPrice,
                                extra_price: usersPriceGroup?.extraPrice ?? user.extraPrice,
                                users_price_group_id: usersPriceGroup?.id,
                              });
                            }}
                            initialPriceGroupId={user.usersPriceGroupId}
                          />
                        </div>
                        <div className="mt-3 flex flex-col sm:flex-row">
                          <Checkbox
                            id={manualCheckboxId}
                            checked={isUsingManualPrice}
                            onChange={() => setIsUsingManualPrice(!isUsingManualPrice)}
                          />
                          <label htmlFor={manualCheckboxId}>
                            {t("users:modal.manual_cost_price")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/3">
                      <div className="sm:col-span-2">
                        <div className="mt-2">
                          <NumberInput
                            label={t("finance:hourly_price")}
                            defaultValue={getValue("cost_price") ?? undefined}
                            onChange={(e) =>
                              setValues({ cost_price: e.currentTarget.valueAsNumber })
                            }
                            disabled={!isUsingManualPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-6 md:basis-1/3">
                      <div className="sm:col-span-2">
                        <div className="mt-2">
                          <NumberInput
                            label={t("finance:extra_price")}
                            defaultValue={getValue("extra_price") ?? undefined}
                            onChange={(e) =>
                              setValues({ extra_price: e.currentTarget.valueAsNumber })
                            }
                            disabled={!isUsingManualPrice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Suspense>
      </div>
    </>
  );
}
