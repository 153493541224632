import { Spinner } from "~/lib/ui/spinner";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "~/lib/utils/number";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { ExpenseLine, Vendor } from "@apacta/sdk";

export default function ExpenseLineSection({
  searchQuery,
  selectedVendor,
  onSelect,
  showBuyingPrice = false,
  showSellingPrice = false,
  showGrossPrice = false,
}: {
  searchQuery: string;
  selectedVendor?: Vendor;
  onSelect: (item: ExpenseLine) => void;
  showBuyingPrice?: boolean;
  showSellingPrice?: boolean;
  showGrossPrice?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const expenseLinesQuery = useQuery({
    queryKey: ["expenseLinesList", searchQuery],
    queryFn: () =>
      api.expenseLinesList({
        q: searchQuery,
        vendorId: selectedVendor?.id,
      }),
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  if (expenseLinesQuery.isFetching) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!expenseLinesQuery.isFetching && !expenseLinesQuery.data?.data.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2">
        <MagnifyingGlassIcon className="h-16 w-16" />
        <span>
          {t("common:no_entity_found", {
            entity: t("products:product_type.expense_line", { count: 2 }).toLowerCase(),
          })}
        </span>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2 divide-y pr-1">
      {expenseLinesQuery.data?.data.map((expenseLine, idx) => (
        <div key={`${expenseLine.id}`} onClick={() => onSelect(expenseLine)} className="pt-2">
          <div className="flex cursor-pointer flex-col gap-2 rounded-lg p-2 hover:bg-shade-100">
            <span>{expenseLine.text}</span>
            <div className="flex w-full justify-between text-sm text-shade-400">
              {showBuyingPrice && (
                <div>
                  {t("common:cost_price")}: {formatCurrency(expenseLine.buyingPrice ?? 0)}
                </div>
              )}
              {showSellingPrice && (
                <div className="text-center">
                  {t("common:sales_price")}: {formatCurrency(expenseLine.sellingPrice ?? 0)}
                </div>
              )}
              {showGrossPrice && (
                <div className="text-right">
                  {t("common:gross_price")}: {formatCurrency(expenseLine.grossPrice ?? 0)}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
