import { Form } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { PageLayout } from "~/lib/ui/page-layout";
import { linkToFormNew } from "~/lib/utils";
import { useLocale } from "~/lib/utils/date";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

const columnHelper = createColumnHelper<Form>();

export default function RegistrationsPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const locale = useLocale();
  const tableState = useDataTableState();

  const columns = useDataColumns(() => [
    columnHelper.accessor("created", {
      header: t("common:created"),
      cell: ({ row }) => (
        <OptionalLink to={linkToFormNew(row.original.id)}>
          {locale.format(row.original.created!)}
        </OptionalLink>
      ),
    }),
    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
    }),

    columnHelper.accessor("project.name", {
      header: t("common:project", { count: 1 }),
    }),
    // TODO: Add employee colum, but only if admin
  ]);
  const dataQ = useQuery({
    queryKey: ["registrations"],
    queryFn: () => api.formsList({}),
  });
  const table = useDataTable(
    {
      columns,
      data: dataQ.data?.data,
      tableState,
    },
    {
      onRowSelectionChange: (selected) => console.log(selected),
    }
  );
  return (
    <PageLayout title={t("common:registration", { count: 2 })}>
      <DataTable table={table} />
    </PageLayout>
  );
}
