import { MouseEvent, ReactNode, useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { ComboboxListItem } from "../types";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ComboboxItem from "./combobox-item";
import ComboboxSpinner from "./combobox-spinner";
import ComboboxNoResults from "./combobox-no-results";
import { useTranslation } from "react-i18next";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { objectStringCompare } from "~/lib/utils/object/object-string-compare";
import ComboboxNewEntity from "~/lib/ui/form-elements/combobox/combobox-new-entity";
import { TNewEntityConfig } from "~/lib/entity-ui/types";
import { twMerge } from "tailwind-merge";

/**
 * @param {ComboboxListItem[]} items Filtered array of items to display
 * @param {string} label Label shown above the input field
 * @param {JSX.Element} tooltip Tooltip next to the label
 * @param {JSX.Element | JSX.Element[]} children Override content of "no results found"
 * @param {boolean} hideDropdownButton Hide the dropdown button
 * @param {boolean} hideClearButton Hide the clear button
 * @param {string} query Used to display what was searched for in the default "no results found" section
 * @param {boolean} isLoading Used to display a spinner while data is being loading
 * @param {Function} onInput Callback when text is entered in the input field
 * @param {Function} onSelect Callback when an item is selected
 * @param {Function} onClear Callback when input is cleared
 * @deprecated Use SelectionCombobox instead. This component uses HeadlessUI and will be removed
 * @description Generates a combobox allowing dynamic content to be filterable by input
 */

export default function SingleCombobox({
  items,
  label,
  defaultItem,
  noResultsTemplate,
  hideDropdownButton = false,
  hideClearButton = false,
  query = "",
  placeholder,
  isLoading = false,
  inputDelay = 0,
  onInput,
  onSelect,
  onDeselect,
  onClear,
  className,
  disabled = false,
  newEntityConfig,
  onClose,
  required = false,
  tooltip,
}: {
  items: Array<ComboboxListItem | null>;
  label?: string;
  defaultItem?: ComboboxListItem | null;
  noResultsTemplate?: ReactNode;
  hideDropdownButton?: boolean;
  hideClearButton?: boolean;
  query?: string;
  placeholder?: string;
  isLoading?: boolean;
  inputDelay?: number;
  onInput?: (v: string) => void;
  onSelect?: (v: ComboboxListItem | null) => void;
  onDeselect?: (v: ComboboxListItem) => void;
  onClear?: () => void;
  className?: string;
  disabled?: boolean;
  newEntityConfig?: TNewEntityConfig;
  onClose?: () => void;
  required?: boolean;
  tooltip?: ReactNode | null;
}) {
  const [value, setValue] = useState<ComboboxListItem | null>(defaultItem || null);
  const [localQuery, setLocalQuery] = useState<string>("");

  const { t } = useTranslation();

  const [noValueText, setNoValueText] = useState<string>(t("ui:combobox.loading-data"));

  useDebounce(
    () => {
      onInput?.(localQuery);
    },
    [localQuery],
    inputDelay
  );

  // Fix flickering
  useDebounce(
    () => {
      setNoValueText(isLoading ? t("ui:combobox.loading-data") : placeholder || "");
    },
    [isLoading],
    1
  );

  useEffect(() => {
    if (defaultItem !== undefined) {
      setValue(defaultItem);
    }
  }, [defaultItem]);

  // Needed as HeadlessUI {selected} does type checking, which does not work with React render methods on complex objects
  const isSelected = (id: string): boolean => {
    if (!value) return false;
    return value.id === id;
  };

  // fix by checking null value and not setting value accordingly

  const handleSelect = (v: ComboboxListItem) => {
    if (objectStringCompare(value, v)) {
      handleDeselect(v);
      setLocalQuery("");
      onInput?.("");
    } else {
      setValue(v);
      onSelect?.(v);
      setLocalQuery("");
      onInput?.("");
    }
  };

  const handleClear = () => {
    setValue(null);
    onClear?.();
  };

  const handleDeselect = (v: ComboboxListItem) => {
    setValue(null);
    onDeselect?.(v);
  };

  const handleClose = (open: boolean) => {
    if (!open) onClose?.();
  };

  return (
    <Combobox
      as="div"
      value={value}
      className={twMerge("text-left", className)}
      onChange={handleSelect}
      disabled={disabled}
      nullable
    >
      {({ open }) => (
        <>
          {label && (
            <Combobox.Label className="text-left text-sm font-medium text-gray-700">
              {label}
            </Combobox.Label>
          )}
          <span className="-mb-1 ml-1 inline-block cursor-pointer">{tooltip && tooltip}</span>
          <div className="relative mt-1">
            <Combobox.Button
              as="div"
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                open && e.preventDefault();
              }}
            >
              <Combobox.Input
                className={twMerge(
                  "block h-10 w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 shadow-sm focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm",
                  disabled ? "bg-gray-100" : "bg-white"
                )}
                required={required}
                placeholder={noValueText}
                onChange={(event) => setLocalQuery(event.target.value)}
                displayValue={(d: ComboboxListItem) => d?.label}
                onBlur={() => handleClose(open)}
                autoComplete="off"
              />
            </Combobox.Button>
            {value && !disabled && !hideClearButton ? (
              <button
                onClick={() => handleClear()}
                className={twMerge(
                  "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-hover"
                )}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            ) : null}
            {items && items.length > 0 && !isLoading ? (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map(
                  (d) =>
                    d && (
                      <Combobox.Option
                        key={d.id}
                        value={d}
                        className={twMerge(
                          "relative mx-3 my-2 cursor-pointer select-none rounded-md hover:bg-shade-100"
                        )}
                      >
                        {({ active }) => (
                          <ComboboxItem item={d} active={active} isSelected={isSelected(d.id)} />
                        )}
                      </Combobox.Option>
                    )
                )}
                {newEntityConfig?.display && (
                  <ComboboxNewEntity
                    label={newEntityConfig.label}
                    Icon={newEntityConfig.icon}
                    onClick={() => newEntityConfig.onSelect()}
                  />
                )}
              </Combobox.Options>
            ) : open && isLoading ? (
              <div className="scrollbar-hide absolute z-10 mt-1 h-40 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ComboboxSpinner />
              </div>
            ) : open && items.length === 0 && !isLoading && newEntityConfig?.display ? (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ComboboxNewEntity
                  label={newEntityConfig.label}
                  Icon={newEntityConfig.icon}
                  onClick={() => newEntityConfig.onSelect()}
                />
              </Combobox.Options>
            ) : open && items.length === 0 && !isLoading && !newEntityConfig ? (
              <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {noResultsTemplate ? noResultsTemplate : <ComboboxNoResults query={query} />}
              </div>
            ) : null}
          </div>
        </>
      )}
    </Combobox>
  );
}
