import { Suspense, memo } from "react";
import {
  ArrayLayoutProps,
  composePaths,
  findUISchema,
  rankWith,
  isObjectArray,
} from "@jsonforms/core";
import { Dialog, Label, getIcon } from "~/lib/ui";
import { JsonFormsDispatch } from "@jsonforms/react";
import { range } from "~/lib/utils/utils";
import { Fragment, useState } from "react";
import { ArrayListItem } from "../components/array-list-item";
import { useTranslation } from "react-i18next";
import { childWithAncestor } from "./childWithAncestor";
import { ArrayListContainer } from "../components/array-list-container";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { SkeletonLoader } from "~/lib/ui/skeleton";

// Purpose: A simple array layout renderer that serves as a fallback
// - If you need anything more fancy, add it with a higher rank.
function ArrayLayoutRendererInner(props: ArrayLayoutProps) {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<{ index: number; mode: "edit" | "create" }>();
  const {
    addItem,
    data,
    uischemas,
    schema,
    path,
    uischema,
    rootSchema,
    moveDown,
    moveUp,
    removeItems,
  } = props;

  // TODO: MasterListItem (renders the list item)
  // TODO: When the item is selected, show a rendering
  //if (data === 0) return null;
  const dataArray = range(data);

  function handleDialogAdd() {
    setSelection(undefined);
  }

  function handleAddButton() {
    setSelection({ index: dataArray.length, mode: "create" });
  }

  // Deletes the selected item and resets selection
  // Needs to handle both cancel of new, and cancel of edit
  function handleDialogCancel() {
    if (!selection) return;
    if (selection.mode === "create") {
      if (dataArray.length > 0) {
        removeItems?.(path, [selection.index])(); // Handler, must be invoked
      }
    }

    setSelection(undefined);
  }

  return (
    <div>
      <Label>{props.label}</Label>
      <ArrayListContainer onAdd={handleAddButton}>
        {dataArray.map((i) => {
          const childPath = composePaths(path, `${i}`);
          const ancestor = uischema.options?.["x-ancestor"];
          const listItemUiSchema = findUISchema(
            uischemas ?? [],
            schema,
            uischema.scope,
            path,
            "HorizontalLayout",
            //ancestor ? `${ancestor}-ObjectControl` : "HorizontalLayout", // TODO: Can we do this dynamically?
            uischema,
            rootSchema
          );

          return (
            <Fragment key={childPath}>
              <Suspense fallback={<SkeletonLoader template="box" />}>
                <ArrayListItem
                  isFirst={i === 0}
                  isLast={i === dataArray.length - 1}
                  onRemove={removeItems?.(path, [i])}
                  onMoveDown={moveDown?.(path, i)}
                  onMoveUp={moveUp?.(path, i)}
                  onSelect={() => setSelection({ index: i, mode: "edit" })}
                >
                  <JsonFormsDispatch
                    uischema={childWithAncestor(listItemUiSchema, uischema)}
                    schema={schema}
                    path={composePaths(path, `${i}`)}
                    enabled={props.enabled}
                  />
                </ArrayListItem>
              </Suspense>
            </Fragment>
          );
        })}
        <Dialog
          open={selection !== undefined}
          onOpenChange={handleDialogCancel}
          render={() => {
            const childUiSchema = findUISchema(
              uischemas ?? [],
              schema,
              uischema.scope,
              path,
              undefined, // "VerticalLayout", // TODO: Can we do this dynamically?
              uischema,
              rootSchema
            );
            const dialogTitle = selection?.mode === "edit" ? t("common:edit") : t("common:add");
            const primaryButtonTitle =
              selection?.mode === "edit" ? t("common:save") : t("common:add");

            return (
              <>
                <DialogHeader title={dialogTitle} Icon={getIcon("add")} />
                <div className="mb-4">
                  <JsonFormsDispatch
                    uischema={childWithAncestor(childUiSchema, uischema)}
                    schema={schema}
                    path={composePaths(path, `${selection?.index}`)}
                    enabled={props.enabled}
                  />
                </div>
                <DialogFooter
                  primary={{ label: primaryButtonTitle, onClick: handleDialogAdd }}
                  onClose={handleDialogCancel}
                />
              </>
            );
          }}
        />
      </ArrayListContainer>
    </div>
  );
}

export const ArrayLayoutRenderer = memo(ArrayLayoutRendererInner);

// I'd really like to avoid doing a manual product control, but I'm not sure how to do it
export const arrayLayoutTester = rankWith(3, isObjectArray);
