import { forwardRef, useState } from "react";
import { LanguageModal } from "./components/modal/language-modal";
import { SidebarMobile } from "./sidebar-mobile";
import { QuickActionPanel } from "./components/quick-action-panel";
import { useMount } from "~/lib/lifecycle-helpers";
import { SidebarDesktop, SidebarDesktopRef } from "./sidebar-desktop";
import { getScreenSizeType, ScreenSizeType } from "../../../utils/tailwind/screen-sizes";
import { Dialog } from "~/lib/ui";

type SidebarProps = {
  sidebarOpen: boolean;
  onVisibilityChange: (b: boolean) => void;
  onSidebarChange: (type: ScreenSizeType) => void;
  onSidebarCollapse: (collapsed: boolean) => void;
};

export const Sidebar = forwardRef<SidebarDesktopRef, SidebarProps>(
  ({ sidebarOpen, onVisibilityChange, onSidebarChange, onSidebarCollapse }: SidebarProps, ref) => {
    const [quickActionOpen, setQuickActionOpen] = useState(false);
    const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
    const [sidebarType, setSidebarType] = useState<ScreenSizeType>(getScreenSizeType());

    /**
     * Attach event listener to resize event to update the sidebar type
     */
    useMount(() => {
      const handleResize = () => {
        const type = getScreenSizeType();
        setSidebarType(type);
        onSidebarChange(type);
      };

      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => window.removeEventListener("resize", handleResize);
    });

    const handleSidebarCollapse = (collapsed: boolean) => {
      onSidebarCollapse(collapsed);
    };

    return (
      <div>
        {sidebarType === "mobile" ? (
          <SidebarMobile
            isOpen={sidebarOpen}
            onDismiss={() => onVisibilityChange(false)}
            onOpenLanguageSelect={() => setLanguageSelectOpen(true)}
          />
        ) : (
          <SidebarDesktop
            ref={ref}
            defaultOpen={sidebarType === "desktop"}
            sidebarType={sidebarType}
            onCollapseChange={handleSidebarCollapse}
            onOpenLanguageSelect={() => setLanguageSelectOpen(true)}
          />
        )}
        <QuickActionPanel isOpen={quickActionOpen} onDismiss={() => setQuickActionOpen(false)} />

        <Dialog
          open={languageSelectOpen}
          onOpenChange={setLanguageSelectOpen}
          render={({ onClose }) => <LanguageModal onClose={onClose} />}
        />
      </div>
    );
  }
);

Sidebar.displayName = "Sidebar";
