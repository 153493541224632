import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { SidebarScroller } from "../sidebar-scroller";
import { Link } from "react-router-dom";
import { NavigationItem } from "../navigation-item/navigation-item";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SidebarLaptopTypeProps } from "../../sidebar-desktop";
import { twMerge } from "tailwind-merge";
import { ReleaseNotesNavItem } from "~/lib/release-notes";

export function SidebarExpanded({
  navigation,
  adminNavigation,
  languageNavigationItem,
  onCollapseChange,
  onOpenLanguageSelect,
  sidebarType,
}: SidebarLaptopTypeProps) {
  const scrollRef = useRef<HTMLElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        "fixed inset-y-0 flex w-64  flex-col print:hidden",
        sidebarType === "laptop" ? "z-navigation-menu" : ""
      )}
    >
      <div className="pointer-events-none absolute top-2 flex h-10 w-full justify-end">
        <div
          className="pointer-events-auto -mr-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-hover text-white"
          onClick={() => onCollapseChange(true)}
        >
          <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
        </div>
      </div>
      <SidebarScroller scrollRef={scrollRef} navRef={navRef} />
      <div
        ref={(el) => (scrollRef.current = el)}
        className="scrollbar-hide flex flex-grow flex-col overflow-y-auto bg-primary pt-8"
      >
        <div className="flex flex-shrink-0 items-center justify-center px-4">
          <Link
            to="/"
            state={{ refreshKey: crypto.getRandomValues(new Uint8Array(1))[0] }} // workaround while we have iframes
          >
            <img className="h-12 w-auto" src="/logo.png" alt={t("ui:navigation.logo_alt")} />
          </Link>
        </div>
        <div className="flex flex-1 flex-col px-4 pt-4">
          <nav
            ref={(e) => (navRef.current = e)}
            className="flex h-full flex-col justify-between space-y-1 py-4"
          >
            <div>
              {navigation.map((item, idx) => (
                <NavigationItem item={item} key={`${item.name}-${idx}`} />
              ))}
            </div>

            <div>
              <ReleaseNotesNavItem />
              {adminNavigation.map((item, idx) => (
                <NavigationItem item={item} key={`${item.name}-${idx}`} />
              ))}

              <NavigationItem
                item={languageNavigationItem}
                onClick={() => onOpenLanguageSelect()}
              />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
