import { NavItem } from "./navigation-item.type";

import { useTranslation } from "react-i18next";
import { useSession } from "~/lib/auth/session";
import { BACKEND_URL } from "~/lib/auth/config";
import { linkToEmployees, linkToProjects, linkToRegistrations } from "~/lib/utils";
import { getIcon } from "~/lib/ui/icons/icon";
import { capitalCase } from "capital-case";

export function useNavigationContent() {
  const { t } = useTranslation();
  const { apiKey } = useSession();

  const adminNavigation: Array<NavItem> = [
    {
      name: t("ui:navigation.superadmin", "Superadmin"),
      href: `${BACKEND_URL}/admin`,
      external: true,
      role: "admin",
      icon: getIcon("superadmin"),
    },
  ];

  const quickActionNavigation: Array<NavItem> = [
    {
      name: t("common:offer_quote", "Offer/Quote"),
      href: "#",
      description: t("ui:navigation.create.offer_quote_description", "Create a new offer/quote"),
      icon: getIcon("offer"),
    },
    {
      name: t("common:job", "Job"),
      href: "#",
      description: t("ui:navigation.create.job_description", "Create a new job"),
      icon: getIcon("task"),
    },
    {
      name: t("common:invoice", { defaultValue: "Invoice", count: 1 }),
      href: "#",
      description: t("ui:navigation.create.invoice_description", "Create a new invoice"),
      icon: getIcon("invoice"),
    },
    {
      name: t("common:customer", { count: 1, defaultValue: "Customer" }),
      href: "#",
      description: t("ui:navigation.create.customer_description", "Create a new customer"),
      icon: getIcon("customer"),
    },
  ];

  const navigation: Array<NavItem> = [
    {
      name: t("common:frontpage"),
      href: "/frontpage",
      icon: getIcon("home"),
      featureName: "cp3.frontpage",
      role: "company",
      badge: {
        label: "Test",
        className: "bg-yellow-100 text-yellow-700",
      },
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:invoice", { count: 0, defaultValue: "Invoices" }),
      href: "/companies/invoices",
      icon: getIcon("invoice"),
      featureName: "invoices",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:invoice", { count: 2, defaultValue: "Invoices" }),
      href: "/invoices",
      icon: getIcon("invoice"),
      featureName: "cp3.invoices",
      role: "company",
      badge: {
        label: "D",
        className: "bg-red-100 text-red-700",
      },
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:planning_legacy", "Calendar and Planning v1"),
      href: "/companies/schedules/new",
      icon: getIcon("planning"),
      featureName: "scheduling_v2",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:planning", "Calendar and Planning"),
      href: "/companies/schedules",
      icon: getIcon("planning"),
      featureName: "scheduling_v2_in_v3",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:customer", { count: 0, defaultValue: "Customers" }),
      href: "/customers",
      icon: getIcon("customer"),
      featureName: "cp3.customers",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:planner", { count: 0, defaultValue: "Planning" }),
      href: "/planning",
      icon: getIcon("planning"),
      featureName: "cp3.planner",
      role: "company",
    },
    {
      name: t("common:vendor_invoice", { count: 0, defaultValue: "Vendor Invoices" }),
      href: "/companies/expenses",
      icon: getIcon("expense"),
      featureName: "project_expenses",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:vendor_invoice", { count: 2, defaultValue: "Vendor Invoices" }),
      href: "/expenses",
      icon: getIcon("expense"),
      featureName: "cp3.expenses",
      role: "company",
      badge: {
        label: "D",
        className: "bg-red-100 text-red-700",
      },
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:employee", { count: 0, defaultValue: "Employees" }),
      href: "/companies/users",
      icon: getIcon("employee"),
      featureName: "users",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:employee", { count: 2, defaultValue: "Employees" }),
      href: linkToEmployees(),
      icon: getIcon("employee"),
      featureName: "cp3.users",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
      badge: {
        label: capitalCase(t("ui:navigation.test", "Test")),
        className: "bg-yellow-100 text-yellow-700",
      },
    },
    {
      name: t("common:product", { count: 2, defaultValue: "Products" }),
      href: "/products",
      icon: getIcon("product"),
      featureName: "cp3.products",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:report", { count: 0 }),
      href: "/reports",
      icon: getIcon("report"),
      featureName: "cp3.reports",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:project", { count: 0 }),
      href: linkToProjects(),
      icon: getIcon("project"),
      featureName: "cp3.projects",
      role: "company",
    },
    {
      name: t("common:offer", { count: 2 }),
      href: "/offers",
      icon: getIcon("offer"),
      featureName: "cp3.offers",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("ui:navigation.rental"),
      href: "/companies/materials",
      icon: getIcon("rental"),
      featureName: "machinery_rental",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("ui:navigation.data_export"),
      href: "/companies/form_templates",
      icon: getIcon("export"),
      featureName: "data_export",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("ui:navigation.orders"),
      href: "/companies/orders",
      icon: getIcon("invoice"),
      featureName: "orders",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("ui:navigation.order_complaints"), // Fakturakontrol (old)
      href: "/companies/order_complaints",
      icon: getIcon("invoice"),
      featureName: "order_complaints",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
    },
    {
      name: t("common:registration", { count: 2 }),
      href: linkToRegistrations(),
      icon: getIcon("registration"),
      featureName: "cp3.registrations",
      role: "company",
      isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
      badge: {
        label: "D",
        className: "bg-red-100 text-red-700",
      },
    },
    {
      name: t("ui:navigation.timeregistration", "Time Registration"),
      href: "/mobile/forms/add",
      role: "company_employee_individual_login",
      featureName: "timereg",
      icon: getIcon("registration"),
    },
    {
      name: t("ui:navigation.timeregistration", "Time Registration"),
      href: "/time-registration/new",
      role: "company_employee_individual_login",
      featureName: "cp3.forms",
      badge: {
        label: capitalCase(t("ui:navigation.new", "New")),
      },
      icon: getIcon("registration"),
    },
    {
      name: t("ui:navigation.project_manager", "Project Manager"),
      href: "/project-leader",
      isHidden: ({ user }) => !user.isProjectLeader,
      icon: getIcon("projectManager"),
    },

    {
      name: t("common:more", "Mere"),
      href: "#",
      icon: getIcon("settings"),
      children: [
        {
          name: t("common:settings"),
          href: "/settings",
          featureName: "cp3.settings",
          role: "company",
          isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
        },
        {
          name: t("ui:navigation.data_agreement", "Data Handling Agreement"),
          href: "https://app.apacta.com/docs/gdpr_apacta_dpa_dk_20240628.pdf",
          external: true,
          isHidden: ({ user }) => user.adminAccess !== "access_to_admin",
        },
        { name: t("common:logout", "Log out"), href: "/logout" },
      ],
    },
  ];

  const mobileNavigation: Array<NavItem> = [
    {
      name: t("ui:navigation.create_new", "Create new"),
      href: "#",
      icon: getIcon("create_new"),
      children: quickActionNavigation,
      featureName: "cp3.create_navbutton",
    },
    ...navigation,
    {
      name: t("ui:navigation.administration", "Administration"),
      href: "#",
      icon: getIcon("administration"),
      children: adminNavigation,
    },
  ];

  return { navigation, mobileNavigation, adminNavigation, quickActionNavigation };
}
