import { Button, Dialog, Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { debounce } from "lodash";
import { useState } from "react";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { IListProjectsProjectStatusEnum, LabelEntity, Project } from "@apacta/sdk";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { CreateProjectDialog } from "~/pages/projects/_cmp/create-project-dialog";
import colors from "tailwindcss/colors";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { Link } from "react-router-dom";
import { linkToProject } from "~/lib/utils";

export function ProjectSection({
  project,
  formState,
}: {
  project?: Project;
  formState: TaskFormState;
}) {
  const { t } = useTranslation();
  const { taskLabels } = useCategoryLabels();
  const selectedLabel = taskLabels.find(
    (label: LabelEntity) => label.id === formState.getValue("labelId")
  );

  const [projectSearchQuery, setProjectSearchQuery] = useState<string>("");
  const [newProjectName, setNewProjectName] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(project);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const api = useAPI();

  const { data, isFetching } = useQuery({
    queryKey: ["projects", projectSearchQuery],
    queryFn: () => {
      return api.iListProjects(
        {
          q: projectSearchQuery,
          projectStatus: IListProjectsProjectStatusEnum.Open,
          sort: "project_number",
          direction: "desc",
        },
        {}
      );
    },
  });

  const projects = (data?.data ?? []) as Array<Project>;

  const updateSearchQuery = debounce((value: string) => {
    setProjectSearchQuery(value);
  }, 500);

  const handleSelectProject = (proj: Project | undefined) => {
    setSelectedProject(proj);
    formState.setValues({ projectId: proj?.id ?? null });
    formState.setValues({ projectName: proj ? proj.fullName : null });
  };

  const handleSelectCreatedProject = (projectId: string) => {
    api.iGetProject({ projectId }).then((proj) => {
      const p = proj.data;
      setSelectedProject(p);
      formState.setValues({ projectId });
      formState.setValues({ projectName: p ? p.fullName : null });
    });
  };

  return (
    <div className="flex w-full items-center gap-4 pr-8">
      <div
        style={{ backgroundColor: selectedLabel ? selectedLabel.bgColor : colors["gray"][400] }}
        className="h-6 w-6 rounded-full"
      ></div>
      <div className="flex grow gap-2">
        <Popover
          config={{
            align: "start",
            side: "bottom",
            sideOffset: -40,
            usePortal: false,
            animate: false,
          }}
          triggerAsChild={true}
          onOpenChange={() => {
            if (!selectedProject) {
              setProjectSearchQuery("");
            }
          }}
          triggerRender={({ open }) => (
            <button className="h-10 grow cursor-pointer rounded-none border-l-0 border-r-0 border-t-0 px-0 py-0 text-base text-shade-500 hover:bg-shade-100 focus:bg-shade-100 focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0">
              <div className="flex w-full cursor-pointer items-center gap-2 border-0 bg-transparent px-3 py-2 text-left">
                {open ? (
                  <>&nbsp;</>
                ) : selectedProject ? (
                  <span className="text-zinc-700">{selectedProject.fullName}</span>
                ) : project ? (
                  <span className="text-zinc-700">{project.fullName}</span>
                ) : (
                  t("common:select_entity", {
                    entity: t("common:project", { count: 1 }).toLowerCase(),
                  })
                )}
                <div>{open ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}</div>
              </div>
            </button>
          )}
        >
          {(close) => (
            <div className="w-[--radix-popover-trigger-width]">
              <div>
                <input
                  placeholder={t("common:search_x", {
                    x: t("common:project", { count: 2 }).toLowerCase(),
                  })}
                  type="text"
                  defaultValue={selectedProject ? selectedProject.fullName : ""}
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => updateSearchQuery(e.currentTarget.value)}
                  className="h-10 w-full cursor-pointer border-0 bg-transparent px-3 py-2 text-base focus:cursor-auto focus:outline-none focus:ring-0 focus-visible:bg-shade-100 focus-visible:outline-none focus-visible:ring-0"
                />
              </div>
              <div className="planning-scrollbar max-h-32 overflow-y-auto border bg-white shadow-sm">
                {isFetching ? (
                  <div className="px-4 py-2">
                    <ContentLoader className={twMerge("h-6 w-full")}>
                      <rect x={0} y={0} width="80vw" height="100vh"></rect>
                    </ContentLoader>
                  </div>
                ) : projects.length ? (
                  <>
                    {projects.map((proj) => (
                      <div
                        key={proj.id}
                        onClick={() => {
                          handleSelectProject(proj);
                          close();
                        }}
                        className="group flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-shade-100"
                      >
                        <div className="group:hover:bg-shade-200 rounded-full bg-shade-100 px-2 py-1 text-xs">
                          #{proj.projectNumber}
                        </div>
                        <div>{proj.name}</div>
                      </div>
                    ))}
                    {projectSearchQuery !== "" && (
                      <div
                        onClick={() => {
                          setNewProjectName(projectSearchQuery);
                          setIsCreateDialogOpen(true);
                          close();
                        }}
                        className="group flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-shade-100"
                      >
                        <Icon name="add" />

                        <div>{t("common:create", { entity: projectSearchQuery })}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <div className="px-3 py-2 text-shade-400">{t("common:no_results")}</div>
                    <div>
                      {projectSearchQuery !== "" && (
                        <div
                          onClick={() => {
                            setNewProjectName(projectSearchQuery);
                            setIsCreateDialogOpen(true);
                            close();
                          }}
                          className="group flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-shade-100"
                        >
                          <Icon name="add" />

                          <div>{t("common:create", { entity: projectSearchQuery })}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Popover>
        {selectedProject && (
          <div className="flex items-center">
            <Link to={linkToProject(selectedProject?.id)} target="_blank">
              <Button variant="secondary" className="flex items-center gap-2" size="small">
                <span>{t("planning:context-menu.go-to-task")}</span>
                <Icon name="externalLink" className="h-4 w-4" />
              </Button>
            </Link>
          </div>
        )}
        <Dialog
          open={isCreateDialogOpen}
          onOpenChange={setIsCreateDialogOpen}
          render={({ onClose }) => (
            <CreateProjectDialog
              onProjectCreated={(projectId) => {
                handleSelectCreatedProject(projectId);
                onClose();
              }}
              onOpenChange={() => onClose()}
              defaultName={newProjectName}
            />
          )}
        />
      </div>
    </div>
  );
}
