import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleCombobox from "~/lib/ui/form-elements/combobox/single-combobox";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Dialog } from "~/lib/ui";
import ProductRulesTooltip from "~/pages/products/_cmp/product-rules-tooltip";

export function ProductRulesSelect({
  onChange,
  initialProductRule,
  hideLabel = false,
}: {
  onChange: (condition: ComboboxListItem | null) => void;
  initialProductRule?: string | null;
  hideLabel?: boolean;
}) {
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const [selected, setSelected] = useState<ComboboxListItem | null>(null);

  useEffect(() => {
    onChange?.(selected);
  }, [selected]);

  const priceTypes: Array<ComboboxListItem> = [
    { id: "NONE", label: t("common:none"), value: null },
    {
      id: "REPLACE_WORKING_HOURS",
      label: t("products:replace_working_hours"),
      value: "REPLACE_WORKING_HOURS",
    },
    {
      id: "REDUCE_WORKING_HOURS",
      label: t("products:reduce_working_hours"),
      value: "REDUCE_WORKING_HOURS",
    },
  ];
  const defaultPriceType = priceTypes.find((i) => i.id === initialProductRule) || null;

  const questionMarkDialog = () => (
    <Dialog
      trigger={<QuestionMarkCircleIcon className="h-4 w-4" />}
      render={() => ProductRulesTooltip()}
    />
  );
  return (
    <SingleCombobox
      items={priceTypes}
      className="w-full"
      label={hideLabel ? "" : t("products:product_rules")}
      query={q}
      onInput={setQ}
      onSelect={(v) => setSelected(v)}
      onDeselect={() => setSelected(null)}
      onClear={() => setSelected(null)}
      defaultItem={defaultPriceType}
      inputDelay={500}
      hideDropdownButton={true}
      hideClearButton={true}
      required={true}
      tooltip={questionMarkDialog()}
    />
  );
}
