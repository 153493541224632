import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { Contact, IListProjectsDirectionEnum } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useParams } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router-dom";
import { useMount } from "~/lib/lifecycle-helpers";
import { EntityTable } from "~/lib/entity-ui";
import { linkToProject, usePagination } from "~/lib/utils";
import ProjectStatusChange from "~/pages/projects/_cmp/project-status-change";
import { Currency } from "~/lib/ui/currency";
import { MAXIMUM_DESCRIPTION_LENGTH } from "~/pages/projects";
import { trimString } from "~/lib/utils/utils";
import { useMe } from "~/lib/auth/use-me";

// TODO - Align with new projects table and API before launching projects

export default function ProjectsTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const api = useAPI();
  const { id: customerId } = useParams();
  const { companySettings } = useMe();

  const [pagination] = usePagination();

  const { t } = useTranslation();

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("common:project", { count: 2 })}`);
  });

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="w-full">
        <div>
          <div className="flex flex-row items-start justify-between">
            <h2>{t("common:project", { count: 2 })}</h2>
          </div>
          {customerId ? (
            <div className="flex w-full flex-col gap-10 rounded-md">
              <EntityTable
                cacheKey={["projects", customerId]}
                dataFn={() =>
                  api.iListProjects({
                    page: pagination.page,
                    q: pagination.q,
                    sort: pagination.sort ?? "project_number",
                    direction: pagination.direction ?? ("desc" as IListProjectsDirectionEnum),
                    contactId: customerId,
                  })
                }
                fields={{
                  number: {
                    label: t("projects:fields.number"),
                    href: (row) => linkToProject(row.id),
                    renderColumn: (row) => (
                      <span className="block font-semibold ">#{row.projectNumber}</span>
                    ),
                    collapse: {
                      breakpoint: "md",
                    },
                    sortBy: "project_number",
                    hideColumn: !companySettings.projectNumbers,
                  },
                  nameDescription: {
                    label: t("common:name"),
                    tdClass: "max-w-sm",
                    href: (row) => linkToProject(row.id),
                    renderColumn: (row) => (
                      <div>
                        <span className="block font-semibold">{row.name}</span>
                        {row.description && (
                          <span className="mt-2 block text-gray-400">
                            {trimString(row.description, MAXIMUM_DESCRIPTION_LENGTH)}
                          </span>
                        )}
                      </div>
                    ),
                    sortBy: "name",
                  },
                  invoicable: {
                    label: t("projects:fields.uninvoiced_amount"),
                    renderColumn: (row) => (
                      <Currency className={row.notInvoicedAmount > 0 ? "text-orange-600" : ""}>
                        {row.notInvoicedAmount}
                      </Currency>
                    ),
                    collapse: {
                      breakpoint: "lg",
                      on: "name",
                    },
                    sortBy: "not_invoiced_amount",
                  },
                  status: {
                    label: t("common:status"),
                    collapse: {
                      breakpoint: "sm",
                      on: "nameDescription",
                    },
                    sortBy: "project_status_id",
                    tdClass: "",
                    renderColumn: (row) => (
                      <ProjectStatusChange project={row} initialStatusId={row.projectStatusId} />
                    ),
                  },
                }}
              />
            </div>
          ) : (
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
