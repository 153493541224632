import { flexRender, Row, RowData, Table } from "@tanstack/react-table";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";
import { TablePagination } from "./pagination";
import { Icon } from "..";
import { Checkbox } from "../form-elements";
import { Fragment, ReactNode, useEffect, useRef } from "react";
import { TableTopBar } from "./table-top-bar";
import { ButtonAction } from "../action-buttons";
import noRowsSVG from "./no-rows.svg";
import { useTranslation } from "react-i18next";
import { PaginationDetails } from "@apacta/sdk";

// Callback extends the generic RowData type
type RowSelectionActionFn<T extends RowData> = <K extends T>(
  selectedRows: Array<Row<K>>
) => Array<ButtonAction>;

type Props<T extends RowData> = {
  table: Table<T>;
  renderExpandedRow?: (row: T) => ReactNode;
  renderFilters?: () => ReactNode;
  /** Actions available for row selection. Pass array or function to array
   * Note: Actions for table columns are configured in the column definition.
   */
  selectionActions?: Array<ButtonAction> | RowSelectionActionFn<T>;
  /** Pagination from the server - helps if we don't know the page-size */
  paginationDetails?: PaginationDetails;
  /** Search Overrides */
  search?: {
    placeholder?: string;
  };

  /** Loading state is handled by the table's meta object. Do not add it here. */
};

/**
 * A rendering component for an instance of React Table or useDataTable()
 *
 * Ideal for pure data without API calls or custom behaviour. See readme for details.
 */
export function DataTable<T extends RowData>({
  table,
  renderExpandedRow,
  selectionActions,
  renderFilters,
  paginationDetails,
  search,
}: Props<T>) {
  const { t } = useTranslation();
  const { pagination } = table.getState();
  const topOfTable = useRef<HTMLDivElement>(null);
  const showLeftActionArea = table.options.enableExpanding || table.options.enableRowSelection;
  const visibleColumnsLength = showLeftActionArea
    ? table.getVisibleFlatColumns().length + 1
    : table.getVisibleFlatColumns().length;

  // If the table data is empty, but the page is above 1, we should go back to the previous page
  useEffect(() => {
    if (!pagination) return;
    if (table.options.data.length === 0 && pagination.pageIndex > 0) {
      console.warn("Table data was empty, going back automatically...");
      table.setPageIndex(pagination.pageIndex - 1);
    }
  }, [table.options.data]);

  // Adjust pagination if it differs from server response
  useEffect(() => {
    if (!paginationDetails?.limit) return;
    if (!table.getState().pagination) return;
    if (table.getState().pagination.pageSize !== paginationDetails.limit) {
      table.setPageSize(paginationDetails.limit);
    }
  }, [paginationDetails]);

  return (
    <>
      <div ref={topOfTable}>
        <TableTopBar
          table={table}
          selectionActions={selectionActions}
          renderFilters={renderFilters}
          placeholder={search?.placeholder}
        />
      </div>
      <div className="apacta-table">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {showLeftActionArea && (
                    <th className="w-10">
                      <div className="flex flex-row justify-start">
                        {table.options.enableRowSelection && (
                          <Checkbox
                            checked={table.getIsAllRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                          />
                        )}
                        {table.options.enableExpanding && (
                          <button onClick={table.getToggleAllRowsExpandedHandler()}>
                            <Icon
                              name={table.getIsAllRowsExpanded() ? "retractRow" : "expandRow"}
                              size="small"
                            />
                          </button>
                        )}
                      </div>
                    </th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={twMerge(
                        header.column.getCanSort() && "cursor-pointer",
                        header.column.columnDef.meta?.className,
                        header.column.columnDef.meta?.headerClassName
                      )}
                      {...(header.column.getCanSort()
                        ? { onClick: header.column.getToggleSortingHandler() }
                        : {})}
                    >
                      <div className={twMerge("inline-flex flex-row")}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getIsSorted() === "asc" && (
                          <Icon name="chevronDown" size="small" className="ml-2" />
                        )}
                        {header.column.getIsSorted() === "desc" && (
                          <Icon name="chevronUp" size="small" className="ml-2" />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {/* Nested original rows TODO: https://codesandbox.io/p/devbox/github/tanstack/table/tree/main/examples/react/sub-components?embed=1&file=%2Fsrc%2Fmain.tsx&theme=dark */}
            {table.getRowModel().rows.map((row, idx) => {
              const enableRowExpansion =
                table.options.enableExpanding && row.getCanExpand() && renderExpandedRow;
              return (
                <Fragment key={`${row.id}-${idx}`}>
                  <tr
                    key={`row-${row.id}-${idx}`}
                    className={twMerge(idx % 2 ? "bg-shade-50" : "bg-white")}
                  >
                    {showLeftActionArea && (
                      <td className="">
                        <div className="flex flex-row justify-start">
                          {table.options.enableRowSelection && (
                            <Checkbox
                              checked={row.getIsSelected()}
                              onChange={row.getToggleSelectedHandler()}
                              disabled={!row.getCanSelect()}
                            />
                          )}
                          {table.options.enableExpanding && row.getCanExpand() && (
                            <button onClick={row.getToggleExpandedHandler()}>
                              <Icon
                                name={row.getIsExpanded() ? "retractRow" : "expandRow"}
                                size="small"
                              />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={`row-${row.id}-${idx}-cell-${cell.id}-${index}`}
                        className={twMerge(
                          cell.column.columnDef.meta?.className,
                          cell.column.columnDef.meta?.cellClassName
                        )}
                      >
                        {table.options.meta?.isLoading && (
                          <ContentLoader className="h-5 rounded-md">
                            <rect x={0} y={0} width="100%" height="100%"></rect>
                          </ContentLoader>
                        )}

                        {!table.options.meta?.isLoading &&
                          flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {enableRowExpansion && (
                    <tr
                      key={`row-${row.id}-${idx}-expanded`}
                      className={twMerge(
                        "hidden animate-slide-down",
                        row.getIsExpanded() && "table-row"
                      )}
                    >
                      <td colSpan={table.getVisibleFlatColumns().length + 1}>
                        {renderExpandedRow?.(row.original)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
            {/* Empty state  */}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={visibleColumnsLength} className="text-center">
                  <img className="inline h-30" src={noRowsSVG} />
                  <p className="mt-4 text-gray-500">{t("common:no_results")}</p>
                </td>
              </tr>
            )}

            {/* Footer rows */}
            {table.getFooterGroups().map((footerGroup) => {
              const footers = footerGroup.headers
                .map((h) => h.column.columnDef.footer)
                .filter(Boolean);

              // Skip showing footers if there are none defined
              if (footers.length === 0) {
                return null;
              }
              return (
                <tr key={footerGroup.id}>
                  <>
                    {showLeftActionArea && <th></th>}
                    {footerGroup.headers.map((footer) => (
                      <th
                        key={footer.id}
                        colSpan={footer.colSpan}
                        className={twMerge(
                          footer.column.columnDef.meta?.className,
                          footer.column.columnDef.meta?.footerClassName
                        )}
                      >
                        {footer.isPlaceholder
                          ? null
                          : flexRender(footer.column.columnDef.footer, footer.getContext())}
                      </th>
                    ))}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="mt-4">
          <TablePagination
            hasNextPage={table.getCanNextPage()}
            hasPrevPage={table.getCanPreviousPage()}
            pageCount={table.getPageCount()}
            pagination={pagination}
            onNewPageIndex={(newIndex) => {
              table.setPageIndex(newIndex);
              window.scrollTo(0, 0); // Since we're not navigating...
            }}
          />
        </div>
      )}
    </>
  );
}
