import { HolidaysTypes } from "date-holidays";
import { matchDates } from "~/lib/utils/date/date-utils";

export const HolidayBadge = ({
  date,
  holidays,
}: {
  date: Date;
  holidays: Array<HolidaysTypes.Holiday>;
}) => {
  const holiday: HolidaysTypes.Holiday | undefined = holidays.find((h) =>
    matchDates(new Date(h.date), date, { ignoreTimestamp: true })
  );

  if (!holiday) return null;

  return (
    <div className="w-fit rounded-full bg-orange-200 px-2 py-0.5 text-xs text-zinc-700">
      {holiday.name}
    </div>
  );
};
