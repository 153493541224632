import { IListProjectsDirectionEnum, ProductBundle } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { FilterGroupActivities } from "~/lib/ui/filters/filter-group-activities";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { Currency } from "~/lib/ui/currency";
import { Button, getIcon } from "~/lib/ui";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

const columnHelper = createColumnHelper<ProductBundle>();

export default function ProductBundlesIndex() {
  const api = useAPI();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [productBundleFilters, setProductBundleFilters] = useTypedSearchParams<{
    activities?: Array<string>;
  }>();

  const columns = useDataColumns(() => {
    return [
      columnHelper.accessor("name", {
        header: t("products:bundle.name"),
        enableSorting: true,
        cell: ({ row }) => (
          <OptionalLink to={row.original.id}>
            <span className="font-semibold">{row.original.name}</span>
          </OptionalLink>
        ),
      }),
      columnHelper.accessor("activity.name", {
        header: t("common:label", { count: 1 }),
        cell: ({ row }) =>
          row.original.activity ? (
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              {row.original.activity.name}
            </span>
          ) : null,
      }),
      columnHelper.accessor("salesPrice", {
        header: t("common:sales_price"),
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => (
          <Currency>{row.original.salesPrice ? row.original.salesPrice : 0}</Currency>
        ),
      }),
      columnHelper.display({
        id: "actions",
        meta: {
          className: "text-right",
        },
        cell: ({ row }) => (
          <ActionButtons
            size="small"
            actions={[
              {
                Icon: getIcon("duplicate"),
                label: t("common:duplicate"),
                onClick: () => handleDuplicateProductBundle(row.original.id),
              },
              {
                Icon: getIcon("delete"),
                label: t("common:delete_prompt", {
                  name: t("common:product_bundle", { count: 1 }).toLowerCase(),
                }),
                mustConfirm: true,
                confirmOptions: {
                  description: t("common:delete_description_prompt", {
                    entity: t("common:product_bundle", { count: 1 }).toLowerCase(),
                  }),
                },
                onClick: () => handleDeleteProductBundle(row.original.id),
              },
            ]}
          />
        ),
      }),
    ];
  });
  const tableState = useDataTableState();

  const pageQ = useQuery({
    queryKey: ["product_bundles", tableState.queryKeys, productBundleFilters],
    queryFn: () =>
      api.productBundlesList({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        sort: tableState.sortBy,
        q: tableState.state.search,
        direction: tableState.sortingDirection as IListProjectsDirectionEnum,
        activityIds: productBundleFilters.activities?.join(","),
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: pageQ.data?.data,
    },
    {
      enableGlobalFilter: true,
    }
  );

  const handleCreateProductBundle = async () => {
    api
      .iCreateProductBundle({
        iCreateProductBundleRequest: { name: t("products:bundle.new_product_bundle_name") },
      })
      .then((v) => {
        if (v.data?.id) {
          navigate(v.data.id);
        }
      });
  };
  const handleDuplicateProductBundle = async function (id: string) {
    await api.iProductBundleDuplicate({ productBundleId: id });
    await pageQ.refetch();
  };
  const handleDeleteProductBundle = async (id: string): Promise<void> => {
    await api.iDeleteProductBundle({ productBundleId: id });
    await pageQ.refetch();
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("common:product_bundle", { count: 2 })}</h2>
        <div>
          <Button
            Icon={getIcon("add")}
            variant="tertiary"
            className="print:hidden"
            onClick={handleCreateProductBundle}
          >
            {t("common:create", {
              defaultValue: "Create {{entity}}",
              replace: { entity: t("common:product_bundle", { count: 1 }).toLocaleLowerCase() },
            })}
          </Button>
        </div>
      </div>
      <DataTable
        table={table}
        renderFilters={() => (
          <FilterGroupActivities
            value={productBundleFilters.activities}
            onConfirmSelection={(selectedActivities) => {
              setProductBundleFilters(
                "activities",
                selectedActivities.map((a) => a.id)
              );
            }}
            onClear={() => setProductBundleFilters("activities", undefined)}
          />
        )}
      />
    </div>
  );
}
