import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const variants = {
  green: "bg-green-100 text-green-800",
  yellow: "bg-yellow-100 text-yellow-800",
  red: "bg-red-100 text-red-800",
  tertiary: "bg-tertiary text-white",
  gray: "bg-gray-100 text-gray-800",
};

export type BadgeVariant = keyof typeof variants;

export type BadgeSize = "sm" | "md";

export default function Badge({
  variant = "gray",
  size = "md",
  label = "Unknown",
  className,
}: {
  variant?: BadgeVariant;
  label?: ReactNode;
  size?: BadgeSize;
  className?: string;
}) {
  return (
    <span
      className={twMerge(
        "inline-flex items-center rounded-full font-medium",
        variants[variant],
        size === "sm" && "px-2.5 py-0.5 text-xs",
        size === "md" && "px-3 py-0.5 text-sm",
        className
      )}
    >
      {label}
    </span>
  );
}
