import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { Icon } from "~/lib/ui";
import { useNavigationContent } from "./navigation-item/navigation-item.content";

export function QuickActionPanel({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) {
  const { quickActionNavigation } = useNavigationContent();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onDismiss()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 ml-64 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Create New Sub-menu */}
        <div className="fixed inset-0 ml-64 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none inset-y-0 left-0 flex max-w-full pr-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative top-24 w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-8 flex pl-2 pt-4 sm:-mr-10 sm:pl-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => onDismiss()}
                      >
                        <span className="sr-only">Close panel</span>
                        <Icon name="close" size="medium" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto rounded-br-xl rounded-tr-xl bg-white">
                    <div className="relative gap-6 bg-white px-3 py-3 text-gray-800 sm:gap-8 sm:px-4 sm:py-4 lg:grid-cols-2">
                      {quickActionNavigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className=" flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-200"
                        >
                          {item.icon ? (
                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary text-white sm:h-12 sm:w-12">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                          ) : null}
                          <div className="ml-4">
                            <p className="text-base">{item.name}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
