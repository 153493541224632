import { useTranslation } from "react-i18next";
import { Invoice } from "@apacta/sdk";

export function InvoiceImage({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  if (invoice.isDraft && !invoice.integrationId) return null; // Apacta drafts no image, no draft text
  const image = invoice.integrationImageUrl ?? "/public/apacta-icon-blue.svg";
  return (
    <div className="flex flex-col gap-1.5">
      <div className="h-7 w-7">
        {image ? (
          <img
            title={
              invoice.integrationId
                ? t("invoices:booked_by_partner_integration")
                : t("invoices:booked_by_apacta")
            }
            src={image}
            className=""
          />
        ) : undefined}
      </div>
    </div>
  );
}
