import { Invoice } from "@apacta/sdk";
import Badge from "~/lib/ui/badge";

export function InvoiceBadge({ invoice }: { invoice: Invoice }) {
  // Badge that shows "sent to X" or "draft" or "booked by X"
  const label = (() => {
    if (invoice.isDraft) {
      if (invoice.integrationId) return `Draft in ${invoice.integration?.name}`;
      return "Draft";
    }
    if (invoice.integrationId) return `Booked in ${invoice.integration?.name}`;
    return "Booked in Apacta";
  })();
  return <Badge variant="green" label={label} />;
}
