import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { usePagination } from "../utils";
import { EntityBase, EntityResponse, SelectionWithCurrentSelection } from "./types";
import { ActionButtonsMulti } from "./action-buttons-multi";
import { twMerge } from "tailwind-merge";
import { SearchInput } from "../ui/search-input";

export type SearchFilterProps<DataResponse> = (data?: DataResponse) => ReactNode;

export function TableTopBar<
  GenericResponse extends EntityResponse,
  EntityType extends EntityBase = GenericResponse["data"][number],
>({
  filters,
  showSearch,
  selection,
  onActionSuccess,
  data,
  searchPlaceholder,
}: {
  filters?: SearchFilterProps<GenericResponse>;
  selection?: SelectionWithCurrentSelection<EntityType>;
  showSearch: boolean;
  onActionSuccess?: () => void;
  data?: GenericResponse;
  searchPlaceholder?: string;
}) {
  const [pagination, setPagination] = usePagination();
  const { t } = useTranslation();

  // Default search placeholder
  if (!searchPlaceholder) {
    searchPlaceholder = t("common:search");
  }

  if (filters === undefined && selection?.actions === undefined && !showSearch) {
    return null; // Nothing to show, return null
  }

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex flex-1 flex-col items-start justify-center">
          {/* Left side */}
          {showSearch && (
            <div className="flex w-full max-w-sm flex-1">
              <SearchInput
                value={pagination.q}
                onChangeValue={(val) => setPagination("q", val)} // Note that this navigates the page
                placeholder={searchPlaceholder}
              />
            </div>
          )}
          {filters && (
            <div className={twMerge("flex", showSearch && "mt-4")}>
              <div className="flex flex-wrap gap-2">{filters(data)}</div>
            </div>
          )}
        </div>
        {selection?.actions && selection?.currentSelection.length > 0 && (
          <div className="flex flex-1 flex-col items-end justify-start">
            {/* Right side. Only shown when selection is enabled */}
            <div className="flex gap-x-4">
              <>
                <div className="flex h-full items-center text-tertiary">
                  {t("common:x_selected", {
                    replace: { x: selection?.currentSelection.length },
                    defaultValue: "{{x}} selected",
                  })}
                </div>
                <ActionButtonsMulti<EntityType>
                  actions={selection?.actions}
                  entities={selection?.currentSelection}
                  onSuccess={onActionSuccess}
                />
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

/**
 * @deprecated We're using debounced search input instead
 */
/*
function SearchInput({
  onSubmit,
  onChange,
  value,
  placeholder,
}: {
  onSubmit?: () => void;
  onChange?: (newValue: string) => void;
  value?: string;
  placeholder: string;
}) {
  const { t } = useTranslation();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit?.();
    }
  };

  return (
    <div className="smd:w-1/2 relative w-full">
      <input
        className="border-1 h-10 w-full rounded-lg border-shade-300 bg-white pl-5 pr-12 text-sm ring-hover focus:border-hover focus:outline-none focus:ring-0"
        type="search"
        value={value}
        name="q"
        onChange={(e) => onChange?.(e.target.value)}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      <button
        type="submit"
        className="absolute right-0 top-0 mr-2 mt-2 rounded-full p-1 hover:text-hover"
        onClick={onSubmit}
      >
        <Icon name="search" className="h-5 w-5" />
      </button>
    </div>
  );
}
*/
