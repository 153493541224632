import { UsersPriceGroup } from "@apacta/sdk";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import SingleCombobox from "~/lib/ui/form-elements/combobox/single-combobox";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";

export function UsersPriceGroups({
  onChange,
  initialPriceGroupId,
  hideLabel = false,
}: {
  onChange: (condition: UsersPriceGroup | null) => void;
  initialPriceGroupId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<UsersPriceGroup | null | undefined>(undefined);

  const { data } = useSuspenseQuery({
    queryKey: ["users_price_groups"],
    queryFn: () => api.usersPriceGroupsList({}),
  });

  useEffect(() => {
    if (selectedGroup === undefined) return;
    onChange?.(selectedGroup);
  }, [selectedGroup]);

  const usersPriceGroups = useMemo(() => data?.data, [data?.data]);
  const filtered = useMemo(
    () => data?.data?.map((group) => groupToListItem(group)) || [],
    [data?.data]
  );

  const defaultUsersPriceGroup = filtered.find((i) => i.id === initialPriceGroupId);

  const manageSelection = function (selectedItem: ComboboxListItem | null) {
    const userPriceGroup = usersPriceGroups?.find((group) => group.id === selectedItem?.id);
    setSelectedGroup(userPriceGroup ?? null);
  };

  return (
    <>
      {/*<Dropdown />*/}
      <SingleCombobox
        items={filtered}
        className="w-full"
        label={hideLabel ? "" : t("common:users_price_groups")}
        query={q}
        onInput={setQ}
        placeholder=""
        onSelect={(v) => manageSelection(v)}
        onDeselect={() => manageSelection(null)}
        onClear={() => manageSelection(null)}
        defaultItem={defaultUsersPriceGroup}
        hideDropdownButton={true}
        required={true}
      />
    </>
  );
}

function groupToListItem(group: UsersPriceGroup): ComboboxListItem {
  return {
    id: group.id || "",
    label: group.name ? `${group.name} (${group.salePrice})` : "",
    value: group.id || "",
  };
}
