import { useTranslation } from "react-i18next";
import DialogHeader from "../ui/dialog/dialog-header";
import { ProjectSelection } from "../ui/selection-combobox/project-selection";
import { getIcon } from "../ui";
import { DialogFooter } from "../ui/dialog/dialog-footer";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "../api";
import { useState } from "react";
import { useNavigate } from "react-router";

export function MoveToProjectDialog(props: {
  onClose: () => void;
  formId: string;
  projectId: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useAPI();

  const [nextProjectId, setNextProjectId] = useState(props.projectId);

  const mut = useMutation({
    mutationFn: () =>
      api.updateForm({
        formId: props.formId,
        createFormRequest: {
          projectId: nextProjectId,
        },
      }),
  });

  return (
    <div>
      <DialogHeader
        title={t("forms:move_to_project", "Move to project")}
        Icon={getIcon("project")}
      />
      <ProjectSelection
        defaultValue={props.projectId}
        onSelect={(pId) => {
          setNextProjectId(pId);
        }}
      />
      <DialogFooter
        onClose={props.onClose}
        primary={{
          disabled: props.projectId === nextProjectId,
          label: t("common:move", "Move"),
          onClick: async () => {
            await mut.mutateAsync();
            props.onClose();
            navigate("../");
          },
        }}
      />
    </div>
  );
}
