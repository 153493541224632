import { ControlPanelApiPaymentTerm } from "@apacta/sdk";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import SingleCombobox from "~/lib/ui/form-elements/combobox/single-combobox";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";
import { useToastOnError } from "~/lib/utils/hooks";

export function PaymentConditionsInput({
  onChange,
  initialConditionId,
  hideLabel = false,
}: {
  onChange: (condition: ControlPanelApiPaymentTerm | null) => void;
  initialConditionId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const [selected, setSelected] = useState<ComboboxListItem | null>(null);

  const { data, error } = useSuspenseQuery({
    queryKey: ["conditions"],
    queryFn: () => api.getPaymentTerms(),
  });

  useToastOnError(error);

  useEffect(() => {
    onChange?.(selected);
  }, [selected]);

  const items = useMemo(
    () => data?.data?.map((pt) => paymentTermToListItem(pt)) || [],
    [data?.data]
  );
  const filtered = useMemo(() => {
    return items.filter((it) => it.label.toLowerCase().includes(q.toLowerCase()));
  }, [q, items]);

  return (
    <SingleCombobox
      items={filtered}
      className="w-full"
      label={hideLabel ? "" : t("customers:modal.create_customer.input.conditions")}
      query={q}
      onInput={setQ}
      onSelect={(v) => setSelected(v)}
      onDeselect={() => setSelected(null)}
      onClear={() => setSelected(null)}
      defaultItem={items.find((i) => i.id === initialConditionId)}
    />
  );
}

function paymentTermToListItem(pt: ControlPanelApiPaymentTerm): ComboboxListItem {
  return {
    id: pt.id || "",
    label: pt.name || "",
    value: pt.id || "",
  };
}
