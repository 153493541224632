import { TimeEntryRuleGroup } from "@apacta/sdk";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import SingleCombobox from "~/lib/ui/form-elements/combobox/single-combobox";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";

export function TimeEntryRuleGroupsInput({
  onChange,
  initialEntryId,
  hideLabel = false,
  customClass = "w-full",
}: {
  onChange: (condition: TimeEntryRuleGroup | null) => void;
  initialEntryId?: string;
  hideLabel?: boolean;
  customClass?: string;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const [selected, setSelected] = useState<ComboboxListItem | null>(null);

  const { data, isLoading } = useSuspenseQuery({
    queryKey: ["time_entry_rule_groups"],
    queryFn: () => api.getTimeEntryRuleGroups(),
  });
  useEffect(() => {
    onChange?.(selected);
  }, [selected]);

  const timeEntryRuleGroups = useMemo(
    () => data?.data?.map((group) => groupToListItem(group)) || [],
    [data?.data]
  );
  const filtered = useMemo(() => {
    return timeEntryRuleGroups.filter((cli) => cli.label.toLowerCase().includes(q.toLowerCase()));
  }, [q, timeEntryRuleGroups]);

  return (
    !isLoading &&
    data?.success &&
    !!data?.data?.length && (
      <SingleCombobox
        items={filtered}
        className={customClass}
        label={hideLabel ? "" : t("common:time_entry_rule_group")}
        query={q}
        onInput={setQ}
        onSelect={(v) => setSelected(v)}
        onDeselect={() => setSelected(null)}
        onClear={() => setSelected(null)}
        defaultItem={timeEntryRuleGroups.find((i) => i.id === initialEntryId)}
        inputDelay={500}
        hideDropdownButton={true}
        required={true}
      />
    )
  );
}

function groupToListItem(group: TimeEntryRuleGroup): ComboboxListItem {
  return {
    id: group.id || "",
    label: group.name || "",
    value: group.id || "",
  };
}
