import { Button } from "~/lib/ui";

export function LanguageItem({
  item,
}: {
  item: { name: string; defaultLocale: string; flag: string };
}) {
  return (
    <Button className="cursor-pointer rounded-md bg-white px-4 py-3 outline-none hover:bg-gray-100 focus-visible:bg-gray-100">
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md ">
        <div className="h-8 w-12">
          <img src={`/flags/${item.flag}.png`} className="h-8 w-12 object-cover" />
        </div>
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <span className="text-zinc-600">{item.name}</span>
        </div>
      </div>
    </Button>
  );
}
