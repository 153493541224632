import { useParams } from "react-router";
import { PageLayout } from "~/lib/ui/page-layout";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import HeaderSection from "~/pages/offers/_cmp/header-section";
import DescriptionSection from "~/pages/offers/_cmp/description-section";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import { offerLineToOrderLineTransformer } from "~/lib/ui/order-lines/lib/transformers";
import OrderLinesBuilderProvider, {
  OrderLinesBuilderContext,
} from "~/lib/ui/order-lines/order-lines-builder-context";
import { usePdfBuilder } from "~/lib/pdf/use-pdf-builder";
import { useOfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { Suspense, useEffect, useRef, useState } from "react";
import { OrderLinesBuilderRef } from "~/lib/ui/order-lines/lib/types";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { PageError } from "~/pages/p/offer/_cmp/page-error";
import { Spinner } from "~/lib/ui/spinner";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useTranslation } from "react-i18next";
import { ResponseError } from "@apacta/sdk";
import { dateIsAfterDate } from "~/lib/utils/date/date-utils";
import { MobileView } from "~/pages/p/offer/[id]/_cmp/mobile-view";
import { Button } from "~/lib/ui";

export default function PublicOfferViewPage() {
  const pageTitle = usePageTitle({ hideBrandName: true });
  const { t } = useTranslation();
  const { id, token } = useParams<{ id: string; token: string }>();
  const api = useAPI();
  const { contentRef, previewPdf } = usePdfBuilder();

  const queryClient = useQueryClient();

  const { data, isFetching, error } = useQuery({
    queryKey: ["offer", id, token],
    queryFn: () => api.offersView({ offerId: id as string, token }),
  });

  const [offerHTML, setOfferHTML] = useState<string | null>(null);
  const offer = data?.data;

  const { formState } = useOfferFormState(offer);
  const orderLinesBuilderRef = useRef<OrderLinesBuilderRef>(null);
  const [isCreatingPreview, setIsCreatingPreview] = useState<boolean>(true);

  const createPreview = async () => {
    const preview = await previewPdf();
    setOfferHTML(preview.innerHTML);
    setIsCreatingPreview(false);
    const titlePrefix = offer?.isQuote ? t("offers:type.quote") : t("offers:type.offer");
    pageTitle.set(`${titlePrefix} ${t("common:from").toLowerCase()} ${offer?.company?.name}`);
  };

  useEffect(() => {
    if (offer && !isFetching && contentRef.current && orderLinesBuilderRef.current?.getLines()) {
      createPreview();
    }

    return () => undefined;
  }, [offer, isFetching, contentRef.current, orderLinesBuilderRef.current?.getLines()]);

  const acceptMutation = useMutation({
    mutationFn: () =>
      api.offerAccept({
        offerAcceptRequest: { offerId: offer?.id as string },
        token: offer?.token as string,
      }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id, offer?.token] });
    },
  });

  const rejectMutation = useMutation({
    mutationFn: () =>
      api.offerReject({
        offerAcceptRequest: { offerId: offer?.id as string },
        token: offer?.token as string,
      }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["offer", offer?.id, offer?.token] });
    },
  });

  const expired =
    offer &&
    (offer.offerStatus?.identifier === "expired" ||
      (offer.expirationDate && dateIsAfterDate(new Date(), new Date(offer.expirationDate))));

  return (
    <>
      {offer && !isFetching ? (
        expired ? (
          <PageError
            title={t("offers:public_offer_page.expired.title")}
            description={t("offers:public_offer_page.expired.description")}
          />
        ) : (
          <Suspense>
            <OrderLinesBuilderProvider
              cacheKey={["offer-lines", offer.id, token as string]}
              dataFn={() =>
                api.getOfferLines({
                  offerId: offer.id,
                  token: token as string,
                  applyRules: true,
                })
              }
              transformerFn={offerLineToOrderLineTransformer}
              initialVatPercent={offer.vatPercent ?? 0}
            >
              <OrderLinesBuilderContext.Consumer>
                {({ isInitialized }) => (
                  <>
                    <MobileView
                      offer={offer}
                      onAccept={() => acceptMutation.mutate()}
                      onReject={() => rejectMutation.mutate()}
                      acceptLoading={acceptMutation.isPending}
                      rejectLoading={rejectMutation.isPending}
                      className="block md:hidden"
                    />
                    <div className="hidden md:block">
                      <PageLayout
                        title={t("offers:send_offer_subject", {
                          type: offer?.isQuote ? t("offers:type.quote") : t("offers:type.offer"),
                          title: offer.title,
                        })}
                        renderActions={() =>
                          offer.offerStatus?.identifier === "accepted" ? (
                            <span className="inline-flex items-center rounded-md border bg-green-50 px-2 py-1 text-xs font-medium text-green-700">
                              {t("offers:status.accepted")}
                            </span>
                          ) : offer.offerStatus?.identifier === "rejected" ? (
                            <span className="inline-flex items-center rounded-md border bg-red-50 px-2 py-1 text-xs font-medium text-red-700">
                              {t("offers:status.rejected")}
                            </span>
                          ) : (
                            <div className="flex gap-4">
                              <Button
                                variant="tertiary"
                                loading={acceptMutation.isPending}
                                disabled={acceptMutation.isPending}
                                onClick={() => acceptMutation.mutate()}
                              >
                                {t("common:accept")}
                              </Button>
                              <Button
                                variant="secondary"
                                loading={rejectMutation.isPending}
                                disabled={rejectMutation.isPending}
                                onClick={() => rejectMutation.mutate()}
                              >
                                {t("common:reject")}
                              </Button>
                            </div>
                          )
                        }
                      >
                        <div>
                          {isCreatingPreview && (
                            <div className="flex justify-center px-20 py-16 text-lg text-zinc-900">
                              <Spinner />
                            </div>
                          )}

                          <div
                            className="flex flex-col gap-12 overflow-auto"
                            dangerouslySetInnerHTML={{ __html: offerHTML ?? "" }}
                          ></div>
                          {isInitialized && (
                            <div
                              ref={contentRef}
                              className="hidden border bg-white px-20 py-16 text-lg tracking-[0.01px] text-zinc-900 shadow-sm"
                            >
                              <div className="flex flex-col gap-8">
                                <HeaderSection
                                  selectedCustomer={offer.contact}
                                  selectedContactPerson={offer.contactPerson}
                                  editMode={false}
                                  companyData={offer.company ?? {}}
                                  formState={formState}
                                  offerNumber={offer.offerNumber}
                                />
                                {/* Section to reflect the title of the offer */}
                                <div className="flex text-3xl">
                                  {t(`offers:type.${formState.getValue("type")}`)}:{" "}
                                  {formState.getValue("title")}
                                </div>
                                <DescriptionSection editMode={false} formState={formState} />
                                <OrderLinesBuilder
                                  offerLinesRule={formState.getValue("offerLinesRule")}
                                  ref={orderLinesBuilderRef}
                                  editMode={false}
                                  overwriteSubAmount={formState.getValue("manualTotalSalesPrice")}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </PageLayout>
                    </div>
                  </>
                )}
              </OrderLinesBuilderContext.Consumer>
            </OrderLinesBuilderProvider>
          </Suspense>
        )
      ) : !offer && !isFetching ? (
        <>
          {(error as ResponseError).response.status !== 400 ? (
            <PageError
              title={t("offers:public_offer_page.not_found.title")}
              description={t("offers:public_offer_page.not_found.description")}
            />
          ) : (
            <PageError
              title={t("offers:public_offer_page.expired.title")}
              description={t("offers:public_offer_page.expired.description")}
            />
          )}
        </>
      ) : (
        <PageSpinner />
      )}
    </>
  );
}
