import { CheckIcon } from "@heroicons/react/20/solid";
import { ComboboxListItem } from "../types";
import { twMerge } from "tailwind-merge";

export default function ComboboxItem({
  item,
  active,
  isSelected,
}: {
  item: ComboboxListItem;
  active: boolean;
  isSelected: boolean;
}) {
  return (
    <div className={twMerge(active && "bg-shade-100")}>
      <div className="flex items-center">
        {item.Icon && <item.Icon className="m-2 h-5 w-5" />}
        <span className={twMerge("truncate p-2", isSelected && "font-semibold")}>{item.label}</span>
        {item.description && (
          <span className="px-2 text-xs text-zinc-400">{`(` + item.description + `)`}</span>
        )}
      </div>
      {isSelected ? (
        <span className={twMerge("absolute inset-y-0 right-0 flex items-center pr-4")}>
          <CheckIcon className={twMerge("h-5 w-5")} aria-hidden="true" />
        </span>
      ) : null}
    </div>
  );
}
