import isEmpty from "lodash/isEmpty";
import {
  ControlProps,
  findUISchema,
  Generate,
  isObjectControl,
  RankedTester,
  rankWith,
  StatePropsOfControlWithDetail,
} from "@jsonforms/core";
import { JsonFormsDispatch } from "@jsonforms/react";
import { useMemo } from "react";
import { childWithAncestor } from "./childWithAncestor";
import { Button, Dialog, getIcon } from "~/lib/ui";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";

export const ObjectRenderer = (props: StatePropsOfControlWithDetail & ControlProps) => {
  const { t } = useTranslation();
  const {
    renderers,
    cells,
    uischemas,
    schema,
    label,
    path,
    visible,
    enabled,
    uischema,
    rootSchema,
  } = props;
  const detailUiSchema = useMemo(
    () =>
      findUISchema(
        uischemas ?? [],
        schema,
        uischema.scope,
        path,
        () =>
          isEmpty(path)
            ? Generate.uiSchema(schema, "VerticalLayout", undefined, rootSchema)
            : {
                ...Generate.uiSchema(schema, "Group", undefined, rootSchema),
                label,
              },
        uischema,
        rootSchema
      ),
    [uischemas, schema, uischema.scope, path, label, uischema, rootSchema]
  );
  return (
    <Dialog
      render={({ onClose }) => (
        <>
          <DialogHeader title={label} Icon={getIcon("edit")} />
          <div className="mb-4">
            <JsonFormsDispatch
              visible={visible}
              enabled={enabled}
              schema={schema}
              uischema={childWithAncestor(detailUiSchema, uischema)}
              path={path}
              renderers={renderers}
              cells={cells}
            />
          </div>
          <DialogFooter
            primary={{ label: t("common:close"), onClick: onClose }}
            onClose={() => {}}
            hideCancel={true}
          />
        </>
      )}
      trigger={<Button variant="secondary">{props.label}</Button>}
    />
  );
};

export const objectControlTester: RankedTester = rankWith(2, isObjectControl);
