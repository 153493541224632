import { IGetProject200Response } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router";
import { twMerge } from "tailwind-merge";
import { useAPI } from "~/lib/api";
import { Button, getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { WarnOnZeroCurrency } from "~/lib/ui/text-decorator";
import { linkToExpense, linkToProduct, linkToProjectForm } from "~/lib/utils";
import { formatDate } from "~/lib/utils/date";
import { useToastOnError } from "~/lib/utils/hooks";
import { formatCurrency } from "~/lib/utils/number";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { FormatValue } from "~/pages/projects/_cmp/FormatValue";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";

export default function ProjectFinanceProductsPage() {
  const { entity: project } = useOutletContext<{ entity: IGetProject200Response["data"] }>();
  const api = useAPI();
  const { t, i18n } = useTranslation();
  const projectParams = useProjectParams();
  const [selectedEntry, setSelectedEntry] = useState<string>();
  const navigate = useNavigate();

  const dataQ = useQuery({
    queryFn: () =>
      api.projectGetKpiCardProductsSubpageProductsTab({
        projectId: project.id as string,
        dateAfter: projectParams.startDate,
        dateBefore: projectParams.endDate,
        activityIds: projectParams.activities,
        isInvoiced: projectParams.isInvoiced,
      }),
    queryKey: ["project-kpi-product-index", project.id as string, projectParams],
  });

  function handleSelectToggle(entryId?: string) {
    setSelectedEntry((prev) => {
      if (prev === entryId) return undefined;
      return entryId;
    });
  }

  useToastOnError(dataQ.error);

  const items = dataQ.data?.data ?? [];
  let sumQuantity = 0;
  let sumCostPricePerUnit = 0;
  let sumCostPriceTotal = 0;
  let sumEstimatedCostPriceTotal = 0;
  let sumSalesPricePerUnit = 0;
  let sumSalesPriceTotal = 0;

  const pType = project.projectType;

  return (
    <>
      <div className="table">
        <table className="text-right">
          <thead>
            <tr>
              <th className="text-left">{t("common:product_name", "Product name")}</th>
              <th>{t("common:quantity", "Quantity")}</th>
              <th>{t("finance:products.cost_per_unit", "Cost per unit")}</th>
              {pType === "hourly" && (
                <th>{t("finance:products.price_per_unit", "Price per unit")}</th>
              )}
              <th>{t("finance:total_cost", "Total cost")}</th>
              {pType === "estimate" && <th className="text-right">{t("common:estimated")}</th>}
              {pType === "hourly" && <th>{t("common:total_price")}</th>}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              sumQuantity += item.quantityActual;
              sumCostPricePerUnit += item.buyingPricePerUnitActual;
              sumCostPriceTotal += item.buyingPriceTotalActual;
              sumSalesPricePerUnit += item.sellingPricePerUnitActual ?? 0;
              sumSalesPriceTotal += item.sellingPriceTotalActual ?? 0;
              sumEstimatedCostPriceTotal += item.buyingPriceTotalEstimated ?? 0;

              const isSelected = selectedEntry === item.id;
              return (
                <Fragment key={item.id}>
                  <tr>
                    <td className="flex items-center text-left">
                      {item.title}&nbsp;
                      {item.entries ? (
                        <Button
                          size="small"
                          title={t("common:details")}
                          Icon={isSelected ? getIcon("chevronUp") : getIcon("chevronDown")}
                          onClick={() => handleSelectToggle(item.id)}
                        />
                      ) : null}
                    </td>
                    <td>{item.quantityActual}</td>
                    <td className="text-right">
                      <WarnOnZeroCurrency>{item.buyingPricePerUnitActual}</WarnOnZeroCurrency>
                    </td>
                    {!project.isFixedPrice && (
                      <td className="text-right">
                        <WarnOnZeroCurrency>{item.sellingPricePerUnitActual}</WarnOnZeroCurrency>
                      </td>
                    )}
                    <td className="text-right">
                      <WarnOnZeroCurrency>{item.buyingPriceTotalActual}</WarnOnZeroCurrency>
                    </td>
                    {pType === "estimate" && (
                      <td>
                        <FormatValue value={item.buyingPriceTotalEstimated ?? 0} unit="currency" />
                      </td>
                    )}
                    {pType === "hourly" && (
                      <td>
                        <WarnOnZeroCurrency>{item.sellingPriceTotalActual ?? 0}</WarnOnZeroCurrency>
                      </td>
                    )}
                    <td className="text-right">
                      {item.type === "product" && (
                        <ActionButtons
                          collapseAt={0}
                          size="small"
                          actions={[
                            {
                              label: t("common:edit"),
                              Icon: getIcon("edit"),
                              onClick: () =>
                                navigate(
                                  linkToProduct(item.id, { referrer: window.location.pathname })
                                ),
                            },
                          ]}
                        />
                      )}
                    </td>
                  </tr>
                  {isSelected && (
                    <tr className={twMerge("text-sm")} key={`${item.id}-row`}>
                      <td colSpan={7} className="w-full">
                        <table className="w-full text-left">
                          <thead>
                            <tr>
                              <th>{t("common:count")}</th>
                              <th>{t("common:date")}</th>
                              <th>{t("common:source", "Source")}</th>
                              {!project.isFixedPrice && (
                                <th>{t("finance:total_price", "Total Price")}</th>
                              )}
                              {project.isFixedPrice && (
                                <th>{t("finance:total_cost", "Total Cost")}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {item.entries?.map((entry) => {
                              const originName =
                                entry.originType === "expense"
                                  ? t("common:expense")
                                  : t("common:form", { count: 1 });
                              const originLink =
                                entry.originType === "expense"
                                  ? linkToExpense(entry.originId, "view")
                                  : linkToProjectForm(project.id, entry.originId);
                              return (
                                <tr key={entry.originId}>
                                  <td>{entry.quantity}</td>
                                  <td>
                                    {entry.date ? (
                                      <OptionalLink to={originLink}>
                                        {formatDate(entry.date, i18n.resolvedLanguage, {
                                          shortDate: true,
                                        })}
                                      </OptionalLink>
                                    ) : (
                                      t("common:not_available")
                                    )}
                                  </td>
                                  <td>
                                    <OptionalLink to={originLink}>{originName}</OptionalLink>
                                  </td>
                                  {!project.isFixedPrice && (
                                    <td>{formatCurrency(entry.sellingPriceTotal)}</td>
                                  )}
                                  {project.isFixedPrice && (
                                    <td>{formatCurrency(entry.buyingPriceTotal)}</td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
            <tr className="bg-shade-50 font-semibold">
              <td className="text-left">{t("common:total")}</td>
              <td>{sumQuantity}</td>
              <td>
                <WarnOnZeroCurrency>{sumCostPricePerUnit}</WarnOnZeroCurrency>
              </td>
              {pType === "hourly" && (
                <td>
                  <WarnOnZeroCurrency>{sumSalesPricePerUnit}</WarnOnZeroCurrency>
                </td>
              )}
              <td>
                <WarnOnZeroCurrency>{sumCostPriceTotal}</WarnOnZeroCurrency>
              </td>
              {pType === "estimate" && (
                <td className="text-right">
                  <WarnOnZeroCurrency>{sumEstimatedCostPriceTotal}</WarnOnZeroCurrency>
                </td>
              )}
              {pType === "hourly" && (
                <td>
                  <WarnOnZeroCurrency>{sumSalesPriceTotal}</WarnOnZeroCurrency>
                </td>
              )}
              <td>&nbsp;</td> {/* Empty cell for actions */}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
