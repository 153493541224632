import { IconProp } from "~/lib/ui/types";
import { Combobox } from "@headlessui/react";
import { KeyboardEvent, MouseEvent } from "react";
import { twMerge } from "tailwind-merge";

export default function ComboboxNewEntity({
  label,
  Icon,
  onClick,
}: {
  label: string;
  Icon?: IconProp;
  onClick: () => void;
}) {
  const handleOnClick = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    onClick();
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLLIElement>) => {
    e.preventDefault();
  };

  return (
    <Combobox.Option
      value={-1}
      onClick={(e) => handleOnClick(e)}
      onKeyUp={(e) => handleKeyUp(e)}
      className={twMerge(
        "relative mx-3 my-2 cursor-default select-none rounded-md hover:cursor-pointer hover:bg-hover hover:text-white"
      )}
    >
      {({ active }) => (
        <div className={twMerge(active && "bg-hover text-white")}>
          <div className="flex items-center">
            {Icon && <Icon className="m-2 h-5 w-5" />}
            <span className="truncate p-2">{label}</span>
          </div>
        </div>
      )}
    </Combobox.Option>
  );
}
