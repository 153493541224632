import { twMerge } from "tailwind-merge";

import * as Popover from "@radix-ui/react-popover";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { useLocale } from "~/lib/utils/date";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatDateOptions } from "~/lib/utils/date/date-utils.types";
import { DateRangeSelect, DateRangeSelectProps } from "./date-range-select";
import { Label } from "../label";

/**
 *
 * @description Should eventually become a date range picker. For now, it's two date-pickers in one.
 * @returns
 */
export function DateRangePopover({
  className,
  label,
  size = "small",
  ...selectProps
}: DateRangeSelectProps & { className?: string; label?: string; size?: "small" | "medium" }) {
  const { format } = useLocale();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const formatOptions: FormatDateOptions = {
    excludeTime: true,
    shortDate: true,
    shortMonth: true,
  };

  function handleInnerTextRender(fDate?: Date, tDate?: Date) {
    // If nothing is selected
    if (fDate === undefined && tDate === undefined) {
      return t("ui:date_range_picker.entire_period", "Entire period");
    }

    if (fDate !== undefined && tDate === undefined) {
      return format(fDate, formatOptions);
    }

    if (fDate !== undefined && tDate !== undefined) {
      // Handle single day selection
      if (fDate.getTime() === tDate.getTime()) return format(fDate, formatOptions);
      return `${format(fDate, formatOptions)} - ${format(tDate, formatOptions)}`;
    }
    selectProps.onChange?.(fDate, tDate);
  }

  function handleSelect(from?: Date, to?: Date, shouldClose?: boolean) {
    selectProps.onChange?.(from, to);
    // Basically, we want to close it when a preset is selected, otherwise let the user close it
    if (shouldClose) {
      setOpen(false);
    }
  }

  return (
    <div
      className={twMerge(
        "flex cursor-pointer flex-row items-center",
        size === "small" && "text-xs",
        className
      )}
    >
      <Popover.Root open={open} onOpenChange={(newOpen) => setOpen(newOpen)}>
        <Popover.Trigger asChild>
          <div>
            {label && <Label>{label}</Label>}
            <div
              className={twMerge(
                "relative flex flex-row items-center rounded-md border border-gray-200 bg-white px-2 py-1 hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover",
                className
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              <span className="">{handleInnerTextRender(selectProps.from, selectProps.to)}</span>
            </div>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="start" sideOffset={10} asChild>
            <div className="rounded-t-md bg-white p-1 shadow-md">
              <Popover.Arrow className=" fill-white" />
              <DateRangeSelect {...selectProps} onChange={handleSelect} />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
