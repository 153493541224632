import { useTranslation } from "react-i18next";
import { ClassicFrame } from "~/lib/ui/helpers/classic-frame";

export default function CompanySettingsPage() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      <ClassicFrame iframeURL="/companies/companies/edit" disableLocationUpdates hideTopbar />
    </div>
  );
}
