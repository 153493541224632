import { Project } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { EntityTable } from "~/lib/entity-ui";
import { linkToCustomer } from "~/lib/utils";
import { formatDate } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";
import { useProjectParams } from "../../_cmp/use-project-params";

export default function ProjectFinanceInvoicesPage() {
  const context = useOutletContext<{ entity: Project }>();
  const api = useAPI();
  const { t, i18n } = useTranslation();
  const projectPeriod = useProjectParams();

  const project = context.entity;

  return (
    <>
      <EntityTable
        showSearch={false}
        dataFn={() =>
          api.projectGetKpiCardInvoicesSubPage({
            projectId: project.id as string,
            dateAfter: projectPeriod.startDate,
            dateBefore: projectPeriod.endDate,
          })
        }
        cacheKey={["project_kpi_invoices", project.id as string]}
        fields={{
          invoiceNo: {
            label: t("common:invoice_no", "Invoice no"),
            href: (row) => `/companies/invoices/getPDF/${row.id}`,
            renderColumn(row) {
              return row.invoiceNumber;
            },
          },
          date: {
            label: t("common:date", "Date"),
            renderColumn(row) {
              if (!row.issuedDate) return t("common:not_available");
              return formatDate(row.issuedDate, i18n.resolvedLanguage, { shortDate: true });
            },
          },
          customer: {
            label: t("common:customer", { count: 1 }),
            href: (row) => linkToCustomer(row.contact?.id ?? ""),
            renderColumn(row) {
              return row.contact?.name ?? t("common:not_available");
            },
          },
          total_wo_vat: {
            label: t("finance:total_wo_vat", "Beløb eksl. moms"),
            renderColumn(row) {
              return formatCurrency(row.grossPayment ?? 0);
            },
          },
        }}
      />
    </>
  );
}
