import { useMutation } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";
import { captureException } from "../error-reporting";
import { useToasts } from "../toast/use-toasts";
import { useModals } from "../ui/modal";
import { Spinner } from "../ui/spinner";
import { EntityBase, MultipleEntityAction } from "./types";

// - If on mobile, it will become a dropdown menu instead.
/**
 * @deprecated Use ActionButtons instead. This is pending deletion.
 */
export function ActionButtonsMulti<Entity extends EntityBase>({
  entities,
  actions,
  onRefresh,
  collapseAt = 3,
  disabled = false,
  onSuccess,
}: {
  entities: Array<Entity>;
  actions: Array<MultipleEntityAction<Entity>>;
  onRefresh?: () => void;
  collapseAt?: number;
  disabled?: boolean;
  onSuccess?: () => void;
}) {
  const toast = useToasts();
  const modal = useModals();

  const mutation = useMutation({
    mutationFn: ({ action }: { action: MultipleEntityAction<Entity>; idx: number }) =>
      action.onExecute(entities).then(() => {
        onSuccess?.();
      }),
    onSettled: () => onRefresh?.(),
    onSuccess: (data, { action }) => {
      if (action.hideToast) {
        return;
      }
      toast.showTemplate("CHANGES_SAVED");
    },
    onError: (err: Error) => {
      toast.showTemplate("OPERATION_FAILED");
      captureException(new Error("Operation failed", { cause: err }));
    },
  });

  async function handleActionPress(action: MultipleEntityAction<Entity>, idx: number) {
    if (action.mustConfirm) {
      const confirmed = await modal.showConfirm({
        Icon: action.icon,
        title: action.label,
        description: action.description,
      });
      if (!confirmed) {
        return;
      }
    }
    mutation.mutate({ action, idx });
  }
  const baseClass =
    "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-tertiary disabled:text-gray-400";

  const isDisabled = entities.length === 0;

  return (
    <>
      <span className={twMerge("isolate rounded-md shadow-sm")}>
        {actions.map(({ icon: Icon, label }, idx) => (
          <button
            key={idx}
            type="button"
            aria-label={label}
            title={label}
            disabled={isDisabled}
            onClick={(e) => {
              e.stopPropagation(); // Otherwise it triggers the row click
              handleActionPress(actions[idx], idx);
            }}
            className={twMerge(
              baseClass,
              idx === 0 && "rounded-l-md",
              idx === actions.length - 1 && "rounded-r-md"
            )}
          >
            {mutation.isPending && mutation.variables?.idx === idx ? (
              <Spinner className="h-5 w-5" />
            ) : (
              <Icon className="inline h-5 w-5" />
            )}
          </button>
        ))}
      </span>
    </>
  );
}
