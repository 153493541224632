/* eslint-disable */
import * as types from './graphql.js';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query queryForTotalInvoice($id: ID!) {\n    project(id: $id) {\n      id\n      totalInvoicedAmount\n    }\n  }\n": types.QueryForTotalInvoiceDocument,
    "query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    salesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}": types.InvoiceKpiDataDocument,
    "\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n": types.ListProjectsTableDocument,
    "query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}": types.ListProjectsTestDocument,
    "\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n": types.ListProjectsDocument,
    "\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n": types.EditProjectNameDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query queryForTotalInvoice($id: ID!) {\n    project(id: $id) {\n      id\n      totalInvoicedAmount\n    }\n  }\n"): (typeof documents)["\n  query queryForTotalInvoice($id: ID!) {\n    project(id: $id) {\n      id\n      totalInvoicedAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    salesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}"): (typeof documents)["query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    salesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}"): (typeof documents)["query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n"): (typeof documents)["\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;