import { useTranslation } from "react-i18next";
import { useState } from "react";
import { PreviewFiles } from "~/pages/projects/_cmp/preview-files";

import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { captureException } from "~/lib/error-reporting";
import { IProductBundleUploadFile200Response, IWallpostCreate201Response } from "@apacta/sdk";
import { useToasts } from "~/lib/toast/use-toasts";
import { Button, Label } from "~/lib/ui";
import { MediaPreview } from "~/lib/ui/media/";

export type PreviewFile = {
  url: string;
  type: string;
  name: string | undefined;
};

interface ImageUploadProps {
  initialFile: PreviewFile | undefined;
  uploadFn: (file: File) => Promise<IProductBundleUploadFile200Response>;
  removeFn: () => Promise<IWallpostCreate201Response>;
  label: string;
}

export default function ImageUpload({ initialFile, uploadFn, removeFn, label }: ImageUploadProps) {
  const { t } = useTranslation();
  const toast = useToasts();
  const [preview, setPreview] = useState<FileList>();

  async function handleUpload(fileList: FileList) {
    try {
      const file = fileList[0];
      await uploadFn(file);
    } catch (err) {
      toast.showTemplate("OPERATION_FAILED");
      captureException(new Error("Operation failed", { cause: err }));
    } finally {
      setPreview(fileList);
      toast.show({
        title: t("products:file_upload_title"),
        description: t("products:file_upload_description"),
        Icon: CheckCircleIcon,
      });
    }
  }

  async function removeImage() {
    try {
      await removeFn();
    } catch (err) {
      toast.showTemplate("OPERATION_FAILED");
    } finally {
      setPreview(undefined);
      initialFile = undefined;
      toast.show({
        title: t("products:bundle.file_remove_title"),
        description: t("products:bundle.file_remove_description"),
        Icon: CheckCircleIcon,
      });
    }
  }

  return (
    <div className="fa-align-left left sm:col-span-2">
      {label && <Label>{label}</Label>}
      {preview && (
        <PreviewFiles files={preview} className="mb-4 mt-5 flex flex-row flex-wrap gap-4" />
      )}
      {initialFile && !preview && (
        <div className=" mb-4 mt-5 flex flex-row flex-wrap gap-4">
          <MediaPreview
            key={initialFile.url}
            href={initialFile.url}
            mimeType={initialFile.type}
            filename={initialFile.name}
          />
        </div>
      )}
      <div className="mb-4 flex flex-row flex-wrap gap-4">
        <UploadButton variant="secondary" multiple={false} onSelected={handleUpload}>
          {t("products:bundle.upload_image_button_text")}
        </UploadButton>
        {(initialFile || preview) && (
          <Button onClick={removeImage}>{t("products:bundle.remove_image_button_text")}</Button>
        )}
      </div>
    </div>
  );
}
