import { useTranslation } from "react-i18next";
import { LanguageDefinition, availableLanguagesList } from "~/lib/i18n/i18n";
import { LanguageItem } from "../language/language-item";
import { getIcon } from "~/lib/ui";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { useSession } from "~/lib/auth/session";

export function LanguageModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { setLanguage } = useSession();

  async function handleSetLanguage(language: LanguageDefinition) {
    try {
      await setLanguage(language);
      onClose();
    } catch (err) {
      console.error("Error happened");
    }
  }

  return (
    <>
      <DialogHeader
        title={t("ui:navigation.change_language", "Change language")}
        Icon={getIcon("language")}
      />
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div>
          <ul
            role="list"
            className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
          >
            {availableLanguagesList.map(([key, value]) => (
              <li onClick={() => handleSetLanguage(value)} className="flex" key={key}>
                <LanguageItem item={value} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
