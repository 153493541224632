import { DayPicker as DayPickerPrimitive } from "react-day-picker";

import { twMerge } from "tailwind-merge";
import { Icon } from "../icons/icon";
import { useLocale } from "~/lib/utils/date";
import { ComponentProps } from "react";

export type CalendarProps = ComponentProps<typeof DayPickerPrimitive>;

export function DayPicker({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { fnsLocale } = useLocale();
  return (
    <DayPickerPrimitive
      showOutsideDays={showOutsideDays}
      locale={fnsLocale}
      weekStartsOn={1} // Monday by default
      className={twMerge("rounded-md rounded-t-md", className)}
      classNames={{
        multiple_months: "",
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 rounded-t-md",
        month: "space-y-4 border bg-white rounded-t-md",
        caption: "rounded-t-md bg-primary text-white flex justify-center p-2 relative items-center",
        caption_label: "text-base font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: twMerge("h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-shade-400 rounded-md w-10 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-10 w-10 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: twMerge(
          "h-10 w-10 items-center justify-center p-0 font-normal hover:text-hover aria-selected:opacity-100"
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-hover text-white hover:bg-hover hover:text-white focus:bg-hover focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        weeknumber: "bg-gray-100 rounded-full w-5",
        ...classNames,
      }}
      components={{
        IconLeft: () => <Icon name="chevronLeft" size="medium" />,
        IconRight: () => <Icon name="chevronRight" size="medium" />,
      }}
      {...props}
    />
  );
}
