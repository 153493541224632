import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { FeedCard } from "./feed-card";
import { useAPI } from "~/lib/api";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToastOnError } from "~/lib/utils/hooks";
import { formatDate } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { FrontpageFeedDTO } from "@apacta/sdk";
import { Spinner } from "~/lib/ui/spinner";

type FeedCardsBySimpleDate = {
  today: Array<FrontpageFeedDTO>;
  yesterday: Array<FrontpageFeedDTO>;
};

export default function FeedOverview() {
  const { t, i18n } = useTranslation();
  const api = useAPI();

  const [dateFrom, setDateFrom] = useState<Date>(() => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  });

  const [dateTo, setDateTo] = useState<Date>(new Date());

  const { data, isFetching, error } = useQuery({
    queryKey: ["frontpageFeedCards", dateFrom, dateTo],
    queryFn: () =>
      api.iGetFeed({ dateFrom, dateTo }).then((res) => {
        return res;
      }),
    refetchInterval: 60 * 1000 * 60, // 60 minutes
  });

  useToastOnError(error);

  const feedCardsByDate = (c: Array<FrontpageFeedDTO>): FeedCardsBySimpleDate => {
    const today = c.filter((card) => {
      if (!card.date) return false; // skip if no date is available
      const todayDateString = formatDate(dateTo, i18n.resolvedLanguage, { excludeTime: true });
      const cardDateString = formatDate(new Date(card.date), i18n.resolvedLanguage, {
        excludeTime: true,
      });
      return todayDateString === cardDateString;
    });

    const yesterday = c.filter((card) => {
      if (!card.date) return false; // skip if no date is available
      const d = new Date(dateTo);
      d.setDate(d.getDate() - 1);
      const yesterdayDateString = formatDate(d, i18n.resolvedLanguage, { excludeTime: true });
      const cardDateString = formatDate(new Date(card.date), i18n.resolvedLanguage, {
        excludeTime: true,
      });
      return yesterdayDateString === cardDateString;
    });

    return {
      today: today.sort((a, b) => {
        if (!a.form?.created || !b.form?.created) return 0;
        return new Date(b.form.created).getTime() - new Date(a.form.created).getTime();
      }),
      yesterday: yesterday.sort((a, b) => {
        if (!a.form?.created || !b.form?.created) return 0;
        return new Date(b.form.created).getTime() - new Date(a.form.created).getTime();
      }),
    };
  };

  const cards = feedCardsByDate(data?.data ?? []);

  return (
    <div>
      <div className="flex flex-col gap-8">
        <HorizontalDivider lineClassName="border-gray-400">
          <span className="whitespace-nowrap rounded-lg bg-primary px-6 py-1 text-lg text-white">
            {t("ui:date_range_picker.today")}
          </span>
        </HorizontalDivider>
        <div className="flex w-full flex-wrap">
          {isFetching ? (
            <div className="flex h-72 w-full flex-col items-center justify-center gap-2">
              <Spinner />
              <p>{t("common:loading")}</p>
            </div>
          ) : cards.today.length ? (
            <>
              {cards.today.map((card) => (
                <FeedCard key={card.id} data={card} />
              ))}
            </>
          ) : (
            <div className="flex w-full justify-center text-gray-400">
              <div>{t("common:no_data")}</div>
            </div>
          )}
        </div>
        <HorizontalDivider lineClassName="border-gray-400">
          <span className="whitespace-nowrap rounded-lg bg-primary px-6 py-1 text-lg text-white">
            {t("ui:date_range_picker.yesterday")}
          </span>
        </HorizontalDivider>
        <div className="flex w-full flex-wrap">
          {isFetching ? (
            <div className="flex h-72 w-full flex-col items-center justify-center gap-2">
              <Spinner />
              <p>{t("common:loading")}</p>
            </div>
          ) : cards.yesterday.length ? (
            <>
              {cards.yesterday.map((card) => (
                <FeedCard key={card.id} data={card} />
              ))}
            </>
          ) : (
            <div className="flex w-full justify-center text-gray-400">
              <div>{t("common:no_data")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
