import { ReactNode } from "react";

export function TabHeading({
  children,
  actionArea,
  subtitle,
}: {
  children?: string;
  actionArea?: ReactNode;
  subtitle?: ReactNode;
}) {
  return (
    <>
      <div className="flex flex-row justify-between gap-2">
        <div className="mb-10">
          <h2 className="mb-2">{children}</h2>
          {subtitle && <div className="mb-2 text-gray-500">{subtitle}</div>}
        </div>
        <div>{actionArea ? <div>{actionArea}</div> : null}</div>
      </div>
    </>
  );
}
