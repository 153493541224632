import {
  IListProjectsDirectionEnum,
  KpiCardHoursSummedIndividualEntry,
  ProjectGetKpiCardHoursSummedSubPage200ResponseDataInner,
} from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper, getExpandedRowModel } from "@tanstack/react-table";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useLocale } from "~/lib/utils/date";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { randomId } from "~/lib/utils/string";
import { UserAvatar } from "~/lib/ui/avatar";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

const columnHelper = createColumnHelper<ProjectGetKpiCardHoursSummedSubPage200ResponseDataInner>();

export default function EmployeesSummedTab() {
  const api = useAPI();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatHours } = useLocale();

  const columns = useDataColumns(() => [
    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
      id: "name",
      cell: ({ row }) => (
        <div className="flex flex-1 justify-between">
          <div className="flex items-center gap-4">
            <UserAvatar user={row.original.user} className="h-8 w-8 text-sm" />
            <OptionalLink to={`employees/${row.original.id}`} openInNewTab={true}>
              {row.original.user?.fullName}
            </OptionalLink>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("totalHours", {
      header: t("common:quantity"),
      id: "count",
      cell: ({ getValue }) => formatHours(getValue() ?? 0),
      meta: {
        className: "text-right",
      },
    }),
  ]);
  const tableState = useDataTableState({
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  });

  const employeeParams = useEmployeesParams();

  const dataQ = useQuery({
    queryKey: [
      "employees.specified",
      CACHE_EMPLOYEES,
      employeeParams,
      tableState.state.pagination,
      tableState.state.sorting,
    ],
    queryFn: () =>
      api.usersGetSummed({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection as IListProjectsDirectionEnum,
        ...employeeParams,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand(row) {
        if (!row.original.entries) return false;
        return row.original.entries.length > 0;
      },
    }
  );

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:summed_overview", "Summed overview")}</h2>
      <DataTable
        table={table}
        renderExpandedRow={(row) => {
          return (
            <table>
              <tbody>
                {row.entries?.map((subrow) => (
                  <tr key={randomId()} className="bg-gray-50">
                    <td>&nbsp;</td>
                    <td className="pad-left-20 pad-right-12 text-left">{subrow.label}</td>
                    <td className="text-right">
                      <EntryValue entry={subrow} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </div>
  );
}

function EntryValue({ entry }: { entry: KpiCardHoursSummedIndividualEntry }) {
  const { formatHours } = useLocale();
  switch (entry.type) {
    case "driving":
      return `${entry.count} km`; // We don't support other units here
    case "time_entry":
      return formatHours(entry.count);
  }
}
