import {
  KpiChardHoursIndividualEntry,
  Project,
  ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner,
} from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper, getExpandedRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { i18n } from "~/lib/i18n/i18n";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { useDataColumns } from "~/lib/ui/data-table/use-data-columns";

import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import Switch from "~/lib/ui/switch";
import { formatDate, useLocale } from "~/lib/utils/date";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";

const columnHelper =
  createColumnHelper<ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner>();

export default function KpiSubPageHoursAllEntries() {
  const { entity: project } = useOutletContext<{ entity: Project }>();
  const projectParams = useProjectParams();
  const api = useAPI();
  const { t } = useTranslation();
  const { formatHours } = useLocale();

  const columns = useDataColumns(() => [
    columnHelper.accessor("formDate", {
      header: t("common:date"),
      id: "form_date",
      enableSorting: true,
      cell: ({ getValue }) => formatDate(getValue(), i18n.resolvedLanguage, { shortDate: true }),
    }),
    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
      enableSorting: true,
      id: "name",
      cell: ({ row }) => (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col justify-center">
            {row.original.user?.fullName}
            {row.original.workDescription && (
              <div className="mt-2 block text-gray-400">{row.original.workDescription}</div>
            )}
          </div>
          {!!row.original.invoiced && (
            <div>
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {t("common:invoiced", "Invoiced")}
              </span>
            </div>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("totalHours", {
      header: t("common:count"),
      id: "count",
      enableSorting: true,
      cell: ({ getValue }) => formatHours(getValue() ?? 0),
      meta: {
        className: "text-right",
      },
    }),
    columnHelper.accessor("approved", {
      header: t("common:approved"),
      cell: ({ row }) => (
        <Switch
          className="inline"
          controlled
          checked={!!row.original.approved}
          onCheckedChange={(checked) => handleApproveForm(row.original, checked)}
        />
      ),
      meta: {
        className: "text-center",
      },
    }),
  ]);

  const tableState = useDataTableState({
    expanded: true,
    pagination: {
      pageIndex: 0,
      pageSize: 200, // controlled by backend, setting it here prevents a refresh
    },
  });

  const dq = useQuery({
    queryKey: [
      "project.hours.specified",
      project.id,
      projectParams,
      tableState.state.pagination,
      tableState.state.sorting,
    ],
    queryFn: () =>
      api.projectGetKpiCardHoursAllFormsSubPage({
        projectId: project.id as string,
        dateAfter: projectParams.startDate,
        dateBefore: projectParams.endDate,
        activityIds: projectParams.activities,
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        isInvoiced: projectParams.isInvoiced,
      }),
  });

  const table = useDataTable(
    {
      isLoading: dq.isLoading,
      data: dq.data?.data,
      columns: columns,
      backendPagination: dq.data?.pagination,
      tableState,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand(row) {
        if (!row.original.entries) return false;
        if (row.original.entries.length > 0) return true;
        return false;
      },
    }
  );

  function refresh() {
    dq.refetch();
  }

  const items = dq.data?.data ?? [];

  async function handleApproveForm(item: (typeof items)[number], newStatus: boolean) {
    await api.changeStatus({
      projectId: project.id,
      changeStatusRequest: {
        approve: newStatus,
        forms: [item.id],
      },
    });
    refresh();
  }

  return (
    <>
      <DataTable
        table={table}
        paginationDetails={dq.data?.pagination}
        renderExpandedRow={(row) => {
          return (
            <table>
              <tbody>
                {row.entries?.map((subrow) => (
                  <tr key={subrow.entityId} className="bg-gray-50">
                    <td>&nbsp;</td>
                    <td>{subrow.label}</td>
                    <td>
                      <EntryValue entry={subrow} />
                    </td>

                    <td></td>

                    <td className="text-right"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
}

function EntryValue({ entry }: { entry: KpiChardHoursIndividualEntry }) {
  const { formatHours } = useLocale();
  switch (entry.type) {
    case "driving":
      return `${entry.count} km`; // We don't support other units here
    case "time_entry":
      return formatHours(entry.count);
  }
}
