import CategoryLabelSelect from "~/lib/ui/category-label/category-label-select";
import { LabelEntity } from "@apacta/sdk";
import { useCategoryLabels } from "~/lib/ui/category-label/use-category-labels";
import { SkeletonLoader } from "~/lib/ui/skeleton";

export function LabelsSelection({
  defaultValues,
  onChange,
}: {
  defaultValues: Array<string> | undefined;
  onChange: (labelIds: Array<string> | undefined) => void;
}) {
  const { labels, isLoadingLabels } = useCategoryLabels("employee");

  const selectedLabels = labels
    .sort((a: LabelEntity, b: LabelEntity) => (b.text > a.text ? -1 : 1))
    .filter((label: LabelEntity) => defaultValues?.includes(label.id));

  return (
    <div className="z-10">
      {isLoadingLabels ? (
        <div className=" flex h-full max-w-80">
          <SkeletonLoader template="carouselThumbnails" />
        </div>
      ) : (
        <CategoryLabelSelect
          entity="employee"
          initialSelectedLabels={selectedLabels}
          options={{ multiselect: true }}
          onChange={(selectedItems) => onChange(selectedItems.map((label) => label.id))}
        />
      )}
    </div>
  );
}
