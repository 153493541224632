import { Spinner } from "~/lib/ui/spinner";
import { Suspense } from "react";
import CalendarDatesMatrix, {
  CalendarDatesMatrixOptions,
} from "~/lib/ui/calendar/calendar-dates-matrix";

export default function CalendarDates({
  selectedDate,
  options,
}: {
  selectedDate: Date;
  options: CalendarDatesMatrixOptions;
}) {
  // Used for the loading state to prevent the calendar changing size
  const fillerMatrix = new Array(6).fill(new Array(8).fill(0));
  return (
    <Suspense
      fallback={
        <>
          <div className="absolute flex h-full w-full items-center justify-center">
            <Spinner className="h-auto w-8" />
          </div>
          {fillerMatrix.map((row: Array<number>, rowIdx) =>
            row.map((col, colIdx) => (
              <div key={`placeholder-${rowIdx}-${colIdx}`} className="aspect-square py-1.5">
                &nbsp;
              </div>
            ))
          )}
        </>
      }
    >
      <CalendarDatesMatrix selectedDate={selectedDate} options={options} />
    </Suspense>
  );
}
