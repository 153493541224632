import { useEffect, useMemo, useRef, useState } from "react";
import { ControlPanelApiProjectStatusResponse } from "@apacta/sdk";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

export function ProjectStatusLabel({
  selectedStatusId,
  onChange,
}: {
  selectedStatusId?: string; // TODO: Must handle if not set
  onChange?: ({ toId, fromId }: { toId: string; fromId?: string }) => void;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const statusQ = useQuery({
    queryKey: ["projectStatus"],
    queryFn: () => api.iListProjectStatuses({}),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });

  const items = statusQ.data?.data ?? [];

  const [open, setOpen] = useState<boolean>(false);

  // Remember last selected id, if update fails for some reason
  const lastId = useRef(selectedStatusId);
  useEffect(() => {
    lastId.current = selectedStatusId;
  }, [selectedStatusId]);

  const openStyle = "bg-green-50 text-green-700";
  const readyStyle = "bg-yellow-50 text-yellow-700";
  const closedStyle = "bg-red-50 text-red-700";
  const unknownStyle = "bg-gray-50 text-gray-700";

  const getStyle = (item?: ControlPanelApiProjectStatusResponse): string => {
    if (item?.projectStatusType.identifier === "open") {
      return openStyle;
    } else if (item?.projectStatusType.identifier === "closed") {
      return closedStyle;
    } else if (item?.projectStatusType.identifier?.includes("ready")) {
      return readyStyle;
    } else {
      return unknownStyle;
    }
  };

  const selectedItem = useMemo(
    () => items.find((v) => v.id === selectedStatusId),
    [items, selectedStatusId]
  );
  const triggerStyle = getStyle(selectedItem);

  const handleSelect = (item: ControlPanelApiProjectStatusResponse): void => {
    onChange?.({ toId: item.id, fromId: lastId.current });
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger disabled={!onChange} className={twMerge("-ml-px", onChange && "hover:z-10")}>
        {!statusQ.isLoading ? (
          <span
            className={twMerge(
              "inline-flex items-center rounded-md border px-2 py-1 text-xs font-medium",
              triggerStyle
            )}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <>
              <span>{selectedItem?.name || t("common:not_selected")}</span>
              {onChange && (
                <span>
                  <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                </span>
              )}
            </>
          </span>
        ) : (
          <ContentLoader className={twMerge("h-6 w-32")}>
            <rect x={0} y={0} width="80%" height="100%"></rect>
          </ContentLoader>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="start">
          <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
            <ul>
              {statusQ.data?.data.map((v, i) => (
                <li
                  key={`status-${i}`}
                  className="flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-tertiary hover:text-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(v);
                  }}
                >
                  <div
                    className={twMerge(
                      "flex h-6 w-6 items-center justify-center rounded-full bg-opacity-100 p-1.5",
                      getStyle(v)
                    )}
                  >
                    <svg fill="currentColor" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.5" cy="11.5" r="11.5" />
                    </svg>
                  </div>
                  <span>{v.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
