import { DropTargetMonitor, useDrop } from "react-dnd";
import { forwardRef, ReactNode } from "react";
import { DragDropType } from "./drag-drop.types";
import { Card } from "~/lib/planning";

type CustomDropZoneProps = {
  index: number;
  onDrop: (card: Card, details: { index: number }) => void;
  children: (isOver: boolean) => ReactNode;
};

export const CustomDropZone = forwardRef<HTMLDivElement, CustomDropZoneProps>(
  ({ index, onDrop, children }: CustomDropZoneProps, ref) => {
    const [{ isOver }, drop] = useDrop(() => ({
      // The type (or types) to accept - strings or symbols
      accept: DragDropType,
      // Props to collect
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
      drop: (item: Card) => handleDrop(item),
    }));

    const handleDrop = (item: Card) => {
      onDrop(item, { index });
    };

    return (
      <div ref={drop} className={isOver ? "bg-green-200" : ""}>
        {children(isOver)}
      </div>
    );
  }
);

CustomDropZone.displayName = "CustomDropZone";
