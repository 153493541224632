import { ControlProps, and, rankWith } from "@jsonforms/core";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

import { isProductEntity } from "./testers";

// Shows search input if no product_id, otherwise shows product name

export function ProductRenderer(props: ControlProps) {
  return (
    <div className="min-w-48">{props.data ? <ProductItemView productId={props.data} /> : null}</div>
  );
}
function ProductItemView(props: { productId: string }) {
  const api = useAPI();
  const productId = props.productId;
  const q = useSuspenseQuery({
    queryKey: ["product", props.productId],
    queryFn: () =>
      api.productsView({
        productId,
      }),
  });

  return (
    <div className="flex flex-1 flex-row items-center gap-4">
      <img src={q.data.data.imageUrl ?? "/image_not_found.jpg"} className="h-16 w-16" />
      <div>{q.data.data.name}</div>
    </div>
  );
}
export const productTester = rankWith(4, and(isProductEntity()));
