import { DependencyList, useMemo } from "react";
import { useTranslation } from "react-i18next";

/**
 * It's a useMemo that re-renders on language change. Nothing fancy.
 * @param factory
 * @returns
 */
export const useDataColumns = <T>(factory: () => T, deps: DependencyList = []) => {
  const { i18n } = useTranslation();
  const columns = useMemo(() => factory(), [i18n.resolvedLanguage, ...deps]);
  return columns;
};
