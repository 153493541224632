import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { createColumnHelper } from "@tanstack/react-table";
import { Outlet } from "react-router";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { PageLayout } from "~/lib/ui/page-layout";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import ProjectStatusChange from "~/pages/projects/_cmp/project-status-change";

const columnHelper = createColumnHelper<ControlPanelApiProjectViewOrIndexResponse>();
const columns = [
  columnHelper.accessor("projectNumber", {
    header: "#",
    enableSorting: true,
    id: "project_number",
    meta: {
      className: "w-[2rem]",
    },
    footer: ({ column, table, header }) => "TODO total",
  }),
  columnHelper.accessor("name", {
    header: "Name",
    enableSorting: true,
    meta: {
      className: "text-left",
    },
  }),
  columnHelper.accessor("projectStatusId", {
    id: "project_status_id",
    header: "Status",
    cell: ({ row }) => (
      <ProjectStatusChange project={row.original} initialStatusId={row.original.projectStatusId} />
    ),
    footer: () => "Status footer",
    enableSorting: true,
  }),
  columnHelper.display({
    id: "actions",
    meta: {
      className: "text-right",
    },
    cell: ({ row }) => (
      <ActionButtons
        collapseAt={4}
        size="small"
        actions={[
          {
            label: "test",
            onClick: () => alert("test" + row.original.id),
            Icon: getIcon("administration"),
          },
        ]}
      />
    ),
  }),
];

export default function PlaygroundTables() {
  return (
    <PageLayout title="Table playground">
      <PageTabs
        tabs={[
          { label: "CustomTable", id: "index", to: "./" },
          { label: "Client only", id: "client", to: "client" },
        ]}
      />
      <Outlet />
    </PageLayout>
  );
}
