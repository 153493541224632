import { Popover } from "~/lib/ui/popover/popover";
import { contrastColor, LabelColor, labelColors } from "~/lib/utils/colors";
import { Label } from "~/lib/ui";
import colors from "tailwindcss/colors";
import { BadgeLabel } from "~/lib/ui/badge-label";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export default function ColorPicker({
  label,
  currentColor,
  onSelect,
}: {
  label: string;
  currentColor: LabelColor;
  onSelect: (color: LabelColor, textColor: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <Label>{label}</Label>
      <Popover
        triggerRender={() => (
          <div className="flex h-10 flex-col justify-center">
            <div className="group flex items-center gap-4">
              <BadgeLabel className="py-0 pl-0 pr-1 text-xs font-semibold">
                <div className="flex items-center gap-3">
                  <div
                    className="h-6 w-6 rounded-full"
                    style={{ backgroundColor: currentColor }}
                  ></div>
                  <span>{`${t("common:pick")} ${t("common:color", {
                    count: 1,
                  }).toLowerCase()}`}</span>

                  <div className="cursor-pointer rounded-full p-0.5 hover:bg-gray-300">
                    <ChevronRightIcon className="h-3 w-3" />
                  </div>
                </div>
              </BadgeLabel>
            </div>
          </div>
        )}
        config={{ side: "right", align: "center" }}
      >
        {(close) => (
          <div className="flex flex-col gap-2 rounded-lg border bg-white p-4 shadow-md">
            <Label>{`${t("common:pick")} ${t("common:color", {
              count: 1,
            }).toLowerCase()}`}</Label>
            <div className="flex w-62 flex-wrap justify-center gap-2">
              {labelColors.map((hexColor) => (
                <div
                  key={hexColor}
                  className={twMerge(
                    "cursor-pointer rounded-md border border-transparent p-1 hover:bg-shade-200",
                    hexColor === currentColor && "border-shade-300"
                  )}
                  onClick={() => {
                    onSelect(hexColor, contrastColor(colors.slate[700], hexColor));
                    close();
                  }}
                >
                  <div
                    className="h-6 w-6  rounded-full"
                    style={{ backgroundColor: hexColor }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
}
