import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Button, Dialog } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { EntityTable } from "~/lib/entity-ui";
import { LabelColor } from "~/lib/utils/colors";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { useEffect, useState } from "react";
import TextInput from "~/lib/ui/form-elements/text-input";
import ColorPicker from "~/lib/ui/colors/color-picker";
import CreateLabelModal from "~/pages/settings/index/_cmp/create-label-modal";
import { useAPI } from "~/lib/api";
import { usePagination } from "~/lib/utils";
import { IListProjectsDirectionEnum, LabelEntity, LabelEntityEntityEnum } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function LabelsPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const [pagination] = usePagination();
  const queryClient = useQueryClient();

  const [entityToEdit, setEntityToEdit] = useState<LabelEntity | null>(null);
  const [editableEntity, setEditableEntity] = useState<LabelEntity | null>(null);
  const cacheKeys = ["labels"];
  const editLabelMutation = useMutation({
    mutationFn: (label: LabelEntity) =>
      api.editLabel({
        labelId: label.id,
        createLabelRequest: {
          text: label.text ?? "",
          bgColor: label.bgColor,
          textColor: label.textColor,
          entity: label.entity as LabelEntityEntityEnum,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: cacheKeys });
    },
  });

  const deleteLabelMutation = useMutation({
    mutationFn: (id: string) => api.deleteLabel({ labelId: id }),
  });

  useEffect(() => {
    if (entityToEdit) {
      setEditableEntity(entityToEdit);
    } else {
      setEditableEntity(null);
    }
  }, [entityToEdit]);

  const handleSaveChanges = async () => {
    if (!editableEntity) return;
    await editLabelMutation.mutateAsync(editableEntity);
  };

  const handleDeleteLabel = async (id: string) => {
    await deleteLabelMutation.mutateAsync(id);
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("settings:tabs.labels")}</h2>
        <div>
          <Dialog
            render={({ onClose }) => <CreateLabelModal onClose={onClose} />}
            trigger={
              <Button variant="tertiary" Icon={PlusIcon}>
                <span>
                  {t("common:create", { entity: t("common:label", { count: 1 }).toLowerCase() })}
                </span>
              </Button>
            }
          />
        </div>
      </div>
      <div>
        <EntityTable
          cacheKey={cacheKeys}
          dataFn={() =>
            api.getLabels({
              page: pagination.page,
              q: pagination.q,
              sort: pagination.sort ?? "created",
              direction: pagination.direction ?? ("desc" as IListProjectsDirectionEnum),
            })
          }
          fields={{
            text: {
              label: t("common:label", { count: 1 }),
              tdClass: "w-4/12 group",
              renderColumn: (row) => (
                <div className="flex items-center gap-4">
                  {row === entityToEdit ? (
                    <ChevronDownIcon className="h-5 w-5" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                  <UICategoryLabel
                    id={row.id}
                    readonly
                    text={row.text ?? ""}
                    bgColor={row.bgColor as LabelColor}
                  />
                </div>
              ),
            },
            color: {
              label: t("common:color", { count: 1 }),
              tdClass: "w-4/12 group",
              renderColumn: (row) => {
                return (
                  <div className="flex h-full gap-4">
                    <div
                      className="h-6 w-6 rounded-full"
                      style={{ backgroundColor: row.bgColor }}
                    ></div>
                  </div>
                );
              },
            },
            entity: {
              label: t("common:availability"),
              tdClass: "w-4/12 group",
              renderColumn: (row) => (
                <div className="flex gap-4 font-semibold">
                  <span className="select-none">
                    {t(`settings:labels.availability.${row.entity}`)}
                  </span>
                </div>
              ),
            },
          }}
          onRowClick={(row) => setEntityToEdit(row)}
          renderRowExpansion={({ entity, numberOfColumns }) => {
            if (entity === entityToEdit)
              return (
                <tr>
                  <td colSpan={numberOfColumns}>
                    <div className="flex gap-8">
                      <div className="w-4/12">
                        <TextInput
                          label={t("common:name")}
                          defaultValue={entityToEdit?.text ?? ""}
                          onChange={(value) =>
                            setEditableEntity((prevState) => {
                              if (!prevState) return null;
                              return {
                                ...prevState,
                                text: value,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="w-4/12">
                        <ColorPicker
                          label={t("common:color", { count: 1 })}
                          currentColor={
                            (editableEntity?.["bgColor"] ?? entity.bgColor) as LabelColor
                          }
                          onSelect={(bgColor, textColor) =>
                            setEditableEntity((prevState) => {
                              if (!prevState) return null;
                              return {
                                ...prevState,
                                bgColor: bgColor,
                                textColor: textColor,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="inline-flex grow items-center justify-end">
                        <div className="flex justify-end gap-4">
                          <Button variant="tertiary" onClick={() => handleSaveChanges()}>
                            {t("common:save")}
                          </Button>
                          <Button variant="secondary" onClick={() => setEntityToEdit(null)}>
                            {t("common:cancel")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
          }}
          actions={[
            {
              icon: TrashIcon,
              label: t("common:delete"),
              mustConfirm: true,
              description: t("settings:labels.delete.description"),
              onExecute: async (row) => handleDeleteLabel(row.id),
              hideToast: true,
            },
          ]}
        />
      </div>
    </div>
  );
}
