import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useSuspenseQueries } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useToastOnError } from "~/lib/utils/hooks";
import { Boundary } from "~/lib/ui/boundary";
import { Button, Dialog, Icon, getIcon } from "~/lib/ui";
import { InvoiceBadge } from "./_cmp/invoice-badge";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { linkToInvoiceV3, linkToProject } from "~/lib/utils";
import { InvoiceSendDialog } from "./_cmp/invoice-send-dialog";
import { CACHE_INVOICES } from ".";
import { InvoiceKpiDataQuery, useGraphQL } from "~/lib/gql";
import { Invoice } from "@apacta/sdk";

export default function InvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useAPI();
  const { invoiceId } = useParams();
  const sdk = useGraphQL();

  const [invoiceQ, kpiQ] = useSuspenseQueries({
    queries: [
      {
        queryKey: [CACHE_INVOICES, invoiceId],
        queryFn: () => api.iGetInvoice({ invoiceId: invoiceId as string }),
      },
      {
        queryKey: [CACHE_INVOICES, invoiceId, "kpi"],
        queryFn: () =>
          sdk.invoiceKPIData({
            id: invoiceId as string,
          }),
      },
    ],
  });

  useToastOnError(invoiceQ.error);

  const invoice = invoiceQ.data?.data;
  const kpi = kpiQ.data.invoice;

  if (!invoice) return null;

  // Invoice with number if any, otherwise just "Invoice"
  const title = invoice?.invoiceNumber
    ? `${t("common:invoice", { count: 1 })} #${invoice?.invoiceNumber}`
    : t("common:invoice", { count: 1 });

  // Creates a draft of a new invoice with the values reversed
  async function handleCreateCreditNote() {
    const res = await api.duplicateInvoice({
      invoiceId: invoice.id,
      asCreditNote: true,
    });
    if (!res.data.id) return;
    navigate(linkToInvoiceV3(res.data.id));
  }

  return (
    <PageLayout
      title={title}
      renderDescription={() => (
        <div className="flex flex-col gap-2">
          <OptionalLink to={linkToProject(invoice.project.id)}>
            <div className="flex flex-row items-center gap-2">
              <Icon name="project"></Icon>
              <div>{invoice?.project?.name}</div>
            </div>
          </OptionalLink>

          <div className="flex flex-row items-center gap-2">
            <InvoiceBadge invoice={invoice} />
          </div>
        </div>
      )}
      renderActions={() => (
        <div className="flex flex-row gap-4">
          <Dialog
            trigger={
              <Button variant="secondary" Icon={getIcon("send")}>
                {t("invoices:send_invoice")}
              </Button>
            }
            render={({ onClose }) => <InvoiceSendDialog onClose={onClose} invoice={invoice} />}
          />
          <Button variant="secondary" Icon={getIcon("add")} onClick={handleCreateCreditNote}>
            {t("invoices:create_credit_note")}
          </Button>
        </div>
      )}
      onBackClick={() => navigate(-1)}
    >
      <Boundary variant="tab">
        <Outlet context={{ invoice, kpi }} />
      </Boundary>
    </PageLayout>
  );
}

export type InvoiceOutlet = { invoice: Invoice; kpi: InvoiceKpiDataQuery["invoice"] };
