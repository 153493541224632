import { Popover } from "~/lib/ui/popover/popover";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import { orderLinesToOfferLinesTransformer } from "~/lib/ui/order-lines/lib/transformers";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BaseFile, Contact, ContactPerson, EditOfferOperationRequest, Offer } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { RefObject, useState } from "react";
import { OrderLinesBuilderRef } from "~/lib/ui/order-lines/lib/types";
import {
  ChevronDownIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import { sentenceToSnakeCase } from "~/lib/utils/string/convert-case";
import { Spinner } from "~/lib/ui/spinner";
import { useModals } from "~/lib/ui/modal";
import SendOfferModal from "~/pages/offers/_cmp/send-offer-modal";
import { useNavigate } from "react-router";
import { Dialog, getIcon } from "~/lib/ui";
import { Button } from "~/lib/ui/buttons/button";
import { dateIsBeforeDate } from "~/lib/utils/date/date-utils";
import { NotAllowedModal } from "~/lib/ui/modal/templates/not-allowed-modal";
import { calculateLinesTotal } from "~/lib/ui/order-lines/lib/calculations";
import SaveUnavailableDialog from "~/pages/offers/_cmp/save-unavailable-dialog";
import { useMe } from "~/lib/auth/use-me";
import { linkToProject } from "~/lib/utils";
import BlockNavigation from "~/lib/navigation/block-navigation";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { runOfferLineRule } from "~/pages/offers/_cmp/helpers/run-offer-line-rules";

type OfferViewPageActionsProps = {
  editMode: boolean;
  isAccepted: boolean;
  isRejected: boolean;
  formState: OfferFormState;
  filesToUpload: Array<File>;
  onFilesUploaded: () => void;
  filesToDelete: Array<BaseFile>;
  onFilesDeleted: () => void;
  getOfferData: () => EditOfferOperationRequest;
  offer: Offer;
  orderLinesBuilderRef: RefObject<OrderLinesBuilderRef>;
  linesModified: boolean;
  filesModified: boolean;
  downloadPdf: (filename: string) => void;
  previewPdf: () => Promise<HTMLDivElement>;
  getPdf: (fileName?: string) => Promise<Blob | void>;
  selectedCustomer?: Contact;
  selectedContactPerson?: ContactPerson;
  editorValidState: boolean;
};
export default function OfferViewPageActions({
  editMode,
  isAccepted,
  isRejected,
  formState,
  filesToUpload,
  onFilesUploaded,
  filesToDelete,
  onFilesDeleted,
  getOfferData,
  offer,
  orderLinesBuilderRef,
  linesModified,
  filesModified,
  downloadPdf,
  previewPdf,
  getPdf,
  selectedCustomer,
  selectedContactPerson,
  editorValidState,
}: OfferViewPageActionsProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { company } = useMe();

  const [actionPending, setActionPending] = useState<boolean>(false);
  const [descriptionWarningOpen, setDescriptionWarningOpen] = useState<boolean>(false);

  const deleteFilesMutation = useMutation({
    mutationFn: (files: Array<BaseFile>) => {
      return api.deleteOfferFile({ offerId: offer.id, requestBody: files.map((file) => file.id) });
    },
    onSettled: () => onFilesDeleted(),
  });

  const saveFilesMutation = useMutation({
    mutationFn: (files: Array<File>) => {
      return api.iOfferUploadFile({ offerId: offer.id, files });
    },
    onSettled: () => onFilesUploaded(),
    onSuccess: () => undefined,
  });

  const saveMutation = useMutation({
    mutationFn: (offerData: EditOfferOperationRequest) => {
      return api.editOffer(offerData);
    },
    onSettled: async (response, errors, variables) => {
      if (!errors) {
        await queryClient.invalidateQueries({ queryKey: ["offer", offer.id] });
      }
    },
    onSuccess: () => undefined,
  });

  const acceptMutation = useMutation({
    mutationFn: () =>
      api.offerAccept({
        offerAcceptRequest: { offerId: offer.id as string },
      }),
    onMutate: () => setActionPending(true),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["offer", offer.id] });
      setActionPending(false);
    },
  });

  const rejectMutation = useMutation({
    mutationFn: () => api.offerReject({ offerAcceptRequest: { offerId: offer.id as string } }),
    onMutate: () => setActionPending(true),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["offer", offer.id] });
      setActionPending(false);
    },
  });

  const handleSave = async () => {
    const characterCount = formState.getValue("description").length;

    if (
      characterCount > 8000 ||
      (orderLinesBuilderRef.current && !orderLinesBuilderRef.current.isValid())
    ) {
      setDescriptionWarningOpen(true);
      return;
    }

    const offerData = getOfferData();
    if (offerData && offerData.editOfferRequest && orderLinesBuilderRef.current) {
      const lines = orderLinesBuilderRef.current.getLines();
      offerData.editOfferRequest.offerLines = orderLinesToOfferLinesTransformer(lines, {
        offerId: offer?.id,
      });

      const manualPrice = formState.getValue("manualTotalSalesPrice");
      if (
        manualPrice === Math.round(calculateLinesTotal(lines, "subAmount", 0)) ||
        (formState.getValue("offerLinesRule") !== "gather_offer_lines" &&
          formState.getValue("offerLinesRule") !== "show_only_product_bundles")
      ) {
        offerData.editOfferRequest.manualTotalSalesPrice = null;
      }

      if (filesToDelete.length) {
        await deleteFilesMutation.mutateAsync(filesToDelete);
      }
      if (filesToUpload.length) {
        await saveFilesMutation.mutateAsync(filesToUpload);
      }

      await saveMutation.mutateAsync(offerData);

      orderLinesBuilderRef.current.resetInitialValues();
    }
  };

  const { showConfirm, showModal } = useModals();

  const handleAccept = async () => {
    if (formState.isModified || linesModified) {
      // If the form is modified and not saved, show a confirm dialog
      await showConfirm(
        {
          variant: "warning",
          Icon: ExclamationTriangleIcon,
          title: t("offers:confirm_not_saved.title"),
          description: t("offers:confirm_not_saved_accept.description"),
        },
        async () => acceptMutation.mutate()
      );
    } else {
      acceptMutation.mutate();
    }
  };

  const handleReject = async () => {
    if (formState.isModified || linesModified) {
      // If the form is modified and not saved, show a confirm dialog
      await showConfirm(
        {
          variant: "warning",
          Icon: ExclamationTriangleIcon,
          title: t("offers:confirm_not_saved.title"),
          description: t("offers:confirm_not_saved_reject.description"),
        },
        async () => rejectMutation.mutate()
      );
    } else {
      rejectMutation.mutate();
    }
  };

  const initialEmail = selectedContactPerson?.email
    ? selectedContactPerson.email
    : selectedCustomer?.email ?? "";

  const saveDisabled =
    (!formState.isModified && !linesModified && !filesModified) ||
    !formState.isValid ||
    !editorValidState;

  const canSendOffer = () => {
    return !dateIsBeforeDate(formState.getValue("expirationDate"), new Date());
  };

  const navigationBlocked =
    formState.isModified || linesModified || filesToUpload.length > 0 || filesToDelete.length > 0;

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState<Blob | undefined>();

  const handlePreviewPdf = async () => {
    await runOfferLineRule(offer, orderLinesBuilderRef, formState, api);
    const pdf = await getPdf(offer.id);
    if (pdf) {
      setPreview(pdf);
      setPreviewOpen(true);
    }
  };

  return (
    <>
      <Dialog
        open={descriptionWarningOpen}
        render={({ onClose }) => <SaveUnavailableDialog onClose={onClose} />}
        onOpenChange={() => setDescriptionWarningOpen(false)}
      />
      <FullScreenFilePreview
        open={previewOpen && !!preview}
        onClose={() => setPreviewOpen(false)}
        file={preview}
      />
      <BlockNavigation when={navigationBlocked} onSaveBeforeNavigate={handleSave} />
      <div className="flex gap-4">
        {!isAccepted && editMode ? (
          <Button
            variant="tertiary"
            onClick={() => handleSave()}
            disabled={saveDisabled}
            loading={saveMutation.isPending}
          >
            {t("common:save_changes")}
          </Button>
        ) : (
          isAccepted && (
            <Button
              variant="tertiary"
              disabled={!offer.project}
              onClick={() => offer.project && navigate(linkToProject(offer.project.id))}
            >
              {t("offers:go_to_project")}
            </Button>
          )
        )}
        {!isAccepted && editMode && (
          <Dialog
            trigger={
              <Button variant="primary">
                <div className="flex items-center gap-2">
                  <EnvelopeIcon className="h-5 w-5" />
                  <span>{t("common:send")}</span>
                </div>
              </Button>
            }
            render={({ onClose }) =>
              canSendOffer() ? (
                <SendOfferModal
                  offer={offer}
                  formState={formState}
                  orderLinesBuilderRef={orderLinesBuilderRef}
                  getPdf={getPdf}
                  onClose={onClose}
                  isModified={formState.isModified || linesModified}
                  saveFn={handleSave}
                  initialEmail={initialEmail}
                  initialSubject={t("offers:send_offer_subject", {
                    type: t(`offers:type.${formState.getValue("type")}`),
                    title: offer.title,
                  })}
                  initialBody={t("offers:send_offer_body", {
                    customerName: selectedContactPerson?.name ?? selectedCustomer?.name,
                    companyName: company.name,
                  })}
                />
              ) : (
                <NotAllowedModal
                  Icon={ExclamationCircleIcon}
                  variant="alert"
                  title={t("offers:cannot_send")}
                  description={t("offers:cannot_send_description")}
                  onClose={onClose}
                />
              )
            }
          />
        )}
        <Button
          variant="primary"
          onClick={() => handlePreviewPdf()}
          iconClassName="h-5 w-5"
          Icon={getIcon("preview")}
        >
          {t("common:see")} {t("common:pdf")}
        </Button>

        {(isAccepted || !editMode) && (
          <ButtonLegacy
            variant="primary"
            onClick={() => downloadPdf(sentenceToSnakeCase(formState.getValue("title")))}
          >
            <div className="flex items-center gap-2">
              <PaperClipIcon className="h-5 w-5" />
              <span>
                {t("common:download")} {t("common:pdf")}
              </span>
            </div>
          </ButtonLegacy>
        )}

        {!isAccepted && !isRejected && (
          <Popover
            config={{ align: "end" }}
            triggerRender={() => (
              <div className="flex items-center justify-between gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover">
                {actionPending ? (
                  <Spinner className="h-6 w-6" />
                ) : (
                  <>
                    <span>{t("common:action", { count: 2 })}</span>
                    <ChevronDownIcon className="h-4 w-4" />
                  </>
                )}
              </div>
            )}
          >
            {(close) => (
              <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
                <ul>
                  <li
                    className="cursor-pointer rounded-lg p-2 hover:bg-shade-100"
                    onClick={async () => {
                      close();
                      await handleAccept();
                    }}
                  >
                    {t("common:approve")}
                  </li>
                  <li
                    className="cursor-pointer rounded-lg p-2 hover:bg-shade-100"
                    onClick={async () => {
                      close();
                      await handleReject();
                    }}
                  >
                    {t("common:reject")}
                  </li>
                  {editMode && (
                    <li
                      className="cursor-pointer rounded-lg p-2 hover:bg-shade-100"
                      onClick={async () => {
                        close();
                        downloadPdf(sentenceToSnakeCase(formState.getValue("title")));
                      }}
                    >
                      {t("common:download")} {t("common:pdf")}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </Popover>
        )}
      </div>
    </>
  );
}
