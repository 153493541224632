import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPI } from "~/lib/api";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import TextInput from "~/lib/ui/form-elements/text-input";
import { LoginLayout } from "./layout";
import { RequestResetPasswordOperationRequest, ResponseError } from "@apacta/sdk";
import { useMutation } from "@tanstack/react-query";
import { captureException } from "~/lib/error-reporting";

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const location = useLocation();
  const [email, setEmail] = useState<string>(location.state?.email || "");
  const [mobile, setMobile] = useState<string>(location.state?.mobile || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<"success" | "failed" | "unknown">("unknown");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>(t("ui:login.reset_failed"));
  const mutation = useMutation({
    mutationFn: (resetPasswordData: RequestResetPasswordOperationRequest) => {
      return api.requestResetPassword(resetPasswordData);
    },
    onSuccess: (data) => {
      setState("success");
    },
    onError: (error: ResponseError) => {
      if (error.response.status === 422) {
        setErrorMessage(t("ui:login.reset_error_description"));
      }
      setState("failed");
      captureException(new Error("Operation failed", { cause: error }));
    },
    onSettled: (data) => {
      setEmail("");
      setMobile("");
      setLoading(false);
    },
  });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    const requestParams = {
      requestResetPasswordRequest: {
        email,
        mobile,
      },
    };

    return mutation.mutate(requestParams);
  }

  return (
    <LoginLayout>
      <div>
        {state === "failed" ? <div className="pb-2 text-red-600">{errorMessage}</div> : null}
      </div>
      <div>
        <div className="relative mt-6">
          <h3>{t("ui:login.reset_password", "Reset password")}</h3>
        </div>
      </div>

      <div className="mt-6">
        {state === "success" ? (
          <div>
            <p>{t("ui:login.reset_success")}</p>
            <ButtonLegacy
              className="mt-4"
              variant="primary"
              onClick={() => navigate("/login", { replace: true })}
            >
              {t("common:back")}
            </ButtonLegacy>
          </div>
        ) : (
          <form action="#" method="POST" className="-space-y-2" onSubmit={handleSubmit}>
            <div className="pb-6">
              <TextInput
                controlled={true}
                value={email}
                id="email"
                name="email"
                type="email"
                label={t("common:email_address")}
                onChange={setEmail}
                disabled={mobile.length > 0}
              />
            </div>
            <div className="text-center">or</div>
            <div className="pb-8">
              <TextInput
                controlled={true}
                value={mobile}
                id="mobile"
                name="mobile"
                type="text"
                label={t("common:phone_number")}
                onChange={setMobile}
                disabled={email.length > 0}
              />
            </div>
            <div className="flex flex-row gap-4">
              <button
                disabled={loading}
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {t("ui:login.reset_password")}
              </button>
              <ButtonLegacy
                variant="secondary"
                onClick={() => navigate("/login", { replace: true })}
              >
                {t("common:cancel")}
              </ButtonLegacy>
            </div>
            <div></div>
          </form>
        )}
      </div>
    </LoginLayout>
  );
}
