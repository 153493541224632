import { PageSpinner } from "~/lib/ui/page-spinner";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { PaginationBar } from "~/lib/entity-ui/pagination-bar";

function TableView({ children }: { children: JSX.Element | Array<JSX.Element> }) {
  return <div>{children}</div>;
}

function Details({
  title,
  description,
  className,
  children,
}: {
  title?: string;
  description?: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element>;
}) {
  return (
    <div className={twMerge("justify-between sm:flex sm:items-center", className)}>
      {title || description ? (
        <div className="sm:flex-auto">
          {title ? <h2 className="text-xl font-semibold text-gray-900">{title}</h2> : null}
          {description ? (
            <p className="mt-2 max-w-2xl text-sm text-gray-700">{description}</p>
          ) : null}
        </div>
      ) : null}

      {children}
    </div>
  );
}

function Actions({ children }: { children: JSX.Element | Array<JSX.Element> }) {
  return <div className="mt-4 sm:mt-0 sm:flex-none">{children}</div>;
}

function Table({
  loading,
  className,
  children,
}: {
  loading?: boolean;
  className?: string;
  children: ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <div className={twMerge(className)}>
      {loading ? (
        <div className="absolute h-full w-full bg-gray-300 bg-opacity-50">
          <PageSpinner
            delay={200}
            className="text-zinc-700"
            loadingMessage={t("customers:table.update")}
          />
        </div>
      ) : null}
      <table>{children}</table>
    </div>
  );
}

function Head({ children }: { children: JSX.Element | Array<JSX.Element> }) {
  return <thead>{children}</thead>;
}

function Row({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children: JSX.Element | Array<JSX.Element>;
}) {
  return (
    <tr
      onClick={() => onClick?.()}
      className={twMerge(onClick && "cursor-pointer hover:bg-gray-50", className)}
    >
      {children}
    </tr>
  );
}

function HeaderCell({
  className,
  children,
}: {
  className?: string;
  children: JSX.Element | Array<JSX.Element> | string;
}) {
  return (
    <th scope="col" className={twMerge(className)}>
      {children}
    </th>
  );
}

function Body({ children }: { children: JSX.Element | Array<JSX.Element> }) {
  return <tbody>{children}</tbody>;
}

function Cell({
  className,
  colSpan = 1,
  children,
}: {
  className?: string;
  colSpan?: number;
  children: JSX.Element | Array<JSX.Element> | string;
}) {
  return (
    <td scope="col" colSpan={colSpan} className={twMerge(className)}>
      {children}
    </td>
  );
}

function Footer({ children }: { children: ReactNode }) {
  return <div className="footer">{children}</div>;
}

TableView.Details = Details;
TableView.Actions = Actions;
TableView.Table = Table;
TableView.Head = Head;
TableView.Row = Row;
TableView.HeaderCell = HeaderCell;
TableView.Body = Body;
TableView.Cell = Cell;
TableView.Footer = Footer;
TableView.Pagination = PaginationBar;

export default TableView;
