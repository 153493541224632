export type CardData = {
  id: string;
  title: string;
  date?: Date;
  address?: string;
  zip?: number;
  startTime?: Date;
  isCollapsed?: boolean;
};

export enum DropZoneOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export const DragDropType = "CARD";
