import { useNavigate, useOutletContext } from "react-router-dom";
import { CreateProductRequest, CreateProductRequestProductRuleEnum } from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "~/lib/toast/use-toasts";
import TextInput from "~/lib/ui/form-elements/text-input";
import { ProductRulesSelect } from "~/pages/products/_cmp/product-rules-select";
import { Button } from "~/lib/ui";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { OutletContext } from "../[id]";

export default function OverviewProductPage() {
  const api = useAPI();
  const { product, refetch } = useOutletContext<OutletContext>();
  const { t } = useTranslation();
  const toast = useToasts();
  const navigate = useNavigate();
  const [{ referrer }] = useTypedSearchParams<{ referrer: string }>();

  const { register, isModified, isValid, onChange, getValue } = useFormState({
    schema: {
      name: z.string().max(255),
      number: z.string().max(255).optional(),
      barcode: z.string().max(255).optional(),
      unit: z.string().optional(),
      product_rule: z.string().nullable(),
    },
    initialValues: {
      name: product.name,
      number: product.productNumber ?? undefined,
      barcode: product.barcode ?? undefined,
      unit: product.origin ?? undefined,
      product_rule: product.productRule ?? null,
    },
  });

  const editProductMutation = useMutation({
    mutationFn: (args: CreateProductRequest) =>
      api.editProduct({ productId: product.id, createProductRequest: args }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED", { timeout: 10000 });
      refetch();
      if (referrer) {
        navigate(referrer);
      }
    },
  });

  function handleSave() {
    editProductMutation.mutate({
      name: getValue("name"),
      productNumber: getValue("number"),
      barcode: getValue("barcode"),
      productRule: getValue("product_rule") as CreateProductRequestProductRuleEnum,
    });
  }

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            disabled={!isModified || !isValid}
            variant="tertiary"
            tabIndex={0}
            onClick={handleSave}
          >
            {t("common:save")}
          </Button>
        }
      />
      <div className="flex-1">
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <TextInput {...register("name")} label={t("common:name")} />
            <ProductRulesSelect
              {...register("product_rule")}
              initialProductRule={getValue("product_rule") ?? "NONE"}
              onChange={(productRule) => {
                productRule?.id && onChange("product_rule", productRule.value);
              }}
            />
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/4">
                <TextInput {...register("number")} label={t("products:number")} />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-3/4">
                <TextInput {...register("barcode")} label={t("common:barcode")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
