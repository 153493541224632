import { ComponentProps, ReactElement } from "react";
import { Button } from "./button";
import { twMerge } from "tailwind-merge";

// Accepts buttons, but will apply class overrides to border classes
// Technically, you can put whatever you want in here, but it will output buttons.
export function ButtonGroup({
  children,
  className,
}: {
  children: Array<ReactElement<ComponentProps<typeof Button>>>;
  className?: string; // Applied to all buttons, after overides
}) {
  return (
    <div className="flex flex-row">
      {children.map((child, index) => {
        if (index === 0) {
          return (
            <Button
              key={`btn-group-${index}`}
              {...child.props}
              className={twMerge("rounded-r-none", className)}
            />
          );
        } else if (index === children.length - 1) {
          return (
            <Button
              key={`btn-group-${index}`}
              {...child.props}
              className={twMerge("rounded-l-none border-l-0 hover:border-l", className)}
            />
          );
        } else {
          return (
            <Button
              key={`btn-group-${index}`}
              {...child.props}
              className={twMerge("rounded-none", className)}
            />
          );
        }
      })}
    </div>
  );
}
