import { NavItem } from "../navigation-item/navigation-item.type";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useRoles } from "~/lib/auth/use-roles";
import Badge from "~/lib/ui/badge";
import { twMerge } from "tailwind-merge";
import { Popover } from "~/lib/ui/popover/popover";
import { debounce } from "lodash";
import { iframeNavigationStateHack } from "~/lib/utils/iframe";
import { FeaturesType, useSession } from "~/lib/auth/session";

export function NavigationItemCollapsed({
  item,
  isSubItem = false,
  onClick,
}: {
  item: NavItem;
  isSubItem?: boolean;
  onClick?: () => void;
}) {
  const isActive = false; // TODO: Revisit when we are rid of frames
  const [isOpen, setIsOpen] = useState<boolean>(false); // only used for Collapsible
  const [isOver, setIsOver] = useState<boolean>(false); // only used for Popover

  const { me } = useSession();

  const features = useFeatureFlags();
  const roles = useRoles();

  /** Use debounce from Lodash to ensure we don't get a lot of popovers when moving the cursor really fast */
  const debouncedMouseEnterFn = debounce((type?: "open" | undefined) => {
    if (type === "open") {
      setIsOpen(true);
    } else {
      setIsOver(true);
    }
  }, 300);

  const handleMouseLeave = () => {
    setIsOver(false);
    debouncedMouseEnterFn.cancel();
  };

  // Skip any items that are locked behind a feature flag
  if (item.featureName && !features.has(item.featureName as FeaturesType)) {
    return null;
  }

  // Skip any items that are locked behind a role
  if (item.role && !roles.has(item.role)) {
    return null;
  }

  // Skip any items that should be hidden
  if (me && item.isHidden?.(me)) {
    return null;
  }

  // Return Disclosure if item has children
  if (item.children) {
    return (
      <div>
        <Popover
          triggerAsChild
          isOpen={isOpen}
          onOpenChange={() => {
            setIsOpen(false);
          }}
          onClick={() => setIsOpen(!isOpen)}
          triggerRender={() => (
            <button
              className={twMerge(
                isActive ? "rounded-lg bg-hover text-white" : "text-gray-200 hover:bg-hover",
                isSubItem ? "pl-11 pr-2" : "pl-2",
                isOver && !isOpen ? "rounded-l-lg bg-hover" : "rounded-lg",
                "group flex w-full cursor-pointer items-center justify-center  py-2 pr-2 text-base"
              )}
            >
              {item.icon ? (
                <item.icon
                  className={twMerge("h-7 w-7 flex-shrink-0 text-gray-200 hover:bg-hover")}
                  aria-hidden="true"
                />
              ) : null}
            </button>
          )}
          config={{
            side: "right",
            sideOffset: 20,
            align: "end",
            arrow: true,
            arrowColor: "#213449",
            contentClassName: "z-navigation-menu-popover",
          }}
        >
          {() => (
            <div className="flex flex-shrink-0 flex-col overflow-hidden rounded-md bg-primary text-base text-gray-200 shadow-md">
              {item.children?.map((subItem, idx) => (
                <div key={subItem.name + idx}>
                  {subItem.external ? (
                    <a
                      href={subItem.href}
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full px-4 py-3 hover:bg-hover hover:text-white"
                      onClick={() => {
                        setIsOver(false);
                        setIsOpen(false);
                      }}
                    >
                      {subItem.name}
                    </a>
                  ) : (
                    <NavLink
                      to={subItem.href}
                      // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
                      state={iframeNavigationStateHack()}
                      className="group flex w-full px-4 py-3  hover:bg-hover hover:text-white"
                      onClick={() => {
                        setIsOver(false);
                        setIsOpen(false);
                      }}
                    >
                      {subItem.name}
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          )}
        </Popover>
      </div>
    );
  }

  // Return anchor tag with link if item is targeting external website
  if (item.external) {
    return (
      <div className="my-1">
        <div onMouseEnter={() => debouncedMouseEnterFn()} onMouseLeave={() => handleMouseLeave()}>
          <Popover
            triggerAsChild
            isOpen={isOver}
            triggerRender={() => (
              <a
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className={twMerge(
                  isSubItem ? "pl-11 pr-2" : "pl-2",
                  isOver ? "rounded-l-lg bg-hover" : "rounded-lg",
                  "group flex w-full items-center justify-center py-2 pr-2 text-base text-gray-200 hover:bg-hover"
                )}
              >
                {item.icon ? (
                  <item.icon
                    className={twMerge("h-7 w-7 flex-shrink-0 text-gray-200 hover:bg-hover")}
                    aria-hidden="true"
                  />
                ) : null}
              </a>
            )}
            config={{
              side: "right",
              sideOffset: 0,
              align: "start",
              contentClassName: "z-navigation-menu-popover",
            }}
          >
            {() => (
              <a href={item.href} target="_blank" rel="noreferrer">
                <div className="flex flex-shrink-0 flex-col overflow-hidden rounded-r-lg bg-hover text-base text-gray-200">
                  <div className="flex px-4 py-2.5">{item.name}</div>
                </div>
              </a>
            )}
          </Popover>
        </div>
      </div>
    );
  }

  // We must not post empty links when our frames are dependant on location, it re-renders everything
  if (onClick) {
    return (
      <div className="my-1">
        <div onMouseEnter={() => debouncedMouseEnterFn()} onMouseLeave={() => handleMouseLeave()}>
          <Popover
            isOpen={isOver}
            triggerAsChild
            triggerRender={() => (
              <button
                onClick={() => onClick && onClick()}
                className={twMerge(
                  isActive ? "rounded-lg bg-hover text-white" : "text-gray-200 hover:bg-hover",
                  isSubItem ? "pl-11 pr-2" : "pl-2",
                  isOver ? "rounded-l-lg bg-hover" : "rounded-lg",
                  "group flex w-full items-center justify-center py-2 pr-2 text-base "
                )}
              >
                {item.icon ? (
                  <item.icon
                    className={twMerge("h-7 w-7 flex-shrink-0 text-gray-200 hover:bg-hover")}
                    aria-hidden="true"
                  />
                ) : null}
              </button>
            )}
            config={{
              side: "right",
              sideOffset: 0,
              align: "start",
              contentClassName: "z-navigation-menu-popover",
            }}
          >
            {() => (
              <div
                className="flex flex-shrink-0 cursor-pointer flex-col overflow-hidden rounded-r-lg bg-hover text-base text-gray-200"
                onClick={() => onClick && onClick()}
              >
                <div className="flex px-4 py-2.5">
                  {item.name}
                  {item.badge ? (
                    <Badge
                      className={twMerge("ml-4", item.badge.className)}
                      size="sm"
                      label={item.badge.label}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </Popover>
        </div>
      </div>
    );
  }

  // Return NavLink if no other conditions are met
  return (
    <div className="my-1">
      <NavLink
        to={item.href}
        // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
        state={iframeNavigationStateHack()}
      >
        {(link) => (
          <div onMouseEnter={() => debouncedMouseEnterFn()} onMouseLeave={() => handleMouseLeave()}>
            <Popover
              isOpen={isOver}
              triggerAsChild
              triggerRender={() => (
                <div
                  className={twMerge(
                    link.isActive ? "bg-hover text-white" : "text-gray-200 hover:bg-hover",
                    isSubItem ? "pl-11 pr-2" : "pl-2",
                    isOver ? "rounded-l-lg bg-hover" : "rounded-lg",
                    "group flex w-full items-center justify-center py-2 pr-2 text-base"
                  )}
                >
                  {item.icon ? (
                    <item.icon
                      className={twMerge("h-7 w-7 flex-shrink-0 text-gray-200 hover:bg-hover")}
                      aria-hidden="true"
                    />
                  ) : null}
                </div>
              )}
              config={{
                side: "right",
                sideOffset: 0,
                align: "start",
                contentClassName: "z-navigation-menu-popover",
              }}
            >
              {() => (
                <div className="flex flex-shrink-0 cursor-pointer flex-col overflow-hidden rounded-r-lg bg-hover text-base text-gray-200">
                  <NavLink
                    to={item.href}
                    // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
                    state={iframeNavigationStateHack()}
                  >
                    <div className="flex px-4 py-2.5">
                      {item.name}
                      {item.badge ? (
                        <Badge
                          className={twMerge("ml-4", item.badge.className)}
                          size="sm"
                          label={item.badge.label}
                        />
                      ) : null}
                    </div>
                  </NavLink>
                </div>
              )}
            </Popover>
          </div>
        )}
      </NavLink>
    </div>
  );
}
