import { formatDate } from "~/lib/utils/date";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { Contact, IListProjectsDirectionEnum } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useParams } from "react-router";
import { useOutletContext } from "react-router-dom";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useMount } from "~/lib/lifecycle-helpers";
import { EntityTable } from "~/lib/entity-ui";
import { usePagination } from "~/lib/utils";
import InvoiceStatus from "~/pages/customers/_cmp/invoice-status";

export default function InvoicesTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const { t, i18n } = useTranslation();
  const api = useAPI();
  const { id: customerId } = useParams();

  const [pagination] = usePagination();

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("common:invoice", { count: 2 })}`);
  });

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="w-full">
        <div>
          <div className="flex flex-row items-start justify-between">
            <h2>{t("common:invoice", { count: 2 })}</h2>
          </div>
          <div className="flex w-full flex-col gap-10 rounded-md">
            {customerId ? (
              <EntityTable
                cacheKey={["projects", customerId]}
                dataFn={() =>
                  api.iListInvoices({
                    page: pagination.page,
                    q: pagination.q,
                    sort: pagination.sort ?? "invoice_number",
                    direction: pagination.direction ?? ("desc" as IListProjectsDirectionEnum),
                    contactId: customerId,
                  })
                }
                fields={{
                  invoiceNo: {
                    label: t("invoices:invoice_number"),
                    href: (row) => `/companies/invoices/getPDF/${row.id}`,
                    thClass: "w-12",
                    renderColumn: (row) => (
                      <span className="font-semibold">{row.invoiceNumber}</span>
                    ),
                    sortBy: "invoiceNo",
                  },
                  projectName: {
                    label: t("common:project", { count: 1 }),
                    href: (row) => `/companies/invoices/getPDF/${row.id}`,
                    renderColumn: (row) =>
                      row.project?.name ? (
                        <span>{row.project.name}</span>
                      ) : (
                        <span className="text-gray-400">{t("common:not_available")}</span>
                      ),
                    sortBy: "projectName",
                  },
                  address: {
                    label: t("common:address"),
                    renderColumn: (row) =>
                      row.contact?.cityName && row.contact?.zipCode && row.contact?.address ? (
                        <div className="flex flex-col">
                          <span>{row.contact.address}</span>
                          <span>{`${row.contact.zipCode} ${row.contact?.cityName}`}</span>
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <span className="text-gray-400">{t("common:not_available")}</span>
                          <span className="text-gray-400">{t("common:not_available")}</span>
                        </div>
                      ),
                  },
                  invoiceDate: {
                    label: t("invoices:invoice_date"),
                    renderColumn: (row) =>
                      row.contact?.name ? (
                        <span>
                          {formatDate(row.issuedDate || null, i18n.resolvedLanguage, {
                            shortDate: true,
                          })}
                        </span>
                      ) : (
                        <span className="text-gray-400">{t("common:not_available")}</span>
                      ),
                  },
                  status: {
                    label: t("common:status"),
                    renderColumn: (row) => <InvoiceStatus invoice={row} />,
                  },
                }}
              />
            ) : (
              <div className="relative h-96 w-full">
                <PageSpinner loadingMessage={t("common:loading")} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
