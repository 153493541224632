import { useIntercom } from "react-use-intercom";
import { useEffect, useState } from "react";
import { useSession } from "../auth/session";
import { useLocation } from "react-router";
import { getScreenSizeType } from "../utils/tailwind/screen-sizes";

export function useApactaIntercom(exceptionPaths: Array<string> = []) {
  const [initialized, setInitialized] = useState(false);
  const intercomBase = useIntercom();
  const { me } = useSession();
  const location = useLocation();

  useEffect(() => {
    // Take exception paths into account
    const isExcepted = exceptionPaths.some((l) => location.pathname.startsWith(l));
    if (isExcepted) return; // Don't initialize on paths without sidebar/auth
    if (!me || !me.user || !me.company || !me.intercomHash) return; // Session isn't initialized yet

    // Initialize and boot up Intercom with the details of the current user
    intercomBase.boot({
      hideDefaultLauncher: getScreenSizeType() === "mobile", // Hide the default launcher on mobile
      userId: me.user.id || "",
      userHash: me.intercomHash,
      name: me.user.firstName,
      email: me.user.email,
      languageOverride: me.user.language?.shortCode, //Ensures that we'll use the user's language rather than let Intercom interpret some browser language
      company: {
        companyId: me.company.id || "", //To tie the user to the company - and yes, this was needed on every request last time we tested it... :-)
        name: me.company.name,
      },
      customAttributes: {
        userType: "admin", //Control panel users are called 'admin'
      },
    });
    intercomBase.hide();
    setInitialized(true);

    return () => {
      intercomBase.shutdown();
      setInitialized(false);
    };
  }, [me]);

  useEffect(() => {
    // Only update if we're initialized, otherwise we'll get an error
    if (initialized) {
      intercomBase.update(); // Send page view update when location changes
    }
  }, [location]);

  return { ...intercomBase, initialized };
}
