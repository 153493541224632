import {
  ComponentProps,
  createContext,
  Dispatch,
  ReactElement,
  Reducer,
  useContext,
  useReducer,
} from "react";
import { randomId } from "~/lib/utils/string";
import { IconProp } from "../types";
import { ConfirmationModal } from "./templates/confirmation-modal";
import Modal from "./modal";

export type CloserCallback = () => void;
export type SubmitCallback = (value?: unknown) => void;

export type RenderModalCallback<ReturnType> = (args: {
  // onClose passed to the render function, but also used internally if you click outside

  onSubmit: (value?: ReturnType) => void;
  onClose: () => void;
  isLoading?: boolean;
  setMustConfirm: (confirm?: string) => void;
}) => ReactElement;

// Say that three times fast
export interface ModalModel {
  id: string;
  isOpen: boolean;
  isLoading: boolean;
  mustConfirm?: string; // If defined, we will display the string in a confirm dialog
  fn: RenderModalCallback<unknown>; // Render Function. Receives modal info, returns ReactElement
  resolverFn?: (value?: unknown) => Promise<unknown>;
  onSubmit: SubmitCallback;
  onClose: CloserCallback;
  props?: SupportedModalProps;
}

// Add as necessary, try to avoid Omit
type SupportedModalProps = Pick<
  ComponentProps<typeof Modal>,
  "size" | "transparent" | "verticalPositionMobile"
>;

type ModalState = Record<string, ModalModel>;

type ModalActions =
  | ({
      // Creates the modal element and signals it to open
      type: "ADD";
    } & Omit<ModalModel, "isOpen" | "isLoading">)
  | {
      // Signals the modal to close
      type: "CLOSE";
      id: string;
    }
  | {
      // Submit signal
      type: "SUBMIT";
      id: string;
    }
  | {
      // Removes the element completely after transitions are done
      type: "REMOVE";
      id: string;
    }
  | {
      type: "MUSTCONFIRM";
      id: string;
      value: string | undefined;
    }
  | {
      type: "LOADING";
      id: string;
      value: boolean;
    };

export const ModalContext = createContext<
  [state: ModalState, dispatch: Dispatch<ModalActions>] | null
>(null);

const initialState: ModalState = {};

export function ModalContextProvider({ children }: { children: ReactElement }) {
  const [modalState, dispatch] = useReducer<Reducer<ModalState, ModalActions>>(
    modalReducer,
    initialState
  );
  return <ModalContext.Provider value={[modalState, dispatch]}>{children}</ModalContext.Provider>;
}

function modalReducer(state: ModalState, action: ModalActions): ModalState {
  switch (action.type) {
    case "ADD":
      return {
        ...state,
        [action.id]: {
          id: action.id,
          fn: action.fn,
          resolverFn: action.resolverFn,
          isOpen: true,
          isLoading: false,
          mustConfirm: undefined,
          onSubmit: action.onSubmit,
          onClose: action.onClose,
          props: action.props,
        },
      };
    case "CLOSE":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isOpen: false,
        },
      };
    case "SUBMIT":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isOpen: false,
        },
      };
    case "MUSTCONFIRM":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          mustConfirm: action.value,
        },
      };
    case "LOADING":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isLoading: action.value,
        },
      };

    case "REMOVE":
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      throw new Error("Received an unhandled action to modalReducer");
  }
}

/**
 *
 * @deprecated Does not play nicely with nested modals/dialogs. Use Dialog component instead.
 */
export function useModals() {
  const ctx = useContext(ModalContext);
  if (ctx === null) {
    throw new Error("ModalContext is null. Remember to wrap the app in a ModalContextProvider");
  }
  const [, dispatch] = ctx;

  async function showModal<ReturnType>(
    fn: RenderModalCallback<ReturnType>,
    options?: {
      resolverFn?: (value: ReturnType) => Promise<unknown>;
      props?: SupportedModalProps;
    }
  ): Promise<ReturnType> {
    const modalId = randomId();
    return new Promise<ReturnType>((resolve) => {
      // Add the modal to the list
      // dispatch to modal state with resolve callback
      dispatch({
        type: "ADD",
        id: modalId,
        fn, // render function
        resolverFn: options?.resolverFn as (value: unknown) => Promise<unknown>, // optional resolver function that needs to resolve before closing
        onClose: async () => {
          dispatch({
            type: "CLOSE",
            id: modalId,
          });
        },
        onSubmit: async (value: unknown) => {
          resolve(value as ReturnType);
          dispatch({
            type: "SUBMIT",
            id: modalId,
          });
        },
        props: options?.props,
      });
    });
  }

  /**
   * Confirmation Dialog.
   *
   * @param props Properties of the confirmation dialog
   * @param resolverFn Optional promise that will prevent closing (not dismissing) the window until resolved.
   * @returns boolean
   */
  async function showConfirm(
    props?: {
      title?: string;
      description?: string;
      Icon?: IconProp;
      variant?: "success" | "warning" | "alert";
    },
    resolverFn?: (value: boolean) => Promise<unknown>
  ) {
    return showModal<boolean>(
      ({ onClose, onSubmit, isLoading }) => {
        return (
          <ConfirmationModal
            title={props?.title}
            description={props?.description}
            Icon={props?.Icon}
            onClose={onClose}
            onSubmit={onSubmit}
            isLoading={isLoading}
            variant={props?.variant}
          />
        );
      },
      {
        resolverFn,
      }
    );
  }

  return { showModal, showConfirm };
}
