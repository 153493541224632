import { twMerge } from "tailwind-merge";

export type ButtonVariant = keyof typeof buttonClasses;
export type ButtonSizes = keyof typeof buttonSizes;

// Please don't export the button classes directly, use the getButtonClasses function
const baseButtonClass =
  "rounded-md text-white inline-flex items-center border font-medium shadow-sm transition-colors duration-200";

const baseDisabled = "cursor-default hover:none";

const buttonClasses = {
  tertiary: twMerge(
    baseButtonClass,
    "border-transparent bg-tertiary text-white hover:bg-tertiary-hover focus-visible:outline-orange-700"
  ),
  primary: twMerge(
    baseButtonClass,
    "border-transparent bg-primary text-white focus-visible:outline-hover sm:hover:bg-hover"
  ),
  secondary: twMerge(
    baseButtonClass,
    "bg-white text-gray-700 focus-visible:outline-hover sm:hover:border-hover sm:hover:text-hover"
  ),
  warning: twMerge(
    baseButtonClass,
    "border-transparent bg-orange-500 text-white focus-visible:outline-hover sm:hover:bg-orange-600"
  ),
};

// Padding and font-size
const buttonSizes = {
  small: "px-2 py-1 text-sm",
  medium: "px-4 py-2 text-base",
};

const disabledClasses: Record<ButtonVariant, string> = {
  tertiary: twMerge(baseButtonClass, baseDisabled, "bg-disabled text-white"),
  primary: twMerge(baseButtonClass, baseDisabled, "bg-disabled text-white"),
  secondary: twMerge(baseButtonClass, baseDisabled, "bg-white text-disabled"),
  warning: twMerge(baseButtonClass, baseDisabled, "bg-disabled text-white"),
};

const iconSizes: Record<ButtonSizes, string> = {
  medium: "h-6 w-6",
  small: "h-5 w-5",
};

const baseIconClass = ""; // Stroking is handled by parent element

export function getButtonIconClass({ size = "medium" }: { size?: ButtonSizes }) {
  return twMerge(baseIconClass, iconSizes[size]);
}

export function getButtonClasses({
  variant,
  disabled = false,
  className = "",
  size = "medium",
}: {
  variant?: ButtonVariant;
  disabled?: boolean;
  className?: string;
  size?: ButtonSizes;
}) {
  return twMerge(
    buttonSizes[size],
    variant && (disabled ? disabledClasses[variant] : buttonClasses[variant]),
    className
  );
}
