import { useTranslation } from "react-i18next";
import { PageLayout } from "~/lib/ui/page-layout";

export default function FormEditPage() {
  const { t } = useTranslation();
  return (
    <PageLayout title={t("forms:edit_form")}>
      <p>todo</p>
    </PageLayout>
  );
}
