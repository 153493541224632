import {
  CardUpdateDTO,
  ControlPanelProductsToTask,
  FormTemplateSimpleEntity,
  IGetTask200ResponseDataRepeatEnum,
  LabelEntity,
  Project,
  RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum,
  TaskCreateOrUpdateDTO,
  UpdateCardListing200Response,
  UpdateCardListingOperationRequest,
  User,
} from "@apacta/sdk";
import { CardReducerState, CardReducerViewState } from "~/lib/planning/card-reducer";
import { DateRange, TaskFormState } from "~/pages/planning/_cmp/types";
import { taskFormStateSchema } from "~/pages/planning/_cmp/crud/state/task-form-state";
import z from "zod";

export interface Card {
  id: string;
  readonly createdById?: string;
  readonly modifiedById?: string;
  readonly companyId?: string;
  userId?: string | null;
  taskId?: string | null;
  type?: CardTypeEnum;
  listIndex: number | null;
  date?: Date | null;
  startTime?: Date | null;
  estimate?: number | null;
  repeat?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum;
  interval?: number;
  endTime?: Date | null;
  readonly created?: Date;
  readonly modified?: Date;
  readonly deleted?: Date;
  status?: TaskStatus;
}

export enum CardTypeEnum {
  Task = "task",
}

export type CardRemoveDetails = {
  originalCard: Card;
  from: Array<string>;
};

export type TaskStatus = z.infer<typeof taskFormStateSchema.status>;

//FormTemplateSimpleEntity

export interface Task {
  id: string;
  readonly createdById?: string;
  readonly modifiedById?: string;
  readonly companyId?: string;
  projectId?: string | null;
  project?: Project | null;
  name: string;
  labels?: Array<LabelEntity>;
  labelIds?: Array<string>;
  description?: string | null;
  readonly created?: Date;
  readonly modified?: Date;
  readonly deleted?: Date;
  repeat?: IGetTask200ResponseDataRepeatEnum;
  endTime?: Date;
  interval?: number;
  products?: Array<ControlPanelProductsToTask>;
  formTemplates?: Array<Partial<FormTemplateSimpleEntity>> | null;
  formTemplateIds?: Array<string> | null;
}

export type CardUpdateAction = {
  cardId: string;
  card: Partial<Card>;
  task: Partial<Task>;
  useLastIndex?: boolean;
};

export type CardPasteAction = {
  userId?: string | null;
  index?: number | null;
  date?: Date | null;
};

export type PlanningContextView = "day" | "week" | "fortnight" | "month";

export const PlanningContextViewLengths = {
  day: 1,
  week: 7,
  fortnight: 14,
  month: null,
};

type StateContext = {
  dateRange?: DateRange;
  selectedDate: Date;
  view: PlanningContextView;
  viewDates: Array<Date>;
  isLoadingCards: boolean;
  isLoadingUsers: boolean;
  cardState: CardReducerState;
  cardStateViews: CardReducerViewState;
  users: Array<User>;
  selectedCard?: Card;
  setSelectedCard: (card: Card | undefined) => void;
  showDeleteDialog: (card?: Card) => void;
};

type ModalContext = {
  modalCreate: (args?: { userId?: string | null; index?: number; date?: Date | null }) => void;
};

type CardContext = {
  cardGet: ({ cardId }: { cardId: string }) => Card | undefined;
  cardGetTask: ({ card }: { card: Card }) => Task | undefined;
  cardGetUser: ({ card }: { card: Card }) => User | undefined;
  cardCopy: ({ card }: { card: Card }) => void;
  cardPaste: ({ userId, index, date }: CardPasteAction) => void;
  cardCanPaste: boolean;
  updateCard: (
    card: CardUpdateDTO,
    task: TaskCreateOrUpdateDTO | Partial<TaskCreateOrUpdateDTO>,
    showToast?: boolean
  ) => void;
};

export type Context = StateContext & ModalContext & CardContext;

/**
 * Submit args
 * @param cards - the cards to submit
 * @param task - the task to submit
 * @param deleteExistingCards - [optional] if true, trigger creation flow instead of update flow, deleting all existing cards before recreating them
 */
type CreateCardAndTaskArgs = {
  type: "CREATE";
  cards: Array<Card>;
  tasks: Array<Task>;
};

type UpdateCardAndTaskArgs = {
  type: "UPDATE";
  card: Partial<Card>;
  task: Partial<Task>;
  useLastIndex?: boolean;
};

export type CardAndTaskMutationArgs = (CreateCardAndTaskArgs | UpdateCardAndTaskArgs) & {
  forceRefresh?: boolean;
  deleteExistingCards?: boolean;
};

export type UseCardsProps = {
  dateRange?: DateRange;
};

export type CardAction = CreateAction | UpdateAction | DeleteAction;

export type UseCards = {
  cards: Record<string, Card>;
  tasks: Record<string, Task>;
  cardCreate: ({
    cards,
    task,
    deleteExistingCards,
    includeWeekends,
    products,
    formTemplateIds,
    status,
  }: {
    cards: Array<Partial<Card>>;
    task: Partial<Task>;
    deleteExistingCards?: boolean;
    includeWeekends?: boolean;
    products?: Array<ControlPanelProductsToTask>;
    formTemplateIds?: Array<string>;
    status: TaskStatus;
  }) => Promise<{ cards: Array<Card>; tasks: Array<Task> }>;
  cardUpdate: (
    args: UpdateCardListingOperationRequest & { showToast?: boolean }
  ) => Promise<UpdateCardListing200Response>;
  cardRemove: ({
    originalCard,
    from,
  }: {
    originalCard: Card;
    from: Array<string>;
  }) => Promise<void>;
  cardRefetch: () => void;
  isFetching: boolean;
  isFetched: boolean;
  cardAction: (args: CardAction) => Promise<CardAndTaskMutationArgs | void>;
};

export type ActionData = {
  selectedLabel: LabelEntity | null;
  selectedEmployee?: string | null;
  selectedIndex?: number;
  deleteExisting?: boolean;
  taskId?: string | null;
};

export type CreateAction = {
  action: "CREATE";
  formState: TaskFormState;
  data: ActionData;
};

export type UpdateAction = {
  action: "UPDATE";
  formState: TaskFormState;
  card: Card;
  data: ActionData;
};

export type DeleteAction = {
  action: "DELETE";
  formState: TaskFormState;
};

export type StartTime = {
  hour: number | null;
  minute: number | null;
};
