import { CSSProperties } from "react";
import { useDragLayer, XYCoord } from "react-dnd";
import { OrderLinesBuilderTableRow } from "~/lib/ui/order-lines/table/order-lines-builder-table-row";

export const OrderLinesBuilderTableDragLayer = () => {
  const layerStyles = (width: number): CSSProperties => {
    return {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: 0,
      top: 0,
      width: `${width}px`,
      height: "100%",
    };
  };

  function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    isSnapToGrid: boolean
  ) {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }

    let { x, y } = currentOffset;

    if (isSnapToGrid) {
      x -= initialOffset.x;
      y -= initialOffset.y;
      x += initialOffset.x;
      y += initialOffset.y;
    }

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles(item.width)}>
      <div style={getItemStyles(initialOffset, currentOffset, false)}>
        <OrderLinesBuilderTableRow {...item} />
      </div>
    </div>
  );
};
