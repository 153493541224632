import * as SwitchPrimitive from "@radix-ui/react-switch";
import { useId } from "react";
import { twMerge } from "tailwind-merge";
import { Label } from "./label";

export default function Switch({
  label,
  defaultChecked,
  checked,
  disabled = false,
  onCheckedChange,
  className,
  controlled = false,
  required,
  error,
}: {
  label?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
  controlled?: boolean;
  required?: boolean;
  error?: string;
}) {
  const id = useId();

  return (
    <div className={twMerge("flex flex-col items-start justify-center", className)}>
      {label && (
        <Label className="" htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      <SwitchPrimitive.Root
        className={twMerge(
          "relative h-6 w-10 rounded-full bg-secondary fill-red-50 hover:bg-hover data-[state=checked]:bg-tertiary",

          disabled && "bg-gray-500 data-[state=checked]:bg-gray-300"
        )}
        id={id}
        disabled={disabled}
        defaultChecked={controlled ? undefined : defaultChecked}
        checked={controlled ? checked : undefined}
        onCheckedChange={onCheckedChange}
        onClick={(e) => e.stopPropagation()}
      >
        <SwitchPrimitive.Thumb
          className={twMerge(
            "block h-4 w-4 rounded-full bg-white transition-transform duration-100 ease-in-out data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-1"
          )}
        />
      </SwitchPrimitive.Root>
      {error && <p className="mt-2 text-left text-sm text-red-600">{error}</p>}
    </div>
  );
}
