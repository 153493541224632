import { twMerge } from "tailwind-merge";
import { Invoice } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

const postedStyle = "bg-green-50 text-green-700";
const draftStyle = "bg-yellow-50 text-yellow-700";
const unknownStyle = "bg-gray-50 text-gray-700";

export default function InvoiceStatus({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  const getStyle = (): string => {
    if (invoice.erpId) {
      return postedStyle;
    } else if (invoice.isDraft) {
      return draftStyle;
    } else {
      return unknownStyle;
    }
  };

  return (
    <span
      className={twMerge(
        "inline-flex items-center rounded-md border px-2 py-1 text-xs font-medium",
        getStyle()
      )}
    >
      {invoice.isDraft ? t("invoices:status.draft") : t("invoices:status.posted")}
    </span>
  );
}
