// Purpose: Easy to use upload button

import { ChangeEvent, useId, useState } from "react";
import { Spinner } from "../spinner";
import { ButtonVariant, getButtonClasses } from "./shared/button.shared";

export function UploadButton({
  onSelected,
  variant,
  children,
  multiple,
  disabled = false,
  accept,
}: {
  onSelected: (files: FileList) => Promise<void> | void;
  variant: ButtonVariant;
  children: string;
  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
}) {
  const uploadId = useId();
  const [loading, setLoading] = useState<boolean>(false);
  // TODO: createObjectURL and cleanup for every file, then return the strings via onSelected

  async function handleSelectFile(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setLoading(true);
    try {
      await onSelected(e.target.files);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <label
        role="button"
        tabIndex={0}
        htmlFor={uploadId}
        className={getButtonClasses({ variant, disabled })}
      >
        <>
          {loading ? <Spinner className="mr-4 h-5 w-5" /> : null}
          {children}
        </>
      </label>
      <input
        multiple={multiple}
        id={uploadId}
        className="sr-only"
        type="file"
        onChange={handleSelectFile}
        accept={accept}
      />
    </>
  );
}
