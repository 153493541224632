import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  TableOptions,
  useReactTable as useReactTableLib,
} from "@tanstack/react-table";
import { useDataTableState } from "./use-data-table-state";
import { useMemo } from "react";
import { merge } from "lodash";
import { PaginationDetails } from "@apacta/sdk";

const createDefaultData = (pageSize: number) => {
  return Array(pageSize).fill({});
};

type UseDataTableArgs<T extends RowData> = {
  mode?: "client" | "server";
  isLoading?: boolean;
  skeletonRows?: number;
  data?: TableOptions<T>["data"];
  columns: TableOptions<T>["columns"];
  /** Used to work around pagination issues caused by backend */
  backendPagination?: PaginationDetails;
  pageCount?: TableOptions<T>["pageCount"];
  // There was pageSize here, but it's cleaner to only manage the tableState, inside of tableState.
  tableState?: ReturnType<typeof useDataTableState>;
};
export const useDataTable = <T extends RowData>(
  args: UseDataTableArgs<T>,
  overrideTemplate?: Partial<TableOptions<T>>
) => {
  if (args.mode === undefined) args.mode = "server";

  const data = useMemo(() => {
    if (args.isLoading) {
      return createDefaultData(
        args.skeletonRows ?? args.tableState?.state.pagination.pageSize ?? 20
      );
    }
    if (args.data === undefined) return [];
    return args.data;
  }, [args.data, args.isLoading]);

  const tableOptions = useMemo(() => {
    // Handle Backend Pagination quirks (like missing pageCount)
    const pageCount = (() => {
      if (args.pageCount) return args.pageCount; // If overriden, use that
      if (args.backendPagination) {
        const bp = args.backendPagination;
        if (bp?.pageCount) return bp?.pageCount; // If backend has pageCount, use that
        if (bp?.pageCount === undefined && bp?.currentPage !== undefined && bp?.hasNextPage) {
          return bp?.currentPage + 1; // No pageCount, but we know we have an extra page
        }
      }
    })();
    let opts: TableOptions<T> = {
      data,
      pageCount: pageCount,
      columns: args.columns,
      getCoreRowModel: getCoreRowModel(),
      enableRowSelection: false,
      defaultColumn: {
        enableSorting: false,
        enableColumnFilter: false,
      },
      enableExpanding: false,
      enableGlobalFilter: false,
      meta: {
        isLoading: args.isLoading,
      },
      state: {
        pagination: args.tableState?.state.pagination,
        sorting: args.tableState?.state.sorting,
        columnVisibility: args.tableState?.state.columnVisibility,
        globalFilter: args.tableState?.state.search,
        expanded: args.tableState?.state.expanded,
      },
      onPaginationChange: args.tableState?.handleTableState("pagination"),
      onSortingChange: args.tableState?.handleTableState("sorting"),
      onGlobalFilterChange: args.tableState?.handleTableState("search"),
      onExpandedChange: args.tableState?.handleTableState("expanded"),
      onColumnVisibilityChange: args.tableState?.handleTableState("columnVisibility"),
    };

    if (args.mode === "client") {
      opts.manualSorting = false;
      opts.manualFiltering = false;
      opts.manualPagination = false;
      opts.getPaginationRowModel = getPaginationRowModel();
      opts.getSortedRowModel = getSortedRowModel();
      opts.getFilteredRowModel = getFilteredRowModel();
    }

    if (args.mode === "server") {
      opts.manualSorting = true;
      opts.manualFiltering = true;
      opts.manualPagination = true;
      opts.manualExpanding = true;
    }
    if (overrideTemplate) {
      opts = merge(opts, overrideTemplate);
    }
    return opts;
  }, [args, overrideTemplate]);

  return useReactTableLib<T>(tableOptions);
};
