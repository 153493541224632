import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { usePagination } from "~/lib/utils/hooks";
import IconButton from "../../lib/ui/buttons/icon-button";
import { EntityTable } from "~/lib/entity-ui";
import { IListProjectsDirectionEnum } from "@apacta/sdk";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useMount } from "~/lib/lifecycle-helpers";
import { formatDate } from "~/lib/utils/date";
import { linkToProject } from "~/lib/utils";

export default function ExpensesPage() {
  const api = useAPI();
  const [pagination] = usePagination();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  useMount(() => {
    setIsMounted(true);
  });

  const handleCreateExpense = (): void => {
    console.log("Create expense");
  };

  const handleDeleteExpense = async (id: string) => {
    console.log("Delete expense", id);
  };

  // TODO: .getExpenses() does not exist yet, use .getInvoices() for now

  return (
    <PageLayout
      title={t("navigation:expenses.title")}
      renderActions={() => (
        <IconButton variant="tertiary" className="print:hidden" onClick={handleCreateExpense}>
          {t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("expenses:expense", { count: 1 }).toLocaleLowerCase() },
          })}
        </IconButton>
      )}
    >
      <EntityTable
        cacheKey={["expenses", isMounted.toString()]}
        dataFn={() =>
          api.expensesList({
            page: pagination.page,
            sort: pagination.sort,
            q: pagination.q,
            direction: pagination.direction as IListProjectsDirectionEnum,
          })
        }
        fields={{
          date: {
            label: t("expenses:delivery_date"),
            href: (row) => `./${row.id}`,
            tdClass: "max-w-sm",
            renderColumn: (row) => (
              <span className="font-semibold">
                {formatDate(row.deliveryDate || null, i18n.resolvedLanguage, {
                  shortDate: true,
                  emptyStringOnNull: true,
                })}
              </span>
            ),
            sortBy: "date",
          },
          invoiceNo: {
            label: t("expenses:invoice_no"),
            href: (row) => `./${row.id}`,
            renderColumn: (row) => <span>{row.supplierInvoiceNumber}</span>,
          },
          projectName: {
            label: t("common:project", { count: 1 }),
            href: (row) => (row.project ? linkToProject(row.project.id) : undefined),
            renderColumn: (row) =>
              row.project?.name ? (
                <span>{row.project?.name}</span>
              ) : (
                <span className="text-gray-400">{t("common:not_available")}</span>
              ),
          },
          vendorName: {
            label: t("expenses:vendor", { count: 1 }),
            renderColumn: (row) =>
              row.vendor?.name ? (
                <span>{row.vendor?.name}</span>
              ) : (
                <span className="text-gray-400">{t("common:not_available")}</span>
              ),
          },
          reference: {
            label: t("common:reference", { count: 1 }),
            renderColumn: (row) =>
              row.reference ? (
                <span>{row.reference}</span>
              ) : (
                <span className="text-gray-400">{t("common:not_available")}</span>
              ),
          },
          emailed: {
            label: t("expenses:emailed"),
            thClass: "text-center",
            renderColumn: (row) => (
              <div className="flex justify-center">{row.sentToEmail.toString()}</div>
            ),
          },
        }}
        actions={[
          {
            icon: TrashIcon,
            label: t("common:delete"),
            mustConfirm: true,
            description: t("customers:modal.delete.description"),
            onExecute: (row) => handleDeleteExpense(row.id),
          },
        ]}
      />
    </PageLayout>
  );
}
