import { UpdateUserIntegrationSettingRequestIntegrationSettingsInner, User } from "@apacta/sdk";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { IntegrationSettingInput } from "~/pages/employees/_cmp/integration-setting-input";

export function IntegrationTypes({
  user,
  onChange,
}: {
  user: User;
  onChange: (value: Array<UpdateUserIntegrationSettingRequestIntegrationSettingsInner>) => void;
}) {
  const [isSettingModified, setIsSettingModified] = useState(false);
  const changedUserIntegrationSettings: Array<UpdateUserIntegrationSettingRequestIntegrationSettingsInner> =
    [];

  const [userIntegrationSetting, setUserIntegrationSetting] = useState({
    integrationSettingId: "" as string,
    value: "" as string,
  });
  const api = useAPI();
  const { data, isLoading } = useQuery({
    queryKey: ["users", user.id],
    queryFn: () => api.getIntegrationTypes({ userId: user.id as string }),
  });
  const integrationTypes = data?.data || [];

  const updateSettings = (uis: UpdateUserIntegrationSettingRequestIntegrationSettingsInner) => {
    const arrIndex = changedUserIntegrationSettings.findIndex(
      (item) => item.integrationSettingId === uis.integrationSettingId
    );
    if (arrIndex !== -1) {
      changedUserIntegrationSettings[arrIndex].value = uis.value;
    } else {
      changedUserIntegrationSettings.push(uis);
    }
  };

  useEffect(() => {
    updateSettings(userIntegrationSetting);
    onChange?.(changedUserIntegrationSettings);
  }, [userIntegrationSetting]);

  return (
    <>
      <div className="flex h-full flex-col gap-8">
        <div className="flex flex-col gap-8 md:flex-row">
          <div className="flex-1">
            {integrationTypes
              .filter((integrationType) => integrationType.identifier !== "erp")
              .map((integrationType) => (
                <div key={integrationType.id}>
                  <div className="heading-page-title mb-2 text-gray-500">
                    {integrationType.name}
                  </div>
                  <div className="text-sm-left heading-page-title font-light">
                    {integrationType.description}
                  </div>
                  {integrationType.integrations?.map((integration) => (
                    <div
                      key={integration.id}
                      className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row"
                    >
                      <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                        {integration.integrationSettings !== undefined && (
                          <>
                            <div className="flex flex-col">
                              <div className="flex-1 text-lg">{integration.name}</div>
                              <div className="text-sm-left text-sm font-light">
                                {integration.description}
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                              {integration.integrationSettings?.map((integrationSetting) => (
                                <div key={integrationSetting.id}>
                                  <IntegrationSettingInput
                                    onChange={(value: string | null) => {
                                      setUserIntegrationSetting({
                                        integrationSettingId: integrationSetting.id as string,
                                        value: value as string,
                                      });
                                      setIsSettingModified(true);
                                    }}
                                    initialIntegrationValue={
                                      integrationSetting?.users?.[0]?.joinData?.value
                                    }
                                    integrationSetting={integrationSetting}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
