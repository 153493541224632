import { IGetTasksForProjectDirectionEnum } from "@apacta/sdk";
import { t } from "i18next";
import { useParams } from "react-router";
import { useAPI } from "~/lib/api";
import { EntityTable } from "~/lib/entity-ui";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { usePagination } from "~/lib/utils/hooks";

import { CACHE_PROJECTS } from "..";
import { usePlanning } from "~/lib/planning";

export default function ProjectTasksPage() {
  const { id } = useParams<{ id: string }>();
  const api = useAPI();
  const projectId = id as string;
  const [pagination] = usePagination();
  const { modalCreate } = usePlanning();

  return (
    <>
      <TabHeading>{t("common:task", { count: 2 })}</TabHeading>

      <EntityTable
        dataFn={() =>
          api.iGetTasksForProject({
            projectId,
            direction: pagination.direction as IGetTasksForProjectDirectionEnum,
            page: pagination.page,
            sort: pagination.sort,
            q: pagination.q,
          })
        }
        cacheKey={[CACHE_PROJECTS, id as string, "tasks"]}
        fields={{
          name: {
            label: t("common:name"),
            renderColumn: (row) => row.name,
          },
          description: {
            label: t("common:description"),
            renderColumn: (row) => row.description,
          },
        }}
      />
    </>
  );
}
