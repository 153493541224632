import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_MAP_ID } from "~/lib/maps";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { SkeletonLoader } from "~/lib/ui/skeleton";

export type LocationType = {
  longitude: number;
  latitude: number;
};

export function LocationView(props: {
  location: LocationType;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
}) {
  const coords = props.location;

  const hasLocation = coords?.latitude && coords.longitude;
  if (!props.loading && !hasLocation) return null;

  return (
    <div>
      <div className="relative mb-4 h-[150px] w-full bg-green-50">
        {props.loading ? (
          <SkeletonLoader template="box" className="h-full w-full" />
        ) : (
          <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <Map
              center={{ lat: coords.latitude, lng: coords.longitude }}
              zoom={15}
              fullscreenControl={false}
              mapId={GOOGLE_MAPS_MAP_ID}
            >
              <AdvancedMarker position={{ lat: coords.latitude, lng: coords.longitude }} />
            </Map>
          </APIProvider>
        )}
      </div>
    </div>
  );
}
