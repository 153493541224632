import Calendar from "./calendar";
import * as Popover from "@radix-ui/react-popover";
import { cloneElement, Fragment, ReactElement, Suspense, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Boundary } from "../boundary";

export default function DatePicker({
  value,
  onChange,
  children,
}: {
  value?: Date;
  onChange?: (d: Date) => void;
  children: ReactElement;
}) {
  const [selectedDate, setSelectedDate] = useState<Date>(value || new Date());
  const [open, setOpen] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleSelectDate = (v: Date) => {
    setSelectedDate(v);
    onChange?.(v);
    setOpen(false);
    setMobileOpen(false);
  };

  useEffect(() => {
    if (value !== selectedDate) {
      setSelectedDate(value || new Date());
    }
  }, [value]);

  return (
    <>
      <div className="sm:hidden">
        {cloneElement(children, {
          onClick: () => setMobileOpen(true),
        })}
        <Transition.Root show={mobileOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20" onClose={setMobileOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-20 overflow-y-auto sm:hidden">
              <div className="flex min-h-full w-full items-center justify-center px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                    <div className="sm:hidden">
                      <Boundary>
                        <Calendar
                          defaultDate={selectedDate}
                          onSelect={(d: Date) => handleSelectDate(d)}
                        />
                      </Boundary>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <div className="hidden sm:block">
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Anchor>
            <div>{cloneElement(children, { onClick: () => setOpen(true) })}</div>
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content>
              <div className="hidden sm:block">
                <Suspense>
                  <Calendar
                    defaultDate={selectedDate}
                    onSelect={(d: Date) => handleSelectDate(d)}
                  />
                </Suspense>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </>
  );
}
