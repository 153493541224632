import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { NumberInput } from "~/lib/ui/form-elements";
import { z } from "zod";
import { getIcon, Icon } from "~/lib/ui";
import { Suspense, useState } from "react";
import { useDebounce } from "~/lib/debounce/use-debounce";

export function ManageSeatsDialog({
  availableSeats,
  usedSeats,
  subscriptionId,
  onSeatsUpdated,
}: {
  availableSeats: number;
  usedSeats: number;
  subscriptionId: string;
  onSeatsUpdated: (seats?: number) => void;
}) {
  const [localSeats, setLocalSeats] = useState<number>(availableSeats);

  const { t } = useTranslation();
  const api = useAPI();
  // Field inputs
  const { isValid, getValue, setValues } = useFormState({
    schema: {
      availableSeats: z.number(),
    },
    initialValues: {
      availableSeats: availableSeats,
    },
  });

  useDebounce(
    () => {
      setValues({ availableSeats: localSeats });
    },
    [localSeats],
    500
  );

  const showSeatsWarningMessage = getValue("availableSeats") < usedSeats;

  const mutation = useMutation({
    mutationFn: () =>
      api.editSubscription({
        subscriptionId: subscriptionId,
        editSubscriptionRequest: { seats: getValue("availableSeats") },
      }),
    onSuccess: (data) => {
      if (!data.data.id) {
        return;
      }
      onSeatsUpdated(getValue("availableSeats"));
    },
  });

  async function handleSubmit(): Promise<void> {
    if (!isValid) {
      return;
    }
    await mutation.mutateAsync();
  }

  return (
    <Suspense>
      <div className="mb-6">
        <DialogHeader title={t("users:adjust_seats")} Icon={getIcon("employee")} />
        <div>
          {showSeatsWarningMessage && (
            <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-red-500 p-1.5 text-white">
                  <Icon name="warningTriangle" className="h-6 w-6" />
                </div>
                <div className="text-sm">{t("users:seats_modal.warning_message")}</div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
                <NumberInput
                  defaultValue={availableSeats}
                  onChange={(e) => setLocalSeats(Number(e.currentTarget.value))}
                  label={t("users:current_seats")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          {usedSeats} {t("users:seats_modal.used_seats")}
        </div>
      </div>
      <DialogFooter
        onClose={onSeatsUpdated}
        primary={{
          onClick: handleSubmit,
          label: t("common:save"),
          disabled: !isValid || showSeatsWarningMessage,
          loading: mutation.isPending,
        }}
      />
    </Suspense>
  );
}
