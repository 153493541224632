import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { roundToDecimal } from "~/lib/utils/number";
import { twMerge } from "tailwind-merge";
import { NumberInput } from "~/lib/ui/form-elements";

export default function PriceSection({
  isFixedPrice,
  isOffer,
  productsTotalCostPrice = 0,
  onProductsTotalCostPriceChange,
  workingHoursTotalCostPrice = 0,
  onWorkingHoursTotalCostPriceChange,
  totalSalesPrice = 0,
  onTotalSalesPriceChange,
  invoiceDiscountPercent = 0,
  onInvoiceDiscountPercentChange,
  className,
}: {
  // Refactoring so we can re-use this.
  isFixedPrice: boolean;
  isOffer: boolean;
  onIsFixedPriceChange: (value: boolean) => void;
  productsTotalCostPrice?: number;
  onProductsTotalCostPriceChange: (value: number) => void;
  workingHoursTotalCostPrice?: number;
  onWorkingHoursTotalCostPriceChange: (value: number) => void;
  totalSalesPrice?: number;
  onTotalSalesPriceChange: (value: number) => void;
  invoiceDiscountPercent?: number;
  onInvoiceDiscountPercentChange?: (value: number) => void;
  className?: string;
}) {
  const { t } = useTranslation();
  const contributionRatioRef = useRef<HTMLInputElement>(null);

  /**
   * See https://www.e-conomic.dk/regnskabsprogram/ordbog/daekningsbidrag
   */

  const getCostPrice = (): number => {
    return productsTotalCostPrice + workingHoursTotalCostPrice;
  };

  const calculateContributionRatio = (cost: number, sales: number) => {
    if (sales === 0) return 0; // avoid division by zero
    const contributionMarginCalculation = sales - cost; // dækningsbidrag
    return (contributionMarginCalculation / sales) * 100;
  };

  const calculateSalesPriceNeeded = (cost: number, coverage: number) => {
    return Math.abs(cost / (1 - coverage / 100));
  };

  const [contributionRatio, setContributionRatio] = useState<number>(
    roundToDecimal(calculateContributionRatio(getCostPrice(), totalSalesPrice || 0), 3)
  );

  const handleContributionChange = (value: number) => {
    // When contribution-rate is focused, we shouldn't update the internal value
    // But instead calculate the sales price and set that and let that set the contribution rate
    //
    setContributionRatio(roundToDecimal(value, 3));
    onTotalSalesPriceChange(roundToDecimal(calculateSalesPriceNeeded(getCostPrice(), value), 2));
  };

  // Update contribution-ratio when variables changes (unless it's focused)
  useEffect(() => {
    if (contributionRatioRef.current && contributionRatioRef.current === document.activeElement)
      return;
    setContributionRatio(
      roundToDecimal(calculateContributionRatio(getCostPrice(), totalSalesPrice || 0), 3)
    );
  });

  const showZeroCostWarningProduct = productsTotalCostPrice === 0 && totalSalesPrice > 0;

  const showZeroCostWarningHours = workingHoursTotalCostPrice === 0 && totalSalesPrice > 0;

  return (
    <div className={twMerge("flex flex-col", className)}>
      <div className="flex flex-col">
        {isFixedPrice ? (
          <div className="grid-cols-auto grid gap-4 md:grid-cols-2">
            <NumberInput
              onChangeValue={onProductsTotalCostPriceChange}
              value={productsTotalCostPrice === 0 ? "" : productsTotalCostPrice}
              placeholder="0"
              label={t("projects:products_total_cost_price")}
              className={twMerge("text-right", showZeroCostWarningProduct && "border-warning")}
            />
            <NumberInput
              onChangeValue={onWorkingHoursTotalCostPriceChange}
              value={workingHoursTotalCostPrice === 0 ? "" : workingHoursTotalCostPrice}
              placeholder="0"
              label={t("projects:working_hours_total_cost_price")}
              disabled={!isFixedPrice}
              className={twMerge("text-right", showZeroCostWarningHours && "border-warning")}
            />
            <NumberInput
              onChangeValue={onTotalSalesPriceChange}
              value={totalSalesPrice === 0 ? "" : totalSalesPrice}
              placeholder="0"
              label={t("projects:total_sales_price")}
              disabled={!isFixedPrice || isOffer}
              className="text-right"
            />
            <NumberInput
              label={t("common:contribution_ratio")}
              value={contributionRatio === 0 ? "" : contributionRatio}
              placeholder="0"
              disabled={!isFixedPrice || isOffer}
              onChangeValue={handleContributionChange}
              className="text-right"
              ref={contributionRatioRef}
            />
          </div>
        ) : (
          <>
            {onInvoiceDiscountPercentChange && (
              <div className="basis-1/3 sm:pr-4">
                <NumberInput
                  onChangeValue={onInvoiceDiscountPercentChange}
                  defaultValue={invoiceDiscountPercent}
                  label={t("projects:fixed_discount_rate")}
                  disabled={isFixedPrice || isOffer}
                  className="text-right"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
