import { Spinner } from "~/lib/ui/spinner";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "~/lib/utils/number";
import { Vendor, VendorProduct } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export default function VendorProductSection({
  searchQuery,
  selectedVendor,
  onSelect,
  showBuyingPrice = false,
  showSellingPrice = false,
  showGrossPrice = false,
}: {
  searchQuery: string;
  selectedVendor?: Vendor;
  onSelect: (item: VendorProduct) => void;
  showBuyingPrice?: boolean;
  showSellingPrice?: boolean;
  showGrossPrice?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const vendorProductsQuery = useQuery({
    queryKey: ["vendorProductsList", searchQuery, selectedVendor],
    queryFn: () =>
      api.vendorProductsList({
        q: searchQuery,
        vendorId: selectedVendor?.id,
      }),
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  if (vendorProductsQuery.isFetching) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!vendorProductsQuery.isFetching && !vendorProductsQuery.data?.data.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2">
        <MagnifyingGlassIcon className="h-16 w-16" />
        <span>
          {t("common:no_entity_found", {
            entity: t("products:product_type.vendor_product", {
              count: 2,
            }).toLowerCase(),
          })}
        </span>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2 divide-y pr-1">
      {vendorProductsQuery.data?.data.map((vendorProduct, idx) => (
        <div key={`${vendorProduct.id}`} onClick={() => onSelect(vendorProduct)} className="pt-2">
          <div className="flex cursor-pointer items-center justify-between rounded-lg hover:bg-shade-100">
            <div className="flex flex-grow flex-col gap-2 p-2">
              <span>{vendorProduct.name}</span>
              <div className="flex w-full justify-between text-sm text-shade-400">
                {showBuyingPrice && (
                  <div className="w-1/3 text-left">
                    {t("common:cost_price")}:{" "}
                    {vendorProduct.buyingPrice
                      ? formatCurrency(vendorProduct.buyingPrice ?? 0)
                      : t("common:not_available")}
                  </div>
                )}

                {showSellingPrice && (
                  <div className="w-1/3 text-center">
                    {t("common:sales_price")}:{" "}
                    {vendorProduct.sellingPrice
                      ? formatCurrency(vendorProduct.sellingPrice ?? 0)
                      : t("common:not_available")}
                  </div>
                )}

                {showGrossPrice && (
                  <div className="w-1/3 text-right">
                    {t("common:gross_price")}:{" "}
                    {vendorProduct.price
                      ? formatCurrency(vendorProduct.price ?? 0)
                      : t("common:not_available")}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center px-2">
              <img src={vendorProduct.vendor?.imageUrl} className="h-6 w-auto" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
