import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { MediaPreview } from "~/lib/ui/media/";

type PreviewFile = {
  url: string;
  type: string;
  name: string;
};

export function PreviewFiles({ files, className }: { files?: FileList; className?: string }) {
  const [previews, setPreviews] = useState<Array<PreviewFile>>([]);

  useEffect(() => {
    if (!files) {
      return;
    }
    const arr = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      type: file.type,
      name: file.name,
    }));

    setPreviews((prev) => {
      for (const f of prev) {
        URL.revokeObjectURL(f.url);
      }
      return arr;
    });
    return () => {
      for (const f of arr) {
        URL.revokeObjectURL(f.url);
      }
    };
  }, [files]);

  return (
    <div className={twMerge("flex flex-row flex-wrap gap-4", className)}>
      {previews.map((file) => (
        <MediaPreview key={file.url} href={file.url} mimeType={file.type} filename={file.name} />
      ))}
    </div>
  );
}
