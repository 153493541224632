import { Invoice } from "@apacta/sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useMe } from "~/lib/auth/use-me";
import { useFormState } from "~/lib/form-state";
import { getIcon, Icon, LabelInput } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import DialogHeader from "~/lib/ui/dialog/dialog-header";
import { TextArea } from "~/lib/ui/form-elements";
import { CACHE_INVOICES } from "..";

export function InvoiceSendDialog({
  onClose,
  pendingSave,
  invoice,
}: {
  onClose: () => void;
  /** If a save is pending, we show message and can call it from here */
  pendingSave?: () => Promise<void>;
  invoice: Invoice;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const queryClient = useQueryClient();
  const formstate = useFormState({
    schema: {
      recipientEmail: z.string().email(),
      subject: z.string(),
      body: z.string(),
    },
    initialValues: {
      recipientEmail: invoice?.contact?.email ?? invoice?.project?.contact?.email ?? "",
      subject: t("invoices:invoice_email_subject", {
        defaultValue: "Invoice from {{companyName}}",
        replace: { companyName: me.company.name },
      }),
      body: t("invoices:invoice_email_body", {
        defaultValue:
          "Thanks for using {{companyName}}.\n\nEnclosed is invoice {{invoiceNumber}} of {{invoiceTotal}} DKK with VAT.\n\nIf you have any questions, you're always welcome to contact us.\n\nBest regards,\n{{companyName}}",
        replace: { companyName: me.company.name, invoiceTotal: invoice.grossPayment },
      }),
    },
  });

  async function handleSendInvoice() {
    const val = formstate.getValues();
    await api.iInvoiceSendEmail({
      invoiceId: invoice.id,
      recipientEmail: val.recipientEmail,
      body: val.body,
      subject: val.subject,
    });
    queryClient.invalidateQueries({
      queryKey: [CACHE_INVOICES, invoice.id],
    });
    onClose();
  }

  return (
    <div className="">
      <DialogHeader title={t("invoices:send_invoice")} Icon={getIcon("send")} />
      <div className="flex flex-col gap-4">
        {pendingSave && (
          <div className="flex gap-4">
            <div>
              <Icon name="warningTriangle" className="h-8 w-8 text-orange-600" />
            </div>
            <div className="whitespace-pre-line">{t("offers:send_offer_modal.description")}</div>
          </div>
        )}
        <div className="flex flex-col gap-4">
          <LabelInput
            required
            defaultValue={formstate.getValue("recipientEmail")}
            label={t("common:email")}
            onChange={(e) => formstate.onChange("recipientEmail", e.currentTarget.value)}
            type="email"
          />
          <LabelInput
            defaultValue={formstate.getValue("subject")}
            label={t("common:subject")}
            required
            onChange={(e) => formstate.onChange("recipientEmail", e.currentTarget.value)}
          />
          <TextArea
            initialValue={formstate.getValue("body")}
            onChange={(s) => formstate.onChange("body", s)}
            label={t("common:message")}
            fullHeight={true}
            className="h-36"
            required={true}
          />
        </div>
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          label: t("invoices:send_invoice"),
          onClick: handleSendInvoice,
          disabled: !formstate.isValid,
        }}
      />
    </div>
  );
}
