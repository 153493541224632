import { SelectionCombobox, getSelectedIds } from "./selection-combobox";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "..";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { ContactPerson } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import CreateEditContactPersonModal from "~/pages/customers/_cmp/customer-tabs/modal/create-contact-person-modal";

type Entity = ContactPerson; //shortened for readability
export function ContactPersonSelection<D extends Entity = Entity>(
  props: SelectionComboboxExtendable<D> & { customerId?: string }
) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const api = useAPI();
  const { t } = useTranslation();
  const vcRef = useRef<ValueChangeHandler>(); // value change ref

  // Array of ids to select. Undefined if searchquery is set
  const idParam = getSelectedIds({
    value: props.value,
    defaultValue: props.defaultValue,
    isSearchDialogOpen,
  });

  const uq = useQuery({
    queryKey: ["contact-persons", props.customerId, idParam, searchQuery],
    queryFn: () =>
      api.getContactPersonsList({
        contactId: props.customerId!,
        q: searchQuery,
        ids: idParam,
      }),
    enabled: !!props.customerId,
  });
  const items = uq.data?.data || [];

  const pendingSelection = useRef<string | undefined>();

  function handleCreated(userId?: string) {
    pendingSelection.current = userId;
    uq.refetch();
  }

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  return (
    <div>
      <SelectionCombobox<D>
        label={t("common:customer_contact", { count: 1 })}
        {...props}
        data={items as Array<D>}
        valueFn={(entity) => entity.id}
        placeholder={t("projects:customer_contact_placeholder")}
        valueChangeRef={vcRef}
        labelFn={(entity) => entity.name} // searching
        loading={uq.isFetching}
        onQueryChange={setSearchQuery}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
        onOpenChange={setIsSearchDialogOpen}
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        render={({ onClose }) => (
          <CreateEditContactPersonModal
            mode="create"
            customerId={props.customerId!}
            onSuccess={handleCreated}
            onClose={onClose}
            defaultContactPersonName={createQuery}
          />
        )}
      />
    </div>
  );
}
