import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createApiInstance } from "~/lib/api";
import { deleteCookie, restoreApiKey as getSessionCookie, saveCookie } from "./cookie";
import { FeaturesType, SessionContext } from "./session";
import { API_KEY_NAME } from "./config";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { GetMe200ResponseFeaturesEnum } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { LANGUAGE_STORE_KEY, LanguageDefinition, availableLanguagesList } from "../i18n/i18n";

// Note that ANY changes to the context state will result in a complete re-render of the application!
// - Try not to add anything unnecessary in here
export function SessionContextProvider({ children }: { children?: ReactNode }) {
  const { i18n } = useTranslation();
  const [apiKey, setApiKey] = useState<string | undefined>(getAPIKey());

  const sessionData = useRestoredSession(apiKey);

  // Configure i18next with the user's language
  useEffect(() => {
    if (!sessionData.me) {
      // Not logged in
      const lang = localStorage.getItem(LANGUAGE_STORE_KEY);
      if (lang) {
        i18n.changeLanguage(lang);
      }
    } else {
      // Logged in
      const langDef = availableLanguagesList.find(
        ([, l]) => l.id === sessionData.me.user.languageId
      );
      if (!langDef) return;
      i18n.changeLanguage(langDef[1].defaultLocale);
    }
  }, [sessionData.me?.user.languageId]);

  // Update API key persistence (cookie and local-storage)
  useEffect(() => {
    if (apiKey) {
      // Login
      saveCookie({ apiKey, scope: "unknown" });
      localStorage.setItem(API_KEY_NAME, apiKey);
    } else {
      // Logout
      deleteCookie();
      localStorage.removeItem(API_KEY_NAME);
    }
  }, [apiKey]);

  const loginWithAPIKey = useCallback(async (newAPIKey: string) => {
    setApiKey(newAPIKey);
  }, []);

  const logout = useCallback(() => {
    setApiKey(undefined);
  }, []);

  const setLanguageM = useMutation({
    mutationFn: ({ langId }: { langId: string }) =>
      sessionData.api.editUser({
        userId: sessionData.me?.user.id as string,
        editUserRequest: {
          languageId: langId,
        },
      }),
  });

  const setLanguage = useCallback(async (language: LanguageDefinition) => {
    try {
      await i18n.changeLanguage(language.defaultLocale); // Tell i18next
      await setLanguageM.mutateAsync({ langId: language.id }); // Tell backend
      // Store in local storage
      localStorage.setItem(LANGUAGE_STORE_KEY, language.defaultLocale);
    } catch (err) {
      // Ignore if it fails
    }
  }, []);

  return (
    <SessionContext.Provider value={{ ...sessionData, logout, setLanguage, loginWithAPIKey }}>
      {children}
    </SessionContext.Provider>
  );
}

function getAPIKey(): string | undefined {
  // 1. Check if we have an API key in the URL
  const params = new URL(document.location.toString()).searchParams;
  const apiKey = params.get("api_key");
  if (apiKey) {
    return apiKey;
  }
  // 2. Check if we have an API key in the cookie
  const cookie = getSessionCookie();
  if (cookie?.apiKey) {
    return cookie.apiKey;
  }

  // 3. Check if we have an API key in the local storage
  const localStoreAPIKey = localStorage.getItem(API_KEY_NAME);
  if (localStoreAPIKey) {
    return localStoreAPIKey;
  }

  // No API key found
  return undefined;
}

function useRestoredSession(apiKey?: string) {
  const api = useMemo(() => createApiInstance(apiKey), [apiKey]);

  // A way for us to skip hammering the API when we don't have a key
  async function fetchSessionData() {
    if (!apiKey) {
      return null;
    }
    return Promise.all([api.getMe(), api.getFeatures()]);
  }

  const q = useSuspenseQuery({
    queryKey: ["session", apiKey],
    queryFn: () => fetchSessionData(),
  });

  const isLoggedIn = q.data !== null || q.error !== null;

  // Not logged in, returning an API client without authentication
  if (!isLoggedIn || q.data === null) {
    return {
      api,
      apiKey,
      isLoggedIn: false,
      features: new Set<GetMe200ResponseFeaturesEnum>(),
    };
  }
  const me = q.data[0];
  const feature = q.data[1];

  return {
    api,
    apiKey,
    features: new Set(
      feature.data.filter((f) => f.identifier).map((f) => f.identifier as FeaturesType)
    ),
    isLoggedIn,
    me,
  };
}
