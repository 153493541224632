import { useTranslation } from "react-i18next";
import { PageLayout } from "~/lib/ui/page-layout";

// Placeholder. There is no form index.
export default function Forms() {
  const { t } = useTranslation();
  return (
    <PageLayout title={t("common:form", { count: 2 })}>
      <p>There is no form index. Use the registration page from projects instead.</p>
    </PageLayout>
  );
}
