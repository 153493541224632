import { t } from "i18next";
import { useState } from "react";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { PreviewFiles } from "./preview-files";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { LabeledTextarea } from "~/lib/ui/form-elements/labeled-textarea";
import { fileListToArray } from "~/lib/utils/utils";

export function CreatePostDialog({
  onClose,
  placeholder,
  onCreatePost,
}: {
  onClose: () => void;
  onCreatePost: (args: { message: string; files: Array<File> }) => Promise<unknown>;
  placeholder: string;
}) {
  const [previews, setPreviews] = useState<FileList>();
  const [message, setMessage] = useState<string>("");

  const nothingToSubmit = message === "" && (!previews || previews.length === 0);

  return (
    <div className="flex flex-col">
      <h3 className="mb-2">{t("projects:feed.create_a_post")}</h3>
      <p className="text-gray-500">{t("projects:feed.wall_posts_are_readable_by")}</p>
      <LabeledTextarea
        tabIndex={1}
        required
        onChange={(e) => setMessage(e.currentTarget.value)}
        placeholder={placeholder}
        className="mb-4 mt-2 block min-h-20 w-full rounded-md border-gray-300 shadow-sm focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm"
      />

      <DNDUpload onSelected={setPreviews} multiple />
      <PreviewFiles files={previews} className="my-5" />

      <DialogFooter
        primary={{
          disabled: nothingToSubmit,
          label: t("projects:feed.post_to_feed"),
          onClick: async () => {
            await onCreatePost({
              message,
              files: fileListToArray(previews),
            });
            onClose();
          },
        }}
        onClose={onClose}
      />
    </div>
  );
}
