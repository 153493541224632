import { ReactNode, useEffect, useRef, useState } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragDropType } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { twMerge } from "tailwind-merge";
import { LabelEntity } from "@apacta/sdk";
import colors from "tailwindcss/colors";
import { Card } from "~/lib/planning";
import Tooltip from "~/lib/ui/tooltip";

export type CardProps = {
  id: string;
  title: string;
  tooltip?: ReactNode;
  date?: Date | null;
  estimate?: number | null;
  address?: string;
  listIndex?: number | null;
  userId?: string | null;
  zip?: number;
  startTime?: Date | null;
  isCollapsed?: boolean;
  onClick?: (cardId: string) => void;
  labels?: Array<LabelEntity>;
  onDrop: (card: Card, details: { index: number }) => void;
  useFullWidth?: boolean;
  useBgColor?: boolean;
};

export function DraggableCard({
  id,
  title,
  tooltip,
  date,
  estimate,
  startTime,
  address,
  listIndex,
  userId,
  zip,
  isCollapsed = false,
  onClick,
  labels,
  onDrop,
  useFullWidth = false,
  useBgColor = false,
}: CardProps) {
  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const [hoverState, setHoverState] = useState<-1 | 0 | 1>(0);

  const [{ isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: Card, monitor) => {
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      if (hoverBoundingRect) {
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Above current card
        if (hoverClientY < hoverMiddleY) {
          handleDrop(item, 0);
          return;
        }

        // Below current card
        if (hoverClientY > hoverMiddleY) {
          handleDrop(item, 1);
          return;
        }

        handleDrop(item, 1);
      }
    },
    hover: (dragItem: Card, monitor) => {
      if (!dropRef.current) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (hoverClientY < hoverMiddleY) {
        setHoverState(-1);
        return;
      }

      // Dragging upwards
      if (hoverClientY > hoverMiddleY) {
        setHoverState(1);
        return;
      }
    },
  }));

  const handleDrop = (item: Card, indexOffset: number) => {
    const sameContainer = item.userId === userId && item.date?.getTime() === date?.getTime();
    const droppedItemIndex = item.listIndex ?? 0;

    const indexWithOffset = (listIndex ?? 0) + indexOffset;

    let newIndex = indexWithOffset;

    if (sameContainer && droppedItemIndex < (listIndex ?? 0)) {
      newIndex = indexWithOffset - 1;
    } else {
      // not same container
      newIndex = indexWithOffset;
    }

    onDrop(item, { index: newIndex });
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: DragDropType,
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: {
        id,
        title,
        date,
        startTime,
        address,
        listIndex,
        userId,
        zip,
        isCollapsed,
        labels,
      },
    }),
    [id, title, date, startTime, address, listIndex, userId, zip, isCollapsed, labels]
  );

  useEffect(() => {
    // this useEffect hides the default preview
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(dragRef);
  drop(preview(dropRef));

  return (
    <div ref={dropRef} className={twMerge(isDragging && "opacity-50")}>
      <div
        className="bg-gray-50 transition-height duration-200 ease-in"
        style={{
          height:
            isOver && hoverState === -1
              ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
              : "0rem",
        }}
      ></div>
      <Tooltip
        disabled={!tooltip || isDragging || isOver}
        delay={500}
        trigger={
          <div
            ref={dragRef}
            onClick={() => onClick?.(id)}
            className={twMerge(
              "flex h-8 shrink-0 cursor-pointer select-none items-center gap-2 overflow-hidden bg-transparent px-2 text-xs hover:bg-black/5",
              useFullWidth ? "w-full" : "w-[calc(10rem-1px)]",
              useBgColor ? "bg-white" : ""
            )}
          >
            <div
              style={{ backgroundColor: labels?.[0]?.bgColor ?? colors["gray"][200] }}
              className={twMerge("h-3 w-3 shrink-0 rounded-full")}
            ></div>
            <div className="flex w-full justify-between">
              <div className="line-clamp-1 text-left">{title}</div>
            </div>
          </div>
        }
      >
        <div className="whitespace-pre-line">{tooltip ?? ""}</div>
      </Tooltip>

      <div
        className="bg-gray-50 transition-height duration-200 ease-in"
        style={{
          height:
            isOver && hoverState === 1
              ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
              : "0rem",
        }}
      ></div>
    </div>
  );
}
